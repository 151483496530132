import {createStore} from 'vuex'
import {setStore,getStore} from "@/config/storage";

// 创建一个新的 store 实例
const store = createStore({
    state() {
        return {
            showModal: false,
            showManyModal: false,
            valiModal: false,
            alreadyModal: false,
            applyModal: false,
            tranModal: false,
            statusModal: false,
            comingModal: false,
            showModalTele: false,
            wallNameSet:getStore('wallName')||'',
            walletStatus: getStore('walletStatus')||-1, // -2 未安装 | -1 已安装，未连接 | 1 正常连接
            isWalletStatus:getStore('isWalletStatus')||false,
            walletAddress: getStore('walletAddress'),
            GivenProvider: getStore('GivenProvider')||'',
            actionStatus:1,// 1 pending 2 success 3 error
            tranStatus:3,//1 Error 2 Successful 3 Pending
            walletInfo: {
                code:null,
                account: ''
            },
            chainName:process.env.VUE_APP_BASE_CHAINNAME
        }
    },
    getters: {
        showModal: (state) => state.showModal,
        showManyModal: (state) => state.showManyModal,
        valiModal: (state) => state.valiModal,
        alreadyModal: (state) => state.alreadyModal,
        applyModal: (state) => state.applyModal,
        tranModal: (state) => state.tranModal,
        statusModal: (state) => state.statusModal,
        walletInfo: (state) => state.walletInfo,
        walletAddress: (state) => state.walletAddress,
        walletStatus: (state) => state.walletStatus,
        actionStatus: (state) => state.actionStatus,
        tranStatus: (state) => state.tranStatus,
        comingModal: (state) => state.comingModal,
        showModalTele: (state) => state.showModalTele,
        isWalletStatus: (state) => state.isWalletStatus,
        GivenProvider: (state) => state.GivenProvider,
        chainName: (state) => state.chainName,
        wallNameSet: (state) => state.wallNameSet,
    },
    mutations: {
        //coming Soon
        isShowModalTele(state, status) {
            console.log("----", status)
            state.showModalTele = status
        },
        //coming Soon
        iscomingModal(state, status) {
            console.log("----", status)
            state.comingModal = status
        },
       //状态值
         //状态值
        tranStatus(state, status) {
            console.log("----", status)
            state.tranStatus = status
        },
       //状态值
        isActionStatus(state, status) {
            console.log("----", status)
            state.actionStatus = status
        },
        //没有PNN-NFT
          //登录
        isShoModal(state, Blo) {
            console.log("----", Blo)
            state.showModal = Blo
        },
        //没有PNN-NFT
        showManyModal(state, Blo) {
            console.log("----", Blo)
            state.showManyModal = Blo
        },
        //没有PNN-NFT
        isValiModal(state, Blo) {

            state.valiModal = Blo
        },
        //已是验证者
        isAlreadyModal(state, Blo) {
            state.alreadyModal = Blo
        },
        //申请验证者
        isApplyModal(state, Blo) {
            state.applyModal = Blo
        },
        //申请状态
          isTranModal(state, Blo) {
            state.tranModal = Blo
              if(Blo === true){
                  setTimeout(()=>{
                      state.tranModal = false
                      state.tranStatus = 3
                  },3000)

              }
        },
        //公共请求状态
        statusModal(state, Blo) {
            state.statusModal = Blo
        },
        getWalletInfo(state, walletInfo) {
            state.walletInfo = walletInfo
        },
        updateWalletStatus(state, data) {
            state.walletStatus = data.code;
            if (data.code === 1) {
                state.walletAddress = data.account;
            }else {
                state.walletAddress = '';
            }
            setStore('walletStatus', data.code)
            setStore('walletAddress', data.account)
        },
        isUupdateWalletStatus(state, data) {
            state.isWalletStatus = data;
            setStore('isWalletStatus',data)
        },
        wallName(state, data) {
            state.wallNameSet = data;
            setStore('wallName',data)
        },
        isGivenProvider(state, data) {
            state.GivenProvider = data;
            setStore('GivenProvider',data)
        },
    }
})


export default store
