<template>
  <div class="conts">
    <div class="ti-card" id="Proposals" data-aos="fade-down"
         data-aos-easing="linear">
      <h5 class="cand-ti">{{ $t(`message.Proposals`) }}</h5>
      <div>
        <div class="pro-ul" @click="propoListClick(item)" v-for="(item,index) in proposalsList" :key="index">
          <div class="ul-lef">
            <div class="tit-pro">#{{ item.id }}. {{ item.title }}</div>
            <div>
              <div class="prog-ess">
                <!--                  VT(item)-->
                <n-progress
                    type="line"
                    :percentage="VT(item.vtScale)"
                    :height="22"
                    :show-indicator="false"
                    :rail-color="railColr"
                    :border-radius="4"
                    :fill-border-radius="4"
                />
                <span>Total VT: {{ item.numberOfVote }} ({{ item.vtScale }})</span>
              </div>
              <div class="prog-ess">
                <!--                  Supports(item)-->
                <n-progress
                    type="line"
                    :percentage="Supports(item.supportScale)"
                    :height="22"
                    :show-indicator="false"
                    :rail-color="railColr"
                    :border-radius="4"
                    :fill-border-radius="4"
                />
                <span>Total VT: {{ item.numberOfSupport }} ({{ item.supportScale }})</span>
              </div>
            </div>
          </div>
          <div class="ul-rig">
            <!-- 投票期的提案-->
            <div v-if="item.status===2" class="rig-txt active">{{ $t(`message.Active`) }}</div>
            <!--Pending公示期的提案 Executed已经执行的提案-->
            <div v-if="item.status===1" class="rig-txt pending">{{ $t(`message.Pending`) }}</div>
            <div v-if="item.status===3" class="rig-txt pending">{{ $t(`message.Executed`) }}</div>
            <!--Invalid  通过但没能按时执行的提案  Failed没有通过的提案-->
            <div v-if="item.status===4" class="rig-txt invalid">{{ $t(`message.Invalid`) }}</div>
            <div v-if="item.status===6" class="rig-txt invalid">{{ $t(`message.Failed`) }}</div>
            <!--执行期（还未执行）的提案-->
            <div v-if="item.status===5" class="rig-txt active">{{ $t(`message.Passed`) }}</div>
            <div class="gin" v-if="item.isCore">
              <div class="nupod PDPColor core">{{ $t(`message.Core`) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import foot from "@/components/foot";
import {totalSupply} from "@/utils/contract/validator";
import {proposalList} from "@/utils/contract/governor";

export default {
  name: "proposals-1",
  components: {foot},
  data() {
    return {
      railColr: 'rgba(255, 157, 0, 0.12)',
      proposalsList: [],
    }
  },
  computed: {
    Supports() {
      return function (item) {
        const num = item ? item : '0'
        const obj = num.split('%')
        return Number(obj[0])
        // return ((item.Support / item.SupportTotal) * 100).toFixed(2)
      }
    },
    VT() {
      return function (item) {
        const num = item ? item : '0'
        const obj = num.split('%')
        return Number(obj[0])
        // return ((item.numberOfSupport / item.numberOfVote) * 100).toFixed(2)
      }
    },
  },
  async mounted() {
    const VTTotal = await totalSupply();
    const list = await proposalList(VTTotal);
    const {ids} = this.$route.query;
    const idArr = ids?.length > 0 ? ids.split(',') : [];
    if (idArr.length > 0) {
      this.proposalsList = list.filter((v) => idArr.includes(v.id));
    } else {
      this.proposalsList = list;
    }
    console.log("==wwww==", list)
  },
  methods: {
    propoListClick({id}) {
      // this.$router.push('/detail')
      this.$router.push({path: '/detail', query: {id}});
    },
  }
}
</script>

<style scoped lang="less">
.conts {
  color: #ffffff;
  background: #17181A;
  background: url("../../assets/PEGO/bgjojn.svg") no-repeat 100%, url("../../assets/PEGO/bag1.svg") no-repeat;
  background-position: right 30px bottom 0, left 0;
  background-size: 672px 672px, 800px;
  box-sizing: border-box;

}

.ti-card {
  :deep(.n-progress-graph-line-fill){
  ///deep/ .n-progress-graph-line-fill {
    background: linear-gradient(90.05deg, #FF9839 17.92%, #FFD02B 64.69%) !important;
  }

  text-align: left;
  padding: 0 100px;

  .cand-ti {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    display: inline-block;
    font-family: BOLD;
  }

  .pro-ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    padding: 10px 30px 15px;
    height: 139px;
    box-sizing: border-box;
    margin-bottom: 30px;

    .ul-lef {
      width: 80%;

      .tit-pro {
        font-weight: 700;
        font-size: 25px;
        font-family: BOLD, sans-serif;
      }

      .prog-ess {
        position: relative;
        height: 100%;

        span {
          position: absolute;
          left: 10px;
          top:0;
          z-index: 2;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          height: 100%;
          line-height: 22PX;
          display: inline-block;
        }
      }

      .prog-ess:nth-child(1) {
        margin: 8px 0 12px;
      }
    }

    .ul-rig {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      width: 10%;
      padding: 10px 0 0;
      box-sizing: border-box;

      .rig-txt {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }

      .active {
        color: #43FFAA;
      }

      .pending {
        color: #299CFF;
      }

      .invalid {
        color: #FA7977;
      }


      .core {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .gin {
    display: flex;
    align-items: center;
  }

  .nupod {
    background: transparent;
    //text-transform: uppercase;
    color: white;
    //padding: 15px 50px;
    overflow: hidden;
    position: relative;
    border-radius: 33px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    transition: .3s;
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid;
    outline-color: rgba(255, 255, 255, 0.5);
    outline-offset: 0px;
    text-shadow: none;
    -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  }

  .nupod:after {
    content: '';
    display: block;
    position: absolute;
    top: -36px;
    left: -100px;
    background: white;
    width: 50px;
    height: 125px;
    opacity: 20%;
    transform: rotate(-45deg);
  }

  .nupod:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px #FFB800;
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 20px;
    text-shadow: 1px 1px 2px #427388;
  }

  .nupod:hover:after {
    left: 120%;
    transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
    -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
  }

  .nupod:active {
    border: none;
    background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    transition: .6s; /*取消过渡*/
    box-shadow: inset 0 0 20px #FF7A00,
    0 0 20px #FFB800;
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388;
  }

  .PDPColor {
    border: none;
    color: #ffffff;
    background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
  }


}
</style>
