<template>
  <div class="hello">
    <div class="imgs">
      <!--      <img src="@/assets/PEGO/logo1.png" alt="LOGO">-->
      <img src="@/assets/PEGO/PEGO.png" @click="$router.push('/')" alt="LOGO">
    </div>
    <div class="rg-li">
      <n-config-provider :theme-overrides="themeOverrides">
        <n-menu class="menus" v-model:value="paths" inverted="true" mode="horizontal"
                @update:value="updates"
                :showOption="paths"
                :options="menuOptions"/>
      </n-config-provider>
      <div>
        <!--        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
        <div class="ans-t dists">
          |&nbsp;&nbsp;&nbsp;
          <img src="@/assets/PEGO/languageIcon.svg" alt="">
          <n-config-provider :theme-overrides="themeOverrides">
            <n-menu class="" v-model="languageShow" :default-value="languageShow" inverted="true" mode="horizontal"
                    @update-value="showOption"
                    ref="menuInstRef"
                    :options="languageListMenu"/>
          </n-config-provider>
        </div>
      </div>
      <!--
      <div class="but-ul">
        <n-button v-if="!isGivenProvider" @click="providerClick" round color="#FF7A00" class="PDP but-plain" ghost :bordered="false">
          <span class="err">{{ $t(`message.NetworkError`) }}</span>
        </n-button>
        <n-button v-else style="cursor: not-allowed;" round color="#FF7A00" class="PDP but-plain nots" ghost :bordered="false">
          <img src="@/assets/ecological/logo.svg" alt="">
          {{ chainName }}
        </n-button>
        <n-button @click="isModalMeta"  round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff"
                  :bordered="false">
          {{ walletAddress ? $Public.account(walletAddress) : $t(`message.linkWallet`) }}
        </n-button>
      </div>
      -->
    </div>
  </div>

</template>

<script>
import {NConfigProvider} from 'naive-ui'
import {RouterLink} from "vue-router";
import {h} from 'vue';
import {NIcon} from "naive-ui";
import {
  ChevronDown
} from "@vicons/ionicons5";
import images from '../assets/PEGO/w3wrllet.svg'
import metamaskImg from '../assets/PEGO/metamask.svg'
import tok from '../assets/PEGO/tok.svg'
import bit from '../assets/PEGO/bitget-wallet.png'
import okxWallet from '../assets/PEGO/okx-full.png'
import {connect} from "@/utils/web3";
import api from "@/api/api"
import {removeStore} from "@/config/storage";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: {
    NConfigProvider,
  },
  data() {
    return {
      activeKey: '',
      themeOverrides: {
        "Dropdown": {
          "color": "#211B1BFF",
          "textColor": "#FFFFFF",
          "optionTextColor": "#FFF",
          "optionTextColorHover": "rgba(111, 120, 118, 1)",
          "optionTextColorActive": "#E2B163FF",
          "optionColorHoverInverted": "rgba(111, 120, 118, 1)",
          "optionColorActiveInverted": "",
          "optionTextColorActiveInverted": "#FFB526",
          "optionTextColorHoverInverted": "#FFF",
          "optionHeightMedium": "48px",
          "optionHeightLarge": "48px",
        },
        "Menu": {
          'itemTextColorActiveHorizontalInverted': "#FFB526",
          'itemTextColorHoverHorizontalInverted': "#FFB526",
          'itemTextColorChildActiveHorizontalInverted': "#FFB526",
          'itemTextColorActiveHoverHorizontalInverted': "rgba(255,181,38,0.5)",
          'itemTextColorChildActiveHoverHorizontalInverted': "rgba(255,181,38,0.5)",
        },
        "Popover": {
          "color": "#211B1BFF",
          "textColor": "#FFFFFF",
        },
        "Select": {
          "textColor": "#FFFFFF",
          "color": "#FFFFFF",
        }
      },
      activeIndex: '1',
      scroll: '',
      timer: '',
      seen: true,
      languageShow: this.$i18n.locale,
      menuOptions: [
        {
          label: () => h(
              RouterLink,
              {
                to: {
                  path: "/",
                  // params: {
                  //   lang: "zh-CN"
                  // }
                }
              },
              {default: () => this.$t(`message.Product`)}
          ),
          key: "/",
        },
        {
          label: () => h('div', {
            style: {
              display: 'flex',
              alignItems: 'center',
            }
          }, [
            h('span', null, {default: () => this.$t(`message.Network`)}),
            h(NIcon, null, {default: () => h(ChevronDown)})
          ]),
          key: "2",
          children: [
            {
              label: () => h(
                "a",
                {
                  href: "https://dapp.pego.network/",//桥
                  target: "_blank",
                  rel: "noopenner noreferrer"
                },
                {default: () => this.$t(`message.PEGOScan`)}
              ),
              key: "Validator",
            },
            {
              label: () => h(
                "a",
                {
                  href: "https://dapp.pego.network/dao",//桥
                  target: "_blank",
                  rel: "noopenner noreferrer"
                },
                {default: () => this.$t(`message.Proposal`)}
              ),
              key: "DAO",
            },
            {
              label: () => h(
                  RouterLink,
                  {
                    to: {
                      path: "/Eco",//生态
                      // params: {
                      //   lang: "zh-CN"
                      // }
                    }
                  },
                  {default: () => this.$t(`message.Build`)}
              ),
              key: "Eco",
            },
          ]
        },
        {
          label: () => h(
              "a",
              {
                href: "https://www.w3bridge.net",//桥
                target: "_blank",
                rel: "noopenner noreferrer"
              },
              {default: () => this.$t(`message.bridge`)}
          ),
          key: "bridge",
        },
        {
          label: () => h(
              "a",
              {
                href: "https://scan.pego.network/",//浏览器
                target: "_blank",
                rel: "noopenner noreferrer"
              },
              {default: () => this.$t(`message.browser`)}
          ),
          key: "browser",
        },
        {
          label: () => h('div', {
            style: {
              display: 'flex',
              alignItems: 'center',
            }
          }, [
            h('span', {}, {default: () => this.$t(`message.wallet`)}),
            h(NIcon, null, {default: () => h(ChevronDown)})
          ]),
          key: "4",
          // icon: renderIcon(BookIcon),
          children: [
            {
              label: () => h('div', {}, [
                    h('img', {
                      src: images,
                      style: {
                        width: "106px",
                        verticalAlign: "middle"
                      },
                      attrs: {
                        src: images
                      }
                    }),
                  ]
              ),
              key: "W3WRLLET",
            },
            {
              label: () => h('div', {}, [
                    h('img', {
                      src: bit,
                      style: {
                        width: "106px",
                        verticalAlign: "middle"
                      },
                      attrs: {
                        src: bit
                      }
                    }),
                  ]
              ),
              key: "BitKeep",
            },
            {
              label: () => h('div', {}, [
                    h('img', {
                      src: metamaskImg,
                      style: {
                        width: "106px",
                        verticalAlign: "middle"
                      },
                      attrs: {
                        src: metamaskImg
                      }
                    }),
                  ]
              ),
              key: "METAMASK",
            },
            {
              label: () => h('div', {}, [
                    h('img', {
                      src: tok,
                      style: {
                        width: "106px",
                        verticalAlign: "middle"
                      },
                      attrs: {
                        src: tok
                      }
                    }),
                  ]
              ),
              key: "POCKET",
            },
            {
              label: () => h('div', {}, [
                    h('img', {
                      src: okxWallet,
                      style: {
                        width: "40px",
                        verticalAlign: "middle",
                      },
                      attrs: {
                        src: okxWallet
                      }
                    }),
                  ]
              ),
              key: "OKXWallet",
            },
          ]
        },

        {
          label: () => h('div', {
            style: {
              display: 'flex',
              alignItems: 'center',
            }
          }, [
            h('span', null, {default: () => this.$t(`message.other`)}),
            h(NIcon, null, {default: () => h(ChevronDown)})
          ]),
          key: "5",
          children: [
            // {
            //   label: () => h(
            //       RouterLink,
            //       {
            //         to: {
            //           path: "/blogLatest",//新闻
            //           params: {
            //             lang: "zh-CN"
            //           }
            //         }
            //       },
            //       {default: () => this.$t(`message.news`)}
            //   ),
            //   key: "news",
            // },
            {
              label: () => h(
                  RouterLink,
                  {
                    to: {
                      path: "/Strategies",//路线图
                      // params: {
                      //   lang: "zh-CN"
                      // }
                    }
                  },
                  {default: () => this.$t(`message.routeMap`)}
              ),
              key: "routeMap",
            },
            {
              label: () => h(
                  RouterLink,
                  {
                    to: {
                      path: "/Foundation",//基金会
                      // params: {
                      //   lang: "zh-CN"
                      // }
                    }
                  },
                  {default: () => this.$t(`message.foundation`)}
              ),
              key: "Foundation",
            },
          ]
        },

      ],
      languageListMenu: [
        {
          label: () => h('div', {
            style: {
              display: 'flex',
              alignItems: 'center',
            }
          }, [
            // h('span', {}, {default: () => this.languageName}),
            // h('img', {
            //   style: {
            //     width: "30px",
            //     verticalAlign: "text-top"
            //   },
            //   attrs: {
            //     src: require("../assets/PEGO/languageIcon.svg")
            //   }
            // },)
          ]),
          key: "23",
          children: [
            {id: 2, label: this.$t('message.EN'), key: 'en-US'},
            {id: 1, label: this.$t('message.ZN'), key: 'zh-CN'},
            {id: 3, label: this.$t('message.JP'), key: 'ja-JP'},
            {id: 4, label: this.$t('message.KO'), key: 'ko-KR'},
            {id: 5, label: this.$t('message.TH'), key: 'th-TH'},
            {id: 6, label: this.$t('message.VI'), key: 'vi-VN'}
          ]
        },

      ],
      languageList: [
        {id: 2, label: this.$t('message.EN'), key: 'en-US'},
        {id: 1, label: this.$t('message.ZN'), key: 'zh-CN'},
        {id: 3, label: this.$t('message.JP'), key: 'ja-JP'},
        {id: 4, label: this.$t('message.KO'), key: 'ko-KR'},
        {id: 5, label: this.$t('message.TH'), key: 'th-TH'},
        {id: 6, label: this.$t('message.VI'), key: 'vi-VN'}
      ],


    }
  },
  async mounted() {
    this.languageShow = this.$i18n.locale
  },
  watch: {
    activeKey(val, old) {
      this.activeKey = val
      console.log("", old)
      // if(val!==old){
      //   this.paths(val)
      // }
    },
    watch: {
      $route: {
        handler(val) {
          this.$route = val
        },
        // 深度观察监听
        deep: true
      }
    }
  },
  computed: {
    menuList() {
      return [
        {
          path: '/',
          key: "/",
        },
        {
          path: "/Validator",//节点竞选,
          key: "Validator",
        },
        {
          path: "/DAO",
          key: "DAO",
        },
        {
          path: "/Eco",
          key: "Eco",
        },
        {
          path: "/blogLatest",
          key: "news",
        },
        {
          path: "/Strategies",
          key: "routeMap",
        },
        {
          path: "/Foundation",
          key: "Foundation",
        },
      ]
    },
    paths() {
      const hash = this.$route.path || window.location.hash
      const index = hash.indexOf("#")
      if (this.activeKey) {
        const menuList = this.menuList.find(item => item.key === this.activeKey)
        if (menuList) {
          if (menuList.path === index) {
            return menuList.key
          } else {
            const keys = this.menuList.find(item => item.path === hash.substr(index + 1, hash.length))
            return keys ? keys.key : ''
          }
        } else {
          const keys = this.menuList.find(item => item.path === hash.substr(index + 1, hash.length))
          return keys ? keys.key : ''
        }
      } else {
        const menuList = this.menuList.find(item => item.path === hash.substr(index + 1, hash.length))
        return menuList ? menuList.key : ''
      }
    },
    languageName() {
      const obj = this.languageList.find(item => item.key === this.$i18n.locale)
      return obj.key

    },
    walletAddress() {
      return this.$store.state.walletAddress
    },
    chainName() {
      return this.$store.state.chainName
    },
    isGivenProvider() {
      return this.$store.state.GivenProvider
    },
  },
  methods: {
    async isModalMeta() {
      let that = this
      if (!this.walletAddress) {
        // await that.$store.commit('isShoModal', true)
        this.$store.commit('showManyModal',true)
        await this.apply()
      } else {
        await that.$store.commit('updateWalletStatus', {code: -1, account: ""});
        await that.$store.commit('isUupdateWalletStatus', false);
        // 暂时隐藏
        removeStore('PEGO_TOKEN')
        //节点领取6-8
        await api.outLogin()
        await window.location.reload()
      }

    },
    // providerClick(){
    //   connect().then(res=>{
    //     console.log('e,',res)
    //   }).catch(e=>{
    //     console.log(e)
    //   })
    // },
    changeLanguage(item) {
      sessionStorage.setItem('lang', item.key)
      this.$i18n.locale = item.key

    },
    updates(ins) {
      console.log("-----updates", ins)
      this.activeKey = ins
      if (ins === 'METAMASK') {
        // this.$store.commit('isShoModal', true)
        window.open('https://metamask.io/')
      } else if (ins === 'W3WRLLET') {
        window.open('https://www.w3wallet.link/#/')
      } else if (ins === 'POCKET') {
        window.open('https://www.tokenpocket.pro/')
      } else if (ins === 'BitKeep') {
        window.open('https://web3.bitget.com/')
      } else if (ins === 'OKXWallet') {
        window.open('https://www.okx.com/web3')
      }
      // else if (ins === 'bridge' && !this.walletAddress) {
      //   this.$store.commit('iscomingModal', true)
      // }
    },
    showOption(key) {
      sessionStorage.setItem('lang', key)
      this.$i18n.locale = key
      window.location.reload()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

///deep/ .n-menu-item-content-header {
:deep(.n-menu-item-content-header) {
  --n-font-size: 14px !important;
  font-family: 'SEMIBOLD';
  font-size: 14px !important;
  overflow: visible !important;
  width: 100% !important;
  text-overflow: ellipsis;

}

///deep/ .n-menu-item {
:deep(.n-menu-item) {
  padding: 0;
}

.n-menu-item-content-hover {


}

///deep/ .n-dropdown {
:deep(.n-dropdown ){
  font-family: 'SEMIBOLD';
  //font-size: 14px !important;
  //top: 300px !important;
}


.hello {
  background: rgba(21, 22, 23, 1);
  width: 100%;
  height: 75px;
  line-height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;

  .rg-li {
    display: flex;
    align-items: center;
    margin-right: 50px;
    font-family: 'LIGHT';

    .ans-t {
      width: 100%;
      white-space: nowrap;
      position: relative;

      img {
        width: 20px;
        object-fit: contain;
        position: absolute;
        right: 15px;
      }

    }

    .dists {
      display: flex;
      align-items: center;
    }


    span {
      cursor: pointer;
      font-family: 'SEMIBOLD';
      font-size: 14px;

      &:hover {
        background-image: linear-gradient(to right, #FF9D00, #FF9D00);
        -webkit-background-clip: text;
        color: transparent;
      }
    }
  }

  .imgs {
    padding-left: 95px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  img {
    //width: 100%;
    height: 36px;
    width: 222.18px;
    object-fit: contain;

  }

}

.cors {
  color: #FFB526;
  position: relative;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}


#menu_index {
  float: right;
  height: 100%;
}

.header-title {
  font-size: 1em;
}

.header-title:hover {
  background: none !important;
  color: #FF9D00 !important;
}


.span-img {
  display: flex;
  align-items: center;
}

.icon-img {
  width: 12px !important;
  margin-left: 3px;

}

.but-ul {
  display: flex;
  align-items: center;
  .err{
    color:#FF706A
  }

  .buts {
    background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    border-radius: 33px;
    border: none !important;
    color: #FFFFFF;
    margin-right: 20px;
    font-family: 'RREGULAR', sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 34px;
    line-height: 34px;
    min-height: 15PX;
    font-size: 10px !important;
    padding: 20px 40px;
    box-sizing: border-box;
  }

  button:active {
    animation: zoomOut; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.5s;
  }

  .but-plain {
    border-radius: 33px;
    background: transparent;
    color: #FFFFFF;
    font-family: 'RREGULAR', sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 34px;
    line-height: 34px !important;
    min-height: 15PX;
    padding: 20px;
    box-sizing: border-box;
    font-size: 10px !important;
    overflow: hidden;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid rgba(255, 255, 255, 0.5);
    outline-offset: 0px;
    text-shadow: none;
    -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    margin-right: 10px;

    img {
      width: 20px;
      object-fit: contain;
      margin-right: 5px;
    }
  }

  .but-plain:after {
    content: '';
    display: block;
    position: absolute;
    top: -36px;
    left: -100px;
    background: white;
    width: 50px;
    height: 125px;
    opacity: 20%;
    transform: rotate(-45deg);
  }

  .but-plain:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px #FFB800;
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 20px;
    text-shadow: 1px 1px 2px #427388;
  }

  .but-plain:hover:after {
    left: 120%;
    transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
    -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
  }


  .nots:active{
    animation: ''!important; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0s;
  }
}
</style>

