<template>
  <div class="conts">
    <div class="ti-card" id="Proposals" data-aos="fade-down"
         data-aos-easing="linear">
      <h5 class="cand-ti">{{ $t(`message.Proposals`) }}</h5>
      <div>
        <div class="pro-ul">
          <div class="ul-lef">
            <div class="tit-pro">#{{ detailData.id }}. {{ detailData.title }}</div>
            <div>
              <div class="lin-t">
                <div class="detail">{{ $t(`message.Detail`) }}: <span v-html="detailData.desc"></span></div>
                <div class="ling">{{ $t(`message.Sponsor`) }}: <span>{{ detailData.sponsor }}</span></div>
                <div class="ling">{{
                    $t(`message.StartTime`)
                  }}:{{ $Public.timeStampToDate(detailData.startDate) }}
                </div>
                <div class="ling">{{
                    $t(`message.VoteTime`)
                  }}:{{ $Public.timeStampToDate(detailData.publicityEndDate) }}~
                  {{ $Public.timeStampToDate(detailData.votingEndDate) }}
                </div>
              </div>
              <div class="prog-ess">
                <n-progress
                    type="line"
                    :percentage="VT(detailData.vtScale)"
                    :height="43"
                    :show-indicator="false"
                    :rail-color="railColr"
                    :border-radius="4"
                    :fill-border-radius="4"
                />
                <span>{{ $t(`message.TotalVT`) }}: {{ detailData.VTTotal }} ({{ detailData.vtScale }})</span>
              </div>
              <div class="votes">
                <div>{{ $t(`message.TotalVotes`) }}:{{ detailData.numberOfVote }}</div>
                <div>{{ $t(`message.TotalVoteToken`) }}:{{ detailData.VTTotal }}</div>
              </div>
              <div class="tet-s">
                <div class="sup">
                  <div class="prog-ess ">
                    <n-progress
                        type="line"
                        :percentage="Supports(detailData.supportScale)"
                        :height="43"
                        :show-indicator="false"
                        :rail-color="railColr"
                        :border-radius="4"
                        :fill-border-radius="4"
                    />
                    <span><img src="@/assets/PEGO/Thumbs-up.svg" alt=""> {{
                        $t(`message.Supports`)
                      }}:  ({{ detailData.supportScale }})</span>
                  </div>
                </div>

                <div class="anst">
                  <div class="prog-ess ">
                    <n-progress
                        type="line"
                        :percentage="Supports(detailData.againstScale)"
                        :height="43"
                        :show-indicator="false"
                        :rail-color="railColr"
                        :border-radius="4"
                        :fill-border-radius="4"
                    />
                    <span>{{ $t(`message.Against`) }}: ({{ detailData.againstScale }})<img src="@/assets/PEGO/alst.svg" alt=""></span>
                  </div>
                </div>
              </div>
              <div class="votes">
                <div>{{ $t(`message.Supports`) }}:{{ detailData.numberOfSupport }}</div>
                <div>{{ $t(`message.Against`) }}:{{ detailData.numberOfAgainst }}</div>
              </div>
            </div>
          </div>
          <div class="ul-rig">
            <!-- 投票期的提案-->
            <div v-if="detailData.status===2" class="rig-txt active">{{ $t(`message.Active`) }}</div>
            <!--Pending公示期的提案 Executed已经执行的提案-->
            <div v-if="detailData.status===1" class="rig-txt pending">{{ $t(`message.Pending`) }}</div>
            <div v-if="detailData.status===3" class="rig-txt pending">{{ $t(`message.Executed`) }}</div>
            <!--Invalid  通过但没能按时执行的提案  Failed没有通过的提案-->
            <div v-if="detailData.status===4" class="rig-txt invalid">{{ $t(`message.Invalid`) }}</div>
            <div v-if="detailData.status===6" class="rig-txt invalid">{{ $t(`message.Failed`) }}</div>
            <!--执行期（还未执行）的提案-->
            <div v-if="detailData.state===5" class="rig-txt active">{{ $t(`message.Passed`) }}</div>
          </div>
          <div class="grids">
            <!-- Pending -->
            <div class="gin" v-if="detailData.status===1">
              <div class="nupod  core PDPColorS">{{ $t(`message.Supports`) }}</div>
            </div>
            <!-- Pending -->
            <div class="gin" v-if="detailData.status === 1">
              <div class="nupod  core PDPColorS">{{ $t(`message.Against`) }}</div>
            </div>
            <!-- Active -->
            <div class="gin" v-if="detailData.status===2">
              <template v-if="+voteInfo.voteAmount === 0">
                <div @click="voteDialog = true;type = 1" class="nupod  core PDPColor">{{ $t(`message.Supports`) }}</div>
              </template>
              <template v-else>
                <div class="nupod  core PDPColorS">{{ $t(`message.Supports`) }}</div>
              </template>
            </div>
            <!-- Active -->
            <div class="gin" v-if="detailData.status ===2">
              <template v-if="+voteInfo.voteAmount === 0">
                <div @click="voteDialog = true;type = 0" class="nupod  core PDPColor">{{ $t(`message.Against`) }}</div>
              </template>
              <template v-else>
                <div class="nupod  core PDPColorS">{{ $t(`message.Against`) }}</div>
              </template>
            </div>
            <!-- Executed Invalid -->
            <div class="gin" v-if="detailData.status === 3 || detailData.status === 4 || detailData.status === 6">
              <div v-if="!voteInfo.drawback && +voteInfo.voteAmount > 0" class="nupod PDPColor core" @click="back">
                {{ $t(`message.GetBackVT`) }}
              </div>
              <div v-else class="nupod PDPColorS core">{{ $t(`message.GetBackVT`) }}</div>
            </div>
            <!-- Passed -->
            <div class="gin" v-if="detailData.status===5">
              <div v-if="!voteInfo.drawback && +voteInfo.voteAmount > 0" class="nupod PDPColor core" @click="back">
                {{ $t(`message.GetBackVT`) }}
              </div>
              <div v-else class="nupod PDPColorS core">{{ $t(`message.GetBackVT`) }}</div>
              <div class="gin">
                <template v-if="isSponsor">
                  <div v-if="!detailData.executed" class="nupod PDPColor core" @click="execute">{{
                      $t(`message.Execute`)
                    }}
                  </div>
                  <div v-else class="nupod PDPColorS core">{{ $t(`message.Execute`) }}</div>
                </template>
              </div>

            </div>
            <!-- 取回质押的PG -->
            <template v-if="isShow && isSponsor">
              <div class="nupod PDPColor core" @click="execute">{{ $t(`message.GetBackPG`) }}</div>
            </template>
          </div>
        </div>
      </div>

      <!--    给节点投票-->
      <modals :LoadingShow="voteDialog" @OnUpdate="voteDialog=!voteDialog,voteValue = ''" :wids="3">
        <template #default>
          <h1> {{ $t(`message.Vote`) }}</h1>

<!--          <div class="but-s sns">-->
<!--            <label for="" class="labe">-->
<!--              {{ $t(`message.ValidatorAddress`) }}-->
<!--            </label>-->
<!--            <n-config-provider :theme-overrides="themeOverrides">-->
<!--              <n-input v-model:value="voteValue" type="text" size="large" placeholder="Address"/>-->
<!--            </n-config-provider>-->
<!--          </div>-->
          <div class="but-s ">
            <label for="" class="labe" v-if="type === 1">
              Support This Proposal
            </label>
            <label for="" class="labe" v-else >
              Against This Proposal
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <n-space vertical>
                <div style="position: relative" class="pgvtMax">
                  <n-input-number v-model:value="voteValue" :show-button="false"
                                  :placeholder="$t(`message.pleaseEnter`)">
                  </n-input-number>
                  <span class="cor pgvt-span">
                VT
              </span>
                  <span class="max-span" @click="voteValue = vtBalance">
                {{ $t(`message.MAX`) }}
              </span>
                </div>

              </n-space>
            </n-config-provider>
            <div class="bal-rig">
              {{ $t(`message.Balance`) }}: {{ Number(vtBalance).toFixed(0) }}
            </div>
          </div>
          <div class="mod-but pgBut" v-if="type === 1">
            <n-button round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
                      @click="handleClick" v-if="voteValue > 0&&voteValue<=vtBalance">
              {{ $t(`message.Supports`) }}
            </n-button>
            <n-button v-else round color="#FF7A00" style="cursor: not-allowed; color: rgba(255,255,255,0.7);"
                      class="PDP buts"
                      ghost text-color="#ffffff" :bordered="false"
            >
              {{ $t(`message.Supports`) }}
            </n-button>
          </div>
           <div class="mod-but pgBut" v-else>
            <n-button round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
                      @click="handleClick" v-if="voteValue > 0&&voteValue<=vtBalance">
              {{ $t(`message.Against`) }}
            </n-button>
            <n-button v-else round color="#FF7A00" style="cursor: not-allowed; color: rgba(255,255,255,0.7);"
                      class="PDP buts"
                      ghost text-color="#ffffff" :bordered="false"
            >
              {{ $t(`message.Against`) }}
            </n-button>
          </div>
        </template>
      </modals>

    </div>
    <foot></foot>
  </div>
</template>

<script>
import foot from "@/components/foot";
import {
  claimVoteToken, executeProposal, vote, proposalList, voteInfo,
} from '@/utils/contract/governor';
import {balanceOf, totalSupply} from '@/utils/contract/validator';
// import {init} from '@/utils/web3';
import api from '@/api/api';
import modals from "@/components/modals.vue"
// import {getStore} from "@/config/storage";

export default {
  name: "detail-1",
  components: {foot,modals},
  data() {
    return {
      walletInfo: {},
      voteDialog: false,
      type: 1, // 1 支持 0 反对
      voteValue: '',
      vtBalance: 0,
      /**
       * state
       * 0 none
       * 1 Pending  period of public
       * 2 Active   period of voting
       * 3 Executed proposal passed and been executed success
       * 4 Invalid  proposal passed but not been executed in valid period
       * 5 Passed   proposal passed
       * 6 Failed   proposal not passed
       */
      detailData: {
        status: 2,
      },
      voteInfo: {
        voteAmount: 0,
      },
      railColr: 'rgba(255, 157, 0, 0.12)',
      themeOverrides: {
        "Dialog": {
          "textColor": "rgba(255, 255, 255, 1)",
          "color": "rgba(56, 63, 70, 0.7)",
          "closeColorPressed": "rgba(196, 15, 15, 0.13)",
          "closeColorHover": "rgba(35, 2, 2, 0)",
          "closeIconColor": "rgba(255, 255, 255, 1)",
          "closeIconColorHover": "rgba(230, 146, 30, 1)",
          "iconColorInfo": "#7398C4FF",
          "borderRadius": "15px",
        },
        "Dropdown": {
          "color": "#211B1BFF",
          "textColor": "#F9F6F6FF",
          "optionTextColor": "#FFF",
          "optionTextColorHover": "rgba(111, 120, 118, 1)",
          "optionTextColorActive": "#E2B163FF",
          "optionColorHoverInverted": "rgba(111, 120, 118, 1)",
          "optionColorActiveInverted": "",
          "optionTextColorActiveInverted": "#FFB526",
          "optionTextColorHoverInverted": "#FFF",
          "optionIconSuffixWidthHuge": "100px",
        },
        "Input": {
          "color": "#222427",
          "placeholderColor": "rgba(237, 228, 228, 1)",
          "caretColor": "#F1ECE8FF",
          "textColor": "rgba(241, 245, 249, 1)",
          "colorFocus": "#222427",
          "border": "1.5px solid rgba(255, 255, 255, 0.3)",
          "borderHover": "1px solid #fff",
          "borderFocus": "1px solid #fff",
          "borderRadius": "4px",
        },
        "Button": {
          "textColorHover": "#ffffff",
          "border": "1px rgba(255, 255, 255, 0.1) solid",
          "borderFocus": "1px #ffffff solid",
          "borderHover": "1px #ffffff solid",
          "borderPressed": "1px #ffffff solid",
          "rippleColor": "#FFB526",
          "borderRadius": "15px",
        }
      },

    }
  },
  async mounted() {
    // getStore('isWalletStatus')?this.walletInfo = await init():''
    await this.init();
  },
  computed: {
    Supports() {
      return function (item) {
        const num = item ? item : '0'
        const obj = num.split('%')
        return Number(obj[0]) ? Number(obj[0]) : 0
      }
    },
    VT() {
      return function (item) {
        const num = item ? item : '0'
        const obj = num.split('%')
        return Number(obj[0]) ? Number(obj[0]) : 0
      }
    },
    Against() {
      return function (item) {
        return ((item.Against / item.SupportTotal) * 100).toFixed(2)
      }
    },
    walletAddress() {
      return this.$store.state.walletAddress
    },
    isSponsor() {
      return (this.detailData.sponsor).toLowerCase() === (this.walletAddress).toLowerCase();
    },
    isShow() {
      return new Date().getTime() < this.detailData.excuttionEndDate && this.detailData.status === 6;
    },
  },
  methods: {
    async init() {
      const {id} = this.$route.query;
      const vtTotal = await totalSupply();
      const list = await proposalList(vtTotal);
      this.detailData = list.find((v) => v.id === id);
      const r = await api.proposalName({proposalIds: id});
      [this.detailData.desc] = r || [''];
      if (this.walletAddress) {
        this.vtBalance = await balanceOf(this.walletAddress);
        this.voteInfo = await voteInfo(id, this.walletAddress);
      }
    },
    async handleClick() {
      this.voteDialog = false;
      try {
        this.$store.commit('isTranModal', true)
        const r = await vote(this.detailData.id, this.type === 1, this.voteValue.toString(), this.walletAddress);
        // this.$store.commit('isTranModal', false)
        this.$store.commit('tranStatus', 2)
        this.$store.commit('isTranModal', true)
        console.log(r, '投票成功');
        await this.init();
      } catch (e) {
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
        // this.$store.commit('isTranModal', false)
      }
    },
    // 赎回所有TV
    async back() {
      try {
        this.$store.commit('isTranModal', true)
        const r = await claimVoteToken(this.detailData.id, this.walletAddress);
        this.$store.commit('tranStatus', 2)
        this.$store.commit('isTranModal', true)
        await this.init();
        console.log(r, '赎回');
      } catch (e) {
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
        // this.$store.commit('isTranModal', false)
      }
    },
    // 执行提案
    async execute() {
      try {
        this.$store.commit('isTranModal', true)
        const r = await executeProposal(this.detailData.id, this.walletAddress);
        // this.$store.commit('isTranModal', false)
        this.$store.commit('tranStatus', 2)
        this.$store.commit('isTranModal', true)
        console.log(r, '执行提案');
        await this.init();
      } catch (e) {
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
        // this.$store.commit('isTranModal', false)
      }
    },
  }
}
</script>

<style scoped lang="less">
@import "@/components/modals.less";
.conts {
  color: #ffffff;
  background: #17181A;
  background: url("../../assets/PEGO/bgjojn.svg") no-repeat 100%, url("../../assets/PEGO/bag1.svg") no-repeat;
  background-position: right 30px bottom 0, left 0;
  background-size: 672px 672px, 800px;
  box-sizing: border-box;

}

.ti-card {
  ///deep/ .n-progress-graph-line-fill {
  :deep(.n-progress-graph-line-fill  ){
    background: linear-gradient(90.05deg, #FF9839 17.92%, #FFD02B 64.69%) !important;
  }

  text-align: left;
  padding: 0 100px;

  .lin-t {
    padding: 20px 0;
  }

  .ling {
    line-height: 35px;
    font-size: 16px;

    span {
      color: rgba(255, 157, 0, 1);
    }
  }

  .cand-ti {
    font-weight: 700;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    display: inline-block;
    font-family: BOLD;
  }

  .pro-ul {
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    padding: 45px 36px;
    box-sizing: border-box;
    margin-bottom: 30px;

    .ul-lef {
      width: 100%;

      .tit-pro {
        font-weight: 700;
        font-size: 25px;
        font-family: BOLD, sans-serif;
      }

      .prog-ess {
        position: relative;
        height: 100%;
        span {
          position: absolute;
          left: 10px;
          top: 0;
          z-index: 2;
          font-weight: 400;
          font-size: 14px !important;
          color: #000000;
          display: inline-flex;
          align-items: center;
          height: 100%;
          line-height: 43PX;
          img {
            margin: 0 5px;
            width: 24px;
            object-fit: contain;
          }
        }
      }

      //.prog-ess:nth-child(1) {
      //  margin: 8px 0 12px;
      //}
    }

    .ul-rig {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      width: 10%;
      padding: 10px 0 0;
      box-sizing: border-box;
      position: absolute;
      right: 0;
      top: 20px;

      .rig-txt {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }

      .active {
        color: #43FFAA;
      }

      .pending {
        color: #299CFF;
      }

      .invalid {
        color: #FA7977;
      }


    }
  }


}

.nupod {
  background: transparent;
  //text-transform: uppercase;
  color: white;
  //padding: 15px 50px;
  overflow: hidden;
  position: relative;
  border-radius: 33px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: .3s;
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

}

.nupod:after {
  content: '';
  display: block;
  position: absolute;
  top: -36px;
  left: -100px;
  background: white;
  width: 50px;
  height: 125px;
  opacity: 20%;
  transform: rotate(-45deg);
}

.nupod:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
  0 0 20px #FFB800;
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 20px;
  text-shadow: 1px 1px 2px #427388;
}

.nupod:hover:after {
  left: 120%;
  transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
  -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
}

.nupod:active {
  border: none;
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
  transition: .6s; /*取消过渡*/
  box-shadow: inset 0 0 20px #FF7A00,
  0 0 20px #FFB800;
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.PDPColor {
  border: none;
  color: #ffffff;
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
}

.PDPColorS {
  border: none;
  color: #ffffff;
  background: #4A4A4A;
  cursor: no-drop;
  pointer-events: none;
  opacity: 0.6;
}

.tet-s {
  position: relative;

  .sup {
    ///deep/ .n-progress-graph-line-fill {
    :deep(.n-progress-graph-line-fill) {
      border-radius: 4px 0 0 0 !important;
    }
  }

  .anst {
    position: absolute;
    top: 0;
    width: 100%;

    transform: rotatey(180deg);
    ///deep/ .n-progress-graph-line-fill {
    :deep(.n-progress-graph-line-fill) {
      background: linear-gradient(-269.99deg, #0068C8 1.08%, #1EE4FF 42.25%) !important;
      border-radius: 4px 0 0 0 !important;
    }

    span {
      transform: rotatey(180deg);
    }
  }

  span {
    font-weight: 700;
    font-size: 12px !important;
    color: #ffffff !important;
    //top: 7px !important;
  }
}

.votes {
  padding: 30px;
  text-align: center;

  div {
    font-family: REGULAR, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
  }
}

.gin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.core {
  font-weight: 400;
  font-size: 12px;
  width: 506px !important;
  height: 54px !important;
  line-height: 54px !important;
}

.grids {
  //display: grid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 40px 60px;
  //grid-row-gap: 20px;
  //grid-column-gap: 20px;
  //grid-template-columns:1fr 1fr;

}
</style>
<style lang="less">
.detail {
  dd {
    display: inline;
    color: rgb(255, 68, 27) !important;
  }
}
</style>
