export default {
    message: {
        Product: '홈페이지',
        Network: '거버넌스',
        Build: '생태계',
        PEGOScan: '노드 선거',
        bridge: '브리지',
        Proposal: 'DAO',
        browser: '브라우저',
        BecomeAValidator: '검증자 되기',
        wallet: '지갑',
        news: '뉴스',
        routeMap: '개발 로드맵',
        foundation: '재단',
        EN: 'English',
        ZN: '繁体中文',
        JP: '日本語',
        KO: '한국인',
        TH: 'แบบไทย',
        VI: 'Tiếng Việt',
        Whitepaper: '화이트페이퍼',
        Blog: '블로그',
        Foundation: '재단',
        blockchain: "<span class='nameColor'>{name}</span>모두를 위해 만든",
        name: '블록체인',
        Support: '고도의 자치성, 확장성 및 지속 가능성을 지원하는 탈중앙화 애플리케이션',
        scalability: '확장성',
        solution: 'Web3 및 AI에 맞춰진 고성능 솔루션',
        EnterTheDAPP: '화이트페이퍼',
        ReadDocs: '문서 읽기',
        GITHUB: '깃허브',
        MainnetLaunchCountdown: '메인넷 론칭 카운트다운',
        Days: '일',
        Hours: '시간',
        Minutes: '분',
        PEGOSolutions: 'PEGO 솔루션',
        PPOS: 'PPOS',
        EnablesSustainable: '공정하고 안전한 거버넌스 모델을 통해 지속 가능한 생태계 구현',
        EVMCompatible: 'EVM 호환',
        CompatibleWith: 'Golang, Rust, Solidity 및 EVM 가상 머신과 호환되어 시스템 간 원활한 dApp 개발을 위한 개발자 친화적인 환경 제공',
        NFTPNNIdentityVerificationSystem: 'NFT-PNN 신원 확인 시스템',
        EnsuresNetwork: 'PNN 스테이킹 및 PGVT 투표를 통해 네트워크의 탈중앙화 및 보안 보장',
        CommunityProposedProposals: '커뮤니티 제안 제안',
        ProvidesFlexibility: '커뮤니티 제안 제안을 통해 네트워크 매개 변수 조정의 유연성 제공',
        AIIntegration: '인공 지능 통합',
        IntegratesWith: '인공 지능 통합을 통해 AI 애플리케이션의 처리 속도와 효율성 향상을 동시에 투명성과 보안을 유지',
        DAOGovernment: 'DAO 거버넌스',
        partner: 'Partner',
        PEGOIsGoverned: 'PEGO는 전 세계 DAO가 자체 암호화 자산인 PG를 통해 거버넌스됩니다.',
        BuyPG: 'PG 구매',
        PGAllowsWeb3: 'PG를 통해 Web3 커뮤니티 구성원은 프로토콜 업그레이드 제안에 참여하고 PEGO의 블록체인 네트워크를 검증하며 스테이킹 보상을 받고 버닝 경매 등을 할 수 있습니다.',
        SupportByPEGOFoundation: 'PEGO 재단 지원',
        ContactUs: '문의하기',
        Government: '거버넌스',
        Validator: '검증자',
        Wallet: '지갑',
        Ecosystem: '생태계',
        W3bridge: 'W3 브릿지',
        Exchange: '거래소',
        Loan: '대출',
        W3BANK: 'W3BANK',
        PEGOTechnology: '© 2023 PEGO 기술',
        ADAOCommunityBasedPublicChain: '개방형, 자치형 및 확장 가능한 Web3 응용 프로그램 계층 기반 인프라',
        Built: '<span class="nameColor">{network}</span>와 함께 구축되었습니까?',
        network: "PEGO 네트워크",
        PEGOHasDesigned: "PEGO는 완전히 커뮤니티 주도의 자치형 Web3 인프라를 디자인하였으며, 모든 체인 상의 매개 변수는 커뮤니티 제안을 통해 유연하게 조정할 수 있습니다. PPOS 합의 메커니즘을 기반으로 PEGO는 공정한 메커니즘 하에서 모든 참여자가 효과적으로 협력할 수 있도록 약속합니다. 또한 PEGO 블록체인은 EVM 시스템과 완벽하게 호환되며, 확장 가능한 탈중앙화 응용 프로그램을 구축하는 데 이상적인 선택지입니다.",
        SubmitYourProject: "프로젝트 제출",
        SubmitYourProjectLink: "",
        ApplyForInvestment: "투자 신청",
        useCases: "사용 사례",
        LoremIpsum: "다양한 Web3 애플리케이션은 PEGO 네트워크의 가장 큰 가치이며, 잠재적으로 킬러 앱이 숨겨져 있을 수 있습니다.",
        CrossChain: "크로스체인",
        CrossChainBrigde: "크로스체인 브리지",
        JoinTheCommunity: '<span class="nameColor">{Community}</span>에 가입하기',
        Community: "커뮤니티",
        PEGODiscordSupport: "PEGO 디스코드 지원",
        ToBeA: '<span class="nameColor">{TOValidator}</span>가 되기',
        TOValidator: "검증자",
        PEGOIsCommitted: "PEGO는 완전히 커뮤니티 주도의 탈중앙화 네트워크를 구축하기 위해 검증자 노드의 설계가 매우 중요합니다. PPOS 합의 메커니즘을 통해 PEGO는 공정한 메커니즘 하에서 모든 참여자가 효과적으로 협력할 수 있도록 약속합니다.",
        CONNECT: "연결하기",
        applyToBe: "검증자 신청하기",
        PEGOValidator: "PEGO 검증자 노드는 특정 소유권 주체를 가진 NFT-PNN '신분증'을 가지고 있습니다. 후보자는 PNN을 스테이킹하고 공식 PEGO 검증자 노드 선거에 참여하여 PEGO 네트워크의 검증자 노드가 됩니다.",
        NFTPNNIDENTITYCARD: "NFT-PNN 신분증",
        MyAccount: "내 계정",
        VotingReward: "투표 보상",
        TokenBalance: "토큰 잔액",
        VotingPower: "투표 권한",
        VotedValidators: "투표한 검증자",
        Rank: "순위",
        Address: "주소",
        Status: "상태",
        MyShare: "내 점유율",
        Operate: "조작",
        ValidatorStatus: "검증자 상태",
        TotalVotes: "총 투표 수",
        NextRound: "다음 라운드",
        ValidatorRanking: "검증자 순위",
        search: "검색",
        Ranking: "순위",
        LatestBlock: "최신 블록",
        ValidatedBlocks: "확인된 블록",
        Votes: "투표 수",
        Action: "작업",
        Vote: "투표",
        PEGO: `PEGO <span class='nameColor'>{dao}</span>`,
        dao: "DAO",
        PEGOEmphasizes: "PEGO는 효율성을 강조하며 커뮤니티 주도 제안을 통해 체인 상의 모든 매개변수를 유연하게 조정할 수 있습니다. 노드 또는 일반 사용자는 일정량의 PG를 스테이킹하여 제안을 발표할 수 있습니다. 규정된 시간 내에 투표를 통과하면 제안이 체인 상 거래로 실행되어 유효해집니다.",
        VotingTaken: '투표 진행 중',
        Sponsor: "주최자",
        Active: "진행 중",
        Executed: "실행됨",
        Voted: "투표 완료",
        SUBMITPROPOSAL: "제안 제출",
        OnChainParameters: "체인 상 매개변수",
        PendingTime: "보류 중 시간",
        VotingTime: "투표 시간",
        ExecutionTime: "실행 시간",
        TotalVotesTotalVt: "총 투표 수/총 VT",
        SupportedVotesTotalVotes: "지지 표수/총 투표 수",
        StakePG: "PG 스테이킹",
        Proposals: "제안",
        SEEALL: "모두 보기",
        ThreeStages: `<span class='nameColor'>{PGStrategies}</span> 세 단계 전략`,
        PGStrategies: "PG 전략",
        BasedOnDao: "DAO를 기반으로",
        InTheInitial: "PEGO 공공 체인 초기 개발 단계에서 모든 준비 작업은 생태계 내 DAO 개념을 기반으로 하여 모두가 공동으로 건설하고 공유하는 것을 목표로 합니다. 또한 생태계는 DAO 거버넌스를 통해 모든 사람이 공동으로 거버넌스하여 자치 규칙에 따라 민주적이고 공정한 시스템을 실현합니다.",
        DAODevelopment: "DAO 개발",
        InThePEGO: "PEGO 네트워크에서는 다양한 유형의 DAO가 등장하며 참여자 수가 증가함에 따라 DAO 거버넌스에 대한 수요도 증가할 것으로 예상됩니다. 이는 블록체인의 적용을 가속화할 수 있습니다.",
        ServingDAO: "DAO에 기여",
        AllBlockchainBased: "모든 블록체인 기반 DAO는 커뮤니티 구성원에게 소유되어 있습니다. 3단계 전략에 따라 PEGO 네트워크는 최종적으로 완전히 DAO 거버넌스 기반의 글로벌 퍼블릭 체인이 될 것입니다.",
        PEGORoadmap: "PEGO 로드맵",
        latestUpdates: "최신 업데이트",
        TheProposal: "PEGO 네트워크 업데이트: 토큰 경제 모델 제안",
        publicChain: "퍼블릭 체인 정보: PEGO 네트워크 토큰 심볼: PG PEGO 네트워크의 거버넌스 토큰인 PG는 BSC 체인에서도 발행되어 크로스 체인 상호 작용에 사용됩니다...",
        TheW3BIWO: "W3B IWO 곧 시작",
        theFuture: "W3Bridge는 PEGO 이코시스템의 초기 개발을 기반으로 한 분산된 Web3 세계의 다리 역할을 할 것입니다. 이는 이코시스템의 확장에 따라...",
        PEGONetwork: "PEGO 네트워크 PN 거버넌스 토큰 론칭",
        toIncentivese: "글로벌 PEGO 네트워크 홍보자를 장려하기 위해 PEGO 네트워크는 커뮤니티의 거버넌스 토큰으로 PN을 론칭할 예정입니다. 이 토큰은...",
        ThePEGOFoundation: `PEGO <span class='nameColor'>{Foundations}</span>`,
        Foundations: '재단',
        pegoEmphasizes: "PEGO 재단은 PEGO 네트워크와 해당 커뮤니티를 구축, 지원 및 발전시키기 위해 시작된 탈중앙화된 조직입니다. 이 조직은 전 세계의 다양한 분야에서 활동하는 업계 전문가와 커뮤니티 핵심 자원봉사자로 구성되어 있습니다.",
        StakePools: "스테이크 풀",
        stakePools: "스테이크 풀은 PEGO의 탈중앙화, 검열 방지 및 DeFi 성장을 촉진하는 유동성 스테이킹 솔루션입니다.",
        STAKEPG: "PG 스테이킹",
        Grants: "그랜트",
        thePegp: "PEGP 재단의 그랜트 프로그램은 PEGO 네트워크의 검열 내성 기능을 더욱 향상시키기 위해 그랜트를 지원합니다.",
        APPLYFORGRANTS: "그랜트 신청",
        HundredFamily: "",
        HundredFamilyDesc: "",
        APPLY100Family: "",
        APPLY100FamilyLink: "",
        JOINPEGOFOUNDATION: "PEGO 재단 가입",
        Ended: "종료됨",
        linkWallet: "지갑 연결",
        BNBSmartChain: "BNB 스마트 체인",
        TelegramCommunity: "텔레그램 커뮤니티",
        EnglishCommunity: "영문 커뮤니티",
        validator: "밸리데이터",
        quitValidator: "종료 노드 선거",
        claim: "청구",
        GetPGVT: "PGVT 받기",
        voteValidator: "밸리데이터에게 투표하기",
        ReturnPGVT: 'PG/W3FT 되돌리기',
        ReturnPGVT2: '냉각 기간은 교환할 수 없습니다',
        ReturnPGVT3: '잔액이 부족합니다',
        IncreaseVote: '투표 증가',
        Refund: '환불',
        quitTheValidator: '종료 노드 선거',
        onceQuited: '한 번 취소하면 PG 보상을 받을 수 없습니다!',
        andYour: '그리고 PNN을 회수할 수 없습니다.',
        noIDont: '아니 난 그만두고 싶지 않아',
        SureIWant: '예, 취소하겠습니다',
        getPGVT: 'PGVT 받기',
        pleaseEnter: '입력하세요',
        MAX: '최대',
        Balance: '잔액',
        BalancePGVT: 'PGVT 잔액',
        returnPGVT: 'PGVT 반환',
        youWantTo: '반환하려는',
        YourBalanceIs: '잔액은',
        isYourBalanceIsVT: 'VT 잔액입니다',
        YourBalanceIsVT: 'VT 잔액이 부족합니다',
        YourBalanceIs1:'이 소스로 이미 주조된 수량은',
        YouneedReturn: '반환해야하는',
        CooldownCountdown: '클리닉 기간',
        Seconds: '초',
        VoteAmount: '투표 수',
        ValidatorAddress: '밸리데이터 주소',
        Withdraw: '철회',
        Withdraw2: '클리닉 기간에는 철회할 수 없습니다',
        WithdrawAmount: '철회 수량',
        NoticeYou: '참고: 클리닉 기간이 종료되면 PGVT가 반환됩니다.',
        ExchangeNFTPNNT: "발행된 NFT-PNNT",
        ValidatorApplication: "밸리데이터 신청",
        SorryYou: "PNN-NFT를 소유하고 있지 않습니다.",
        anyPNNNFT: "발행을 위해 PNN을 사용하십시오",
        // howToGet: "NFT-PNN (PEGO-Chain)을 얻는 방법",
        // IfYouHave: "PNN-BSC 토큰을 가지고 있다면,",
        clickHere: "조각화하기",
        // toMint: "PNN-NFT(PEGO-Chain)를 발행하려면.",
        ConnectWallet: "지갑 연결",
        YourPNNNFT: "당신의 PNN NFT",
        applyToBeAValidator: "밸리데이터로 신청하기",
        Transaction: "트랜잭션",
        CongratulationsYou: "축하합니다! 당신은 현재 밸리데이터입니다!",
        JoinOur: '우리의',
        discord: 'Discord',
        toGetHow: '밸리데이터 노드를 운영하는 방법을 알아보세요.',
        Failed: '실패',
        Pending: '보류 중',
        MoreAboutDAO: 'DAO에 대해',
        CreatProposal: "제안 작성",
        CoolingOffPeriodOfPGVT: "PGVT의 클리닉 기간",
        CoolingOffPeriodOfPGW3FT: "PG&W3FT의 클리닉 기간",
        ValidatorDistrbution: "밸리데이터 분포",
        AdditionalPGIssued: "추가 PG 발행",
        LowestGasFee: "최저 가스 수수료",
        BlockReward: "블록 보상",
        ValidatorNumbers: "밸리데이터 수",
        SupportedToken: "지원되는 토큰",
        Blacklist: "블랙리스트",
        TotalVT: "총 VT",
        Supports: "지원",
        Invalid: "유효하지 않음",
        Passed: "통과됨",
        Core: "핵심",
        ProposalType: "제안 유형",
        AdjustThe: "제안 매개변수 조정",
        AddOrDelete: "PGVT를 얻기 위한 팜 토큰 추가 또는 제거",
        AdjustTheDistribution: "보상 분배 비율 조정",
        BlaDecentralized: "BlaDecentralized 재단은 제안자 주소로부터 추가 PG를 발행합니다.",
        AdjustTheLowestGasFee: "최저 가스 수수료 조정",
        AdjustTheBlockReward: "블록 보상 조정",
        AdjustThenumberOfValidators: "밸리데이터 수 조정",
        ChoosePDP: "PDP 선택",
        ParametersChanges: "매개변수 변경",
        NowIs: "현재",
        ExeuctionTime: "실행 시간",
        TotalVotesvt: "총 투표 수/총 VT",
        SupportedVotesTotalVotess: "지지 표수/총 투표 수",
        AddDelete: "추가/삭제",
        AddANewFarmingToken: "새로운 파밍 토큰 추가",
        DeleteoneFarmingToken: "파밍 토큰 하나 삭제",
        TokenAddress: "토큰 주소",
        CoolingOffPeriodOf: "클리닉 기간",
        TheDistributionProportionForValidator: "밸리데이터 및 사용자 분배 비율, 현재 사용자 참여율은",
        AddAnAddressToBlacklist: "주소를 블랙리스트에 추가",
        DeleteAnAddressOutOfBlacklist: "블랙리스트에서 주소 삭제",
        Amount: "금액",
        TheAmountOfPGIssued: "발행된 PG의 금액",
        NumberOfValidators: "밸리데이터 수",
        YouNeedToStake: "스테이킹해야 할 PG",
        makeSureYou: "PG가 충분한지 확인하십시오.",
        PGBalance: "PG 잔액",
        FailedToCreateProposal: "제안 작성에 실패했습니다.",
        Detail: "세부 정보",
        StartTime: "시작 시간",
        VoteTime: "투표 시간",
        TotalVoteToken: "총 투표 토큰",
        Against: "반대",
        GetBackVT: "VT 회수하기",
        Execute: "실행",
        Metamask: "Metamask",
        DoNotHaveMetamask: "Metamask가 설치되어 있지 않으신가요?",
        Install: "설치",
        YoureAlreadyAValidator: "이미 검증자입니다!",
        Error: "오류",
        Successful: "성공",
        GetBackPG: "PG 회수하기",
        About: "약",
        proposal: "제안",
        pegoScan: "PEGO 브라우저",
        other: "기타",
        comingSoon: "곧 출시 예정",
        PWGBountyProgram: 'PWG 바운티 프로그램',
        Web3GrantProgram: 'PEGO Web3 그랜트 프로그램은 신생하는 Web3 응용계층에 대한 자금, 기술 및 리소스의 포괄적인 지원을 목표로 합니다.',
        PWGCanApplyForType: 'PWG는 다음 유형에 지원할 수 있습니다',
        seedIncentive: '시드 인센티브',
        StartupFunding: '우수한 Web3 아이디어에 대한 스타트업 자금을 제공합니다',
        financialSupport: '재정적인 지원',
        otherSupport: '기타 지원',
        ProjectLanding: '프로젝트 착륙 지원 컨설팅',
        EcologicalCoCreation: '생태 공생 유형',
        ecosystem: 'PEGO Web3 생태계의 중요한 구성 요소가 됩니다',
        CoCreationSupport: '공생 지원',
        ProvideFull: '전반적인 기술 지원을 제공하며 필요한 협력 리소스와 통합하여 PEGO 생태계 전반의 운영 활동에 참여합니다.',
        SpecialType: 'Layer2＆3 특별 유형',
        ProvideSpecial: 'PEGO에서 L2 및 L3 구축 참여자에게 특별한 지원을 제공합니다',
        AccelerateSupport: '가속 지원',
        TargetedTechnical: '대상 기술 지원을 제공하고 핵심 팀 구축을 지원합니다',
        StrategicInvestment: '전략적 투자 유형',
        MeetTheDevelopment: 'PEGO 생태계 전략적 발전 요구를 충족합니다',
        strategicSupport: '전략적인 지원',
        ImplementationOfThe: '전체 라이프사이클의 착지, 전략, 비즈니스 운영, 기술 및 비즈니스 컨설팅을 제공하며 최고 수준의 협력 리소스와 연결하고, 이후 자금 유도를 진행합니다.',
        WhatIs100FamilyTitle: '',
        WhatIs100Family: '',
        Why100FamilyTitle: '',
        Why100Family: '',
        Type100FamilyTitle: '',
        Type100Family: '',
        Contact100Family: '',
        Contact100FamilyName: '',
        Contact100FamilyLink: '',
        VoterDistribution: '사용자 분포',
        homeHeader: 'PEGO는 창조를 위해 탄생했습니다',
        infrastructure: 'Web3 인프라스트럭처, PPOS 합의, 가상 머신 (VM), DAO 거버넌스, 블록체인 도구 등 모두를 위한 PEGO는 웹3 시대의 블록체인 인프라스트럭처입니다',
        CompleteTheMainnetLaunch: '메인넷 론칭 완료',
        CompleteNodeCampaign: '노드 캠페인 완료',
        W3swapLaunched: 'W3swap 론칭',
        W3WalletIsOnline: 'W3Wallet 온라인',
        ListedOnTheWorld: '주요 거래소 상장',
        StartApplicationEcologicalConstruction: '응용 프로그램 생태 구축 시작',
        PromoteMEMEEcologicalConstruction: "MEME 생태 구축을 촉진합니다",
        PromoteFOMO: "FOMO 생태 구축을 촉진합니다",
        StarttheGlobal: "글로벌 생태로드쇼 시작",
        AchieveEcological: "200개 생태 온라인화 달성",
        TheNumberOfValid: "유효한 월렛 수가 100만을 초과",
        TheTotalNumber: "블록 트랜잭션 총량이 500만을 초과",
        TVLExceeded: "TVL이 1억 달러를 초과",
        CompleteTheLaunch: '주요 거래소 상장 완료',
        AchieveEcologicalOnline: '500개 생태 온라인화 달성',
        TheNumberOfEffective: '유효한 월렛 수가 200만을 초과',
        heTotalNumberOfBlock: '블록 트랜잭션 총량이 2000만을 초과',
        TVLExceededBillion: 'TVL이 10억 달러를 초과',
        Alreadyin: '이미 존재함',
        NotIn: '존재하지 않음',
        FillInAAddressAlreadyIn: '이미 존재하는 주소를 입력하세요',
        FillInTheTokenContractAddress: "토큰 계약 주소를 입력하세요",
        AlreadySupported: "이미 지원됨",
        DecentralizedFoundationWillIssue: "분산형 재단은 개시자의 주소에 추가 PG를 발행합니다",
        GasFee: "가스 수수료",
        Add: '추가',
        Delete: '삭제',
        W3swap:'W3swap',
        AMegaPlatform:'최고의 거래 솔루션을 통합한 대형 플랫폼',
        W3Wallet:'W3Wallet',
        MultiFunction:'PEGO Network 생태계 기반의 다기능 Web3 크로스체인 월렛',
        W3Bridge:"W3Bridge",
        RebuildTheFragmented:"조각화된 Web3 네트워크 재구축",
        EnterAsAnInteger:"정수로 입력하세요",
        NetworkError:"네트워크 오류",
        nodeReward:"노드 보상",
        award:"보상",
        toBeCollected:"수령 대기",
        received:"수령 완료",
        NumberOfBlocks:'블록 수',
        EcologicalDAPP:'생태 DAPP',
        receiveAward:'수령',
        pleaseGoToWallet:'지갑으로 이동해주세요',
        AuditReport:'감사 보고서',
        ProposalDescription:'제안 설명',
        nodeCard:'노드 카드',
        PDP1:'PDP-1 제안',
        DetermineOr:'목적: DAO 거버넌스 제안의 일부 중요 매개변수를 결정 또는 조정하는 데 사용됩니다',
        TheAdjustableParameters:'조정 가능한 매개변수는 다음과 같습니다: 공시 기간/투표 기간/실행 기간/PG 스테이킹 양/조건1/조건2',
        PDP2:'PDP-2형 제안',
        decideWhich:'어떤 유형의 LP 생성 토큰이 PGVT 스테이킹 및 교환에 사용될 수 있는지 결정합니다 (LP 내 PG 수가 500,000 미만인 경우에도 제안이 승인되어도 PGVT를 새로 생성할 수 없습니다)',
        PDP3:'PDP-3형 제안',
        adjustTheCooling:'PGVT 생성 및 교환 냉각 기간, VT 투표에 의한 교환 냉각 기간을 결정하고 조정하기 위해 사용됩니다',
        PDP4:'PDP-4형 제안',
        usedToDetermine:'노드 및 투표자 보상 분배 비율을 결정하고 조정하기 위해 사용됩니다',
        PDP5:'PDP-5형 제안',
        controlMalicious:'일부 악성 계약 및 계정에 동결 또는 리스크 관리 조치를 시행하여 악영향을 신속하게 통제하고 제거하는 데 사용됩니다',
        PDP6:'PDP-6형 제안',
        controlMaliciousContracts:'PG 추가 발행에 사용되며 어떤 사용자든 제안을 생성할 수 있습니다',
        PDP7:'PDP-7형 제안',
        usedToAdjust:'다른 단계 요구에 대응하기 위해 체인 상의 최소 Gas 수수료를 조정하기 위해 사용됩니다',
        PDP8:'PDP-8형 제안',
        adjustTheBlock:'블록 보상 값 조정',
        PDP9:'PDP-9형 제안',
        adjustTheNumber:'온라인 검증 노드 수 조정',
        BitKeep:'BitKeep',
        TOKENPOCKET:'TOKEN POCKET',
        OKXWallet:'OKX Wallet',

        //6-13
        votingAddress:'투표 주소',
        numberOfVotes:'투표 수',
        votingTime:'투표 시간',
        votingList:'투표 목록',
        ExploreThePEGOEcosystem:'PEGO 에코시스템 탐색',
        submitProject:'프로젝트 제출',
        allItems:'모든 항목',
        all:'모두',
        startSearching:'검색 시작',
        audit:'감사',
        CreatingAndOwning:'지갑을 만들고 소유하는 것은 PEGO에 연결하고 Web3에 진입하는 첫 번째 단계입니다. 지갑의 개인 키와 니모닉을 안전하게 보관하십시오.',
        OpeningMetamask:"Metamask를 열고 있습니다",
        PleaseConfirmInMetamask:"Metamask에서 확인해주세요",
        OpeningBitkeep:"BitKeep를 열고 있습니다",
        OpeningOKXWallet:"OKX를 열고 있습니다",
        NotInstalled:"미설치",
        EcologicalCooperationApplicationForm:"생태 협력 신청서",
        WelcomeToJoin:"가입해 주셔서 감사합니다. 데이터를 제출하신 후 최대한 빠른 시일 내에 연락 드리겠습니다. 그 전에 아래 정보를 입력해주시기 바랍니다.",
        PEGOEcologicalBusiness:"PEGO 생태 비즈니스 협력 담당자 ID",
        Telegram:"텔레그램",
        Btok:"Btok",
        projectName:"프로젝트명",
        projectLogo:"프로젝트 로고",
        ImageFormatsLike:".jpg, .png, .gif, .bmp, .psd, .tiff 등의 이미지 형식을 지원합니다.",
        ChooseImage:'이미지 선택',
        Lessthan:'500MB 미만',
        ProjectDescription:'프로젝트 개요',
        itemCategory:'프로젝트 카테고리 또는 위치',
        anyOtherType:'DEX, NFT 또는 기타 유형',
        ProjectBenefits:'프로젝트 이점',
        WhatCanItBringToThe:'PEGO 에코시스템에 어떤 영향을 줄 수 있나요?',
        ProjectInfluence:'프로젝트 영향력',
        WhatKindOfCooperation:'PEGO 에코시스템과 어떤 협력을 원하시나요?',
        TokenListedOn:'토큰이 W3swap에 상장되었나요?',
        TokenListedOnW3swap:'토큰이 W3swap에 상장되었습니다',
        TokensListedOnW3Wallet:'토큰이 W3Wallet에 상장되었습니다',
        TokenListingMappingTool:'토큰 상장 매핑 도구',
        TokenListedOnLunchpadHomepage:'토큰이 Lunchpad 홈페이지에 상장되었습니다',
        TokenOpening:'토큰이 BTOK-PEGO 체인 토큰의 상장/커뮤니티 레드 패킷을 오픈합니다',
        TheTokenIsListed:'토큰이 AVE-PEGO 섹션에 상장되었습니다',
        TheProjectSeeks:'프로젝트는 기술, 자금 및 기타 리소스 지원을 찾고 있습니다',
        TheApplicationEnters:'응용 프로그램이 PEGO 퍼블릭 체인에 진입합니다',
        Other:'기타',
        ProjectEconomicModel:'프로젝트 경제 모델',
        ProjectInformation:'프로젝트 정보',
        ProjectPPTAndAny:'프로젝트 PPT 및 기타 관련 자료를 업로드할 수 있습니다',
        ProjectOfficialWebsite:'프로젝트 공식 웹 사이트',
        ProjectTwitter:'프로젝트 트위터',
        ProjectTelegramCommunity:'프로젝트 텔레그램 커뮤니티',
        ProjectDiscordCommunity:'프로젝트 디스코드 커뮤니티',
        ProjectGithub:'프로젝트 Github',
        ContactTelegramID:'연락 텔레그램 ID',
        Submit:'제출',
        PleaseEnterAKeyword:'키워드를 입력해주세요',
        downloadFile:"다운로드",
        votingRecord:"투표 기록.csv",
        previousPage:"이전 페이지",
        nextPpage:"다음 페이지",
        ReturnPGVTWPG:"제작할 수 없음",
        officialEmail:'공식 이메일',
        businessCooperation:'비즈니스 협력',
        copy:'성공적으로 복사',
    },

}
