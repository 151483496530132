/**
 *@title
 *@author liqingjie
 *@date 2023/2/21 10:54
 */
import Web3 from 'web3';
import Decimal from 'decimal.js';
import validatorAbi from '../../abi/Validator.json';
import {validatorAdder} from "@/utils/address";
import {getStore} from '@/config/storage'

const contractAddress = validatorAdder;

const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
const web3js = new Web3(BitKeepProvider);
let contract = null;
if (web3js) {
    contract = new web3js.eth.Contract(validatorAbi.abi, contractAddress);
}

// 申请成为节点
export const registerToValidator = async (addr, tokenId) => {
    try {
        const nonce = await web3js.eth.getTransactionCount(addr);
        console.log(nonce);
        return contract.methods.registerToValidator(Web3.utils.toBN(+tokenId)).send({from: addr});
    } catch (e) {
        console.log(e)
    }
};
// 已投票节点列表
export const getCandidatesByDelegator = async (addr) => {
    try {
        const data = await contract.methods.getCandidatesByDelegator(addr).call();
        const newData = (data.ranks || []).map((rank, idx) => {
            const delegate = Web3.utils.fromWei(data.delegates[idx], 'ether');
            const myDelegate = Web3.utils.fromWei(data.myDelegates[idx], 'ether');
            return {
                rank,
                address: data.addrs[idx],
                available: data.availables[idx],
                delegate,
                myDelegate,
                share: new Decimal(+myDelegate).div(delegate).times(100).toFixed(2),
            };
        });
        return newData;
    } catch (e) {
        console.log(e)
    }

};
// 节点状态
export const validatorStatus = async (addr) => {
    try {
        const sts = await contract.methods.validatorStatus(addr).call();
        return +sts;
    } catch (e) {
        console.log(e)
    }
};
// rank 排名
export const getCandidates = async () => {
    const data = await contract.methods.getCandidates(1000).call();
    const newData = (data.addrs || []).map((address, idx) => {
        const delegate = Web3.utils.fromWei(data.delegates[idx], 'ether');
        return {
            address,
            available: data.availables[idx],
            blockProduced: data.blockProduced[idx],
            lastBlock: data.lastBlocks[idx],
            delegate,
        };
    });
    return newData;
};
// 查询节点状态，是否是在线节点
export const isOnlineValidator = async () => {

    try {
        return contract.methods.isOnlineValidator().call();
    } catch (e) {
        console.log(e)
    }
};
// 退出竞选
export const cancelValidator = async (addr) => {
    try {
        return contract.methods.cancelValidator().send({from: addr})
    } catch (e) {
        console.log(e)
    }
};

export const validatorNumber = async () => {
    try {
        return contract.methods.validatorNumber().call()
    } catch (e) {
        console.log(e)
    }

};

export const onlineValidatorNumber = async () => {
    try {
        return contract.methods.onlineValidatorNumber().call()
    } catch (e) {
        console.log(e)
    }
};


export const totalSupply = async () => {
    try {
        const n = await contract.methods.totalSupply().call();
        return Web3.utils.fromWei(n, 'ether');
    } catch (e) {
        console.log(e)
    }

};
// 查询奖励
export const getUnClaimedReward = async (addr) => {
    try {
        const v = await contract.methods.getUnClaimedReward(addr).call();
        return Web3.utils.fromWei(v, 'ether');
    } catch (e) {
        console.log(e)
    }
};
// 提取奖励
export const claimReward = async (addr) => {
    try {
        contract.methods.claimReward().send({from: addr});
    } catch (e) {
        console.log(e)
    }
}

// 投票
export const delegate = async (addr, value, from) => {
    try {
        contract.methods.delegate(addr, Web3.utils.toWei(value)).send({from});
    } catch (e) {
        console.log(e)
    }
};
// 取消投票
export const undelegate = async (addr, value, from) => {
    try {
        return contract.methods.undelegate(addr, Web3.utils.toWei(value)).send({from});
    } catch (e) {
        console.log(e)
    }

};

// VT 余额
export const balanceOf = async (addr) => {
    try {
        const v = await contract.methods.balanceOf(addr).call();
        return Web3.utils.fromWei(v, 'ether');
    } catch (e) {
        console.log(e)
    }


};

export const getPendingUndelegateTime = async (addr1, addr2) => {
    try {
       return await contract.methods.getPendingUndelegateTime(addr1, addr2).call();
    } catch (e) {
        console.log(e)
    }

}
