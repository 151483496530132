import {moment} from "@/utils/common";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
moment.extend(utc);
moment.extend(timezone);
let Public = new Object()
// account
Public.account = function (num) {
    //获得字符的前3位
    if (num) {
        var a = num.slice(0, 4)
        var b = num.substring(num.length - 6)
        var ok = a + '……' + b
        return ok
    }

},
  Public.timeStampToDate = function (timeStamp, reg = 'YYYY/MM/DD HH:mm:ss') {
    if (!Number(timeStamp)) return '...';
    return moment.utc(timeStamp*1000).tz(moment.tz.guess()).format(reg);
  }
    // Public.timeStampToDate = function (timeStamp, reg = 'YYYY/MM/DD HH:mm:ss') {
    //     if (!timeStamp) return '...';
    //     return moment.utc(timeStamp).tz(moment.tz.guess()).format(reg);
    // }
export default Public
