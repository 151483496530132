import Web3 from 'web3';
import withdrawAbi from '../../abi/withdraw.json';
import {getWithdraw} from "@/utils/address";
import {getStore} from '@/config/storage'

const contractAddress = getWithdraw;

const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
const web3js = new Web3(BitKeepProvider);
let contract = null;
if (web3js) {
    contract = new web3js.eth.Contract(withdrawAbi.abi, contractAddress);
}


// 提币
export const getTokenWithdraw = async (rewardsAmount,tokenAddress,signature,from) => {
    try {
     return await contract.methods.Withdraw(rewardsAmount,tokenAddress,signature).send({from});
    } catch (e) {
        console.log(e)
    }

}
