/**
 *@title
 *@author liqingjie
 *@date 2021/7/2 14:04
 */
import axios from 'axios';
import {
  cookies,
  md5, moment,
} from '../utils/common';
import { createDiscreteApi } from "naive-ui";
import { typeOf } from '../utils';
import {
  getStore,
  setStore
} from "@/config/storage";
import api from "@/api/api";
import store from "@/store";

console.log("1---------",window.location.origin,process.env.VUE_APP_BASE_TEST)
// let baseURL=process.env.NODE_ENV === 'development'?'':'http://pgtest.scidom.xyz/pego-server/'//测试服地址
let baseURL=process.env.VUE_APP_BASE_TEST==='production'?window.location.origin:process.env.VUE_APP_BASE_API
// let baseURL=process.env.NODE_ENV === 'development' ?'':window.location.origin//外部

const instance = axios.create({baseURL, timeout: 1000 * 60})


const {message} = createDiscreteApi(
    ["message"],
);
let isRewrite = false;
instance.interceptors.request.use((config) => ({
  ...config,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'X-B3-Traceid': moment().valueOf() * 1000,
    'X-B3-Spanid': moment().valueOf() * 1000,
    DEVICEID: md5(`${navigator.userAgent}${cookies.get('token')}`),
    DEVICESOURCE: 'web',
    HKVAXTOKEN: cookies.get('token') || '',
    'auth-token':getStore("PEGO_TOKEN")|| '',
    // lang: i18n.locale,
    lang: sessionStorage.getItem('lang')||"en-US",
    // SOURCEURL:config.source || window.location.origin,
    // SOURCEURL:process.env.NODE_ENV === 'development' ? config.source || window.location.origin:'http://pgtest.scidom.xyz/pego-server/',
    SOURCEURL:baseURL,
  },
}));

instance.interceptors.response.use(({
  data = {}, config = {}, headers,
}) => {
  // console.log({
  //   data,
  //   config,
  //   headers,
  //   request,
  // });
  if (/(?=<!DOCTYPE html)/.test(data)) {
    if (isRewrite) return;
    isRewrite = true;
    document.write(data);
    return;
  }
  // Do something with response data
  if (typeOf(data) !== 'object') return data;
  // 部分浏览器返回的header里面HKVAXTOKEN存在大小写区别
  const responseToken = headers?.HKVAXTOKEN || headers?.hkvaxtoken;
  switch (data?.code) {
    case '1000000': // 去登录
      // logOut(0);
      return Promise.reject(new Error(`${data.code} Time out`));
     // 暂时隐藏5-26
    // 节点领取6-8
     case '401': // 去登录
      // logOut(0);
       if(store.state.walletAddress){
         api.getNonce({prefix: "login", userAddress: store.state.walletAddress}).then(logins=>{
           console.log("logins",logins,logins.data)
           window.ethereum.request({
             method: "personal_sign",
             params: [logins.data || '', store.state.walletAddress]
           }).then(sign=>{
             api.walletLogin({
               nonce: logins.data,
               password:sign,
               username: store.state.walletAddress
             }).then(res => {
               console.log(res)
               if (res.code === 200) {
                 setStore("PEGO_TOKEN", res.data)
                 window.location.reload()
               } else {
                 setStore("PEGO_TOKEN", '')
               }
             })
           })
         })
       }
         return Promise.reject(new Error(`${data.code} Time out`));
    case '140002': // IP 地址变化
      // logOut(1);
      return Promise.reject(new Error(`${data.code} IP Change`));
    case '000000': // 正常
      if (responseToken && responseToken !== cookies.get('token')) cookies.set('token', responseToken);
      return Promise.resolve(data.data);
    default:
      console.log("default",data,config.closeFeedbackMessage,['110264'].includes(data?.code) )
      if (config.closeFeedbackMessage || ['110264'].includes(data?.code)) {
        return Promise.reject(data);
        // message.warning(data.msg)
      }
      message.warning(data.msg)
      // return Promise.reject(data);
  }
}, (error) => {
  const { response } = error;

  message.error(response?.data?.message)
  return Promise.reject(response.data);
});

export default instance;
