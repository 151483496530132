/**
 *@title
 *@author liqingjie
 *@date 2023/2/21 10:54
 */

import Web3 from 'web3';
import form from '../../abi/form.json';
import LP from '../../abi/LP.json';
import {farm} from "@/utils/address";
import {getStore} from '@/config/storage'

// export const contractAddress = farm;

let contract = null;

const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
const web3js = new Web3(BitKeepProvider);
if (web3js) {
  contract = new web3js.eth.Contract(form.abi, farm);
}

export const getTokenLp = async (address) => {
  try {
    return await contract.methods.getTokenLp(address).call();
  } catch (e) {
    console.log(e)
  }
};

export const token0 = async (address) => {
let tract= await new web3js.eth.Contract(LP.abi, address);
  try {
    return await tract.methods.token0().call();
  } catch (e) {
    console.log(e)
  }
};

export const token1 = async (address) => {
  let tract= await new web3js.eth.Contract(LP.abi, address);
  try {
    return await tract.methods.token1().call();
  } catch (e) {
    console.log(e)
  }
};


