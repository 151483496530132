import {createRouter,createWebHashHistory} from 'vue-router';
// 1. 定义路由组件.
// 也可以从其他文件导入
import Home from '../views/home.vue';
import Product from '../views/productCenter.vue';
import Scan from '../views/PEGOScan.vue';
import dao from '../views/DAO/DAO.vue';
import pgStrategies from '../views/pgStrategies.vue';
import blogLatest from '../views/blogLatest.vue';
import foundation from '../views/foundation.vue';
import proposals from '../views/DAO/proposals.vue';
import detail from '../views/DAO/detail.vue';
import dappDetail from '../views/detail.vue';
import applicationForm from '../views/applicationForm.vue';
import i18n  from "@/i18n";
// console.log("i18n",i18n,)
// i18n.global.t("message.homeHeader")
// i18n.global.t("message.message.Product")
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
    {path: '/',
        name: i18n.global.t("message.homeHeader"), component: Home,
        meta:{
            keywords:i18n.global.t("message.homeHeader"),
            description:i18n.global.t("message.infrastructure")
        }
    },
    {path: '/Eco', name: i18n.global.t("message.Build"), component: Product},
    {path: '/Validator', name: i18n.global.t("message.PEGOScan"), component: Scan},
    {path: '/DAO', name: i18n.global.t("message.Proposal"), component: dao},
    {path: '/Strategies', name: i18n.global.t("message.routeMap"), component: pgStrategies},
    {path: '/blogLatest', name: i18n.global.t("message.news"), component: blogLatest},
    {path: '/Foundation', name: i18n.global.t("message.foundation"), component: foundation},
    {path: '/proposals', name: i18n.global.t("message.Proposals"), component: proposals},
    {path: '/detail', name: i18n.global.t("message.Detail"), component: detail},
    {
        path: '/dapp/detail',
        name: 'dApp-detail',
        component: dappDetail,
    }, {
        path: '/dapp/applicationForm',
        name: 'dApp-applicationForm',
        component: applicationForm,
    },
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    // history: createWebHistory(),
    routes, // `routes: routes` 的缩写
})

router.beforeEach((to)=>{
    window.document.title = to.name;
})
export default router
