<template>
  <div class="">
    <div class="ti-card pad">
      <h1 data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500" v-html="$t(`message.ThePEGOFoundation`,{Foundations:$t(`message.Foundations`)})">
      </h1>
      <div class="txt-fu">
        <div data-aos-easing="linear"
             data-aos="fade-up"
             data-aos-duration="500">
          {{ $t(`message.pegoEmphasizes`) }}
        </div>
      </div>
    </div>
    <div class="grid-ul">
      <n-grid x-gap="24" y-gap="24" cols="1 l:2 xl:2 2xl:2" responsive="screen">
        <n-grid-item>
          <div class="gr-items" data-aos="flip-up"
               data-aos-easing="ease-in-sine"
               data-aos-duration="500">
            <div class="pad-l">
              <div class="h6">{{ $t(`message.StakePools`) }}</div>
              <div class="gr-txt">
                {{ $t(`message.stakePools`) }}
              </div>
            </div>
            <div class="but-ul">
              <div class="PDP buts" @click="stakePg" >{{ $t(`message.STAKEPG`) }}</div>
            </div>
          </div>
        </n-grid-item>
        <n-grid-item>
          <div v-if=" $t(`message.HundredFamily`) == '' " class="gr-items" data-aos="flip-down"
               data-aos-easing="ease-in-sine"
               data-aos-duration="500">
            <div class="pad-l">
              <div class="h6">{{ $t(`message.Grants`) }}</div>
              <div class="gr-txt">{{ $t(`message.thePegp`) }}
              </div>
            </div>
            <div class="but-ul">
              <div class="PDP buts" @click="$store.commit('iscomingModal', true)" >{{ $t(`message.APPLYFORGRANTS`) }}</div>
            </div>
          </div>
          <div v-else class="gr-items" data-aos="flip-down"
               data-aos-easing="ease-in-sine"
               data-aos-duration="500">
            <div class="pad-l">
              <div class="h6">{{ $t(`message.HundredFamily`) }}</div>
              <div class="gr-txt">{{ $t(`message.HundredFamilyDesc`) }}
              </div>
            </div>
            <div class="but-ul">
              <div class="PDP buts" @click="applyFor100Family" >{{ $t(`message.APPLY100Family`) }}</div>
            </div>
          </div>
        </n-grid-item>
      </n-grid>
      <div class="join pwg" data-aos="fade-down"
           data-aos-easing="linear"
           data-aos-duration="500">
        <div class="pwg-grant">
          <fragment v-if="!is100FamilyAvailable">
            <div class="cand-ti">{{ $t(`message.PWGBountyProgram`)}}</div>
            <p class="Grant">
              {{ $t(`message.Web3GrantProgram`)}}
            </p>
            <div class="Grant types">
              {{ $t(`message.PWGCanApplyForType`)}}：
            </div>
            <div class="li-txt">
              <div class="genre">{{ $t(`message.seedIncentive`)}}：{{ $t(`message.StartupFunding`)}}</div>
              <div class="txt">
                <div class="">{{ $t(`message.financialSupport`)}}：$5000-$20000</div>
                <div> {{ $t(`message.otherSupport`)}}：{{ $t(`message.ProjectLanding`)}}</div>
              </div>
            </div>
            <div class="li-txt">
              <div class="genre">{{ $t(`message.EcologicalCoCreation`)}}：{{ $t(`message.ecosystem`)}}</div>
              <div class="txt">
                <div class="">{{ $t(`message.financialSupport`)}}：$5000-$100000</div>
                <div>{{ $t(`message.CoCreationSupport`)}}：{{ $t(`message.ProvideFull`)}}</div>
              </div>
            </div>
            <div class="li-txt">
              <div class="genre">{{ $t(`message.SpecialType`)}}：{{ $t(`message.ProvideSpecial`)}}</div>
              <div class="txt">
                <div class="">{{ $t(`message.financialSupport`)}}：$50000-$1000000</div>
                <div>{{ $t(`message.AccelerateSupport`)}}：{{ $t(`message.TargetedTechnical`)}}</div>
              </div>
            </div>
            <div class="li-txt">
              <div class="genre">{{ $t(`message.StrategicInvestment`)}}：{{ $t(`message.MeetTheDevelopment`)}}</div>
              <div class="txt">
                <div class="">{{ $t(`message.financialSupport`)}}：$50000-$1000000</div>
                <div>{{ $t(`message.strategicSupport`)}}：{{ $t(`message.ImplementationOfThe`)}}
                </div>
              </div>
            </div>
          </fragment>
          <fragment v-else>
            <div class="li-txt">
              <div class="genre">{{ $t(`message.WhatIs100FamilyTitle`)}}</div>
              <div class="txt">
                <div class="">{{ $t(`message.WhatIs100Family`)}}</div>
              </div>
            </div>
            <div class="li-txt">
              <div class="genre">{{ $t(`message.Why100FamilyTitle`)}}</div>
              <div class="txt">
                <div class="">{{ $t(`message.Why100Family`)}}</div>
              </div>
            </div>
            <div class="li-txt">
              <div class="genre">{{ $t(`message.Type100FamilyTitle`)}}</div>
              <div class="txt">
                <div class="">{{ $t(`message.Type100Family`)}}</div>
              </div>
            </div>

            <div class="tableContainer">
              <table>
                <caption>{{ nonFarmTableData()['tableCaption'] }}</caption>
                <thead>
                  <tr>
                    <th v-for="title in nonFarmTableData()['tableHeader']">{{ title }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in nonFarmTableData()['tableRow']">
                    <td v-for="data in row">{{ data }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="tableContainer">
              <table>
                <caption>{{ farmTableData()['tableCaption'] }}</caption>
                <thead>
                  <tr>
                    <th v-for="title in farmTableData()['tableHeader']">{{ title }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in farmTableData()['tableRow']">
                    <td v-for="data in row">{{ data }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="li-txt">
              <div class="txt">
                <div>{{ $t(`message.Contact100Family`)}} <a class="contact" :href="this.$t(`message.Contact100FamilyLink`)">{{ $t(`message.Contact100FamilyName`)}}</a></div>
              </div>
            </div>
          </fragment>
        </div>
      </div>
    </div>


    <foot></foot>
  </div>
</template>

<script>
import foot from "@/components/foot";

export default {
  name: "foundation_1",
  components: {foot},
  data() {
    return {
      nonFarmTableData: () => {
        const language = sessionStorage.getItem('lang') || "en-US";
        if(language === "zh-CN") {
          return this.cn_TableData_NonFarm;
        } else if(language === "en-US") {
          return this.en_TableData_NonFarm
        }
      },
      farmTableData: () => {
        const language = sessionStorage.getItem('lang') || "en-US";
        if(language === "zh-CN") {
          return this.cn_TableData_Farm;
        } else if(language === "en-US") {
          return this.en_TableData_Farm
        }
      },
      cn_TableData_NonFarm: {
        'tableCaption': '非农场生态',
        'tableHeader': [
          '', 'Gas连续10天', 'W3挖矿', 'W3B', 'W3W', 'W3K', 'W3FT投票填'
        ],
        'tableRow': [
          ['种子', '≥200/每日', '√', 'X', 'X', 'X', 'X'],
          ['优质', '≥300/每日', '√', '√', 'X', 'X', 'X'],
          ['强势', '≥500/每日', '√', '√', '√', 'X', 'X'],
          ['核心', '≥800/每日', '√', '√', '√', '√', '√'],
        ]
      },
      cn_TableData_Farm: {
        'tableCaption': '农场生态',
        'tableHeader': [
          '', '10天LP体量', 'W3swap防夹子', '官网生态上架', 'W3钱包上架', 'W3swap上架', '自媒体官宣', '官方社区AMA', 'W3swap默认币种'
        ],
        'tableRow': [
          ['观察名单', '100,000 USD',	'√', 'X', 'X', 'X', 'X', 'X', 'X'],
          ['种子', '100,000 PG', '√', '√', '√', 'X', 'X', 'X', 'X'],
          ['优质', '200,000 PG', '√', '√', '√', '√', 'X', 'X', 'X'],
          ['强势', '300,000 PG', '√', '√', '√', '√', '√', 'X', 'X'],
          ['核心', '500,000 PG', '√', '√', '√', '√', '√', '√', '√'],
        ]
      },
      en_TableData_NonFarm: {
        'tableCaption': 'Non-Farm Ecosystem',
        'tableHeader': [
          'Level', 'Gas Continuous for 10 Days', 'W3 Mining', 'W3B', 'W3W', 'W3K', 'W3FT Voting & Fill' 
        ],
        'tableRow': [
          ['Seed', '≥200 Daily', '√', 'X', 'X', 'X', 'X'],
          ['High-Quality', '≥300 Daily', '√', '√', 'X', 'X', 'X'],
          ['Strong', '≥500 Daily', '√', '√', '√', 'X', 'X'],
          ['Ecosystem Core', '≥800 Daily', '√', '√', '√', '√', '√'],
        ]
      },
      en_TableData_Farm: {
        'tableCaption': 'Farm Ecosystem',
        'tableHeader': [
          'Level', '10-Day LP Volume', 'W3swap Anti-Whale', 'Official Website Integration', 'W3 Wallet Integration', 'W3 swap Listing', 'Self-Media Official Announcement', 'Official community AMA', 'W3swap Default Token'
        ],
        'tableRow': [
          ['Observer', '100,000 USD',	'√', 'X', 'X', 'X', 'X', 'X', 'X'],
          ['Seed', '100,000 PG', '√', '√', '√', 'X', 'X', 'X', 'X'],
          ['High-Quality', '200,000 PG', '√', '√', '√', '√', 'X', 'X', 'X'],
          ['Strong', '300,000 PG', '√', '√', '√', '√', '√', 'X', 'X'],
          ['Ecosystem Core', '500,000 PG', '√', '√', '√', '√', '√', '√', '√'],
        ]
      },
    }
  },
  methods:{
    stakePg(){
      window.open("https://w3swap.link/");
    },
    applyFor100Family() {
      const link = this.$t('message.APPLY100FamilyLink')
      if(link != '') {
        window.open(link)
      } else {
        this.$store.commit('iscomingModal', true)
      }
    },
  },
  computed:{
    is100FamilyAvailable() {
      let check = this.$t('message.WhatIs100FamilyTitle') != '' ? true : false;
      return check;
    },
  }
}
</script>

<style scoped lang="less">
.ti-card {
  padding: 60px 100px;
  background: url("@/assets/PEGO/Ellipse6.svg") no-repeat center top -75px;
  background-size: 100%;
  text-align: center;


  h1 {
    font-weight: 700;
    font-size: 60px;
  }

  .txt-fu {
    text-align: center;
    display: inline-block;

    div {
      text-align: left;
      width: 639px;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      font-family: LIGHT, sans-serif;
    }
  }
}

.pad {
  padding-bottom: 100px;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 122, 0, 0.1) 0%, rgba(255, 184, 0, 0.08) 40%, rgba(56, 57, 58, 0.05) 50%);
  border-image-slice: 1;

}

.grid-ul {
  padding: 60px 120px;
  background: linear-gradient(132.39deg, #313539 6.54%, #22262B 30.66%, rgba(32, 35, 38, 0) 45.46%), url("../assets/PEGO/Ellipse.png") no-repeat bottom;
  background-size: 100%;

  .gr-items {
    text-align: left;

    .pad-l {
      background: linear-gradient(180deg, #F38787 0%, #FFDF6F 100%) no-repeat;
      background-size: 3px 90%;
      background-origin: border-box;
      background-position: left top 12px;
      padding-left: 20px;
      width: 465px;
    }

    .h6 {
      font-weight: 600;
      font-size: 30px;
      //line-height: 41px;
      font-family: SEMIBOLD, sans-serif;
      padding: 0 0 30px 0;
    }

    .gr-txt {
      font-family: REGULAR, sans-serif;
      font-weight: 400;
      font-size: 20px !important;
      line-height: 30px;
      color: #EAF4FF;
    }

    .but-ul {
      padding-top: 75px;

      .buts {
        background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
        margin-right: 20px;
        min-width: 206px;
        height: 54px;
        line-height: 54px;
        font-size: 16px !important;
        color: #ffffff;
        font-family: RREGULAR, sans-serif;
        word-wrap: inherit;
        display: inline-block;
        padding: 0 30px;
      }
    }
  }
}

//join
.join {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0 20px;

  .j-cont {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 4px 24px 0.1px rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    padding: 45px 120px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 700;
      font-size: 60px;
      line-height: 134%;
      color: #FFFFFF;
      width: 40%;
      text-align: left;
      font-family: BOLD, sans-serif;
    }

    .j-img {
      display: flex;
      align-items: center;

      .buts {
        background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
        border-radius: 33px;
        min-width: 206px;
        height: 54px;
        line-height: 54px;
        font-size: 16px !important;
        color: #ffffff;
        font-family: SEMIBOLD, sans-serif;
        word-wrap: inherit;
        display: inline-block;
        padding: 0 30px;
      }

    }
  }
}

.pwg {

  .pwg-grant {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 4px 24px 0.1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 14px;
    padding: 75px 120px;
    text-align: left;
  }

  .cand-ti {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //background-clip: text;
    //text-fill-color: transparent;
    display: inline-block;
    font-family: BOLD, sans-serif;
    //padding: 10px 0;
  }

  .Grant {
    font-weight: 400;
    font-size: 20px;
    line-height: 50px;
    color: #EAF4FF;
    font-family: 'REGULAR', sans-serif;
  }

  .types {
    font-weight: 700;
    font-family: 'BOLD', sans-serif;
  }

  .li-txt {
    padding: 15px 0;

    .genre {
      display: inline-block;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #FF9D00;
      flex: none;
      order: 0;
      flex-grow: 0;
      border: 1px #FF9D00 solid;
      border-radius: 32px;
      padding: 10px 30px;
      margin: 15px 0;
      font-family: 'REGULAR', sans-serif;
    }

    .txt {
      div {
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;
        font-family: 'REGULAR', sans-serif;
      }
    }
  }

}

.contact {
  color: #ff9d00;
}

.tableContainer {
  overflow-x: auto;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  margin: 25px 0px;

  caption {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
  }

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even){
    background-color: #f2f2f245;
  }

  tr:hover {
    background-color: #7b6544;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #ff9d00;
    color: white;
  }
}
</style>
