<template>
  <div class="conts">
    <div class="t-t"><span>PEGO</span>{{ $t('message.EcologicalCooperationApplicationForm') }}</div>
    <div class="win-d">
    <div class="forms">
      <div>
        {{ $t('message.WelcomeToJoin') }}
      </div>
      <div class="p-t">
        <p>{{ $t('message.PEGOEcologicalBusiness') }}：</p>
        <p>{{ $t('message.Telegram') }}：https://t.me/PEGOWeb3_business</p>
        <p>{{ $t('message.Btok') }}：https://d65535.com/PEGOWeb3_business</p>
      </div>
      <div class="form-ul">
        <div class="form-t">
        <div class="ul-li">
          <div class="titl">1. {{ $t('message.projectName') }}</div>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.projectName`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">2. {{ $t('message.projectLogo') }}</div>
          <p class="txt">{{$t('message.ImageFormatsLike')}}</p>
          <div class="enter">
            <n-upload
                action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f"
                :default-file-list="fileList"
                list-type="image-card"
            >
              <div>
                <img src="@/assets/PEGO/ppder.svg" alt="" class="img-t">
                <p>{{$t('message.ChooseImage')}}</p>
                ({{ $t('message.Lessthan') }})
              </div>
            </n-upload>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">3. {{ $t('message.ProjectDescription') }}</div>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectDescription`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">4. {{ $t('message.itemCategory') }}</div>
          <p class="p-t">
            {{ $t('message.anyOtherType') }}
          </p>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.itemCategory`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">5. {{ $t('message.ProjectBenefits') }}</div>
          <p class="p-t">
            {{ $t('message.WhatCanItBringToThe') }}
          </p>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectBenefits`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">6. {{ $t('message.ProjectInfluence') }}</div>
          <p class="p-t">
            {{ $t('message.ProjectInfluence') }}
          </p>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectInfluence`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">7. {{ $t('message.WhatKindOfCooperation') }}<span>*</span></div>
          <div class="enter">
            <n-checkbox-group v-model:value="cities">
              <n-checkbox value="1" :label="$t('message.TokenListedOn')"/>
              <n-checkbox value="2" :label="$t('message.TokenListedOnW3swap')"/>
              <n-checkbox value="3" :label="$t('message.TokensListedOnW3Wallet')"/>
              <n-checkbox value="4" :label="$t('message.TokenListingMappingTool')"/>
              <n-checkbox value="5" :label="$t('message.TokenListedOnLunchpadHomepage')"/>
              <n-checkbox value="6" :label="$t('message.TokenOpening')"/>
              <n-checkbox value="7" :label="$t('message.TheTokenIsListed')"/>
              <n-checkbox value="8" :label="$t('message.TheProjectSeeks')"/>
              <n-checkbox value="9" :label="$t('message.TheApplicationEnters')"/>
              <n-checkbox value="10" :label="$t('message.Other')"/>
            </n-checkbox-group>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">8. {{$t('message.ProjectEconomicModel')}}</div>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectEconomicModel`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl"> 9. {{$t('message.ProjectInformation')}}</div>
          <p class="p-t">
            {{$t('message.ProjectPPTAndAny')}}
          </p>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectInformation`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">10. {{$t('message.ProjectOfficialWebsite')}}</div>

          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectOfficialWebsite`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">11. {{$t('message.ProjectTwitter')}}</div>

          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectTwitter`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">12. {{$t('message.ProjectTelegramCommunity')}}</div>

          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectTelegramCommunity`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">13. {{$t('message.ProjectDiscordCommunity')}}</div>

          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectDiscordCommunity`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">14. {{$t('message.ProjectGithub')}}</div>

          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ProjectGithub`)" class="seachs">
            </n-input>
          </div>
        </div>
        <div class="ul-li">
          <div class="titl">15. {{$t('message.ContactTelegramID')}} <span>*</span></div>
          <div class="enter">
            <n-input v-model:value="searchForm.name"
                     :placeholder="$t(`message.ContactTelegramID`)" class="seachs">
            </n-input>
          </div>
        </div>
        </div>

        <div class="submits">
          {{$t('message.Submit')}}
        </div>
      </div>
    </div>
    </div>


    <foot></foot>
  </div>
</template>

<script>
// import api from '@/api/api';
import foot from "@/components/foot";

export default {
  name: "applicationForm",
  components: {
    foot,
  },
  data() {
    return {
      searchForm: {
        name: ''
      }
    }
  }
}
</script>

<style scoped lang="less">
.conts {
  background: url("@/assets/PEGO/Ellipse6.svg") no-repeat, url("@/assets/PEGO/bgjojn.svg") no-repeat;
  background-position: center top -75px, right 30px bottom 0;
  background-size: 100%, 672px 672px;
  text-align: center;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //flex-direction: column;


}

.t-t {
  margin: 94px 0 50px;
  font-weight: 700;
  font-size: 60px;

  span {
    display: inline-block;
    line-height: 82px;
    background: linear-gradient(90.02deg, #F38787 0.87%, #FFDF6F 25.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }


}
.win-d{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 46px;
  width: 100%;
}
.forms {
  background: #131313;
  border-radius: 24px;
  padding: 44px 54px;
  width: 700px;
  text-align: left;
  font-size: 14px;
  --n-color-checked:#FFB800;
  .p-t {
    p {
      font-size: 14.004px;
      line-height: 10px;
    }
  }

  .form-ul {

    .ul-li {
      margin-bottom: 30px;
    }

    .titl {
      font-weight: 400;
      font-size: 15.996px;
      line-height: 25px;

      span {
        color: darkred;
      }
    }

    .txt {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
    }

    .p-t {
      font-weight: 400;
      font-size: 14.004px;
      line-height: 5px;
    }

    .enter {
      margin: 10px 0 10px 15px;

    }
  }

.form-t{
  padding-bottom: 51px;
}
}

.seachs {
  --n-border-hover: 1px solid rgba(255, 255, 255, 1) !important;
  --n-border-focus: 1px solid rgba(255, 255, 255, 1) !important;
  --n-caret-color: #FFFFFF !important;
  --n-border: 1px solid rgba(255, 255, 255, 0.3) !important;
  width: 100%;
  height: 34px;
  line-height: 34px;
  background: #2C2F34 !important;
  border-radius: 4px;
  caret-color: #2C2F34 !important;
  color: white !important;
  font-size: 16px;
  margin-right: 5px;
  text-align: left;

  ///deep/ .n-input__input-el {
  :deep(.n-input__input-el) {
    color: #ffffff !important;
    caret-color: #FFFFFF !important;
    -webkit-appearance: none;
    scrollbar-width: none;
    width: 100%;
    min-width: 0;
    height: 34px;
    line-height: 34px;
    background-color: transparent;
  }
}

.img-t {
  width: 47px;
  height: 43px;
}

:deep(.n-upload-trigger--image-card) {
  width: 150px;
  height: 150px;

}

:deep(.n-upload-dragger) {
  background: #2C2F34;
  border: 1px dashed #FFFFFF;
}
:deep(.n-checkbox){
  display: flex;
  font-size: 16px;
  line-height: 30px;
}
:deep(.n-checkbox__label){
  color:#ffffff;
  margin-left: 20px;
}

.submits {
  background: #FF9D00;
  border-radius: 33px;
  font-weight: 400;
  font-size: 16px;
  height: 54px;
  line-height: 54px;
  text-align:center;
  font-family: 'RREGULAR', sans-serif;

}
</style>
