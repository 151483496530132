<template>
  <div class="fot-bag">
    <div class="bag">
      <div class="ant">
        <p class="p-text">
          {{ $t(`message.SupportByPEGOFoundation`) }}
        </p>
        <div class="img-ul">
          <img data-aos="fade-right" data-aos-once="true" class="twitter" src="@/assets/PEGO/twitter-x.svg" alt="" @click="curlClick(1)">
          <img data-aos="zoom-in-up" data-aos-once="true" src="@/assets/PEGO/FJ.svg" alt="" @click="curlClick(2)">
          <img data-aos="zoom-in-down" data-aos-once="true" src="@/assets/PEGO/ve.svg" alt="" @click="curlClick(3)">
          <img data-aos="zoom-in-down" data-aos-once="true" src="@/assets/PEGO/Subtract.svg" alt="" @click="curlClick(5)">
          <img data-aos="fade-up-left" data-aos-once="true" src="@/assets/PEGO/gitbook.svg" alt=""
               @click="curlClick(4)">
<!--          <img data-aos="fade-up-left" data-aos-once="true" src="@/assets/PEGO/guthub.svg" alt="" @click="curlClick()">-->
        </div>
<!--        暂时隐藏-->
<!--          <p class="dibao">-->
<!--            {{$t(`message.TelegramCommunity`) }}-->
<!--          </p>-->
<!--          <div class="dis-ul">-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/yinwen.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/ribe.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/yi3.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/yin4.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="dis-ul">-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/yin5.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/yin6.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/yin7.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <img src="@/assets/PEGO/yin4.svg" alt="">-->
<!--              <span>{{$t(`message.EnglishCommunity`) }}</span>-->
<!--            </div>-->
<!--          </div>-->
      </div>
      <div class="li">
        <div data-aos="fade-left"
             data-aos-easing="ease-in-sine" data-aos-once="true">
          <div>{{ $t(`message.About`) }}</div>
          <p @click="Dapp">{{ $t(`message.Whitepaper`) }}</p>
          <p @click="$store.commit('isShowModalTele',true)">{{ $t(`message.ContactUs`) }}</p>
        </div>
        <div data-aos="fade-left"
             data-aos-easing="ease-in-sine" data-aos-once="true">
          <div>{{ $t(`message.Government`) }}</div>
          <p @click="$router.push('/PEGOScan')">{{ $t(`message.Validator`) }}</p>
          <p @click="$router.push('/DAO')">{{ $t(`message.proposal`) }}</p>
        </div>
        <div data-aos="fade-left"
             data-aos-easing="ease-in-sine" data-aos-once="true">
          <div>{{ $t(`message.Ecosystem`) }}</div>
          <p @click="walletClik('https://www.w3wallet.link/#/')">{{ $t(`message.Wallet`) }}</p>
          <p @click="pegoScan">{{ $t(`message.pegoScan`) }} </p>
          <p @click="walletClik('https://www.w3bridge.net')">{{ $t(`message.W3bridge`) }}</p>
          <p @click="walletClik('https://w3swap.link/')">{{ $t(`message.Exchange`) }}</p>
          <p @click="walletClik('https://w3bank.finance/')">{{ $t(`message.Loan`) }}</p>
        </div>
      </div>
    </div>
    <div class="fot-text">
      <div>
        {{ $t(`message.PEGOTechnology`) }}
      </div>
      <div>
        {{ $t(`message.ADAOCommunityBasedPublicChain`) }}
      </div>
    </div>
  </div>

</template>

<!--Icon1：电报-->
<!--中文链接：https://t.me/pegonetwork2-->
<!--英文链接：https://t.me/pegoofficial-->

<!--Icon2：推特-->
<!--https://twitter.com/pegonetwork-->

<!--Icon3：Discord-->
<!--https://dsc.gg/pegonetwork-->

<!--Icon4：Gitbook-->
<!--中文：https://docs.w3swap.finance/v/zh/-->
<!--英文：https://docs.w3swap.finance/-->
<script>
export default {
  name: "foot-1",
  methods: {
    curlClick(key) {
      switch (key) {
        case 1:
          // window.location.href = "https://twitter.com/pegonetwork";
          window.open("https://twitter.com/pegonetwork");
          break;
        case 2:
          // window.location.href = "https://t.me/pegoofficial";
          if (this.$i18n.locale!== "zh-CN") {
            window.open("https://t.me/pegoofficial");
          } else {
            window.open("https://t.me/pegonetwork2");
          }
          break;
        case 3:
          // window.location.href = "https://dsc.gg/pegonetwork";
          window.open("https://dsc.gg/pegonetwork");
          break;
        case 4:
          // this.$store.commit('iscomingModal', true)
          if (this.$i18n.locale!== "zh-CN") {
            window.open("https://pego-network.gitbook.io/pego-network/v/pego-wiki-1/");
          } else {
            window.open("https://pego-network.gitbook.io/pego-network/");
          }
          break;
        case 5:
          if (this.$i18n.locale !== "zh-CN") {
            window.open("https://t.me/PEGO_NEWS");
          } else {
            window.open("https://t.me/PEGO_cn");
          }
          break;
      }
    },
    pegoScan(){
      window.open("https://scan.pego.network/");
    },

    walletClik(url){
      window.open(url);
    },

    //白皮书
    Dapp(){
      if (this.$i18n.locale !== "zh-CN") {
        window.open(window.location.origin + "/pego/whitepaper_en.pdf");
      } else {
        window.open(window.location.origin + "/pego/whitepaper_zh-tw.pdf");
      }
    }
  }
}
</script>

<style scoped lang="less">
.fot-bag {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7.5px);
}

.bag {
  //background: #2E343C;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 10vw;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);

  .ant {
    text-align: left;

    .dibao {
      padding-top: 30px;
      font-weight: 700;
      font-size: 14px;
      font-family: RBLOD, sans-serif;
    }

    .dis-ul {
      display: flex;
      align-items: center;
      line-height: 60px;

      div {
        display: flex;
        align-items: center;
        padding-right: 25px;
        cursor: pointer;

        img {
          width: 25px;
          height: 25px;
          object-fit: contain;
          margin-right: 8px;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          color: #EEEEEE;
        }
      }

      div:hover {
        text-shadow: 1px 1px 2px #427388;
        transform: scale(1.1);
        color: #ffffff;
      }
    }
  }

  .p-text {
    font-weight: 700;
    font-size: 16px;
    font-family: 'BOLD';
    text-align: left;
  }

  .img-ul {
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-between;

    img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      outline: 1px solid;
      outline-color: rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      outline-offset: 0px;
      text-shadow: none;
      -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

      &:hover {
        transform: scale(1.5);
        cursor: pointer;
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 15px;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
        0 0 20px #FFB800;
        //background-blend-mode: lighten;
        background-image: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
        background-size: cover;
        background-blend-mode: multiply; //排除模式
        mix-blend-mode: difference;
      }

      &:active {
        animation: backOutUp; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 0.5s;
      }

      &:visited {
        background-image: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
        background-blend-mode: lighten;
      }
    }
  }

  .li {
    display: flex;
    width: 50%;
    justify-content: space-between;
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    padding-top: 20px;
    color: #ffffff;

    p {
      font-weight: 400;
      color: #eeeeee;
      font-size: 14px;
      font-family: 'RREGULAR', sans-serif;


      &:hover {
        color: #ffffff;
        text-shadow: 1px 1px 2px #427388;
        cursor: default;
        transform: scale(1.1);
        cursor: pointer;
      }
    }

    div {
      font-family: 'RBLOD';
    }
  }

}

.fot-text {
  height: 59px;
  color: #959595;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10vw;
  width: 100%;
  box-sizing: border-box;
  padding-right: 7vw;
  font-family: 'REGULAR';
}

.twitter {
  background: white;
}


</style>
