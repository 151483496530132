// import process from 'process';
// import { Message } from 'element-ui';
import { useMessage } from "naive-ui";
import { Decimal } from 'decimal.js';
import cookiesJs from 'cookies-js';
import { getDomain } from './index';

export { default as md5 } from 'md5';
export { default as moment } from 'dayjs';
export {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
const Message = useMessage();
export const toast = (options = {
  message: '成功',
  type: 'success',
}) => Message(typeof options === 'string' ? {
  message: options,
  type: 'warning',
} : options);

const production = process.env.NODE_ENV === 'production';
cookiesJs.defaults.expires = 60 * 60 * 24 * 7; // 默认7天
cookiesJs.defaults.domain = production ? getDomain() : 'localhost';

export const BigJs = Decimal;
export const cookies = cookiesJs;
