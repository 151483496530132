export default {
    message: {
        Product: 'Home',
        Network: 'Governance',
        Build: 'Network',
        PEGOScan: 'Validator',
        bridge: 'Cross-chain Bridge',
        Proposal: 'DAO',
        browser: 'Blockchain Explorer',
        BecomeAValidator: 'Become a Validator',
        wallet: 'Wallet',
        news: 'News',
        routeMap: 'Roadmap',
        foundation: 'Foundation',
        EN: 'English',
        ZN: '繁体中文',
        JP: '日本語',
        KO: '한국인',
        TH: 'แบบไทย',
        VI: 'Tiếng Việt',
        Whitepaper: 'Whitepaper',
        Blog: 'Blog',
        Foundation: 'Foundation',
        blockchain: "The <span class='nameColor'>{name}</span> built for everyone",
        name: 'Blockchain',
        Support: 'Support decentralized applications with high-autonomy,',
        scalability: 'scalability, and sustainability.',
        solution: 'A high-performance solution for Web3 and AI development.',
        EnterTheDAPP: 'Whitepaper',
        ReadDocs: 'Docs',
        GITHUB: 'GitHub',
        MainnetLaunchCountdown: 'Mainnet Launch Countdown',
        Days: 'Days',
        Hours: 'Hours',
        Minutes: 'Minutes',
        PEGOSolutions: 'PEGO Solutions',
        PPOS: 'PPOS',
        EnablesSustainable: 'Enables sustainable ecosystem and uses a fair and secure governance model.',
        EVMCompatible: 'EVM Compatible',
        CompatibleWith: 'Compatible with Golang, Rust, Solidity, and EVM virtual machines to create a developer-friendly environment for building dApps that can work seamlessly across different platforms.',
        NFTPNNIdentityVerificationSystem: "NFT-PNN Identity Verification System",
        EnsuresNetwork: "Ensures network decentralization and security by allowing users to become network validation nodes through PNN staking and PGVT voting.",
        CommunityProposedProposals: 'Community-proposed Proposals',
        ProvidesFlexibility: 'Provides flexibility to adjust network parameters through community-proposed proposals.',
        AIIntegration: 'AI Integration',
        IntegratesWith: "Integrates with AI to enhance the processing speed and efficiency required for AI applications, while maintaining transparency and security.",
        DAOGovernment: 'Ecological Governance',
        partner: 'Partner',
        PEGOIsGoverned: 'PEGO is governed by a global DAO through its native crypto asset: PG.',
        BuyPG: 'Buy PG',
        PGAllowsWeb3: "PG allows Web3 community members to participate in protocol upgrade proposals, validation of PEGO's blockchain network, staking for rewards, burn auctions and more.",
        SupportByPEGOFoundation: 'Supported by PEGO Foundation',
        ContactUs: 'Contact Us',
        Government: 'Government',
        Validator: 'Validator',
        Wallet: 'Wallet',
        Ecosystem: 'Ecosystem',
        W3bridge: 'Cross-chain Bridge',
        Exchange: 'Exchange',
        Loan: 'Lending',
        W3BANK: 'W3Bank',
        PEGOTechnology: '© 2023 PEGO Technology',
        ADAOCommunityBasedPublicChain: 'An open, autonomous, scalable, and sustainable infrastructure for Web3 applications.',
        Built: `Built with <span class='nameColor'>{network}</span>`,
        network: "PEGO Network?",
        PEGOHasDesigned: "PEGO has designed a fully community-driven autonomous Web3 infrastructure, where all on-chain parameters can be flexibly adjusted through proposals initiated by the community. According to the PPOS consensus, the established composite economic incentive mechanism can promote the sustainable development of the PEGO ecosystem. Additionally, the PEGO blockchain is fully compatible with the EVM system, making it an ideal choice for developers aiming to build scalable decentralized applications.",
        SubmitYourProject: "Submit your project",
        SubmitYourProjectLink: "https://forms.gle/kTMANTFCiWjL78xz9",
        ApplyForInvestment: "Apply for investment",
        useCases: "Use Cases",
        LoremIpsum: "The diverse Web3 applications are the greatest value of the PEGO Network, among which may hide the killer applications of the future.",
        CrossChain: "Cross-Chain",
        CrossChainBrigde: "Cross-Chain Brigde",
        JoinTheCommunity: `Join the <span class='nameColor'>{Community}</span>`,
        Community: "Community",
        PEGODiscordSupport: "PEGO Discord Support",
        ToBeA: `To be a  <span class='nameColor'>{TOValidator}</span>`,
        TOValidator: "Validator",
        PEGOIsCommitted: "PEGO is committed to building a fully community-driven decentralized network, and the design of validator nodes is crucial to achieving this goal. Using the PPOS consensus mechanism, PEGO promises that all participants in the decentralized network can collaborate more effectively under a fair mechanism.",
        CONNECT: "CONNECT",
        applyToBe: "APPLY TO BE A VALIDATOR",
        PEGOValidator: "PEGO validator nodes are characterized by a specific equity ownership subject, the NFT-PNN \"identity card\" of the validator node.  Candidates become PEGO network validator nodes by pledging PNN and participating in the election of formal validator nodes.",
        NFTPNNIDENTITYCARD: "NFT-PNN IDENTITY CARD",
        MyAccount: " My Account",
        VotingReward: "Voting reward",
        TokenBalance: "Token balance",
        VotingPower: "Vote",
        VotedValidators: "Voted Validators",
        Rank: "Rank",
        Address: "Address",
        Status: "Status",
        MyShare: "My share",
        Operate: "Operate",
        ValidatorStatus: "Validator Status",
        TotalVotes: "Total votes",
        NextRound: "Next round",
        ValidatorRanking: "Validator Ranking",
        search: "search",
        Ranking: "Ranking",
        LatestBlock: "Latest Block",
        ValidatedBlocks: "Validated Blocks",
        Votes: "Votes",
        Action: "Action",
        Vote: "Vote",
        PEGO: `PEGO  <span class='nameColor'>{dao}</span>`,
        dao: "DAO",
        PEGOEmphasizes: " PEGO emphasizes efficiency and allows all parameters in the chain to be flexibly adjusted through community-initiated proposals. Nodes or ordinary users can initiate proposals by pledging a certain amount of PG. If the proposal is voted through within the specified time, it will be executed and take effect through a chain transaction.",
        VotingTaken: 'Voting taken',
        Sponsor: "Sponsor",
        Active: "Active",
        Executed: "Executed",
        Voted: "Voted",
        SUBMITPROPOSAL: "SUBMITPROPOSAL",
        OnChainParameters: "On -Chain Parameters",
        PendingTime: "Pending Time",
        VotingTime: "Voting Time",
        ExecutionTime: "Execution Time",
        TotalVotesTotalVt: "Total Votes/Total VT",
        SupportedVotesTotalVotes: "Supported Votes/Total Votes",
        StakePG: "Stake PG",
        Proposals: "Proposals",
        SEEALL: "SEE ALL",
        ThreeStages: `<span class='nameColor'>{PGStrategies}</span> in Three Stages`,
        PGStrategies: "PG Strategies",
        BasedOnDao: "Based on DAO",
        InTheInitial: "During the initial development stages of PEGO's public chain, all preparations will be based on the DAO concept within the ecosystem. This will ensure that it can grow for all and by all. Additionally, the ecosystem will be governed under DAO governance, allowing for democracy and fairness in accordance with self-governance rules.",
        DAODevelopment: "Developing DAO",
        InThePEGO: "Various types of DAO will emerge within the PEGO Network. As the ecosystem attracts more participants, the need for DAO governance will increase. This could accelerate the adoption of blockchain technology.",
        ServingDAO: "Serving DAO",
        AllBlockchainBased: "All DAOs based on blockchain are owned by members of the community. Following the three-stage strategy, PEGO Network aims to become the first public chain in the world that is fully governed by DAOs.",
        PEGORoadmap: "PEGO Roadmap",
        latestUpdates: "& latest updates",
        TheProposal: "The proposal of PEGO Network’s Update Tokenomics",
        publicChain: "Public Chain Info: PEGO Network Token Ticker: PG As the governance token of PEGO Network, PG is also issued on the BSC chain for cross-chain interactions...",
        TheW3BIWO: "The W3B IWO will open soon",
        theFuture: "The future of W3Bridge is to be the bridge of the fragmented Web3 world, which will be realized based on the ecosystem development of PEGO in the early stage. With the expanded ecosyste...",
        PEGONetwork: "PEGO Network launches PN Governance token",
        toIncentivese: "To incentivise global preachers of PEGO Network, PEGO Network will launch PN as the governance token of its community.The token will be applied to rewarding ...",
        ThePEGOFoundation: `The PEGO <span class='nameColor'>{Foundations}</span>`,
        Foundations: 'Foundation',
        pegoEmphasizes: "The PEGO Foundation is established to support and develop the PEGO network and its community. It comprises core volunteers from the community and industry experts from various fields worldwide.",
        StakePools: "Stake Pools",
        stakePools: "Stake Pools are a liquid staking solution that promote censorship resistance,decentralization, and the growth of DeFi on PEGO.",
        STAKEPG: "STAKE PG",
        Grants: "Grants",
        thePegp: "The PEGP Foundation Grants Program is focused on funding efforts to further censorship resistance on the PEGO Network.",
        APPLYFORGRANTS: "APPLY FOR GRANTS",
        HundredFamily: "Hundred-Family Ecosystem 2.0",
        HundredFamilyDesc: "A comprehensive upgraded ecological support strategy, providing full lifecycle funding to every Web3 rising star on the PEGO chain.",
        APPLY100Family: "APPLY TO JOIN",
        APPLY100FamilyLink: "https://forms.gle/jNmpn4qvSWFq98aGA",
        JOINPEGOFOUNDATION: "JOIN PEGO FOUNDATION",
        Ended: "Ended",
        linkWallet: "Connect",
        BNBSmartChain: "BNB Smart Chain",
        TelegramCommunity: "Telegram Community",
        EnglishCommunity: "English Community",
        validator: "Validator",
        quitValidator: "Quit the Validator Election",
        claim: "Claim",
        GetPGVT: "Get PGVT",
        voteValidator: "Vote Validator",
        ReturnPGVT: 'Redeem PG/W3FT',
        ReturnPGVT2: 'Inoperable during cooldown',
        ReturnPGVT3: 'Insufficient balance',
        IncreaseVote: 'Add',
        Refund: 'Withdraw',
        quitTheValidator: 'Quit the Validator Election',
        onceQuited: 'Once quited, you will no longer get PG reward anymore!',
        andYour: 'You cannot recover your PNN',
        noIDont: 'No, I don’t want to quit',
        SureIWant: 'Sure, I want to quit',
        getPGVT: 'Get PGVT',
        pleaseEnter: 'Please Enter',
        MAX: 'MAX',
        Balance: 'Balance',
        BalancePGVT: 'PGVT餘額',
        returnPGVT: 'Return PGVT',
        youWantTo: 'Redeem',
        YourBalanceIs: 'Your Balance is',
        isYourBalanceIsVT: 'VT  balance',
        YourBalanceIsVT: 'Your VT balance is insufficient',
        YourBalanceIs1:'Amount minted from this source',
        YouneedReturn: 'You need return',
        CooldownCountdown: 'Cool off Period',
        Seconds: 'Seconds',
        VoteAmount: 'Vote Amount',
        ValidatorAddress: 'Validator Address',
        Withdraw: 'Withdraw Vote',
        Withdraw2: 'Inoperable during cooldown',
        WithdrawAmount: 'Withdraw Votes',
        NoticeYou: 'Notice: You will get your PGVT back after the cooling off period.',
        ExchangeNFTPNNT: "Mint NFT-PNNT",
        ValidatorApplication: "Validator Application",
        SorryYou: "You do not hold PNN-NFT yet,",
        anyPNNNFT: "Please use PNN for mint",
        // howToGet: "How to get a NFT-PNN（PEGO-Chain）？",
        // IfYouHave: "If you have PNN-BSC token,",
        clickHere: "Mint",
        // toMint: "to mint a PNN-NFT (PEGO Chain).",
        ConnectWallet: "Connect Wallet",
        YourPNNNFT: "Your PNN NFT",
        applyToBeAValidator: "APPLY TO BE A VALIDATOR",
        Transaction: "Transaction",
        CongratulationsYou: "Congratulations! You are a validator now!",
        JoinOur: 'Join our',
        discord: 'discord',
        toGetHow: 'to get how to run a validator.',
        Failed: 'Failed',
        Pending: 'Transaction Initiated',
        MoreAboutDAO: 'More about DAO',
        CreatProposal: "Creat Proposal",
        CoolingOffPeriodOfPGVT: "Cooling off Period of PGVT",
        CoolingOffPeriodOfPGW3FT: "Cooling off Period of PG&W3FT",
        ValidatorDistrbution: "Validator Distrbution",
        AdditionalPGIssued: "Additional PG Issued",
        LowestGasFee: "Lowest Gas Fee",
        BlockReward: "Block Reward",
        ValidatorNumbers: "Validator Numbers",
        SupportedToken: "Supported Token",
        Blacklist: "Blacklist",
        TotalVT: "Total VT",
        Supports: "Support",
        Invalid: "Invalid",
        Passed: "Passed",
        Core: "Core",
        ProposalType: "Proposal Type",
        AdjustThe: "Adjust the parameters of Proposal",
        AddOrDelete: "Add or Delete the Farming Token to get PGVT",
        AdjustTheDistribution: "Adjust the Distribution proportion of Reward",
        BlaDecentralized: "BlaDecentralized Foundation will issue additional PG to the Sponsor's addresscklist",
        AdjustTheLowestGasFee: "Adjust the Lowest Gas fee",
        AdjustTheBlockReward: "Adjust the Block Reward",
        AdjustThenumberOfValidators: "Adjust the number of Validators",
        ChoosePDP: "Choose PDP",
        ParametersChanges: "Parameters Changes",
        NowIs: "Now is",
        ExeuctionTime: "Exeuction Time",
        TotalVotesvt: "Total Votes / Total VT",
        SupportedVotesTotalVotess: "Supported Votes / Total Votess",
        AddDelete: "Add/Delete",
        AddANewFarmingToken: "Add a new Farming Token",
        DeleteoneFarmingToken: "Delete one Farming Token",
        TokenAddress: "Token Address",
        CoolingOffPeriodOf: "Cooling off Period of",
        TheDistributionProportionForValidator: "Validator and voter distribution ratio, voters now account for",
        AddAnAddressToBlacklist: "Add an address to blacklist",
        DeleteAnAddressOutOfBlacklist: "Delete an address out of blacklist",
        Amount: "Amount",
        TheAmountOfPGIssued: "The amount of PG issued",
        NumberOfValidators: "Number of Validators",
        YouNeedToStake: "You need to stake",
        makeSureYou: "PG, make sure you have enough balance.",
        PGBalance: "PG Balance",
        FailedToCreateProposal: "Failed To Create Proposal",
        Detail: "Detail",
        StartTime: "Start time",
        VoteTime: "Vote time",
        TotalVoteToken: "Total Vote Token",
        Against: "Against",
        GetBackVT: "Get Back VT",
        Execute: "Execute",
        Metamask: "Metamask",
        DoNotHaveMetamask: " Do not have Metamask ?",
        Install: " Install",
        YoureAlreadyAValidator: " You’re already a validator!",
        Error: " Error",
        Successful: " Successful",
        GetBackPG: " Get Back PG",
        About: "About",
        proposal: "Proposal",
        pegoScan: "Blockchain Explorer",
        other: "About",
        comingSoon: "Coming soon",
        PWGBountyProgram: 'PWG Bounty Program',
        Web3GrantProgram: 'The PEGO Web3 Grant program aims to provide comprehensive support in terms of funding, technology, and resources to emerging forces of the Web3 application layer.',
        PWGCanApplyForType: 'Types of PWG applications',
        seedIncentive: 'Seed Incentive',
        StartupFunding: 'Provides start-up funds for excellent Web3 ideas.',
        financialSupport: 'Financial support',
        otherSupport: 'Other support',
        ProjectLanding: 'Project landing consultation',
        EcologicalCoCreation: 'Ecological Co-creation',
        ecosystem: 'Become an important part of the PEGO Web3 ecosystem.',
        CoCreationSupport: 'Co-creation support',
        ProvideFull: "Provides full technical support, matches corresponding cooperative resources according to development needs, and integrates into PEGO's overall operational activities.",
        SpecialType: 'Layer2 & 3 Special',
        ProvideSpecial: 'Provides special support for those establishing L2 and L3 on PEGO.',
        AccelerateSupport: 'Acceleration support',
        TargetedTechnical: 'Provides targeted technical support, helps to build core team.',
        StrategicInvestment: 'Strategic Investment',
        MeetTheDevelopment: 'In line with the strategic development needs of the PEGO ecosystem.',
        strategicSupport: 'Strategic support',
        ImplementationOfThe: 'Full lifecycle support for implementation, strategy, business operations, technical and business consulting, comprehensive team building and management support, linking top-tier industry resources, and follow-up financing coaching.',
        WhatIs100FamilyTitle: 'What is the Hundred-Family Ecosystem 2.0',
        WhatIs100Family: "The Hundred-Family Ecosystem 2.0 is a comprehensive upgrade to the existing PEGO Hundred-Family Ecosystem. Projects can now receive more comprehensive, multi-layered, and targeted empowerment. This primarily includes empowering projects' LP to enhance project value and increasing project exposure through the official PEGO ecosystem matrix.",
        Why100FamilyTitle: 'Why is the Hundred Families Ecosystem Needed',
        Why100Family: "The Hundred Families Ecosystem's main focus is to nurture high-quality projects for the PEGO ecosystem. A thriving and robust application layer is also PEGO's most core competitive strength in the external world. High-quality projects can receive the essential resource support at various stages of development, gain liquidity and user market infusion, and accelerate their entry into the explosive growth phase.",
        Type100FamilyTitle: 'Hundred-Family Ecosystem 2.0 Levels',
        Type100Family: 'Observer Class, Seed Class, High-Quality Class, Strong Class and Ecosystem Core Class',
        Contact100Family: 'For application inquiries, please contact Telegram ID:',
        Contact100FamilyName: 'PEGOWeb3_business',
        Contact100FamilyLink: 'https://t.me/PEGOWeb3_business',
        VoterDistribution: 'Voter distribution ratio',
        homeHeader: 'PEGO Network - The blockchain built for everyone',
        infrastructure: 'Web3 infrastructure. PPOS Consensus. Virtual Machine (VM). DAO Governance. Blockchain tools. PEGO is the blockchain infrastructure for everyone in the Web3 era.',
        CompleteTheMainnetLaunch: 'Mainnet launch completed.',
        CompleteNodeCampaign: 'Node election completed.',
        W3swapLaunched: 'W3swap launched.',
        W3WalletIsOnline: 'W3 wallet launched.',
        ListedOnTheWorld: 'Listed on major global exchanges.',
        StartApplicationEcologicalConstruction: 'Started building the application ecosystem.',
        PromoteMEMEEcologicalConstruction: "Promoting MEME ecosystem development.",
        PromoteFOMO: "Promoting FOMO ecosystem development.",
        StarttheGlobal: "Initiated global ecological roadshow.",
        AchieveEcological: "Achieved 200 ecological launches.",
        TheNumberOfValid: "Over 1 million effective wallets.",
        TheTotalNumber: "Exceeded 5 million total block transactions.",
        TVLExceeded: "TVL surpassed 100 million US dollars.",
        CompleteTheLaunch: 'Listed on most mainstream global exchanges.',
        AchieveEcologicalOnline: 'Achieved 500 ecological launches.',
        TheNumberOfEffective: 'Over 2 million effective wallets.',
        heTotalNumberOfBlock: 'Exceeded 20 million total block transactions.',
        TVLExceededBillion: 'TVL surpassed 1 billion US dollars.',
        Alreadyin: 'Already in',
        NotIn: 'Not in',
        FillInAAddressAlreadyIn: 'Fill in a addressAlready in',
        FillInTheTokenContractAddress: "Fill in the token contract address",
        AlreadySupported: "Already supported",
        DecentralizedFoundationWillIssue:"Decentralized Foundation will issue additional PG to the Sponsor's address",
        GasFee:"Gas Fee",
        Add:'Add',
        Delete:'Delete',
        W3swap:'W3swap',
        AMegaPlatform:'A mega platform combining all the best trading solutions',
        W3Wallet:'W3Wallet',
        MultiFunction:'Multi-function Web3 cross-chain wallet based on the PEGO Network ecosystem',
        W3Bridge:"W3Bridge",
        RebuildTheFragmented:"Rebuild the Fragmented Web3 Network",
        EnterAsAnInteger:"Please enter a positive integer",
        NetworkError:"Network Error",
        nodeReward:"NODE Reward",
        award:"Award",
        toBeCollected:"To be collected",
        received:"Received",
        NumberOfBlocks:'Number of blocks',
        EcologicalDAPP:'Ecological DAPP',
        receiveAward:'Receive',
        pleaseGoToWallet:'Please go to wallet to switch network',
        AuditReport:'Audit Report',
        ProposalDescription:'Proposal Description',
        nodeCard:'Casting Node Card',
        PDP1:'PDP-1 Type Proposal',
        DetermineOr:'Purpose: Used to determine or adjust some key parameters of DAO governance proposals',
        TheAdjustableParameters:'Adjustable parameters are as follows: Proposal publicity period, voting period//execution period/the number of PGs that need to be pledged for the proposal/1 type of condition for the proposal to take effect/2 types of conditions for the proposal to become effective',
        PDP2:'PDP-2 Type Proposal',
        decideWhich:'Decide which type of farm tokens generated by LP can be used to pledge to obtain PGVT (if the number of PG in LP is less than 500,000, PGVT cannot be minted after the proposal is passed)',
        PDP3:'PDP-3 Type Proposal',
        adjustTheCooling:'Used to determine and adjust the duration of the PGVT minting redemption cooling period and VT voting redemption cooling period',
        PDP4:'PDP-4 Type Proposal',
        usedToDetermine:'Used to determine and adjust the reward distribution ratio of nodes and voters',
        PDP5:'PDP-5 Type Proposal',
        controlMalicious:'Decided to implement freezing or risk control measures on certain malicious contracts and accounts to quickly control and eliminate the adverse effects of malicious behavior',
        PDP6:'PDP-6 Type Proposal',
        controlMaliciousContracts:'For additional issuance of PG, any user can initiate a proposal',
        PDP7:'PDP-7 Type Proposal',
        usedToAdjust:'Used to adjust the minimum Gas fee on the chain to meet the needs of different stages',
        PDP8:'PDP-8 Type Proposal',
        adjustTheBlock:'Adjust the value of the block reward',
        PDP9:'PDP-9 Type Proposal',
        adjustTheNumber:'Adjust the online number of verification nodes',
        BitKeep:'BitKeep',
        TOKENPOCKET:'TOKEN POCKET',
        OKXWallet:'OKX Wallet',
        //6-13
        votingAddress:'Voting Address',
        numberOfVotes:'Number Of Votes',
        votingTime:'Voting Time',
        votingList:'Voting List',
        ExploreThePEGOEcosystem:'Explore the PEGO Ecosystem',
        submitProject:'Submit Project',
        allItems:'All items',
        all:'ALL',
        startSearching:'Start',
        audit:'Audit',
        CreatingAndOwning:'Creating and owning a wallet is the first step to connect PEGO and enter Web3, please follow the steps to create or connect a wallet. Remember to keep your wallet private key and seed phrase safe.',
        OpeningMetamask:"Opening Metamask",
        PleaseConfirmInMetamask:"Please confirm in Metamask",
        OpeningBitkeep:"Opening Bitkeep",
        NotInstalled:"Not Installed",
        OpeningOKXWallet:"Opening OKX",
        EcologicalCooperationApplicationForm:"Ecological cooperation application form",
        WelcomeToJoin:"Welcome, we will contact you as soon as possible after submitting the information, please be sure to fill in the following information before then.",
        PEGOEcologicalBusiness:"PEGO ecological business cooperation official contact ID",
        Telegram:"Telegram",
        Btok:"Btok",
        projectName:"Project name",
        projectLogo:"Project logo",
        ImageFormatsLike:"Image formats like .jpg, .png, .gif, .bmp, .psd, .tiff are supported.",
        ChooseImage:'Choose Image',
        Lessthan:'Less than 500MB',
        ProjectDescription:'Project Introduction',
        itemCategory:'Item category or positioning',
        anyOtherType:'DEX, NFT or any other type',
        ProjectBenefits:'Project advantages',
        WhatCanItBringToThe:'What can it bring to the PEGO ecosystem?',
        ProjectInfluence:'Project influence',
        WhatKindOfCooperation:'In what aspect do you want to cooperate with the PEGO ecosystem?',
        TokenListedOn:'Token listed on W3 cross-chain bridge？',
        TokenListedOnW3swap:'Token listed on W3swap',
        TokensListedOnW3Wallet:'Tokens listed on W3 wallet',
        TokenListingMappingTool:'Token Listing Mapping Tool',
        TokenListedOnLunchpadHomepage:'Token Listed on Lunchpad Homepage',
        TokenOpening:'Token opening BTOK-PEGO chain Token ranking/community red envelope',
        TheTokenIsListed:'The token is listed on the AVE-PEGO board',
        TheProjectSeeks:'The project seeks technical, financial and other resource support',
        TheApplicationEnters:'The application enters the PEGO public chain',
        Other:'Other',
        ProjectEconomicModel:'Project economic model',
        ProjectInformation:'Project information',
        ProjectPPTAndAny:'Project PPT and any other project-related materials can be uploaded',
        ProjectOfficialWebsite:'Project official website',
        ProjectTwitter:'Project Twitter',
        ProjectTelegramCommunity:'Project Telegram community',
        ProjectDiscordCommunity:'Project Discord community',
        ProjectGithub:'Project Github',
        ContactTelegramID:'Contact Telegram ID',
        Submit:'Submit',
        PleaseEnterAKeyword:'Please enter a keyword',
        downloadFile:"Download",
        votingRecord:"Voting records.csv",
        previousPage:"Last page",
        nextPpage:"Next page",
        ReturnPGVTWPG:"Unable to cast",
        officialEmail:'Official email',
        businessCooperation:'Business Cooperation',
        copy:'Copy successfully',
    },
}
