import axiosInstance from './index';

class Service {
    constructor() {
        this.api = axiosInstance;
    }

    getDAppList(data) {
        return this.api.post('/app/dapp/list', data);
    }

    getDAppTypeList() {
        return this.api.get('/app/dapp/type_list');
    }

    bannerList() {
        return this.api.get('/app/article/banner_list');
    }

    articleList(data) {
        return this.api.post('/app/article/list', data);
    }

    articleDetail(data) {
        return this.api.post('/app/dapp/detail', data);
    }

    articleType(data) {
        return this.api.get('/app/article/list_type', data);
    }

    // nft
    nft(data) {
        return this.api.post('/app/nft/asset', data);
    }

    // dao
    createProposal(data) {
        return this.api.post('/app/dao/createProposal', data);
    }

    proposalName(data) {
        return this.api.post('/app/dao/names', data);
    }

    summary(data) {
        return this.api.post('/app/dao/summary', data);
    }

    //获取签名
    getNonce(data) {
        return this.api.get('/app/auth/getNonce', data);
    }

    //登录
    walletLogin(data) {
        return this.api.post('/app/auth/walletLogin', data);
    }

    //登出
    outLogin(data) {
        return this.api.get('/app/auth/outLogin', data);
    }


    //资产列表
    walletList(data) {
        return this.api.get('/app/wallet/list', data);
    }

    //提币
    getWithdrawCoin(data) {
        return this.api.post('/app/wallet/withdrawCoin', data);
    }

    //获取出块数
    getBlockNum(data) {
        return this.api.get('/app/wallet/getBlockNum', data);
    }

//账户余额
    getAccountAvailableBalance(data) {
        return this.api.get('/app/wallet/getAccountAvailableBalance', data);
    }

    //提币记录
    withdrawPage(data) {
        return this.api.get('/app/wallet/withdraw-page', data);
    }


}

export default new Service();
