<template>
  <div class="cont">
    <n-message-provider>
      <HelloWorld class="head"/>
      <router-view class="cons"></router-view>
      <!--   -->
      <n-config-provider :theme-overrides="themeOverrides">
        <!--      链接钱包-->
        <n-modal v-model:show="showModal"
                 preset="dialog"
                 title=""
                 :show-icon="false"
                 @close="OnUpdate"
                 @esc="OnUpdate"
                 @maskClick="OnUpdate"
                 positive-text=""
                 negative-text="">

          <template #action>
            <div class="tra" :style="{ width:'2.5rem'}">
              <h1>{{ $t(`message.ConnectWallet`) }}</h1>
              <div>
                <img src="@/assets/PEGO/mack.png" alt="" class="macts">
              </div>
              <div class="font-text">
                {{ $t(`message.Metamask`) }}
              </div>
              <div class="mod-but pgBut">
                <n-button round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
                          @click="connectClick">
                  {{ $t(`message.CONNECT`) }}
                </n-button>
              </div>
              <div class="txt">
                {{ $t(`message.DoNotHaveMetamask`) }}<a
                  href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
                  target="_blank">{{ $t(`message.Install`) }}</a>
              </div>
            </div>
          </template>
        </n-modal>
        <!--        多钱包-->
        <n-modal v-model:show="showManyModal"
                 preset="dialog"
                 title=""
                 :show-icon="false"
                 @close="OnManyUpdate"
                 @esc="OnManyUpdate"
                 @maskClick="OnManyUpdate"
                 positive-text=""
                 negative-text=""
                 class="modal-many">

          <template #action>
            <div class="tra tra-dis" :style="{ width:'5rem'}">
              <div class="tra-l">
                <div class="consts">{{ $t(`message.ConnectWallet`) }}</div>
                <div class="p-txt">
                  {{ $t('message.CreatingAndOwning') }}
                </div>
                <div class="dists">
                  <div @click="connectClick('Metamask')">
                    <div class="bagst" :class="wallName==='Metamask'?'bords':''">
                      <img src="@/assets/PEGO/mack.svg" alt="" class="mactsMany">
                    </div>
                    <div class="font-textMany">
                      {{ $t(`message.Metamask`) }}
                    </div>
                  </div>

                  <div @click="connectClick('BitKeep')">
                    <div class="bagst " :class="wallName==='BitKeep'?'bords':''">
                      <img src="@/assets/PEGO/bikt.svg" alt="" class="mactsMany">
                    </div>
                    <div class="font-textMany">
                      {{ $t(`message.BitKeep`) }}
                    </div>
                  </div>
                </div>
                <div class="dists">
                  <div @click="connectClick('OKXWallet')">
                    <div class="bagst " :class="wallName==='OKXWallet'?'bords':''">
                      <img src="@/assets/PEGO/okx.svg" alt="" class="mactsMany">
                    </div>
                    <div class="font-textMany">
                      {{ $t(`message.OKXWallet`) }}
                    </div>
                  </div>

                </div>
                <!--                <div class="dists pad-but">-->
                <!--                  <div @click="connectClick('TokenPocket')">-->
                <!--                    <div>-->
                <!--                      <img src="@/assets/PEGO/token.svg" alt="" class="mactsMany">-->
                <!--                    </div>-->
                <!--                    <div class="font-textMany">-->
                <!--                      {{ $t(`message.TOKENPOCKET`) }}-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="tra-r">
                <img v-if="isLogo===0" src="@/assets/PEGO/isLogo.svg" alt="">
                <!--                -->
                <div v-if="wallName==='Metamask'&&isLogo===1">
                  <img src="@/assets/PEGO/mack.svg" alt="" class="mactsMany">
                  <div class="mask">
                    {{ $t('message.OpeningMetamask') }}
                  </div>
                  <div class="q-r">
                    {{ $t('message.PleaseConfirmInMetamask') }}
                  </div>
                </div>
                <div v-if="wallName==='BitKeep'&&isLogo===1">
                  <img src="@/assets/PEGO/bikt.svg" alt="" class="mactsMany">
                  <div class="mask">
                    {{ $t('message.OpeningBitkeep') }}
                  </div>
                </div>
                <div v-if="wallName==='OKXWallet'&&isLogo===1">
                  <img src="@/assets/PEGO/okx.svg" alt="" class="mactsMany">
                  <div class="mask">
                    {{ $t('message.OpeningOKXWallet') }}
                  </div>
                </div>
                <div v-if="isLogo===2">
                  <div class="isa-Z">
                    {{ wallName }} {{ $t('message.NotInstalled') }}
                  </div>
                  <img src="@/assets/PEGO/mack.svg" alt="" class="mactsMany pads">
                  <div class="a-z"
                       @click="openURL('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en')">
                    {{ $t('message.Install') }}
                  </div>
                </div>
                <div v-if="isLogo===3">
                  <div class="isa-Z">
                    {{ wallName }}{{ $t('message.NotInstalled') }}
                  </div>
                  <img src="@/assets/PEGO/bikt.svg" alt="" class="mactsMany pads">
                  <div class="a-z" @click="openURL('https://bitkeep.com/en/download?type=2')">
                    {{ $t('message.Install') }}
                  </div>
                </div>
                <div v-if="isLogo===5">
                  <div class="isa-Z">
                    {{ wallName }}{{ $t('message.NotInstalled') }}
                  </div>
                  <img src="@/assets/PEGO/okx.svg" alt="" class="mactsMany pads">
                  <div class="a-z" @click="openURL('https://www.okx.com/download')">
                    {{ $t('message.Install') }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </n-modal>
        <!--      已是验证者-->
        <n-modal v-model:show="alreadyModal"
                 preset="dialog"
                 title=""
                 :show-icon="false"
                 @close="valreadyUpdate"
                 @esc="valreadyUpdate"
                 @maskClick="valreadyUpdate"
                 positive-text=""
                 negative-text="">

          <template #action>
            <div class="tra" :style="{ width:'2.5rem'}">
              <h1>{{ $t(`message.ValidatorApplication`) }}</h1>
              <div>
                <img src="@/assets/PEGO/info.svg" alt="" class="macts">
              </div>
              <div class="font_text pad-vali">
                {{ $t(`message.YoureAlreadyAValidator`) }}
              </div>
            </div>
          </template>
        </n-modal>

        <!--请求状态-->
        <n-modal v-model:show="tranModal"
                 preset="dialog"
                 title=""
                 :show-icon="false"
                 @close="tranUpdate"
                 @esc="tranUpdate"
                 @maskClick="tranUpdate"
                 positive-text=""
                 negative-text="">
          <template #action>
            <div class="tra" :style="{ width:'2.5rem'}">
              <h1>{{ $t(`message.Transaction`) }}</h1>
              <div class="peds redCol" v-if="tranStatus===1">
                {{ $t(`message.Error`) }}!
              </div>
              <div class="peds suts" v-else-if="tranStatus===2">
                {{ $t(`message.Successful`) }}
              </div>
              <div class="peds" v-else>
                {{ $t(`message.Pending`) }}......
              </div>
            </div>
          </template>
        </n-modal>

        <!--请求状态comingSoonLeft-->
        <n-modal v-model:show="comingModal"
                 preset="dialog"
                 title=""
                 :show-icon="false"
                 @close="comingUpdate"
                 @esc="comingUpdate"
                 @maskClick="comingUpdate"
                 positive-text=""
                 negative-text="" class="modals">
          <template #action>
            <div class="tra backdrop">
              <div class="conings">
                {{ $t(`message.comingSoon`) }}...
              </div>
            </div>
          </template>
        </n-modal>
      </n-config-provider>
    <div class="ins-t" v-if="showModalTele" @click.stop="contModalTele">
      <div class="model-tra">
        <div class="lxwm dist">
          {{ $t(`message.ContactUs`) }}
          <img src="@/assets/PEGO/close.svg" @click.stop="contModalTele" alt="">
        </div>
        <div class="tra">
          <div class="dist lis">
            <div class="dist ">
              <img src="@/assets/PEGO/yx.svg" class="imt" alt="">
              <div class="pads">
                {{ $t(`message.officialEmail`) }} :<span class="" >{{ emall }}</span>
              </div>
            </div>
            <div class="dist ">
              <img class="" @click.stop="copy(emall)" src="@/assets/PEGO/copy.svg" alt="">
            </div>
          </div>
          <div class="hz">
            <h1>{{ $t(`message.businessCooperation`) }}：</h1>
            <div class="dist lis">
              <div class="dist ">
                <img src="@/assets/PEGO/tele.svg" class="imt" alt="">
                <div class="pads">
                  Telegram：<span class="">{{ Telegram }}</span>
                </div>
              </div>
              <div class="dist ">
                <img class="" @click.stop="copy(Telegram)" src="@/assets/PEGO/copy.svg" alt="">
              </div>
            </div>
            <div class="dist lis" v-if="this.$i18n.locale === 'zh-CN'">
              <div class="dist ">
                <img src="@/assets/PEGO/yx.svg" class="imt" alt="">
                <div class="pads ">
                  Btok：<span>{{ Btok }}</span>
                </div>
              </div>
              <div class="dist ">
                <img class="" @click.stop="copy(Btok)" src="@/assets/PEGO/copy.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </n-message-provider>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import {WOW} from 'wowjs'
import {
  connect,
  init, isGivenProvider,
} from "@/utils/web3";
import {getStore} from "@/config/storage";
import copyToClipboard from '@/utils/copyToClipboard'
import { createDiscreteApi } from "naive-ui";
const {message} = createDiscreteApi(
    ["message"],
);
export default {
  name: 'App',
  components: {
    HelloWorld,
  },
  data() {
    return {
      showModalTele: false,
      Telegram: 'https://t.me/PEGOWeb3_business',
      Btok: 'https://d65535.com/PEGOWeb3_business',
      emall: 'contact@pego.network',
      isLogo: 0,
      showModal: false,
      showManyModal: false,
      valiModal: false,
      alreadyModal: false,
      applyModal: false,
      tranModal: false,
      statusModal: false,
      comingModal: false,
      nftList: [],
      walletInfo: {},
      // msg:useMessage(),
    }
  },
  watch: {
    isShowModal(val) {
      this.showModal = val
    },
    isShowManyModal(val) {
      this.isLogo = 0
      this.showManyModal = val
    },
    isvaliModal(val) {
      this.valiModal = val
    },
    isalreadyModal(val) {
      this.alreadyModal = val
    },
    isapplyModal(val) {
      this.applyModal = val
    },
    istranModal(val) {
      this.tranModal = val
    },
    isstatusModal(val) {
      this.statusModal = val
    },
    showComingModal(val) {
      this.comingModal = val
    },
    ModalTele(val) {
      this.showModalTele = val
    },

  },
  computed: {
    wallName() {
      return this.$store.state.wallNameSet
    },
    tranStatus() {
      return this.$store.state.tranStatus
    },
    isShowModal() {
      return this.$store.state.showModal
    },
    isShowManyModal() {
      return this.$store.state.showManyModal
    },
    isvaliModal() {
      return this.$store.state.valiModal
    },
    isalreadyModal() {
      return this.$store.state.alreadyModal
    },
    isapplyModal() {
      return this.$store.state.applyModal
    },

    istranModal() {
      return this.$store.state.tranModal
    },
    isstatusModal() {
      return this.$store.state.statusModal
    },
    showComingModal() {
      return this.$store.state.comingModal
    },
    ModalTele() {
      return this.$store.state.showModalTele
    },
    WalletStatus() {
      const WalletStatus = getStore('isWalletStatus')
      const wallName = getStore('wallName')
      if (WalletStatus === 'true') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.walletInfo = init(wallName)
      }
      return WalletStatus
    },
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',    //需要执行动画元素的Class
      animateClass: 'animated',    //animation.css动画的Class
      offset: 0,    //距离可视区域多少开始执行动画
      mobile: true,    //是否在移动设备执行动画
      live: false    //异步加载的内容是否有效
    })
    wow.init();
    // monitor();
    this.showModal = this.isShowModal
    this.showManyModal = this.isShowManyModal
    this.valiModal = this.isvaliModal
    this.alreadyModal = this.isalreadyModal
    this.applyModal = this.isapplyModal
    this.tranModal = this.istranModal
    this.statusModal = this.isstatusModal
    this.comingModal = this.showComingModal
    this.showModalTele = this.ModalTele
    isGivenProvider()
    this.WalletStatus


    // this.$store.commit('isUupdateWalletStatus', this.WalletStatus===true ? this.WalletStatus : false);
  },
  methods: {
    contModalTele() {
      this.$store.commit('isShowModalTele', !this.showModalTele)
    },
    comingUpdate() {
      this.$store.commit('iscomingModal', !this.comingModal)
    },
    OnUpdate() {
      this.$store.commit('isShoModal', !this.showModal)
    },

    OnManyUpdate() {
      this.$store.commit('showManyModal', !this.showManyModal)
    },

    valreadyUpdate() {
      this.$store.commit('isAlreadyModal', !this.alreadyModal)
    },

    async tranUpdate() {
      await this.$store.commit('isTranModal', !this.tranModal)
      // await this.$store.commit('tranStatus', 3)
    },
    async connectClick(name) {
      // this._bitkeepConnect()
      await this.$store.commit('wallName', name);
      await this.$store.commit('isUupdateWalletStatus', true);
      let that = this
      await connect().then(e => {
        console.log("connect", e)
        this.isLogo = 1
        if (e) {
          setTimeout(function () {
            that.$store.commit('showManyModal', false)
            window.location.reload()
          }, 3000)
        }
      }).catch(err => {
        if (err === 'wallet not find') {
          // const tempwindow=window.open('_blank'); // 先打开页面
          if (getStore('wallName') === 'Metamask') {
            this.isLogo = 2
            // this.openURL('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en')

          } else if (getStore('wallName') === 'BitKeep') {
            this.isLogo = 3
            // this.openURL('https://bitkeep.com/en/download?type=2')

          } else if (getStore('wallName') === 'TokenPocket') {
            // this.openURL('https://www.tokenpocket.pro/zh/download/app')

          } else if (getStore('wallName') === 'OKXWallet') {
            this.isLogo=5
          }
        }
      });
      this.walletInfo = await init(name)

    },
    /*打开预览链接*/
    openURL(url) {
      let aLabel = document.createElement('a');
      //设置链接
      aLabel.setAttribute('href', url);
      //新窗口打开链接
      aLabel.setAttribute('target', '_blank');
      //设置标签ID
      aLabel.setAttribute('id', 'reportpoint');
      // 防止反复添加
      if (document.getElementById('reportpoint')) {
        document.body.removeChild(document.getElementById('reportpoint'));
      }
      document.body.appendChild(aLabel);
      aLabel.click();
      console.log("打开链接:", url);
      //window.open(url);
    },
    copy(val) {
      copyToClipboard(val)
      message.success(this.$t('message.copy'))

    }
  }
}
</script>
<style lang="less">
@import "./common/app.css";

html body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #ffffff;
  //background: #151617;
  background: linear-gradient(180deg, #202326 0%, #202326 18.74%, #1B1D1F 22.92%, #1B1D1F 100%) no-repeat;
  font-family: RREGULAR;
  box-sizing: border-box;

  ///deep/ .n-dorpdown {
  :deep(.n-dorpdown) {
    --n-option-color-hover: #FF9D00 !important;
    --n-option-color-active: #FF9D00 !important;
    //position: absolute !important;
    //top: 300px !important;


  }


}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  //min-width: 400px;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  font-family: RREGULAR;

}

.n-modal {
  backdrop-filter: blur(7.5px);
  width: auto !important;


}

.cont {
  width: 100%;
  display: flex;
  /*align-items: center;*/
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'RREGULAR', sans-serif;

}

button {
  font-family: 'SEMIBOLD', sans-serif;
}

.head {
  position: sticky;
  top: 0;
  z-index: 10;
}

:root {
  font-size: 14px;
}

.modals {
  --n-padding: 0 0 0 0 !important;
  background-color: rgba(56, 63, 70, 0.7);
  background-image: url("@/assets/PEGO/comingSoonLeft.svg"), url("@/assets/PEGO/comingSoonRight.svg");
  background-repeat: no-repeat;
  background-position: left center, right top;
  background-size: 144px 185px;
  backdrop-filter: blur(7.5px);
  width: 312px;
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .n-dialog__title {
    padding-right: 0 !important;
  }
}

.modal-many {
  position: relative;
  width: 488px;
  height: 700px;
  padding-top: calc(67px + 50px);
  box-sizing: border-box;

}

.ins-t {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  margin: 0 auto;
  left: auto;
  right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(39, 44, 49, 0.3);
}

.model-tra {
  position: relative;
  padding: 0 !important;
  overflow: hidden;
  background: rgba(39, 44, 49, 0.9);
  width: 4.5rem;
  border-radius: 15px;


  .n-dialog__title {
    height: 0;
  }

  .lxwm {
    font-size: 22px;
    font-weight: 600;
    padding: 15px 30px;
    line-height: 40px;
    font-family: 'SEMIBOLD', sans-serif;
    background: rgba(51, 51, 51, 0.64);
    box-sizing: border-box;
    justify-content: space-between;
  }

  .tra {
    text-align: center;
    padding: 30px;
    box-sizing: border-box;

    .lis {
      height: 49px;
      line-height: 49px;
      padding: 10px 16px;
      border-radius: 10px;
      background: rgba(33, 36, 39, 0.8);
      width: 4rem;
      box-sizing: border-box;
      justify-content: space-between;
    }

    .imt {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }

    .pads {
      padding-left: 30px;
      font-size: 20px;
      font-weight: 600;
    }

    .hz {
      text-align: left;

      h1 {
        line-height: 60px;
      }

      .lis {
        margin: 0 0 30px;
      }
    }
  }

  .dist {
    display: flex;
    align-items: center;

  }

}
</style>
<style>
.nameColor {
  background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;

}

.ti-ti {
  font-weight: 800;
  font-size: 42px;
  line-height: 134%;
  font-family: RREGULAR;
  background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.PDP {
  border-radius: 33px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.PDP:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
  0 0 20px #FFB800;
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;

}

.PDP:active {
  border: 1px solid;
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
  transition: .6s; /*取消过渡*/

}

</style>
<style lang="less" scoped>
//弹出框
.tra {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-weight: 600;
    font-size: 30px;
    font-family: SEMIBOLD, sans-serif;
  }


  .dists {
    display: flex;
    width: 100%;
    padding: 20px 40px;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .pad-but {
    padding: 25px 45px 0;
  }

  .macts {
    width: 90.57px;
    height: 84px;
    object-fit: contain;
    padding-top: 10px;
  }

  .bagst {
    width: 84.56px;
    height: 84.56px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-color: #ffffff;
    border-radius: 18px;
  }

  .bords {
    border: solid 2px transparent;
    border-radius: 22px;
    background-image: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;

  }

  .mactsMany {
    width: 80.56px;
    height: 80.56px;
    object-fit: contain;
    background-color: #ffffff;
    border-radius: 18px;
  }

  .font-textMany {
    font-weight: 400;
    font-size: 16.9128px !important;
    line-height: 22px;
    font-family: REGULAR, sans-serif;
    padding: 12px 0;
    text-align: center;
  }

  .font-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    font-family: REGULAR, sans-serif;
    color: #FF9D00;
    padding: 30px 0;
  }

  .txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    font-family: REGULAR, sans-serif;

    a {
      color: rgba(41, 156, 255, 1);
      outline-style: none;
    }
  }

  .mod-but {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      width: 48%;
      height: 54px;
      line-height: 54px;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px !important;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
      text-align: center;
      padding: 0;
    }

    .but-plain {
      background: #535353;
    }

    .buts {
      background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    }
  }

  .pgBut {
    width: 100%;
    padding: 30px 0;
    justify-content: center;

    button {
      width: 60%;
    }
  }

  .font_text {
    font-weight: 700;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #FF9D00;
    font-family: BOLD, sans-serif;
  }

  .txt_cor {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #299CFF;
    padding: 30px 0;
  }

  .foot_t {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }

  .pad-vali {
    padding: 40px 0;
  }

  .bags {
    width: 156px;
    height: 156px;
    background: #999999;
  }

  .pnn {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: #FF9D00;
    font-family: BOLD, sans-serif;
  }

  .bal {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    font-family: REGULAR, sans-serif;
  }
}

.peds {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #43FFAA;
  padding: 30px 0 50px;
}

.redCol {
  color: #FA7977;
}

.suts {
  color: rgba(41, 156, 255, 1);
}

.now {
  text-align: center;
  width: 300px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #FF9D00;
  padding: 30px 0 50px;
}

.disco {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  font-family: REGULAR, sans-serif;
  padding-bottom: 30px;

  span {
    color: rgba(45, 216, 240, 1);

  }
}

.backdrop {
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #FF9D00;
  width: 312px;
  height: 185px;
  font-family: 'BOLD', sans-serif;
}

.tra-dis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tra-l {
  text-align: left;
  position: absolute;
  top: 0px;
  width: 50%;
  left: 0;
  height: 100%;
  padding: 50px 30px;
  box-sizing: border-box;
  background: rgba(11, 11, 11, 0.5);

  .consts {
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    text-align: left;
    color: #FFFFFF;
  }

  .p-txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    font-family: REGULAR, sans-serif;
    padding-bottom: 100px;
  }
}

.tra-r {
  position: absolute;
  top: 0px;
  width: 50%;
  left: 50%;
  height: 100%;
  padding: 50px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(56, 63, 70, 0.5);

  img {
    width: 266px;
    height: 266px;
    object-fit: contain;
  }

  .mask {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    padding: 49px 0 17px;
    font-family: REGULAR, sans-serif;
  }

  .q-r {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.8);
    font-family: REGULAR, sans-serif;
  }

  .isa-Z {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    font-family: SEMIBOLD, sans-serif;
  }

  .pads {
    margin: 49.5px 0;
  }

  .a-z {
    width: 286px;
    height: 53px;
    background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    border-radius: 33px;
    font-weight: 600;
    font-size: 20px;
    line-height: 53px;
    font-family: SEMIBOLD, sans-serif;
  }


}
</style>
