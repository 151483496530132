/**
 *@title
 *@author liqingjie
 *@date 2023/2/21 10:54
 */
/* eslint-disable */
import Web3 from 'web3';
import Decimal from 'decimal.js';
import governorAbi from '../../abi/Governor.json';
import {governorAdder} from "@/utils/address";
import {getStore} from '@/config/storage'
// import i18n from '../../setup/i18n-setup';

const contractAddress = governorAdder;

let contract = null
const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
const web3js = new Web3(BitKeepProvider);
if (web3js) {
  contract = new web3js.eth.Contract(governorAbi.abi, contractAddress);
}

const textMap = {
  'en-US': {
    1: 'Proposal Parameters Changes',
    2: 'Farming Token Supported',
    3: 'Cooling off period',
    4: 'Distribution Proportion of Reward',
    5: 'Blacklist',
    6: 'Issue Additional PG',
    7: 'Gas Fee',
    8: 'Block Reward',
    9: 'Validator Number',
  },
  'zh-CN': {
    1: '提案参数',
    2: '增减支持代币',
    3: '冷却期调整',
    4: '奖励分配比例',
    5: '黑名单',
    6: '增发PG',
    7: '最低矿工费',
    8: '区块奖励',
    9: '节点数量',
  }
}

export const proposalList = async (VTTotal) => {

  try{
    const data = await contract.methods.proposalList(0, 1000).call();
    const newData = (data.states || []).map((status, idx) => {
      const numberOfVote = Web3.utils.fromWei(data.proposals_[idx]['numberOfVotes'], 'ether')
      const numberOfSupport = Web3.utils.fromWei(data.proposals_[idx]['numberOfSupport'], 'ether')
      const proposalType = data.proposals_[idx]['proposalType']
      return {
        id: data.ids_[idx],
        // title: `PDP-${proposalType} ${textMap[i18n.locale][proposalType]}`,
        title: `PDP-${proposalType} `,
        sponsor: data.proposals_[idx]['sponsor'],
        isCore: data.proposals_[idx]['isCore'], // 是否是核心提案
        proposalType, // 提案类型
        executed: data.proposals_[idx]['executed'], // 提案是否已执行
        VTTotal,
        // 投票的VT 数量
        numberOfVote,
        // 投票的VT 数量占总数量的比例
        vtScale: +VTTotal === 0 ? '0.00%' : `${new Decimal(+numberOfVote).div(VTTotal).times(100).toFixed(2)}%`,
        // 支持数量
        numberOfSupport,
        // 支持占比
        supportScale: +numberOfVote === 0 ? '0.00%' : `${new Decimal(+numberOfSupport).div(numberOfVote).times(100).toFixed(2)}%`,
        // 反对数量
        numberOfAgainst: new Decimal(0).plus(numberOfVote).minus(numberOfSupport).toString(),
        // 反对占比
        againstScale: +numberOfVote === 0 ? '0.00%' : `${(new Decimal(+numberOfVote).minus(numberOfSupport)).div(numberOfVote).times(100).toFixed(2)}%`,
        status: +status,
        startDate: +`${data?.['date_']?.[idx].startDate}000`,
        publicityEndDate: +`${data?.['date_']?.[idx].publicityEndDate}000`,
        votingEndDate: +`${data?.['date_']?.[idx].votingEndDate}000`,
        excuttionEndDate: +`${data?.['date_']?.[idx].excuttionEndDate}000`,
      };
      return v;
    });
    return newData;
  }catch (e){
    console.log(e)
  }

};
// 投票
export const vote = async (id, flag, number, from) => contract.methods.vote(id, flag, Web3.utils.toWei(number)).send({ from });
// 执行提案
export const executeProposal = async (id, from) => contract.methods.executeProposal(id).send({ from });
// 赎回投票 VT
export const claimVoteToken = async (id, from) => contract.methods.claimVoteToken(id).send({ from });
// 创建提案
export const newProposalInPg = async (contractAddress, id, callback, from, value) => {
  const r = await contract.methods.newProposalInPg(contractAddress, id, callback).send({ from, value: Web3.utils.toWei(value) });
  return r
};
// 查询提案中该地址的投票信息
export const voteInfo = async (id, addr) => {
  const info = await contract.methods.voteInfo(id, addr).call();
  return {
    voteAmount: Web3.utils.fromWei(info['voteAmount']),
    drawback: info['drawback'],
    inSupport: info['inSupport'],
    voter: info['voter'],
  }
};

