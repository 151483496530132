/**
 *@title
 *@author liqingjie
 *@date 2023/2/21 10:54
 */

import Web3 from 'web3';
import erc20Abi from '../../abi/erc20.json';
import {getStore} from '@/config/storage'

export default function (contractAddress) {
  const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
  const web3js = new Web3(BitKeepProvider);
  if (web3js) {
    const contract = new web3js.eth.Contract(erc20Abi.abi, contractAddress);
    return {
      balanceOf: async (addr) => {
        const v = await contract.methods.balanceOf(addr).call();
        return Web3.utils.fromWei(v, 'ether');
      },

      allowance: async (owner, spender) => {
        const v = await contract.methods.allowance(owner, spender).call();
        return Web3.utils.fromWei(v, 'ether');
      },

      approve: (spender, amount, from) => contract.methods.approve(spender, Web3.utils.toWei(amount)).send({ from }),

      getSymbol: async () =>{
        try {
          return await  contract.methods.symbol().call()
        }catch (e){
          console.log(e)
        }
      },
    };
  }
}
