<template>
  <div class="conts">
    <div class="bakg">
      <div class="header-one">
        <div class="head-left">
          <h1 data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500" v-html="$t(`message.PEGO`,{dao:$t(`message.dao`)})">
          </h1>
          <div class="text-dev" data-aos="fade-right" data-aos-easing="linear"
               data-aos-duration="500">
            {{ $t(`message.PEGOEmphasizes`) }}
          </div>
          <div class="but-ul" data-aos="flip-left">
            <div class="PDP buts" v-if="walletAddress" @click="$store.commit('iscomingModal', true)">
              {{ $t(`message.MoreAboutDAO`) }}
            </div>
            <div class="PDP buts" @click="apply" v-else>{{ $t(`message.CONNECT`) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pad">
      <div class="ti-card">
        <div class="cand-ti my-pand">{{ $t(`message.MyAccount`) }}</div>
        <!--        登录后显示-->
        <div class="accounts" v-if="walletAddress">
          <!--          登录账号地址显示-->
          <div>
            {{ walletAddress }}
            <span v-if="!isGivenProvider"
                  style="display: inline-block;margin-left: 10px">{{ $t(`message.NetworkError`) }}</span>

          </div>
        </div>
        <n-grid x-gap="30" :cols="3">
          <n-gi>
            <div class="li_tet" data-aos="flip-left">
              <div class="dis_li">
                <div class="txt">{{ $t(`message.VotingTaken`) }}</div>
                <img src="@/assets/PEGO/acc-union.svg" alt="">
              </div>
              <div class="numb">
                <div>{{ Number(tokenInfo.value).toFixed(0) }} VT</div>
              </div>
              <div class="vot-txt">
                {{ $t(`message.VotingTaken`) }}<span>{{ Number(tokenInfo.onVoting).toFixed(0) }}</span>
              </div>
              <div class="vot-txt">
                {{ $t(`message.Ended`) }}<span>{{ Number(tokenInfo.ended).toFixed(0) }}</span>
              </div>
            </div>
          </n-gi>
          <n-gi>
            <div class="li_tet" data-aos="flip-up">
              <div class="dis_li">
                <div class="txt">{{ $t(`message.Sponsor`) }}</div>
                <img src="@/assets/PEGO/sponsor.svg" alt="">
              </div>
              <div class="numb" @click="view(sponsorInfo.value, 'sponsor')">
                <div>{{ sponsorInfo.value }}</div>
              </div>
              <div class="vot-txt">
                {{ $t(`message.Active`) }}<span>{{ sponsorInfo.active }}</span>
              </div>
              <div class="vot-txt">
                {{ $t(`message.Executed`) }}<span>{{ sponsorInfo.executed }}</span>
              </div>
            </div>
          </n-gi>
          <n-gi>
            <div class="li_tet" data-aos="flip-right">
              <div class="dis_li">
                <div class="txt">{{ $t(`message.Voted`) }}</div>
                <img src="@/assets/PEGO/power.svg" alt="">
              </div>
              <div class="numb" @click="view(voteInfo.value, 'vote')">
                <div>{{ voteInfo.value }}</div>
              </div>
              <div class="vot-txt">
                {{ $t(`message.Active`) }}<span>{{ voteInfo.active }}</span>
              </div>
              <div class="vot-txt">
                {{ $t(`message.Executed`) }}<span>{{ voteInfo.executed }}</span>
              </div>
            </div>
          </n-gi>
        </n-grid>
        <div class="but-ul cet" data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="500">
          <div @click="handleCreate" class="PDP buts" style="padding: 0 10px">{{ $t(`message.CreatProposal`) }}</div>
        </div>
      </div>
      <div class="ti-card">
        <h5 class="cand-ti">{{ $t(`message.OnChainParameters`) }}</h5>
        <div class="card-bags">
          <n-grid cols="11" responsive="screen" class="grd-rd" data-aos="flip-up">
            <n-gi class="gin" @click="handleClick(1)">
              <div class="nupod" :class="{ 'PDPColor': selected === 1 }">PDP1</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(2)">
              <div class="nupod" :class="{ 'PDPColor': selected === 2 }">PDP2</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(3)">
              <div class="nupod" :class="{ 'PDPColor': selected === 3 }">PDP3</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(4)">
              <div class="nupod" :class="{ 'PDPColor': selected === 4 }">PDP4</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(5)">
              <div class="nupod" :class="{ 'PDPColor': selected === 5 }">PDP5</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(6)">
              <div class="nupod" :class="{ 'PDPColor': selected === 6 }">PDP6</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(7)">
              <div class="nupod" :class="{ 'PDPColor': selected === 7 }">PDP7</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(8)">
              <div class="nupod" :class="{ 'PDPColor': selected === 8 }">PDP8</div>
            </n-gi>
            <n-gi class="gin" @click="handleClick(9)">
              <div class="nupod" :class="{ 'PDPColor': selected === 9 }">PDP9</div>
            </n-gi>

          </n-grid>
          <n-grid cols="3" responsive="screen" class="timebag " data-aos="flip-left">
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.PendingTime`) }}</div>
                <div>{{ parameter.pdp1.p0 || '-' }}</div>
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.VotingTime`) }}</div>
                <div>{{ parameter.pdp1.p1 || '-' }}</div>
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.ExecutionTime`) }}</div>
                <div>{{ parameter.pdp1.p2 || '-' }}</div>
              </div>
            </n-grid-item>
          </n-grid>
          <n-grid cols="3" responsive="screen" class="timebag " data-aos="flip-right">
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.TotalVotesTotalVt`) }}</div>
                <div>{{ parameter.pdp1.p3 || '-' }} %</div>
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.SupportedVotesTotalVotes`) }}</div>
                <div>{{ parameter.pdp1.p4 || '-' }} %</div>
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.StakePG`) }}</div>
                <div>{{ parameter.pdp1.p5 || '-' }}</div>
              </div>
            </n-grid-item>
          </n-grid>
          <n-grid cols="3" v-if="selected===3" responsive="screen" class="timebag " data-aos="flip-right">
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.CoolingOffPeriodOfPGVT`) }}</div>
                <div>{{ parameter.pdp3[0] || '-' }} H</div>
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="grid-item">
                <div>
                  {{ $t(`message.CoolingOffPeriodOfPGW3FT`) }}
                </div>
                <div>{{ parameter.pdp3[1] || '-' }} H</div>
              </div>
            </n-grid-item>
          </n-grid>
          <n-grid cols="3" v-if="selected===4" responsive="screen" class="timebag " data-aos="flip-right">
            <n-grid-item>
              <div class="grid-item">
                <div> {{ $t(`message.ValidatorDistrbution`) }}</div>
                <!--              <div>{{ parameter.pdp4 }} %</div>-->
                <div>{{ (parameter.pdp4 / 10) || '0' }} : {{ ((100 - parameter.pdp4) / 10) || '0' }}</div>
              </div>
            </n-grid-item>

          </n-grid>
          <!--        <n-grid cols="3" v-if="selected===6" responsive="screen" class="timebag " data-aos="flip-right">-->
          <!--          <n-grid-item>-->
          <!--            <div class="grid-item">-->
          <!--              <div>{{ $t(`message.AdditionalPGIssued`) }}</div>-->
          <!--              <div>{{ parameter.pdp6||'-' }}</div>-->
          <!--            </div>-->
          <!--          </n-grid-item>-->
          <!--          <n-grid-item>-->
          <!--          </n-grid-item>-->
          <!--          <n-grid-item>-->
          <!--          </n-grid-item>-->
          <!--        </n-grid>-->
          <n-grid cols="3" v-if="selected===7" responsive="screen" class="timebag" data-aos="flip-right">
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.LowestGasFee`) }}</div>
                <div>{{ NP.times(parameter.pdp7, 21000) || '-' }} PG</div>
              </div>
            </n-grid-item>
          </n-grid>
          <n-grid cols="3" v-if="selected===8" responsive="screen" class="timebag" data-aos="flip-right">
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.BlockReward`) }}</div>
                <div>{{ parameter.pdp8 || '-' }}</div>
              </div>
            </n-grid-item>
          </n-grid>
          <n-grid cols="3" v-if="selected===9" responsive="screen" class="timebag" data-aos="flip-right">
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.ValidatorNumbers`) }}</div>
                <div>{{ parameter.pdp9 || '-' }}</div>
              </div>
            </n-grid-item>
          </n-grid>
          <n-grid cols="1" v-if="selected===2" responsive="screen" class="timebag time-one" data-aos="flip-right">
            <n-grid-item>
              <div class="grid-item">
                <div>{{ $t(`message.SupportedToken`) }}</div>
                <div>
                  <template v-for="item in parameter.pdp2" :key="item">
                    <p>{{ item }}</p>
                  </template>

                </div>
              </div>
            </n-grid-item>
          </n-grid>
          <!--        <n-grid cols="1" v-if="selected===5" responsive="screen" class="timebag time-one" data-aos="flip-right">-->
          <!--          <n-grid-item>-->
          <!--            <div class="grid-item">-->
          <!--              <div>{{ $t(`message.Blacklist`) }}</div>-->
          <!--              <div v-for="item in parameter.pdp5" :key="item">-->
          <!--                <p>{{ item }}</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </n-grid-item>-->
          <!--        </n-grid>-->
          <div cols="1" class="bags-t">
            <h6>
              {{ $t(`message.ProposalDescription`) }}
            </h6>
            <template v-for="(item) in typeProposal" :key="item.key">
              <n-grid cols="1" v-if="selected===item.key" responsive="screen" class="timebag" data-aos="flip-right">
                <n-grid-item>
                  <div class="grid-Proposal">
                    <div class="tlts">{{ item.title }}</div>
                    <div class="t-t">
                      <p>{{ item.label }}</p>
                      <p v-if="item.key===1">{{ item.text }}</p>
                    </div>
                  </div>
                </n-grid-item>
              </n-grid>
            </template>

          </div>

        </div>
      </div>
      <div class="ti-card" id="Proposals" data-aos="fade-down"
           data-aos-easing="linear">
        <h5 class="cand-ti">{{ $t(`message.Proposals`) }}</h5>
        <div>
          <div class="pro-ul" v-for="(item,index) in list" :key="index" @click="handleGoDetail(item)">
            <div class="ul-lef">
              <div class="tit-pro">#{{ item.id }}. {{ item.title }}</div>
              <div>
                <div class="prog-ess">
                  <!--                  VT(item)-->
                  <n-progress
                      type="line"
                      :percentage="VT(item.vtScale)"
                      :height="22"
                      :show-indicator="false"
                      :rail-color="railColr"
                      :border-radius="4"
                      :fill-border-radius="4"
                  />
                  <span>{{ $t(`message.TotalVT`) }}: {{ item.numberOfVote }} ({{ item.vtScale }})</span>
                </div>
                <div class="prog-ess">
                  <!--                  Supports(item)-->
                  <n-progress
                      type="line"
                      :percentage="Supports(item.supportScale)"
                      :height="22"
                      :show-indicator="false"
                      :rail-color="railColr"
                      :border-radius="4"
                      :fill-border-radius="4"
                  />
                  <span>{{ $t(`message.Supports`) }}: {{ item.numberOfSupport }} ({{ item.supportScale }})</span>
                </div>
              </div>
            </div>
            <div class="ul-rig">
              <!-- 投票期的提案-->
              <div v-if="item.status===2" class="rig-txt active">{{ $t(`message.Active`) }}</div>
              <!--Pending公示期的提案 Executed已经执行的提案-->
              <div v-if="item.status===1" class="rig-txt pending">{{ $t(`message.Pending`) }}</div>
              <div v-if="item.status===3" class="rig-txt pending">{{ $t(`message.Executed`) }}</div>
              <!--Invalid  通过但没能按时执行的提案  Failed没有通过的提案-->
              <div v-if="item.status===4" class="rig-txt invalid">{{ $t(`message.Invalid`) }}</div>
              <div v-if="item.status===6" class="rig-txt invalid">{{ $t(`message.Failed`) }}</div>
              <!--执行期（还未执行）的提案-->
              <div v-if="item.status===5" class="rig-txt active">{{ $t(`message.Passed`) }}</div>
              <div class="gin" v-if="item.isCore">
                <div class="nupod PDPColor core">{{ $t(`message.Core`) }}</div>
              </div>
            </div>
          </div>
          <div class="but-ul cet cent">
            <div class="PDP buts" @click="seeAllClick">{{ $t(`message.SEEALL`) }}</div>
          </div>
        </div>
      </div>
    </div>

    <foot></foot>
    <!--Creat Proposal-->
    <modals :LoadingShow="creatShow" @OnUpdate="creatShow=!creatShow" :wids="3">
      <template #default>
        <h1>{{ $t(`message.CreatProposal`) }}</h1>
        <div class="but-s sns">
          <label for="" class="labe">
            {{ $t(`message.ProposalType`) }}
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <div class="popo">
              <n-button @click="ulShow=!ulShow,paraShow=false,addShow=false" icon-placement="right" text-color="#ffffff"
                        color="#222427" strong
                        class="wins">
                {{ selPopo(id) }}
                <template #icon>
                  <Icon color="#ffffff" size="20" class="icons">
                    <ChevronDown v-show="!ulShow"/>
                    <ChevronUp v-show="ulShow"/>
                  </Icon>
                </template>
              </n-button>
              <div class="cot-po" :class="{'ans':ulShow}">
                <div class="bodr" v-for="(item,index) in pdpOptions" :key="index"
                     @click="id=item.key,ulShow=!ulShow">
                  <div class="bagst" :class="{'corBg':id===item.key}"> {{ item.label }}</div>
                </div>
              </div>
            </div>
          </n-config-provider>
          <div class="bal-rig">
            <span v-if="id===1||id===3">{{ $t(`message.AdjustThe`) }}</span>
            <span v-if="id===2">{{ $t(`message.AddOrDelete`) }}</span>
            <span v-if="id===4">{{ $t(`message.AdjustTheDistribution`) }}</span>
            <span v-if="id===5">{{ $t(`message.Blacklist`) }}</span>
            <span v-if="id===6">{{ $t(`message.BlaDecentralized`) }}</span>
            <span v-if="id===7">{{ $t(`message.AdjustTheLowestGasFee`) }}</span>
            <span v-if="id===8">{{ $t(`message.AdjustTheBlockReward`) }}</span>
            <span v-if="id===9">{{ $t(`message.AdjustThenumberOfValidators`) }}</span>
          </div>
        </div>
        <div v-if="id===1">
          <div class="but-s sns">
            <label for="" class="labe">
              {{ $t(`message.ChoosePDP`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div class="popo">
                <n-button @click="paraShow=!paraShow,ulShow=false,addShow=false" icon-placement="right"
                          text-color="#ffffff"
                          color="#222427" strong
                          class="wins">
                  {{ parameters(pdp1Type) }}
                  <template #icon>
                    <Icon color="#ffffff" size="20" class="icons">
                      <ChevronDown v-show="!paraShow"/>
                      <ChevronUp v-show="paraShow"/>
                    </Icon>
                  </template>
                </n-button>
                <div class="cot-po" :class="{'ans':paraShow}">
                  <div class="bodr" v-for="(item) in pdpOptions" :key="item.key"
                       @click="pdp1Type=item.key,paraShow=!paraShow">
                    <div class="bagst" :class="{'corBg':pdp1Type===item.key}"> {{ item.label }}</div>
                  </div>
                </div>
              </div>
            </n-config-provider>
          </div>
          <div class="but-s ">
            <label for="" class="labe">
              {{ $t(`message.ParametersChanges`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  {{ $t(`message.PendingTime`) }}
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp1['0']" :show-button="false"
                                :placeholder="`Now is ${pdp1['p0']}`">
                </n-input-number>
                <span class="max-span cor"> H </span>
              </div>
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  {{ $t(`message.VotingTime`) }}
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp1['1']" :show-button="false"
                                :placeholder="`Now is ${pdp1['p1']}`">
                </n-input-number>
                <span class="max-span cor"> H </span>
              </div>
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  {{ $t(`message.ExeuctionTime`) }}
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp1['2']" :show-button="false"
                                :placeholder="`Now is ${pdp1['p2']}`">
                </n-input-number>
                <span class="max-span cor"> H </span>
              </div>
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  {{ $t(`message.TotalVotesvt`) }}
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp1['3']" :show-button="false"
                                :placeholder="`Now is ${pdp1['p3']}%`">
                </n-input-number>
                <span class="max-span cor"> % </span>
              </div>
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  {{ $t(`message.SupportedVotesTotalVotess`) }}
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp1['4']" :show-button="false"
                                :placeholder="`Now is ${pdp1['p4']}%`">
                </n-input-number>
                <span class="max-span cor"> % </span>
              </div>
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  {{ $t(`message.StakePG`) }}
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp1['5']" :show-button="false"
                                :placeholder="`Now is ${pdp1['p5']}`">
                </n-input-number>
              </div>
            </n-config-provider>
          </div>
        </div>
        <div v-if="id===2">
          <div class="but-s sns">
            <label for="" class="labe">
              {{ $t(`message.AddDelete`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div class="popo">
                <n-button @click="addShow=!addShow,ulShow=false,paraShow=false" icon-placement="right"
                          text-color="#ffffff"
                          color="#222427" strong
                          class="wins">
                  {{ pdp2.name }}
                  <template #icon>
                    <Icon color="#ffffff" size="20" class="icons">
                      <ChevronDown v-show="!addShow"/>
                      <ChevronUp v-show="addShow"/>
                    </Icon>
                  </template>
                </n-button>
                <div class="cot-po" :class="{'ans':addShow}">
                  <div class="bodr" v-for="(item) in addOptions" :key="item.name"
                       @click="pdp2.flag = item.flag, pdp2.name = item.name ,addShow=!addShow">
                    <div class="bagst" :class="{'corBg':pdp2.name===item.flag}"> {{ item.name }}</div>
                  </div>
                </div>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              <span v-if="pdp2.flag">
                {{ $t(`message.AddANewFarmingToken`) }}</span>
              <span v-else>{{ $t(`message.DeleteoneFarmingToken`) }}</span>
            </div>
          </div>
          <div class="but-s ">
            <label for="" class="labe">
              <span v-if="id===2">{{ $t(`message.TokenAddress`) }}</span>
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative">
                <n-input class="inpt-n" v-model:value="pdp2.address" :show-button="false"
                         placeholder="Fill in a address">
                </n-input>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              <span v-if="pdp2.address===''"> {{ $t(`message.FillInTheTokenContractAddress`) }}</span>
              <span v-if="pdp2.address&&isSupportFarmToken===true"
                    style="color: #FF706A"> {{ $t(`message.AlreadySupported`) }}</span>
            </div>
          </div>
        </div>
        <div v-if="id===3">
          <div class="but-s ">
            <label for="" class="labe">
              {{ $t(`message.CoolingOffPeriodOf`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  PGVT
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp3['0']" :show-button="false"
                                :placeholder="`Now is ${pdp3['pgvtLockTimeInMinutes']}`">
                </n-input-number>
                <span class="max-span cor"> H </span>
              </div>
              <div style="position: relative" class="dis-t">
                <div class="txt-d">
                  PG&W3FT
                </div>
                <n-input-number class="inpt-n" v-model:value="pdp3['1']" :show-button="false"
                                :placeholder="`Now is ${pdp3['validatorLockTimeInMinutes']}`">
                </n-input-number>
                <span class="max-span cor"> H </span>
              </div>
              <!--              <div style="position: relative" class="dis-t">-->
              <!--                <div class="txt-d">-->
              <!--                  PNN-->
              <!--                </div>-->
              <!--                <n-input-number class="inpt-n" v-model:value="changeTo" :show-button="false" placeholder="Now is">-->
              <!--                </n-input-number>-->
              <!--                <span class="max-span cor"> H </span>-->
              <!--              </div>-->
            </n-config-provider>
          </div>
        </div>
        <div v-if="id===4">
          <div class="but-s ">
            <label for="" class="labe">
              {{ $t(`message.VoterDistribution`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative">
                <n-input-number v-model:value="pdp4.value" :show-button="false" placeholder="≥0">
                </n-input-number>
                <span class="max-span cor"> % </span>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              {{ $t(`message.TheDistributionProportionForValidator`) }}<span style="color:rgba(41, 156, 255, 1);">{{
                pdp4.placeholder
              }}%</span>
            </div>
          </div>
        </div>
        <div v-if="id===5">
          <div class="but-s sns">
            <label for="" class="labe">
              {{ $t(`message.AddDelete`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div class="popo">
                <n-button @click="addShow=!addShow,ulShow=false,paraShow=false" icon-placement="right"
                          text-color="#ffffff"
                          color="#222427" strong
                          class="wins">
                  {{ pdp5.name }}
                  <template #icon>
                    <Icon color="#ffffff" size="20" class="icons">
                      <ChevronDown v-show="!addShow"/>
                      <ChevronUp v-show="addShow"/>
                    </Icon>
                  </template>
                </n-button>
                <div class="cot-po" :class="{'ans':addShow}">
                  <div class="bodr" v-for="(item) in addOptions" :key="item.name"
                       @click="pdp5.flag = item.flag, pdp5.name = item.name ,addShow=!addShow">
                    <div class="bagst" :class="{'corBg':pdp5.name===item.flag}"> {{ item.name }}</div>
                  </div>
                </div>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              <span v-if="pdp5.name==='Add'||pdp5.name==='添加'"> {{ $t(`message.AddAnAddressToBlacklist`) }}</span>
              <span v-if="pdp5.name==='Delete'||pdp5.name==='移除'"> {{
                  $t(`message.DeleteAnAddressOutOfBlacklist`)
                }}</span>
            </div>
          </div>
          <div class="but-s ">
            <label for="" class="labe">
              <span v-if="id===5"> {{ $t(`message.Address`) }}</span>
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative">
                <n-input class="inpt-n" v-model:value="pdp5.address" :show-button="false"
                         @input="onUpdatePdp5"
                         placeholder="Fill in a address">
                </n-input>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              <span v-if="isblack===true" style="color: #FF706A"> {{ $t(`message.Alreadyin`) }}</span>
              <span v-else-if="isblack===false" style="color: #FF706A">{{ $t(`message.NotIn`) }}</span>
              <span v-else-if="isblack==='null'" style="color: #FF706A">{{
                  $t(`message.FillInAAddressAlreadyIn`)
                }}</span>
            </div>
          </div>
        </div>
        <div v-if="id===6">
          <div class="but-s ">
            <label for="" class="labe">
              {{ $t(`message.Amount`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative">
                <n-input-number v-model:value="pdp6.value" :show-button="false" placeholder="≤100000">
                </n-input-number>
                <span class="max-span cor"> PG </span>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              {{ $t(`message.TheAmountOfPGIssued`) }}
            </div>
          </div>
        </div>
        <div v-if="id===7">
          <div class="but-s ">
            <label for="" class="labe">
              {{ $t(`message.Amount`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative">
                <n-input v-model:value="pdp7.value" :show-button="false" :placeholder="`≥${pdp7.min}`">
                </n-input>
                <span class="max-span cor"> PG </span>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              {{ $t(`message.NowIs`) }}:{{ NP.times(pdp7.placeholder, 21000) }} PG
            </div>
          </div>
        </div>
        <div v-if="id===8">
          <div class="but-s ">
            <label for="" class="labe">
              {{ $t(`message.BlockReward`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative">
                <n-input-number v-model:value="pdp8.value" :show-button="false" placeholder="≥9">
                </n-input-number>
                <span class="max-span cor"> PG </span>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              {{ $t(`message.NowIs`) }}:{{ pdp8.placeholder }}
            </div>
          </div>
        </div>
        <div v-if="id===9">
          <div class="but-s ">
            <label for="" class="labe">
              {{ $t(`message.NumberOfValidators`) }}
            </label>
            <n-config-provider :theme-overrides="themeOverrides">
              <div style="position: relative">
                <n-input-number v-model:value="pdp9.value" :show-button="false" placeholder="≥21">
                </n-input-number>
              </div>
            </n-config-provider>
            <div class="bal-rig">
              {{ $t(`message.NowIs`) }}:{{ pdp9.placeholder }}
            </div>
          </div>
        </div>
        <div class="maks">
          <div>
            {{ $t(`message.YouNeedToStake`) }}<span style="color: rgba(41, 156, 255, 1)">{{
              stakingAmount
            }}</span>{{ $t(`message.makeSureYou`) }}

          </div>
          <div>
            {{ $t(`message.PGBalance`) }}: <span style="color: rgba(255, 112, 106, 1)">{{ pgBalance }}</span>
          </div>
        </div>
        <div class="mod-but pgBut">
          <n-button v-if="isGivenProvider&&pgBalance>=stakingAmount&&id!==5&&id!==2&&pgBalance>0" round color="#FF7A00"
                    class="PDP buts" ghost
                    text-color="#ffffff" :bordered="false"
                    @click="CreatProposalClick">
            {{ $t(`message.CreatProposal`) }}
          </n-button>
          <n-button
              v-else-if="isGivenProvider&&pgBalance>=stakingAmount&&pgBalance>0&&id===5&&isblack===false&&pdp5.flag===true"
              round
              color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
              @click="CreatProposalClick">
            {{ $t(`message.CreatProposal`) }}
          </n-button>
          <n-button
              v-else-if="isGivenProvider&&pgBalance>=stakingAmount&&pgBalance>0&&id===5&&isblack===true&&pdp5.flag==false"
              round color="#FF7A00"
              class="PDP buts" ghost text-color="#ffffff" :bordered="false"
              @click="CreatProposalClick">
            {{ $t(`message.CreatProposal`) }}
          </n-button>
          <!--          v-if="pdp2.address&&isSupportFarmToken===true"-->
          <n-button
              v-else-if="isGivenProvider&&pgBalance>=stakingAmount&&pgBalance>0&&id===2&&isSupportFarmToken===true&&pdp2.flag===false"
              round color="#FF7A00"
              class="PDP buts" ghost text-color="#ffffff" :bordered="false"
              @click="CreatProposalClick">
            {{ $t(`message.CreatProposal`) }}
          </n-button>
          <n-button
              v-else-if="isGivenProvider&&pgBalance>=stakingAmount&&pgBalance>0&&id===2&&isSupportFarmToken===false&&pdp2.flag===true"
              round color="#FF7A00"
              class="PDP buts" ghost text-color="#ffffff" :bordered="false"
              @click="CreatProposalClick">
            {{ $t(`message.CreatProposal`) }}
          </n-button>
          <n-button v-else style="cursor: not-allowed; opacity: 0.5;" round color="#FF7A00" class="PDP buts" ghost
                    text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.CreatProposal`) }}
          </n-button>
        </div>
      </template>
    </modals>
  </div>
</template>

<script>
import foot from "@/components/foot";
import modals from "@/components/modals.vue"
import {
  ChevronDown,
  ChevronUp
} from "@vicons/ionicons5";
import {Icon} from '@vicons/utils'
import NP from 'number-precision'
import {
  contractAddress,
  setVotingRules,
  getBaseGasPrice,
  getBlockRewards,
  getDelegatorRewardPercent,
  getLockTime,
  getMintPg,
  getStakingPgAmount,
  getValidatorNum,
  getVotingRules,
  supportFarmToken,
  setFarmToken,
  setLockTime,
  setDelegatorRewardPercent,
  setBlackAddress,
  mintPg,
  setBaseGasPrice, setBlockRewards, setValidatorNum, getIisBlack
} from "@/utils/contract/dao";
import {newProposalInPg} from '@/utils/contract/governor';
import {
  formWei, getBalance,
  // init
} from "@/utils/web3";
import api from "@/api/api";
import {totalSupply, balanceOf} from "@/utils/contract/validator";
import {proposalList} from "@/utils/contract/governor";
import {useMessage} from "naive-ui";
import Web3 from "web3";

export default {
  name: "PEGOScan",
  components: {
    foot,
    modals,
    Icon,
    ChevronDown,
    ChevronUp
  },
  metaInfo() {
    return {
      title: this.$t("message.Proposal"),
      meta: [
        {
          name: 'keywords',
          content: this.keyword
        },
        {
          name: 'description',
          content: this.description
        }
      ]
    }

  },
  data() {
    return {
      NP: NP,
      msg: useMessage(),
      walletInfo: {},
      tokenInfo: {
        value: 0,
        onVoting: 0,
        ended: 0,
      },
      sponsorInfo: {
        value: 0, // 作为提案发起人的提案数量
        active: 0, // 正在执行
        executed: 0, // 已经执行
      },
      voteInfo: {
        value: 0, // 该地址参加过投票的提案数量
        active: 0, // 正在执行
        executed: 0, // 已经执行
      },
      sponsorProposalList: [],
      voterProposalList: [],
      pgBalance: 0,
      stakingAmount: 0,
      proposalsList: [],
      parameter: {
        pdp1: {
          p0: 0,
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
        },
        pdp2: [],
        pdp3: {
          0: 0,
          1: 0,
        },
        pdp4: '-',
        pdp5: [],
        pdp6: '-',
        pdp7: '-',
        pdp8: '-',
        pdp9: '-',
      },

      pdp1: {},
      pdp2: {
        flag: true,
        name: this.$t("message.Add"),
        address: '',
      },
      pdp3: {
        0: 0,
        1: 0,
      },
      pdp4: {
        value: 0,
        placeholder: 0,
      },
      pdp5: {
        flag: true,
        name: this.$t("message.Add"),
        address: '',
      },
      pdp6: {
        value: 0,
        placeholder: 0,
      },
      pdp7: {
        placeholder: 0,
        min: 0,
        value: 0,
      },
      pdp8: {
        placeholder: 0,
        value: 0,
      },
      pdp9: {
        placeholder: 0,
        value: 0,
      },
      isblack: null,
      active: true,
      creatShow: false,
      ulShow: false,
      paraShow: false,
      addShow: false,
      changeTo: '',
      actives: 1,
      selected: 1,
      railColr: 'rgba(255, 157, 0, 0.12)',
      pdp1Type: 1,
      pdp1TypeName: 'PDP-1',
      id: 1,
      idName: 'PDP-1',
      addVlue: "1",
      paraOptions: {
        'PDP-1': "PDP-1",
        'PDP-2': "PDP-2",
        'PDP-3': "PDP-3",
        'PDP-4': "PDP-4",
        'PDP-5': "PDP-5",
        'PDP-6': "PDP-6",
        'PDP-7': "PDP-7",
        'PDP-8': "PDP-8",
        'PDP-9': "PDP-9",
      },
      addOptions: [
        {flag: true, name: this.$t("message.Add")},
        {flag: false, name: this.$t("message.Delete")}
      ],
      themeOverrides: {
        "Dropdown": {
          "color": "#211B1BFF",
          "textColor": "#F9F6F6FF",
          "optionTextColor": "#FFF",
          "optionTextColorHover": "rgba(111, 120, 118, 1)",
          "optionTextColorActive": "#E2B163FF",
          "optionColorHoverInverted": "rgba(111, 120, 118, 1)",
          "optionColorActiveInverted": "",
          "optionTextColorActiveInverted": "#FFB526",
          "optionTextColorHoverInverted": "#FFF",
          "optionIconSuffixWidthHuge": "100px",
        },
        "Input": {
          "color": "#222427",
          "placeholderColor": "rgba(237, 228, 228, 1)",
          "caretColor": "#F1ECE8FF",
          "textColor": "rgba(241, 245, 249, 1)",
          "colorFocus": "#222427",
          "border": "1.5px solid rgba(255, 255, 255, 0.3)",
          "borderHover": "1px solid #fff",
          "borderFocus": "1px solid #fff",
          "borderRadius": "4px",
        },
        "Button": {
          "textColorHover": "#ffffff",
          "border": "1px rgba(255, 255, 255, 0.1) solid",
          "borderFocus": "1px #ffffff solid",
          "borderHover": "1px #ffffff solid",
          "borderPressed": "1px #ffffff solid",
          "rippleColor": "#FFB526",
          "borderRadius": "15px",
        }
      },
    }
  },
  computed: {
    typeProposal() {
      return [
        {
          title: this.$t("message.PDP1"),
          label: this.$t("message.DetermineOr"),
          text: this.$t("message.TheAdjustableParameters"),
          key: 1,
        },
        {
          title: this.$t("message.PDP2"),
          label: this.$t("message.decideWhich"),
          key: 2,
        },
        {
          title: this.$t("message.PDP3"),
          label: this.$t("message.adjustTheCooling"),
          key: 3,
        },
        {
          title: this.$t("message.PDP4"),
          label: this.$t("message.usedToDetermine"),
          key: 4,
        },
        {
          title: this.$t("message.PDP5"),
          label: this.$t("message.controlMalicious"),
          key: 5,
        },
        {
          title: this.$t("message.PDP6"),
          label: this.$t("message.controlMaliciousContracts"),
          key: 6,
        },
        {
          title: this.$t("message.PDP7"),
          label: this.$t("message.usedToAdjust"),
          key: 7,
        },
        {
          title: this.$t("message.PDP8"),
          label: this.$t("message.adjustTheBlock"),
          key: 8,
        },
        {
          title: this.$t("message.PDP9"),
          label: this.$t("message.adjustTheNumber"),
          key: 9,
        },
      ]
    },
    pdpOptions() {
      return [
        {
          label: "PDP-1",
          key: 1,
        },
        {
          label: "PDP-2",
          key: 2,
        },
        {
          label: "PDP-3",
          key: 3,
        },
        {
          label: "PDP-4",
          key: 4,
        },
        {
          label: "PDP-5",
          key: 5,
        },
        {
          label: "PDP-6",
          key: 6,
        },
        {
          label: "PDP-7",
          key: 7,
        },
        {
          label: "PDP-8",
          key: 8,
        },
        {
          label: "PDP-9",
          key: 9,
        },
      ]
    },
    isGivenProvider() {
      return this.$store.state.GivenProvider
    },
    keyword() {
      return this.$t("message.homeHeader")
    },
    description() {
      return this.$t("message.infrastructure")
    },
    Supports() {
      return function (item) {
        const num = item ? item : '0'
        const obj = num.split('%')
        return Number(obj[0])
      }
    },
    VT() {
      return function (item) {
        const num = item ? item : '0'
        const obj = num.split('%')
        return Number(obj[0])
      }
    },

    parameters() {
      return function (val) {
        let obj = this.pdpOptions.find(item => item.key === val)
        return obj.label
      }
    },
    selPopo() {
      return function (val) {
        let obj = this.pdpOptions.find(item => item.key === val)
        return obj.label
      }
    },
    walletAddress() {
      return this.$store.state.walletAddress
    },
    list() {
      return this.proposalsList ? this.proposalsList.filter((v) => v.status === 1 || v.status === 2) : [];
    },
    isSupportFarmToken() {
      const result = this.parameter.pdp2.filter(word => word === this.pdp2.address);
      console.log("result", result);
      return result.length > 0 ? true : false

    },
  },
  watch: {
    id(val) {
      this.id = val
    },
    creatShow(val) {
      this.creatShow = val
    },
    pdp1Type(val) {
      this.pdp1Type = val
    },
    addVlue(val) {
      this.addVlue = val
    },
    'pdp2.address'(val) {
      console.log("pdp2.address", val, this.pdp2.flag)
      this.pdp2.address = val
    },

  },
  async mounted() {
    if (this.$route.query.id) {
      let toElement = document.getElementById(this.$route.query.id);
      toElement.scrollIntoView(true);
    }
    await this.Creatinit();
  },
  methods: {
    async onUpdatePdp5(val) {
      this.isblack = await getIisBlack(val)
    },
    async handleClick(index) {
      this.selected = index;
      this.parameter.pdp1 = await getVotingRules(index);
    },
    async getParameter() {
      this.parameter.pdp1 = await getVotingRules(1);
      this.parameter.pdp2 = await supportFarmToken();
      this.parameter.pdp3 = await getLockTime();
      this.parameter.pdp4 = await getDelegatorRewardPercent();
      this.parameter.pdp6 = await getMintPg();
      this.parameter.pdp7 = await getBaseGasPrice();
      this.parameter.pdp8 = await getBlockRewards();
      this.parameter.pdp9 = await getValidatorNum();
    },
    async changePdp1Type(item) {
      this.pdp1Type = item.id;
      this.pdp1TypeName = item.name;
      this.pdp1 = await getVotingRules(this.pdp1Type);
    },
    handleChangeId(item) {
      this.id = item.id;
      this.idName = item.name;
      this.stakingPgAmount();
    },
    async stakingPgAmount() {
      this.stakingAmount = await getStakingPgAmount(this.id);
    },
    async init() {
      // 获取参数
      this.pdp1 = await getVotingRules(this.pdp1Type);
      // 根据ID查询创建提案所需要的PG
      this.stakingPgAmount();
      this.pdp3 = await getLockTime();
      const p4 = await getDelegatorRewardPercent();
      this.pdp4 = {
        placeholder: p4,
        value: p4,
      };
      const p6 = await getMintPg();
      this.pdp6 = {
        value: p6,
        placeholder: p6,
      };
      const p7 = await getBaseGasPrice();
      this.pdp7 = {
        placeholder: p7,
        min: 0,
        value: NP.times(p7, 21000),
      };
      const p8 = await getBlockRewards();
      this.pdp8 = {
        placeholder: p8,
        value: p8,
      };
      const p9 = await getValidatorNum();
      this.pdp9 = {
        placeholder: p9,
        value: p9,
      };
    },

    async Creatinit() {
      const VTTotal = await totalSupply();
      this.proposalsList = await proposalList(VTTotal);
      await this.getParameter();
      if (this.walletAddress) {
        api.summary({address: this.walletAddress}).then((res) => {
          this.tokenInfo.onVoting = formWei(res.onVoting);
          this.tokenInfo.ended = formWei(res.ended);
          this.sponsorInfo = {
            value: res.proposalNumber || 0,
            active: res.proposalActiveNumber || 0,
            executed: res.proposalExecutedNumber || 0,
          };
          this.voteInfo = {
            value: res.votedNumber || 0,
            active: res.votedActiveNumber || 0,
            executed: res.votedExecutedNumber || 0,
          };
          this.voterProposalList = res.voterProposalList || [];
          this.sponsorProposalList = res.sponsorProposalList || [];
        });
        this.tokenInfo.value = await balanceOf(this.walletAddress);
        this.pgBalance = await getBalance(this.walletAddress);
      }
    },
    //creatClick
    handleCreate() {
      this.init()
      this.creatShow = true
    },
    async handleCreate1() {
      try {
        if ([0, 1, 2, 3, 4, 5].every((v) => this.pdp1[v] === this.pdp1[`p${v}`])) {
          this.msg.error(this.$t(`message.FailedToCreateProposal`));
          return;
        }
        const callback = await setVotingRules(this.pdp1Type, this.pdp1['0'].toString(), this.pdp1['1'].toString(), this.pdp1['2'].toString(), this.pdp1['3'].toString(), this.pdp1['4'].toString(), this.pdp1['5'].toString());
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `Adjust the parameters of <dd>${this.pdp1TypeName}</dd>, Pending Time: ${this.pdp1.p0} to <dd>${this.pdp1['0']}</dd>,Voting Time: ${this.pdp1.p1} to <dd>${this.pdp1['1']}</dd>,Execution Time: ${this.pdp1.p2} to <dd>${this.pdp1['2']}</dd>,Total Votes/Total VT: ${this.pdp1.p3} to <dd>${this.pdp1['3']}</dd>,Supported Votes/Total Votes: ${this.pdp1.p4} to <dd>${this.pdp1['4']}</dd>,Stake PG: ${this.pdp1.p5} to <dd>${this.pdp1['5']}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        console.log(e);
        this.$store.commit('tranStatus', 1)
      }
      // this.$store.commit('isTranModal', false)
    },
    async handleCreate2() {
      if (this.pdp2.address === '') {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        const callback = await setFarmToken(this.pdp2.address, this.pdp2.flag);
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `${this.pdp2.flag ? 'Add' : 'Delete'} <dd>${this.pdp2.address}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        // this.$store.commit('isTranModal', false)
        this.$store.commit('tranStatus', 1)
      }
    },
    async handleCreate3() {
      if (this.pdp3.pgvtLockTimeInMinutes === this.pdp3['0'] && this.pdp3.validatorLockTimeInMinutes === this.pdp3['1']) {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        const callback = await setLockTime(this.pdp3['0'], this.pdp3['1'].toString(), this.pdp3['2'].toString());
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `PGVT: ${this.pdp3.pgvtLockTimeInMinutes} to <dd>${this.pdp3['0']}</dd>, PG&W3FT: ${this.pdp3.validatorLockTimeInMinutes} to <dd>${this.pdp3['1']}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        // this.$store.commit('isTranModal', false)
        this.$store.commit('tranStatus', 1)
      }
    },
    async handleCreate4() {
      if (this.pdp4.value === this.pdp4.placeholder) {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        const callback = await setDelegatorRewardPercent(this.pdp4.value.toString());
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `Validator: ${this.pdp4.placeholder} to <dd>${this.pdp4.value}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        this.$store.commit('tranStatus', 1)
        // this.$store.commit('isTranModal', false)
      }
    },
    async handleCreate5() {
      if (this.pdp5.address === '') {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        const callback = await setBlackAddress(this.pdp5.address, this.pdp5.flag);
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `${this.pdp5.flag ? 'Add' : 'Delete'} <dd>${this.pdp5.address}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        this.$store.commit('tranStatus', 1)
        // this.$store.commit('isTranModal', false)
      }
    },
    async handleCreate6() {
      if (this.pdp6.value === this.pdp6.placeholder || +this.pdp6.value > 100000) {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        const callback = await mintPg(this.walletAddress, this.pdp6.value.toString());
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `Amount: <dd>${this.pdp6.value}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        console.log("handleCreate6", e)
        this.$store.commit('tranStatus', 1)
        // this.$store.commit('isTranModal', false)
      }
    },
    async handleCreate7() {
      if (this.pdp7.value === NP.times(this.pdp7.placeholder, 21000)) {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        let pdp7Value = NP.divide(this.pdp7.value, 21000)
        console.log("pdp7Value", pdp7Value.toFixed(18), Web3.utils.toWei(pdp7Value.toFixed(18)));
        const callback = await setBaseGasPrice(pdp7Value.toFixed(18))
        console.log("callback", callback)
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `${NP.times(this.pdp7.placeholder, 21000)} to <dd>${pdp7Value.toFixed(18)}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        this.$store.commit('tranStatus', 1)
        // this.$store.commit('isTranModal', false)
      }
    },
    async handleCreate8() {
      if (this.pdp8.value === this.pdp8.placeholder) {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        const callback = await setBlockRewards(this.pdp8.value.toString());
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `${this.pdp8.placeholder} to <dd>${this.pdp8.value}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        // this.$store.commit('isTranModal', false)
        this.$store.commit('tranStatus', 1)
      }
    },
    async handleCreate9() {
      if (this.pdp9.value === this.pdp9.placeholder) {
        this.msg.error(this.$t(`message.FailedToCreateProposal`));
        return;
      }
      try {
        const callback = await setValidatorNum(this.pdp9.value.toString());
        const r = await newProposalInPg(contractAddress, this.id, callback, this.walletAddress, this.stakingAmount);
        return {
          name: `${this.pdp9.placeholder} to <dd>${this.pdp9.value}</dd>`,
          proposalId: r.events.ProposalAdded.returnValues.proposalID,
          sponsor: this.walletAddress,
        };
      } catch (e) {
        // this.$store.commit('isTranModal', false)
        this.$store.commit('tranStatus', 1)
      }
    },
    async CreatProposalClick() {
      // if(this.pgBalance <= 0){
      //   this.msg.error({message:this.$t('余额不足'),className:"isIndexTos"})
      //   return false
      // }

      this.creatShow = false;
      this.$store.commit('isTranModal', true)
      const r = await this[`handleCreate${this.id}`]();
      this.Creatinit()
      this.$store.commit('isTranModal', false)
      if (r) {
        api.createProposal(r);
      }
    },
    seeAllClick() {
      this.$router.push('/proposals')
    },
    handleGoDetail({id}) {
      this.$router.push({path: '/detail', query: {id}});
    },
    view(num, type) {
      if (num === 0) {
        return;
      }
      let ids = [];
      if (type === 'sponsor') {
        ids = this.sponsorProposalList;
      } else {
        ids = this.voterProposalList;
      }
      this.$router.push({path: '/detail', query: {ids: ids.join(',')}});
    },
  }

}
</script>
<style scoped lang="less">
.conts {
  color: #ffffff;
  background: #17181A;
  background: url("../../assets/PEGO/bgjojn.svg") no-repeat 100%;
  background-position: right 30px bottom 0;
  background-size: 672px 672px;
  box-sizing: border-box;
}

.bakg {
  background: url("../../assets/PEGO/bag1.svg") no-repeat;
  padding: 0px 0 0 100px;
  background-size: 800px;

}

.header-one {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 70px 0 100px 0px;
  background: url("@/assets/PEGO/pego-dao.png") no-repeat right 90px top 124px, url("@/assets/PEGO/dao-2.png") no-repeat right 13% top 300px;
  background-size: 470px 353px, 342px 328px;

  .head-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      width: 100%;
      font-family: BOLD;
      text-align: left;
    }


  }
}

.text-dev {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  font-family: REGULAR;
  width: 660px;
  color: #ffffff;
  //word-wrap:break-word;
  //white-space:nowrap;
}

.but-ul {
  padding-top: 75px;

  .buts {
    background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    margin-right: 20px;
    width: 194px;
    height: 54px;
    line-height: 54px;
    color: #ffffff;
    font-family: REGULAR;
  }
}

.pad {
  padding-bottom: 180px;
  border-top: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 122, 0, 0.1) 0%, rgba(255, 184, 0, 0.08) 40%, rgba(56, 57, 58, 0.05) 50%);
  border-image-slice: 1;
  position: relative;

}


.cand-ti {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  text-align: center;
  background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  display: inline-block;
  font-family: BOLD;
}

.my-pand {
  padding: 60px 0;
}

.accounts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  position: absolute;
  margin-bottom: 15px;
  top: 120px;

  div {
    font-family: 'REGULAR', sans-serif;
    font-size: 18px;
    color: #FF9D00;
    display: flex;
    align-items: center;

  }


}

.cet {
  text-align: center;
  display: flex;
  justify-content: center;

  .buts {
    width: 263px;
  }

}

.cent {
  padding-top: 0px;
}

.ti-card {

  ///deep/ .n-progress-graph-line-fill {
  :deep(.n-progress-graph-line-fill) {
    background: linear-gradient(90.05deg, #FF9839 17.92%, #FFD02B 64.69%) !important;
  }

  text-align: left;
  padding: 0 100px;

  .li_tet {
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    padding: 30px;

    .dis_li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .txt {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        height: 45px;
        font-family: SEMIBOLD;
      }

      img {
        //width: 31px;
        height: 35px;
        object-fit: contain;
      }
    }

    .numb {
      font-style: normal;
      font-weight: 300;
      font-size: 30px;
      font-family: LIGHT, sans-serif;
      cursor: pointer;

    }

    .vot-txt {
      font-weight: 400;
      font-size: 16px;
      line-height: 40px;
      color: #C4C5C7;
      font-family: REGULAR, sans-serif;
      position: relative;

      span {
        position: absolute;
        left: 130px;
        color: #ffffff !important;
        font-family: REGULAR;
      }
    }
  }

  .grd-rd {
    height: 60px;
    line-height: 60px;
    background: #23262C;
    //border-top-right-radius: 16px;
    //border-top-left-radius: 16px;
    padding-left: 35px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
  }

  .card-bags {
    background: #1C1F22;
    //border: 1px red solid;
    border-radius: 16px;
    overflow: hidden;
  }

  .timebag {
    text-align: center;
    padding: 30px 0 0;
    box-sizing: border-box;
    //background: #1C1F22;
    //border: 1px red solid;

    .grid-item {
      line-height: 50px;
      padding: 10px 0;

      div:nth-child(1) {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #C4C5C7;
      }

      div:nth-child(2) {
        font-size: 20px;
      }
    }
  }

  //.timebag:last-child {
  //  padding-top: 15px;
  //  padding-bottom: 30px;
  //  border-bottom-right-radius: 16px;
  //  border-bottom-left-radius: 16px;
  //}
  .bags-t {
    text-align: center;
    //border: 1px red solid;
    padding: 79px 66px 30px;
    box-sizing: border-box;

    h6 {
      font-weight: 400;
      font-size: 26px;
      line-height: 35px;
      text-align: center;
      margin: 0 !important;
      font-family: 'REGULAR', sans-serif;
    }

    .grid-Proposal {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .tlts {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      font-family: 'SEMIBOLD', sans-serif;
    }

    .t-t {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #C4C5C7;
      //width: 280px;
      //text-align: left;
      font-family: 'REGULAR', sans-serif;
    }
  }

  .time-one {
    padding: 0 66px;
    text-align: left;

    .grid-item {
      div:nth-child(1) {
        padding: 30px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #C4C5C7;
        text-align: left;
      }

      div:nth-child(2) {
        font-weight: 300;
        font-size: 16px;
        line-height: 8px;
        color: #FF9D00;
      }

      border-top: 1px solid rgba(255, 255, 255, 0.39);
    }
  }

  .one-bag {
    border-radius: 0;
    background: rgba(28, 31, 34, 0.5);
    border-bottom: 1px solid #3A3B3D;
  }

  .bor-bag {
    background: rgba(28, 31, 34, 0.5);
    border-radius: 0;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .status {
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    text-align: center;
    padding: 35px 0;

    .sta-li {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
    }

    .sta-ti {
      font-style: normal;
      font-weight: 300;
      font-size: 50px;
    }
  }
}

.nubt {
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
  color: #ffffff;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  width: 73px;
}

.gin {
  display: flex;
  align-items: center;
}


.nupod {
  background: transparent;
  //text-transform: uppercase;
  color: white;
  //padding: 15px 50px;
  overflow: hidden;
  position: relative;
  border-radius: 33px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: .3s;
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

}

.nupod:after {
  content: '';
  display: block;
  position: absolute;
  top: -36px;
  left: -100px;
  background: white;
  width: 50px;
  height: 125px;
  opacity: 20%;
  transform: rotate(-45deg);
}

.nupod:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
  0 0 20px #FFB800;
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 20px;
  text-shadow: 1px 1px 2px #427388;
}

.nupod:hover:after {
  left: 120%;
  transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
  -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
}

.nupod:active {
  border: none;
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
  transition: .6s; /*取消过渡*/
  box-shadow: inset 0 0 20px #FF7A00,
  0 0 20px #FFB800;
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.PDPColor {
  border: none;
  color: #ffffff;
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
}

.pro-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
  backdrop-filter: blur(7.5px);
  border-radius: 15px;
  padding: 10px 30px 15px;
  height: 139px;
  box-sizing: border-box;
  margin-bottom: 75px;

  .ul-lef {
    width: 80%;

    .tit-pro {
      font-weight: 700;
      font-size: 25px;
      font-family: BOLD, sans-serif;
    }

    .prog-ess {
      position: relative;
      height: 100%;

      span {
        position: absolute;
        left: 10px;
        top: 0;
        z-index: 2;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        height: 100%;
        line-height: 22PX;
        display: inline-block;
      }
    }

    .prog-ess:nth-child(1) {
      margin: 8px 0 12px;
    }
  }

  .ul-rig {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 10%;
    padding: 10px 0 0;
    box-sizing: border-box;

    .rig-txt {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }

    .active {
      color: #43FFAA;
    }

    .pending {
      color: #299CFF;
    }

    .invalid {
      color: #FA7977;
    }


    .core {
      font-weight: 400;
      font-size: 12px;
    }
  }
}
</style>
<style lang="less" scoped>
@import "../../components/modals.less";

</style>
<style scoped lang="less">
.cot-po {
  top: 55px !important;
  border-radius: 4px !important;
  padding: 0 10px;
  background: #222427 !important;
  border: 1px solid rgba(255, 255, 255, 0.1);

  .bodr {
    line-height: 21px;
    border-bottom: 0.3px solid rgba(255, 255, 255, 0.1);
  }

  .bodr:last-child {
    border-bottom: none;
  }

  div {
    padding: 5px 15px;

  }

  .bagst:hover {
    background: #383F46 !important;
    border-radius: 8px;

  }

  .corBg {
    background: #383F46 !important;
    border-radius: 8px;
  }

}

.maks {
  text-align: left;
  padding: 30px 10px;

  div {
    line-height: 40px;
    font-weight: 400;
    font-size: 14px;
  }

  div:nth-child(2) {
    font-weight: 400;
    font-size: 12px;
  }
}

.dis-t {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 0 30px;
  width: 100%;

  .txt-d {
    width: 30%;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
  }

  .inpt-n {
    width: 70%;
    height: 42px !important;
    line-height: 42px !important;
  }

  ///deep/ .n-input__input-el {
  :deep(.n-input__input-el) {
    color: #ffffff !important;
    caret-color: #FFFFFF !important;
    -webkit-appearance: none;
    scrollbar-width: none;
    width: 100%;
    min-width: 0;
    height: 42px !important;
    line-height: 42px !important;
    text-align: left;
    font-size: 16px;
    background-color: transparent;
  }

}
</style>
