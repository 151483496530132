<template>
  <!-- Blog & latest updates -->
  <div class="cons">
    <div class="ti-card">
      <div class="sea-h1">
        <h5 class="blogs" data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">{{ $t(`message.Blog`) }} <span>{{ $t(`message.latestUpdates`) }}</span></h5>
        <n-input :placeholder="$t(`message.search`)" class="seachs">
          <template #prefix>
            <Icon color="#D9D9E6" size="20" class="icons">
              <SearchOutline/>
            </Icon>
          </template>
        </n-input>
      </div>
      <div>
        <h6>{{ $t(`message.Blog`) }}Total articles: 04</h6>
        <n-grid x-gap="24" y-gap="24" cols="2 l:3 xl:3 2xl:4" responsive="screen">
          <n-grid-item>
            <div class="bag-grid" data-aos="flip-right"
                 data-aos-easing="ease-out-cubic"
                 data-aos-duration="500">
              <div>
                <img src="@/assets/PEGO/blog1.png" alt="">
              </div>
              <div class="con">
                <div class="times" data-aos="fade-left"
                     data-aos-duration="800">28 Mar 2021 03:10
                </div>
                <div class="tlt" data-aos="fade-left"
                     data-aos-duration="800">{{ $t(`message.TheProposal`) }}
                </div>
                <div class="txt" data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                     data-aos-duration="500">
                  {{ $t(`message.publicChain`) }}
                </div>
              </div>
            </div>
          </n-grid-item>
          <n-grid-item>
            <div class="bag-grid" data-aos="fade-up"
                 data-aos-duration="500">
              <div>
                <img src="@/assets/PEGO/blog2.png" alt="">
              </div>
              <div class="con">
                <div class="times" data-aos="fade-up"
                     data-aos-duration="800">28 Mar 2021 03:10
                </div>
                <div class="tlt" data-aos="fade-up"
                     data-aos-duration="800">{{ $t(`message.TheW3BIWO`) }}
                </div>
                <div class="txt" data-aos="zoom-out" data-aos-duration="500">
                  {{ $t(`message.theFuture`) }}
                </div>
              </div>
            </div>
          </n-grid-item>
          <n-grid-item>
            <div class="bag-grid" data-aos="flip-left"
                 data-aos-easing="ease-out-cubic"
                 data-aos-duration="500">
              <div>
                <img src="@/assets/PEGO/blog3.png" alt="">
              </div>
              <div class="con">
                <div class="times" data-aos="fade-right"
                     data-aos-duration="800">28 Mar 2021 03:10
                </div>
                <div class="tlt" data-aos="fade-right"
                     data-aos-duration="800">{{ $t(`message.PEGONetwork`) }}
                </div>
                <div class="txt" data-aos="fade-up-right" data-aos-easing="ease-out-cubic"
                     data-aos-duration="500">
                  {{ $t(`message.toIncentivese`) }}
                </div>
              </div>
            </div>
          </n-grid-item>
          <n-grid-item>
            <div class="bag-grid" data-aos="flip-right"
                 data-aos-easing="ease-out-cubic"
                 data-aos-duration="500">
              <div>
                <img src="@/assets/PEGO/blog4.png" alt="">
              </div>
              <div class="con">
                <div class="times" data-aos="fade-left"
                     data-aos-duration="800">28 Mar 2021 03:10
                </div>
                <div class="tlt" data-aos="fade-left"
                     data-aos-duration="800">{{ $t(`message.TheProposal`) }}
                </div>
                <div class="txt" data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                     data-aos-duration="500">
                  {{ $t(`message.publicChain`) }}
                  {{ $t(`message.publicChain`) }}
                </div>
              </div>
            </div>
          </n-grid-item>
        </n-grid>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import foot from "@/components/foot";
import {SearchOutline} from "@vicons/ionicons5";
import {Icon} from '@vicons/utils'

export default {
  name: "blogLatest",
  components: {foot, SearchOutline, Icon},
}
</script>

<style scoped lang="less">
.cons {
  background: url("../assets/PEGO/bgjojn.svg") no-repeat right 30px bottom 0;
  background-size: 672px 672px;
}

.ti-card {
  padding: 100px;
  box-sizing: border-box;
}

.sea-h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seachs {
  --n-border-hover: 1px solid rgba(255, 255, 255, 1) !important;
  --n-border-focus: 1px solid rgba(255, 255, 255, 1) !important;
  --n-caret-color: #FFFFFF !important;
  --n-border: 1px solid rgba(255, 255, 255, 0.3) !important;
  width: 343px;
  height: 50px;
  background: #2C2F34 !important;
  border-radius: 4px;
  caret-color: #2C2F34 !important;
  color: white !important;
  text-align: left;
  font-size: 16px;

  ///deep/ .n-input__input-el {
  :deep(.n-input__input-el) {
    color: #ffffff !important;
    caret-color: #FFFFFF !important;
    -webkit-appearance: none;
    scrollbar-width: none;
    width: 100%;
    min-width: 0;
    height: 50px;
    background-color: transparent;
  }

  .icons {
    font-size: 20px !important;
  }
}

h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  font-family: REGULAR, sans-serif;
  color: #C4C5C7;
  text-align: left;
}

.blogs {
  font-weight: 700;
  font-size: 60px;
  line-height: 82px;
  background: linear-gradient(90.02deg, #F38787 0.87%, #FFDF6F 25.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //background-clip: text;
  //text-fill-color: transparent;
  font-family: BOLD, sans-serif;

  span {
    background: #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //background-clip: text;
    //text-fill-color: transparent;
    font-family: BOLD, sans-serif;
  }
}

.bag-grid {
  background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
  box-shadow: 0 4px 18px #17191B;
  backdrop-filter: blur(7.5px);
  border-radius: 15px;
  overflow: hidden;
  min-width: 404px;

  img {
    ////width: 404px;
    //height: 305px;
    width: 100%;
    object-fit: contain;
  }

  .con {
    padding: 30px;
    text-align: left;

  }

  .times {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: 'REGULAR', sans-serif;
    color: #FF9D00;
  }

  .tlt {
    padding: 20px 0;
    font-family: 'BOLD', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    height: 44px;
    line-height: 30px;
    min-width: 404px;
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行

  }

  .txt {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    font-family: 'LIGHT', sans-serif;
    color: #E9E9FF;
    height: 104px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 4; //显示的行
  }
}
.bag-grid:after{
  content: "";
  position: absolute;
  left: -140%;
  top: 0;
  width: 100%;
  height: 100%;
  transform:skew(-30deg);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));
}
.bag-grid:hover {
  transform:scale(1.1) translateY(-20px);
  box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);

  &:after{
    left: 140%;
    transition: all .3s;
  }

}
.grids {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;


}
</style>
