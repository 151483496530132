<template>
  <div>
    <n-config-provider :theme-overrides="themeOverrides">
      <n-modal v-model:show="showModal"
               preset="dialog"
               title=""
               :show-icon="false"
               @close="OnUpdate"
               @esc="OnUpdate"
               @maskClick="OnUpdate"
               positive-text=""
               negative-text="">
        <template #action>
          <div class="tra" :style="{ width:wids+'rem'}">
            <slot name="action">
            </slot>
          </div>
        </template>
        <template #default >
          <div class="tra" :style="{ width:wids+'rem'}">
            <slot name="default">
            </slot>
          </div>
        </template>

      </n-modal>
    </n-config-provider>

  </div>
</template>

<script>



export default {
  name: "modal-s",
  props: ['LoadingShow','wids' ],
  data() {
    return {
      showModal: false,

      themeOverrides: {
        "Dialog": {
          "textColor": "rgba(255, 255, 255, 1)",
          "color": "rgba(56, 63, 70, 0.7)",
          "closeColorPressed": "rgba(196, 15, 15, 0.13)",
          "closeColorHover": "rgba(35, 2, 2, 0)",
          "closeIconColor": "rgba(255, 255, 255, 1)",
          "closeIconColorHover": "rgba(230, 146, 30, 1)",
          "iconColorInfo": "#7398C4FF",
          "borderRadius": "15px",
        }
      },
    }
  },
  watch: {
    LoadingShow(val) {
      this.showModal = val
    },
  },
  async mounted() {
    this.showModal=this.LoadingShow
    // this.showModal = this.$store.state.showModal

  },
  computed: {
    walletAddress() {
      return this.$store.state.walletAddress
    },
    isSponsor() {
      return (this.detailData.sponsor).toLowerCase() === (this.walletAddress).toLowerCase();
    },
    isShow() {
      return new Date().getTime() < this.detailData.excuttionEndDate && this.detailData.status === 6;
    },
  },
  methods: {
    OnUpdate() {
      this.$emit('OnUpdate')
    },

  }
}
</script>

<style scoped lang="less">
.tra {
  text-align: center;
}
</style>

