export default {
    message: {
        Product: 'ホームページ',
        Network: 'ガバナンス',
        Build: 'エコシステム',
        PEGOScan: 'ノード選挙',
        bridge: 'ブリッジ',
        Proposal: 'DAO',
        browser: 'ブラウザ',
        BecomeAValidator: 'バリデーターになる',
        wallet: 'ウォレット',
        news: 'ニュース',
        routeMap: '開発ルートマップ',
        foundation: '財団',
        EN: 'English',
        ZN: '繁体中文',
        JP: '日本語',
        KO: '한국인',
        TH: 'แบบไทย',
        VI: 'Tiếng Việt',
        Whitepaper: 'ホワイトペーパー',
        Blog: 'ブログ',
        Foundation: '財団',
        blockchain: "<span class='nameColor'>{name}</span>のためのオープンで自治的なWeb3アプリケーション層の基盤",
        name: 'ブロックチェーン',
        Support: '高度な自治性、スケーラビリティ、および持続可能性をサポートする分散型アプリケーション。',
        scalability: 'スケーラビリティ',
        solution: 'Web3およびAI向けの高性能ソリューション。',
        EnterTheDAPP: 'ホワイトペーパー',
        ReadDocs: 'ドキュメントを読む',
        GITHUB: 'GITHUB',
        MainnetLaunchCountdown: 'メインネットローンチまでのカウントダウン',
        Days: '日',
        Hours: '時間',
        Minutes: '分',
        PEGOSolutions: 'PEGOソリューション',
        PPOS: 'PPOS',
        EnablesSustainable: '公正で安全なガバナンスモデルを通じて持続可能なエコシステムを実現します。',
        EVMCompatible: 'EVM互換',
        CompatibleWith: 'Golang、Rust、Solidity、およびEVM仮想マシンと互換性があり、シームレスなクロスプラットフォームのdAppの開発者に友好的な環境を提供します。',
        NFTPNNIdentityVerificationSystem: 'NFT-PNN身分証明システム',
        EnsuresNetwork: 'PNNステーキングとPGVT投票を通じて、ネットワークの分散化とセキュリティを確保します。',
        CommunityProposedProposals: 'コミュニティ提案の提案',
        ProvidesFlexibility: 'コミュニティ提案を通じて、ネットワークパラメータを調整する柔軟性を提供します。',
        AIIntegration: 'AI統合',
        IntegratesWith: 'AI統合を実現し、AIアプリケーションの処理速度と効率を向上させながら、透明性とセキュリティを維持します。',
        DAOGovernment: 'DAOガバナンス',
        partner: 'Partner',
        PEGOIsGoverned: 'PEGOは、グローバルなDAOによってそのネイティブトークンPGを通じてガバナンスされます。',
        BuyPG: 'PGを購入する',
        PGAllowsWeb3: 'PGにより、Web3コミュニティメンバーはプロトコルのアップグレード提案に参加し、PEGOのブロックチェーンネットワークを検証し、ステーキング報酬を受け取り、バーンオークションを行うことができます。',
        SupportByPEGOFoundation: 'PEGO Foundationのサポート',
        ContactUs: 'お問い合わせ',
        Government: 'ガバナンス',
        Validator: 'バリデーター',
        Wallet: 'ウォレット',
        Ecosystem: 'エコシステム',
        W3bridge: 'W3ブリッジ',
        Exchange: '取引所',
        Loan: 'ローン',
        W3BANK: 'W3BANK',
        PEGOTechnology: '© 2023 PEGOテクノロジー',
        ADAOCommunityBasedPublicChain: 'オープンで自治的なWeb3アプリケーション層基盤',
        Built: '<span class="nameColor">{network}</span>と共に構築されましたか？',
        network: "PEGOネットワーク",
        PEGOHasDesigned: "PEGOは、完全にコミュニティ主導の自治型Web3インフラストラクチャを設計しており、すべてのチェーン上パラメータはコミュニティ提案を通じて柔軟に調整できます。PPOSコンセンサスメカニズムに基づいて、持続可能なPEGOエコシステムを促進するための複合経済刺激メカニズムが構築されています。さらに、PEGOブロックチェーンはEVMシステムと完全に互換性があり、スケーラブルな分散アプリケーションを構築するための理想的な選択肢です。",
        SubmitYourProject: "プロジェクトを提出する",
        SubmitYourProjectLink: "",
        ApplyForInvestment: "投資申請",
        useCases: "ユースケース",
        LoremIpsum: "多様なWeb3アプリケーションは、PEGOネットワークの最大の価値であり、将来のキラーアプリが潜在的に含まれています。",
        CrossChain: "クロスチェーン",
        CrossChainBrigde: "クロスチェーンブリッジ",
        JoinTheCommunity: '<span class="nameColor">{Community}</span>に参加する',
        Community: "コミュニティ",
        PEGODiscordSupport: "PEGO Discordサポート",
        ToBeA: '<span class="nameColor">{TOValidator}</span>になる',
        TOValidator: "バリデーター",
        PEGOIsCommitted: "PEGOは、完全にコミュニティ主導の分散型ネットワークを構築することに取り組んでおり、バリデーターノードの設計はこの目標を達成するために重要です。PPOSコンセンサスメカニズムにより、公正なメカニズムの下で、分散型ネットワークのすべての参加者が効果的に協力できるとPEGOは約束しています。",
        CONNECT: "接続する",
        applyToBe: "バリデーターになる申請",
        PEGOValidator: "PEGOバリデーターノードの特徴は、特定の所有権主体を持つNFT-PNN「IDカード」を持っていることです。候補者はPNNをステーキングし、正式なPEGOバリデーターノードの選挙に参加してPEGOネットワークのバリデーターノードになります。",
        NFTPNNIDENTITYCARD: "NFT-PNN身分証明書",
        MyAccount: "マイアカウント",
        VotingReward: "投票報酬",
        TokenBalance: "トークン残高",
        VotingPower: "投票パワー",
        VotedValidators: "投票済みバリデーター",
        Rank: "ランク",
        Address: "アドレス",
        Status: "ステータス",
        MyShare: "マイシェア",
        Operate: "操作する",
        ValidatorStatus: "バリデーターステータス",
        TotalVotes: "総投票数",
        NextRound: "次のラウンド",
        ValidatorRanking: "バリデーターランキング",
        search: "検索",
        Ranking: "ランキング",
        LatestBlock: "最新ブロック",
        ValidatedBlocks: "検証済みブロック",
        Votes: "投票数",
        Action: "アクション",
        Vote: "投票",
        PEGO: `PEGO <span class='nameColor'>{dao}</span>`,
        dao: "DAO",
        PEGOEmphasizes: "PEGOは効率を重視し、コミュニティが提案を柔軟に調整できるようにチェーン上のすべてのパラメータを設定します。ノードまたは一般ユーザーは一定量のPGをステークして提案を発行できます。所定の時間内に投票によって承認されると、提案はチェーン上のトランザクションとして実行され、有効になります。",
        VotingTaken: '投票中',
        Sponsor: "スポンサー",
        Active: "アクティブ",
        Executed: "実行済み",
        Voted: "投票済み",
        SUBMITPROPOSAL: "提案を送信する",
        OnChainParameters: "チェーン上のパラメータ",
        PendingTime: "保留時間",
        VotingTime: "投票時間",
        ExecutionTime: "実行時間",
        TotalVotesTotalVt: "総投票数/総VT",
        SupportedVotesTotalVotes: "サポートされている投票数/総投票数",
        StakePG: "PGをステークする",
        Proposals: "提案",
        SEEALL: "すべて表示",
        ThreeStages: `<span class='nameColor'>{PGStrategies}</span>の3つのステージ`,
        PGStrategies: "PGストラテジー",
        BasedOnDao: "DAOに基づいて",
        InTheInitial: "PEGOパブリックチェーンの初期開発段階では、生態系の中でDAOの概念を基にしたすべての準備が行われ、共同構築と共有が実現されます。さらに、生態系はDAOガバナンスによって共同運営され、民主的かつ公正な自治ルールに基づいています。",
        DAODevelopment: "DAOの発展",
        InThePEGO: "PEGOネットワークでは、さまざまなタイプのDAOが登場し、エコシステムへの参加者の増加に伴い、DAOガバナンスへの需要も高まるでしょう。これはブロックチェーンの普及を加速する可能性があります。",
        ServingDAO: "DAOへの貢献",
        AllBlockchainBased: "すべてのブロックチェーンベースのDAOはコミュニティメンバーによって所有されます。 3つのステージ戦略に基づいて、PEGOネットワークは最終的に完全なDAOガバナンスベースのパブリックチェーンとなります。",
        PEGORoadmap: "PEGOロードマップ",
        latestUpdates: "最新のアップデート",
        TheProposal: "PEGOネットワークのアップデート：トークンエコノミーモデル提案",
        publicChain: "パブリックチェーン情報：PEGOネットワーク トークンシンボル：PG PEGOネットワークのガバナンストークンとして、PGはBSCチェーン上でも発行され、クロスチェーンの相互作用に使用されます...",
        TheW3BIWO: "W3B IWOが間もなく開始されます",
        theFuture: "W3Bridgeは、PEGOエコシステムの早期開発を基盤に、分散されたWeb3ワールドの架け橋となるでしょう。エコシステムの拡大に伴い...",
        PEGONetwork: "PEGOネットワークはPNガバナンストークンを導入",
        toIncentivese: "PEGOネットワークのグローバルな普及者を奨励するために、PEGOネットワークはコミュニティのガバナンストークンとしてPNを導入します。このトークンは...",
        ThePEGOFoundation: `PEGO <span class='nameColor'>{Foundations}</span>`,
        Foundations: '財団',
        pegoEmphasizes: "PEGO Foundationは、PEGOネットワークとそのコミュニティの構築、サポート、発展を目指す分散型組織です。この組織は、世界各地の業界の専門家とコミュニティの中心的なボランティアから成り立っています。",
        StakePools: "ステークプール",
        stakePools: "ステークプールは、PEGOの分散化、検閲耐性、DeFiの成長を促進する流動性ステーキングソリューションです。",
        STAKEPG: "PGをステークする",
        Grants: "助成金",
        thePegp: "PEGP Foundation Grant Programは、PEGOネットワークの耐検閲性能向上を助成することに特化しています。",
        APPLYFORGRANTS: "助成金の申請",
        HundredFamily: "",
        HundredFamilyDesc: "",
        APPLY100Family: "",
        APPLY100FamilyLink: "",
        JOINPEGOFOUNDATION: "PEGO Foundationに参加",
        Ended: "終了しました",
        linkWallet: "ウォレットをリンク",
        BNBSmartChain: "BNBスマートチェーン",
        TelegramCommunity: "Telegramコミュニティ",
        EnglishCommunity: "英語コミュニティ",
        validator: "バリデータ",
        quitValidator: "バリデータを辞める",
        claim: "請求",
        GetPGVT: "PGVTを獲得する",
        voteValidator: "バリデータに投票する",
        ReturnPGVT: 'PGVTを返却する',
        ReturnPGVT2: 'クールダウン中は返却不可',
        ReturnPGVT3: '残高不足',
        IncreaseVote: '投票数を増やす',
        Refund: '返金',
        quitTheValidator: 'バリデータを辞める',
        onceQuited: '一度辞めると、PGの報酬を受け取れなくなります！ ',
        andYour: 'また、PNNを回収することはできません。',
        noIDont: 'いいえ、辞めたくないです',
        SureIWant: 'はい、辞めたいです',
        getPGVT: 'PGVTを獲得する',
        pleaseEnter: '入力してください',
        MAX: 'マックス',
        Balance: '残高',
        BalancePGVT: 'PGVTの残高',
        returnPGVT: 'PGVTを返却する',
        youWantTo: '返却する',
        YourBalanceIs: '残高は',
        isYourBalanceIsVT: 'VT残高です',
        YourBalanceIsVT: 'VTの残高が不足しています',
        YourBalanceIs1:'このソースからすでに鋳造された量は',
        YouneedReturn: '返却が必要です',
        CooldownCountdown: 'クールダウン期間',
        Seconds: '秒',
        VoteAmount: '投票額',
        ValidatorAddress: 'バリデータのアドレス',
        Withdraw: '引き出す',
        Withdraw2: 'クールダウン中は引き出せません',
        WithdrawAmount: '引き出す数',
        NoticeYou: '注意：クールダウン期間が終了すると、PGVTが返却されます。 ',
        ExchangeNFTPNNT: "NFT-PNNTの鋳造",
        ValidatorApplication: "バリデータ申請",
        SorryYou: "PNN-NFTを所有していません。",
        anyPNNNFT: "PNNを使用して鋳造してください",
        // howToGet: "NFT-PNN（PEGO-Chain）を入手する方法",
        // IfYouHave: "PNN-BSCトークンをお持ちの場合、",
        clickHere: "鋳造する",
        // toMint: "PNN-NFT（PEGO-Chain）を1つ鋳造してください。",
        ConnectWallet: "ウォレットに接続",
        YourPNNNFT: "PNN NFT",
        applyToBeAValidator: "バリデータになるために申請する",
        Transaction: "トランザクション",
        CongratulationsYou: "おめでとうございます！あなたは現在、バリデータです！",
        JoinOur: '私たちの',
        discord: 'Discord',
        toGetHow: 'バリデータノードの実行方法について学ぶ。',
        Failed: '失敗しました',
        Pending: 'ペンディング',
        MoreAboutDAO: 'DAOについて',
        CreatProposal: "提案を作成する",
        CoolingOffPeriodOfPGVT: "PGVTのクールダウン期間",
        CoolingOffPeriodOfPGW3FT: "PG&W3FTのクールダウン期間",
        ValidatorDistrbution: "バリデータの分布",
        AdditionalPGIssued: "追加のPG発行",
        LowestGasFee: "最低ガス料金",
        BlockReward: "ブロック報酬",
        ValidatorNumbers: "バリデータ数",
        SupportedToken: "サポートされるトークン",
        Blacklist: "ブラックリスト",
        TotalVT: "総VT",
        Supports: "サポート",
        Invalid: "無効",
        Passed: "承認済み",
        Core: "コア",
        ProposalType: "提案タイプ",
        AdjustThe: "提案パラメータを調整する",
        AddOrDelete: "農場トークンを追加または削除する",
        AdjustTheDistribution: "報酬分配の割合を調整する",
        BlaDecentralized: "BlaDecentralized Foundationは、提案者のアドレスに追加のPGを発行します",
        AdjustTheLowestGasFee: "最低ガス料金を調整する",
        AdjustTheBlockReward: "ブロック報酬を調整する",
        AdjustThenumberOfValidators: "バリデータ数を調整する",
        ChoosePDP: "PDPを選択する",
        ParametersChanges: "パラメータの変更",
        NowIs: "現在",
        ExeuctionTime: "実行時間",
        TotalVotesvt: "総投票数/総VT",
        SupportedVotesTotalVotess: "サポートされている投票数/総投票数",
        AddDelete: "加える/デリート",
        AddANewFarmingToken: "新しいファーミングトークンを追加する",
        DeleteoneFarmingToken: "1つのファーミングトークンを削除する",
        TokenAddress: "トークンアドレス",
        CoolingOffPeriodOf: "クールダウン期間",
        TheDistributionProportionForValidator: "バリデータとユーザーの分配比率、ユーザーは現在",
        AddAnAddressToBlacklist: "アドレスをブラックリストに追加する",
        DeleteAnAddressOutOfBlacklist: "アドレスをブラックリストから削除する",
        Amount: "量",
        TheAmountOfPGIssued: "発行されるPGの量",
        NumberOfValidators: "バリデータ数",
        YouNeedToStake: "ステークする必要があります",
        makeSureYou: "PG、残高が十分であることを確認してください。",
        PGBalance: "PGの残高",
        FailedToCreateProposal: "提案の作成に失敗しました",
        Detail: "ディテール",
        StartTime: "始まる時間",
        VoteTime: "投票時間",
        TotalVoteToken: "総投票トークン",
        Against: "に対して",
        GetBackVT: "VTを取り戻す",
        Execute: "実行する",
        Metamask: "Metamask",
        DoNotHaveMetamask: "Metamaskをインストールしていませんか？",
        Install: "インストール",
        YoureAlreadyAValidator: "あなたはすでにバリデーターです！",
        Error: "エラー",
        Successful: "成功",
        GetBackPG: "PGを取り戻す",
        About: "約",
        proposal: "提案",
        pegoScan: "PEGOブラウザー",
        other: "その他",
        comingSoon: "近日公開",
        PWGBountyProgram: 'PWGバウンティプログラム',
        Web3GrantProgram: 'PEGO Web3グラントプログラムは、新興のWeb3アプリケーション層に対して資金、技術、リソースの包括的なサポートを提供することを目的としています。',
        PWGCanApplyForType: 'PWGは次のタイプを申請できます',
        seedIncentive: 'シードインセンティブ',
        StartupFunding: '優れたWeb3アイデアのスタートアップ資金を提供します',
        financialSupport: '財政的な支援',
        otherSupport: 'その他の支援',
        ProjectLanding: 'プロジェクトの着地支援コンサルティング',
        EcologicalCoCreation: 'エコロジカルコクリエーション',
        ecosystem: 'PEGO Web3エコシステムの重要な一部となる',
        CoCreationSupport: 'コクリエーションのサポート',
        ProvideFull: '全面的な技術サポートを提供し、適切な協力リソースに接続し、PEGOエコシステム全体の運営活動に統合します。',
        SpecialType: 'Layer2＆3スペシャルタイプ',
        ProvideSpecial: 'PEGO上でL2およびL3を構築する参加者に特別なサポートを提供します',
        AccelerateSupport: 'アクセラレートサポート',
        TargetedTechnical: '対象となる技術サポートを提供し、コアチームの構築を支援します',
        StrategicInvestment: '戦略的投資タイプ',
        MeetTheDevelopment: 'PEGOエコロジーの戦略的な発展ニーズを満たします',
        strategicSupport: '戦略的なサポート',
        ImplementationOfThe: 'ライフサイクル全体の着地、戦略、ビジネスオペレーション、技術およびビジネスコンサルティングを提供し、トップレベルの協力リソースに接続し、後続の資金誘導を行います。',
        WhatIs100FamilyTitle: '',
        WhatIs100Family: '',
        Why100FamilyTitle: '',
        Why100Family: '',
        Type100FamilyTitle: '',
        Type100Family: '',
        Contact100Family: '',
        Contact100FamilyName: '',
        Contact100FamilyLink: '',
        VoterDistribution: 'ユーザー比率',
        homeHeader: 'PEGOは創造のために生まれました',
        infrastructure: 'Web3インフラストラクチャ、PPOSコンセンサス、仮想マシン（VM）、DAOガバナンス、ブロックチェーンツール、PEGOはすべての人向けのブロックチェーンインフラストラクチャです',
        CompleteTheMainnetLaunch: 'メインネットの立ち上げを完了する',
        CompleteNodeCampaign: 'ノードキャンペーンを完了する',
        W3swapLaunched: 'W3swapがローンチされました',
        W3WalletIsOnline: 'W3Walletがオンラインで利用可能',
        ListedOnTheWorld: '世界の主要取引所に上場',
        StartApplicationEcologicalConstruction: 'アプリケーションエコロジーの構築を開始する',
        PromoteMEMEEcologicalConstruction: "MEMEエコロジーの構築を推進する",
        PromoteFOMO: "FOMOエコロジーの構築を推進する",
        StarttheGlobal: "グローバルエコロジーツアーを開始する",
        AchieveEcological: "200のエコシステムのオンライン化を達成する",
        TheNumberOfValid: "有効なウォレットの数が100万を超える",
        TheTotalNumber: "ブロックトランザクションの合計数が500万を超える",
        TVLExceeded: "TVLが1億ドルを突破する",
        CompleteTheLaunch: '主要取引所への上場を完了する',
        AchieveEcologicalOnline: '500のエコシステムのオンライン化を達成する',
        TheNumberOfEffective: '有効なウォレットの数が200万を超える',
        heTotalNumberOfBlock: 'ブロックトランザクションの合計数が2000万を超える',
        TVLExceededBillion: 'TVLが10億ドルを突破する',
        Alreadyin: '既に存在',
        NotIn: '存在しない',
        FillInAAddressAlreadyIn: '既に存在するアドレスを入力してください',
        FillInTheTokenContractAddress: "トークンの契約アドレスを入力してください",
        AlreadySupported: "既にサポートされています",
        DecentralizedFoundationWillIssue: "分散型財団はPGを発行します",
        GasFee: "ガス料金",
        Add: '加える',
        Delete: 'デリート',
        W3swap:'W3swap',
        AMegaPlatform:'すべての最高の取引ソリューションを統合した大規模プラットフォーム',
        W3Wallet:'W3Wallet',
        MultiFunction:'PEGO Networkエコシステムに基づくマルチ機能Web3クロスチェーンウォレット',
        W3Bridge:"W3Bridge",
        RebuildTheFragmented:"断片化したWeb3ネットワークを再構築する",
        EnterAsAnInteger:"整数として入力してください",
        NetworkError:"ネットワークエラー",
        nodeReward:"ノード報酬",
        award:"アワード",
        toBeCollected:"回収待ち",
        received:"受け取り済み",
        NumberOfBlocks:'ブロック数',
        EcologicalDAPP:'エコロジカルDAPP',
        receiveAward:'受け取る',
        pleaseGoToWallet:'ウォレットにアクセスしてください',
        AuditReport:'監査報告書',
        ProposalDescription:'提案の説明',
        nodeCard:'ノードカード',
        PDP1:'PDP-1タイプ提案',
        DetermineOr:'目的：DAOガバナンス提案のいくつかの重要なパラメーターを決定または調整するために使用されます',
        TheAdjustableParameters:'調整可能なパラメーターは次のとおりです：公示期間/投票期間/実行期間/PGのステーキング量/条件1/条件2',
        PDP2:'PDP-2タイプ提案',
        decideWhich:'どのタイプのLP生成トークンがPGVTのステーキングと交換できるかを決定します（LP内のPG数が500000未満の場合、提案が承認されてもPGVTを鋳造することはできません）',
        PDP3:'PDP-3タイプ提案',
        adjustTheCooling:'PGVTの鋳造および贖回のクールダウン期間、およびVTの投票による贖回のクールダウン期間を決定および調整するために使用されます',
        PDP4:'PDP-4タイプ提案',
        usedToDetermine:'ノードおよび投票者の報酬分配比率を決定および調整するために使用されます',
        PDP5:'PDP-5タイプ提案',
        controlMalicious:'一部の悪意のある契約およびアカウントに対して凍結またはリスク管理措置を実施し、悪影響を迅速に制御および排除するためのものです',
        PDP6:'PDP-6タイプ提案',
        controlMaliciousContracts:'PGの追加発行に使用され、どのユーザーでも提案を作成できます',
        PDP7:'PDP-7タイプ提案',
        usedToAdjust:'異なる段階の要件に対応するために、チェーン上の最低Gas料金を調整するために使用されます',
        PDP8:'PDP-8タイプ提案',
        adjustTheBlock:'ブロック報酬を調整する',
        PDP9:'PDP-9タイプ提案',
        adjustTheNumber:'検証ノードのオンライン数を調整する',
        BitKeep:'BitKeep',
        TOKENPOCKET:'TOKEN POCKET',
        OKXWallet:'OKX Wallet',

        //6-13
        votingAddress:'投票アドレス',
        numberOfVotes:'投票数',
        votingTime:'投票時間',
        votingList:'投票リスト',
        ExploreThePEGOEcosystem:'PEGOエコシステムの探索',
        submitProject:'プロジェクトを提出する',
        allItems:'すべてのアイテム',
        all:'すべて',
        startSearching:'検索を開始',
        audit:'監査',
        CreatingAndOwning:'ウォレットを作成して所有することは、PEGOに接続し、Web3に入るための第一歩です。ウォレットの秘密鍵とニーモニックを安全に保管してください。',
        OpeningMetamask:"Metamaskを開いています",
        PleaseConfirmInMetamask:"Metamaskで確認してください",
        OpeningBitkeep:"BitKeepを開いています",
        OpeningOKXWallet:"OKXを開いています",
        NotInstalled:"インストールされていません",
        EcologicalCooperationApplicationForm:"生態協力申請フォーム",
        WelcomeToJoin:"参加していただきありがとうございます。データを送信した後、できるだけ早くご連絡させていただきます。それまで以下の情報を入力してください。",
        PEGOEcologicalBusiness:"PEGO生態ビジネス協力の公式連絡先ID",
        Telegram:"Telegram",
        Btok:"Btok",
        projectName:"プロジェクト名",
        projectLogo:"プロジェクトロゴ",
        ImageFormatsLike:".jpg、.png、.gif、.bmp、.psd、.tiffなどの画像形式がサポートされています。",
        ChooseImage:'画像を選択',
        Lessthan:'500MB未満',
        ProjectDescription:'プロジェクトの概要',
        itemCategory:'プロジェクトのカテゴリまたは位置づけ',
        anyOtherType:'DEX、NFT、その他のタイプ',
        ProjectBenefits:'プロジェクトの利点',
        WhatCanItBringToThe:'PEGOエコシステムにもたらすことができるものは何ですか？',
        ProjectInfluence:'プロジェクトの影響力',
        WhatKindOfCooperation:'PEGOエコシステムとどのような協力をしたいですか？',
        TokenListedOn:'トークンがW3スワップにリストされていますか？',
        TokenListedOnW3swap:'トークンがW3swapにリストされています',
        TokensListedOnW3Wallet:'トークンがW3ウォレットにリストされています',
        TokenListingMappingTool:'トークンリストマッピングツール',
        TokenListedOnLunchpadHomepage:'トークンがランチパッドホームページにリストされています',
        TokenOpening:'トークンがBTOK-PEGOチェーントークンのリストになります/コミュニティレッドパケット',
        TheTokenIsListed:'トークンがAVE-PEGOセクションにリストされています',
        TheProjectSeeks:'プロジェクトは技術、資金、その他のリソースのサポートを求めています',
        TheApplicationEnters:'アプリケーションがPEGOパブリックチェーンに参入します',
        Other:'その他',
        ProjectEconomicModel:'プロジェクトの経済モデル',
        ProjectInformation:'プロジェクト情報',
        ProjectPPTAndAny:'プロジェクトのPPTおよびその他の関連情報をアップロードできます',
        ProjectOfficialWebsite:'プロジェクトの公式ウェブサイト',
        ProjectTwitter:'プロジェクトのTwitter',
        ProjectTelegramCommunity:'プロジェクトのTelegramコミュニティ',
        ProjectDiscordCommunity:'プロジェクトのDiscordコミュニティ',
        ProjectGithub:'プロジェクトのGithub',
        ContactTelegramID:'連絡窓口Telegram ID',
        Submit:'申し出る',
        PleaseEnterAKeyword:'キーワードを入力してください',
        downloadFile:"ダウンロード",
        votingRecord:"投票記録.csv",
        previousPage:"前のページ",
        nextPpage:"次のページ",
        ReturnPGVTWPG:"鋳造できません",
        officialEmail:'公式メール',
        businessCooperation:'事業協力',
        copy:'コピーに成功しました',
    },

}
