/**
 *@title
 *@author liqingjie
 *@date 2023/2/21 10:54
 */
import Web3 from 'web3';
import Decimal from 'decimal.js';
import pgvtAbi from '../../abi/Pgvt.json';
import {pgvtAdder} from "@/utils/address";
import {getStore} from '@/config/storage'

const contractAddress = pgvtAdder;

const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
const web3js = new Web3(BitKeepProvider);
let contract = null;
if (web3js) {
  contract = new web3js.eth.Contract(pgvtAbi.abi, contractAddress);
}
// pgvt 余额 || 代币的余额
export const balanceOf = async (addr) => {
  const v = await contract.methods.balanceOf(addr).call();
  return Web3.utils.fromWei(v, 'ether');
};
// 获取质押金额
export const getStakingAmount = async (tokenAddr, accountAddr) => {
  const v = await contract.methods.stakingAmount(tokenAddr, accountAddr).call();
  return Web3.utils.fromWei(v, 'ether');
};

// 查询当前token 需要归还PGVT的金额
export const stakingPgvtAmount = async (tokenAddr, accountAddr) => {
  const v = await contract.methods.stakingPgvtAmount(tokenAddr, accountAddr).call();
  return Web3.utils.fromWei(v, 'ether');
};
// 质押pg 换取 pgvt
export const stakingPg = async (addr, value) => {
  const gasPrice = await web3js.eth.getGasPrice();
  return contract.methods.stakingPg().send({
    from: addr,
    value: new Decimal(value).mul(Decimal.pow(10, 18)).toFixed(),
    gasPrice,
  });
};
// 质押pg代币 换取 pgvt
export const stakingFarmingToken = async (tokenAddress, value, from) => contract.methods.stakingFarmingToken(tokenAddress, Web3.utils.toWei(value)).send({
  from,
});

// 归还PGVT
export const unStaking = async (tokenAddress = '0x0000000000000000000000000000000000000000', addr) => contract.methods.unStaking(tokenAddress).send({ from: addr });

// 查询归还到期时间
export const getFarmingTokenLockTime = (addr1, addr2) => contract.methods.getFarmingTokenLockTime(addr1, addr2).call();

// 查询token价格
export const getFarmingTokenPrice = async (address) => {
  const v = await contract.methods.getFarmingTokenPrice(address).call();
  return Web3.utils.fromWei(v, 'ether');
};
