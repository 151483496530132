import { createApp } from 'vue'
import App from './App.vue'
import router from  '@/router'
import store from  '@/store'
import "@/utils/rem";
import 'amfe-flexible'
import naive from 'naive-ui'
import i18n from './i18n/index'
import Public from '@/utils/public'
// import {createMetaManager, plugin as vueMetaPlugin} from 'vue-meta';
import MetaInfo from 'vue-meta-info'

import mitt from 'mitt'
const bus = mitt()
const app=createApp(App)
// app.use(createMetaManager(false, {
//     meta: { tag: 'meta', nameless: true }
// }))
// app.use(vueMetaPlugin)
app.use(router)
app.use(store)
app.use(naive)
app.use(i18n)
app.use(MetaInfo)

app.config.globalProperties.$bus = bus
app.config.globalProperties.$Public = Public
// app.config.globalProperties.$message = useMessage()
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'wowjs/css/libs/animate.css'
// // 引入动画库
// import "animate.css"
import animate from 'animate.css'
app.use(animate)
AOS.init();
import Mixin from '@/utils/modes';
app.mixin(Mixin);
// import { init } from './utils/web3';
// init().then((r) => {
//     store.commit('updateWalletStatus', r);
// });
app.mount('#app')

// createApp(App).use(router).use(iView).mount('#app')
