<template>
  <!--PG Strategies in Three Stages  -->
  <div>
    <div class="ti-card pad">
      <h1 data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500" v-html="$t(`message.ThreeStages`,{PGStrategies:$t(`message.PGStrategies`)})">
      </h1>
      <n-grid x-gap="30" y-gap="20" cols="2 l:3" responsive="screen">
        <n-gi>
          <div class="li_tet" data-aos="flip-right"
               data-aos-easing="ease-out-cubic"
               data-aos-duration="500">
            <div class="dis_li">
              <div class="txt" data-aos="fade-left"
                   data-aos-duration="800">{{ $t(`message.BasedOnDao`) }}
              </div>
            </div>
            <div class="vot-txt" data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                 data-aos-duration="800">
              {{ $t(`message.InTheInitial`) }}
            </div>
          </div>
        </n-gi>
        <n-gi>
          <div class="li_tet" data-aos="fade-up"
               data-aos-duration="500">
            <div class="dis_li">
              <div class="txt" data-aos="fade-up"
                   data-aos-duration="800">{{ $t(`message.DAODevelopment`) }}
              </div>
            </div>
            <div class="vot-txt" data-aos="zoom-out" data-aos-duration="800">
              {{ $t(`message.InThePEGO`) }}
            </div>

          </div>
        </n-gi>
        <n-gi>
          <div class="li_tet" data-aos="flip-left"
               data-aos-easing="ease-out-cubic"
               data-aos-duration="500">
            <div class="dis_li">
              <div class="txt" data-aos="fade-right"
                   data-aos-duration="800">{{ $t(`message.ServingDAO`) }}
              </div>
            </div>
            <div class="vot-txt" data-aos="fade-up-right" data-aos-easing="ease-out-cubic"
                 data-aos-duration="800">
              {{ $t(`message.AllBlockchainBased`) }}
            </div>

          </div>
        </n-gi>
      </n-grid>
    </div>
    <div class="roadm">
      <div class="ti-tis">{{ $t(`message.PEGORoadmap`) }}</div>
      <div>
        <n-grid :cols="2" class="conul">
          <n-grid-item class="conli">
            <div class="q-o">
              <div class="t-t"><span data-aos="fade-up-right">2023 Q3</span></div>
              <div class="txt">
                <p data-aos="fade-up-right">{{ $t(`message.ListedOnTheWorld`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.StartApplicationEcologicalConstruction`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.PromoteMEMEEcologicalConstruction`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.PromoteFOMO`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.StarttheGlobal`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.AchieveEcological`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.TheNumberOfValid`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.TheTotalNumber`) }}</p>
                <p data-aos="fade-up-right">{{ $t(`message.TVLExceeded`) }}</p>
              </div>
            </div>
          </n-grid-item>
          <n-grid-item class="conli">
            <div class="q-o">
              <div class="t-t">2023 Q2</div>
              <div class="txt">
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1000">{{ $t(`message.CompleteTheMainnetLaunch`) }}</p>
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1200">{{ $t(`message.CompleteNodeCampaign`) }}</p>
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1400">{{ $t(`message.W3swapLaunched`) }}</p>
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1600">{{ $t(`message.W3WalletIsOnline`) }}</p>
              </div>
            </div>
            <div class="q-o">
              <div class="t-t">2023 Q4</div>
              <div class="txt">
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1200">{{ $t(`message.CompleteTheLaunch`) }}</p>
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1400">{{ $t(`message.AchieveEcologicalOnline`) }}</p>
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1600">{{ $t(`message.TheNumberOfEffective`) }}</p>
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1600">{{ $t(`message.heTotalNumberOfBlock`) }}</p>
                <p data-aos="fade-up-left" data-aos-easing="ease-out-cubic"
                   data-aos-duration="1600">{{ $t(`message.TVLExceededBillion`) }}</p>
              </div>
            </div>
          </n-grid-item>
        </n-grid>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import foot from "@/components/foot";

export default {
  name: "pgStrategies",
  components: {foot},
}
</script>

<style scoped lang="less">
.ti-card {
  text-align: center;
  padding: 60px 100px;
  background: url("@/assets/PEGO/pbblur.svg") no-repeat center top -75px, url("../assets/PEGO/bag3.svg") left 0px top 130px no-repeat, url("../assets/PEGO/bgblurr.svg") right 0px top 179px no-repeat,;
  background-size: 1430px 427px, 239px 624px, 181px 624px;
  height: 100%;

  h1 {
    font-weight: 700;
    font-size: 60px;
    font-family: 'BOLD', sans-serif;
  }

  .li_tet {
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.5) 0%, rgba(33, 37, 45, 0.5) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    padding: 35px;
    text-align: left;
    min-height: 352px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    transition: all .2s;

    .dis_li {
      padding-bottom: 15px;

      .txt {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 41px;
      }
    }

    .numb {
      font-style: normal;
      font-weight: 300;

    }

    .vot-txt {
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.8);
      font-family: 'LIGHT', sans-serif;
    }
  }

  .li_tet:after {
    content: "";
    position: absolute;
    left: -120%;
    top: 0;
    width: 100%;
    height: 100%;
    transform: skew(-30deg);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));
  }

  .li_tet:hover {
    transform: scale(1.1) translateY(-20px);
    box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);

    &:after {
      left: 120%;
      transition: all .3s;
    }

  }

  .grd-rd {
    height: 60px;
    line-height: 60px;
    background: #23262C;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding-left: 80px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
  }

  .timebag {
    text-align: center;
    padding: 30px 0 0;
    box-sizing: border-box;
    background: #1C1F22;

    .grid-item {
      //border: 1px red solid;
      line-height: 50px;
      padding: 10px 0;

      div:nth-child(1) {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #C4C5C7;
      }

      div:nth-child(2) {
        font-size: 20px;
      }
    }
  }

  .time-one {
    padding-top: 15px;
    padding-bottom: 30px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .one-bag {
    border-radius: 0;
    background: rgba(28, 31, 34, 0.5);
    border-bottom: 1px solid #3A3B3D;
  }


  .status {
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    text-align: center;
    padding: 35px 0;

    .sta-li {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
    }

    .sta-ti {
      font-style: normal;
      font-weight: 300;
      font-size: 50px;
    }
  }
}

.pad {
  padding-bottom: 100px;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 122, 0, 0.1) 0%, rgba(255, 184, 0, 0.08) 40%, rgba(56, 57, 58, 0.05) 50%);
  border-image-slice: 1;
}

.roadm {
  padding: 110px 0 0;
  box-sizing: border-box;
  background: url("@/assets/PEGO/Ellipse.png") no-repeat bottom;
  background-size: 100% 519px;

  .ti-tis {
    font-weight: 700;
    font-size: 42px;
    background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-family: 'BOLD', sans-serif;
  }
}


.conul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: #ffffff;
  position: relative;
  box-sizing: border-box;
  padding: 100px 0 60px 0;

}

.conli {
  width: calc(100% - 20px);
  position: relative;
}

.q-o {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: left;
  font-family: 'REGULAR', sans-serif;
  //border: 1px red solid;

  .t-t {
    position: relative;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    text-align: left;
    color: #FFA800;
    height: 54px;
    width: 100%;
    font-family: SEMIBOLD, sans-serif;
    box-sizing: border-box;
  }

  .txt {
    margin-top: 15px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
    font-family: 'REGULAR', sans-serif;

  }
}

.conli:nth-child(1) {
  margin-left: 221px;
  padding-left: 20px;
  padding-top: 160px;
  width: calc(100% - 221px);
  box-sizing: border-box;

  .txt, .t-t {
    padding-right: 180px;
    text-align: right;
  }

  .q-o:nth-child(1) {
    padding-bottom: 160px;

  }

  .q-o:nth-child(2) {
    padding-bottom: 176px;
  }

  .q-o:after {
    content: '';
    position: absolute;
    border-right: 1px solid white;
    height: 100%;
    right: 0;
    z-index: 2;
    top: 27.5px;
  }

  .q-o:last-of-type:after {
    border: none !important;
  }
  .t-t:before {
    content: '•';
    font-size: 25px;
    color: #ffffff;
    position: absolute;
    height: 35px;
    line-height: 35px;
    width: 35px;
    text-align: center;
    border-radius: 100%;
    border: 1px solid #fff;
    z-index: 3;
    right: calc(-33px / 2);
    top: calc(54px / 2 - 37px / 2);
    box-sizing: border-box;

  }

  .t-t:after {
    content: '';
    position: absolute;
    border-top: 1px solid white;
    height: 2px;
    width: 115px;
    right: calc(35px / 2);
    z-index: 2;
    top: calc(100% / 2);
    box-sizing: border-box;
  }
}

.conli:nth-child(2) {
  //padding-top: 130px;
  width: calc(100% - 80px);

  .txt, .t-t {
    padding-left: 180px;
  }

  .q-o:nth-child(1) {
    padding-bottom: 220px;
  }

  .q-o:nth-child(2) {
    padding-bottom: 150px;
  }
  .q-o:nth-child(1):after {
    content: '';
    position: absolute;
    border-left: 1px solid white;
    height: 100%;
    left: -1px;
    z-index: 2;
    top: 27.5px;
  }
  .t-t:before {
    content: '•';
    font-size: 25px;
    color: #ffffff;
    position: absolute;
    height: 35px;
    line-height: 35px;
    width: 35px;
    text-align: center;
    border-radius: 100%;
    border: 1px solid #fff;
    z-index: 3;
    left: calc(-38px / 2);
    top: calc(54px / 2 - 35px / 2);
    box-sizing: border-box;
  }
  .t-t:after {
    content: '';
    position: absolute;
    border-top: 1px solid white;
    height: 2px;
    width: 115px;
    left: calc(33px / 2);
    z-index: 2;
    top: calc(100% / 2);
    box-sizing: border-box;
  }
  .q-o:nth-child(1) {
    .t-t:before {
      background: #FF9D00;
    }
  }
}

.q-o:hover {
  .t-t:before {
    background: #FF9D00;
    border: none;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px #FFB800;
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
  }

  .t-t:after {
    box-shadow: 0 0 80px 1px white;
  }

}
</style>
