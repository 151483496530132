export default {
    message: {
        Product: 'หน้าหลัก',
        Network: 'การบริหาร',
        Build: 'ระบบนิเวศ',
        PEGOScan: 'การเลือกตั้งโหนด',
        bridge: 'สะพาน',
        Proposal: 'DAO',
        browser: 'เบราว์เซอร์',
        BecomeAValidator: 'เป็นผู้ตรวจสอบ',
        wallet: 'กระเป๋าเงิน',
        news: 'ข่าว',
        routeMap: 'แผนที่เส้นทางการพัฒนา',
        foundation: 'มูลนิธิ',
        EN: 'English',
        ZN: '繁体中文',
        JP: '日本語',
        KO: '한국인',
        TH: 'แบบไทย',
        VI: 'Tiếng Việt',
        Whitepaper: 'กระดาษสีขาว',
        Blog: 'บล็อก',
        Foundation: 'มูลนิธิ',
        blockchain: "พัฒนาสำหรับทุกคนใน<span class='nameColor'>{name}</span>",
        name: 'บล็อกเชน',
        Support: 'แอปพลิเคชันแบบกระจายศูนย์ที่สนับสนุนความเป็นอิสระ ความสามารถในการปรับขนาด และความยั่งยืนในระดับสูง',
        scalability: 'ความยืดหยุ่นในการขยาย',
        solution: 'สำหรับการพัฒนา Web3 และ AI ที่มีประสิทธิภาพสูง',
        EnterTheDAPP: 'กระดาษสีขาว',
        ReadDocs: 'อ่านเอกสาร',
        GITHUB: 'GitHub',
        MainnetLaunchCountdown: 'นับถอยหลังการเปิดตัวเครือข่ายหลัก',
        Days: 'วัน',
        Hours: 'ชั่วโมง',
        Minutes: 'นาที',
        PEGOSolutions: 'แนวทางการแก้ปัญหาของ PEGO',
        PPOS: 'PPOS',
        EnablesSustainable: 'ให้ระบบนิเวศที่ยั่งยืนผ่านรูปแบบการบริหารที่เป็นธรรมและปลอดภัย',
        EVMCompatible: 'รองรับ EVM',
        CompatibleWith: 'รองรับ Golang, Rust, Solidity และ EVM เพื่อให้นักพัฒนาสามารถสร้าง dApp ที่ทำงานได้อย่างต่อเนื่องบนแพลตฟอร์มต่างๆ',
        NFTPNNIdentityVerificationSystem: 'ระบบการตรวจสอบตัวตน NFT-PNN',
        EnsuresNetwork: 'ทำให้เครือข่ายมีความได้เปรียบและปลอดภัยผ่านการมัดรวมทรัพย์สิน PNN และการลงคะแนนเพื่อโหนด PGVT',
        CommunityProposedProposals: 'ข้อเสนอของชุมชน',
        ProvidesFlexibility: 'ให้ความยืดหยุ่นในการปรับเปลี่ยนพารามิเตอร์ของเครือข่ายผ่านการเสนอข้อเสนอของชุมชน',
        AIIntegration: 'การผสมผสานกับปัญญาประดิษฐ์',
        IntegratesWith: 'ผสานการใช้ปัญญาประดิษฐ์เพื่อเพิ่มความเร็วและประสิทธิภาพในการประมวลผลที่จำเป็นสำหรับการใช้งาน AI ในสภาพแวดล้อมที่โปร่งใสและปลอดภัย',
        DAOGovernment: 'การบริหารตามหลัก DAO',
        partner: 'Partner',
        PEGOIsGoverned: 'PEGO ได้รับการบริหารจาก DAO ทั่วโลกผ่านสินทรัพย์ดิจิทัลแบบเดิมของตน PG',
        BuyPG: 'ซื้อ PG',
        PGAllowsWeb3: 'PG ช่วยให้สมาชิกชุมชน Web3 สามารถเข้าร่วมข้อเสนออัพเกรดโปรโตคอล ตรวจสอบเครือข่าย PEGO รับรางวัลการเครือข่าย PGVT การจำหน่ายเผาไหม้และอื่นๆ',
        SupportByPEGOFoundation: 'สนับสนุนโดยมูลนิธิ PEGO',
        ContactUs: 'ติดต่อเรา',
        Government: 'การบริหาร',
        Validator: 'ผู้ตรวจสอบ',
        Wallet: 'กระเป๋าเงิน',
        Ecosystem: 'ระบบนิเวศ',
        W3bridge: 'สะพาน W3',
        Exchange: 'แลกเปลี่ยน',
        Loan: 'สินเชื่อ',
        W3BANK: 'W3BANK',
        PEGOTechnology: '© 2023 PEGO Technology',
        ADAOCommunityBasedPublicChain: 'พื้นฐานสำหรับเครือข่าย Web3 ที่เป็นเสรี องค์การเอกชน สามารถขยายตัวและยั่งยืนได้',
        Built: 'สร้างร่วมกับ<span class="nameColor">{network}</span>?',
        network: 'เครือข่าย PEGO',
        PEGOHasDesigned: 'PEGO ออกแบบพื้นฐาน Web3 ที่เป็นระบบนิเวศที่ดูแลโดยชุมชนอย่างสมบูรณ์ที่สุด ที่สามารถปรับเปลี่ยนพารามิเตอร์ใดๆ บนเครือข่ายได้อย่างยืดหยุ่นผ่านข้อเสนอของชุมชน ด้วยการใช้กลไก PPOS ได้สร้างกลไกกระตุ้นเศรษฐกิจที่ผสมผสานอย่างลงตัวเพื่อสนับสนุนการพัฒนารักษาอย่างยั่งยืนของระบบนิเวศ PEGO นอกจากนี้ PEGO ยังเป็นบล็อกเชนที่เข้ากันได้สมบูรณ์กับระบบ EVM ซึ่งเป็นทางเลือกที่เหมาะสำหรับนักพัฒนาในการสร้างแอปพลิเคชันดีแอนทรัลไซต์ที่ขยายตัวได้อย่างยาวนาน',
        SubmitYourProject: 'ส่งโครงการของคุณ',
        SubmitYourProjectLink: "",
        ApplyForInvestment: 'สมัครขอลงทุน',
        useCases: 'การใช้งาน',
        LoremIpsum: 'โอกาสในการใช้งาน Web3 หลากหลายเป็นความมุ่งหมายของ PEGO Network ซึ่งอาจซ่อนแอปพลิเคชันระดับต้นทุนในอนาคต',
        CrossChain: 'Cross-Chain',
        CrossChainBrigde: 'สะพาน Cross-Chain',
        JoinTheCommunity: 'เข้าร่วม<span class="nameColor">{Community}</span>',
        Community: 'ชุมชน',
        PEGODiscordSupport: 'การสนับสนุน PEGO Discord',
        ToBeA: 'เป็น<span class="nameColor">{TOValidator}</span>',
        TOValidator: 'ผู้ตรวจสอบ',
        PEGOIsCommitted: 'PEGO มุ่งมั่นที่จะสร้างเครือข่ายที่เป็นระบบนิเวศที่เต็มที่จากชุมชนและการออกแบบโหนดผู้ตรวจสอบเป็นสิ่งที่สำคัญในการบรรลุเป้าหมายนี้ ผ่านกลไก PPOS  PEGO มีความมุ่งมั่นที่จะให้ผู้เข้าร่วมทุกคนในเครือข่ายที่ไม่แทรกแซงทำงานร่วมกันได้อย่างมีประสิทธิภาพ',
        CONNECT: 'เชื่อมต่อ',
        applyToBe: 'สมัครเป็นผู้ตรวจสอบ',
        PEGOValidator: 'ผู้ตรวจสอบ PEGO มีเอกลักษณ์ด้วยการเป็นเจ้าของ "บัตรประจำตัว" NFT-PNN ที่มีสิทธิ์เฉพาะ ผู้สมัครจะได้รับการคัดเลือกให้ทำสำเนา PNN และเข้าร่วมการเลือกตั้งโหนดผู้ตรวจสอบ PEGO เพื่อเป็นโหนดตรวจสอบของเครือข่าย PEGO',
        NFTPNNIDENTITYCARD: 'บัตรประจำตัว NFT-PNN',
        MyAccount: 'บัญชีของฉัน',
        VotingReward: 'รางวัลการลงคะแนนเสียง',
        TokenBalance: 'ยอดเงินคงเหลือของโทเค็น',
        VotingPower: 'สิทธิ์ในการลงคะแนนเสียง',
        VotedValidators: 'ผู้ตรวจสอบที่ได้รับการลงคะแนนเสียง',
        Rank: 'อันดับ',
        Address: 'ที่อยู่',
        Status: 'สถานะ',
        MyShare: 'ส่วนแบ่งของฉัน',
        Operate: 'ดำเนินการ',
        ValidatorStatus: 'สถานะผู้ตรวจสอบ',
        TotalVotes: 'จำนวนคะแนนทั้งหมด',
        NextRound: 'รอบถัดไป',
        ValidatorRanking: 'อันดับผู้ตรวจสอบ',
        search: 'ค้นหา',
        Ranking: 'อันดับ',
        LatestBlock: 'บล็อกล่าสุด',
        ValidatedBlocks: 'บล็อกที่ตรวจสอบแล้ว',
        Votes: 'คะแนน',
        Action: 'การดำเนินการ',
        Vote: 'ลงคะแนนเสียง',
        PEGO: `PEGO  <span class="nameColor">{dao}</span>`,
        dao: 'DAO',
        PEGOEmphasizes: "PEGO เน้นความเป็นประสิทธิภาพและอนุญาตให้ปรับเปลี่ยนพารามิเตอร์ทั้งหมดบนเครือข่ายโดยใช้ข้อเสนอที่เกิดขึ้นในชุมชน โหนดหรือผู้ใช้ทั่วไปสามารถเริ่มต้นข้อเสนอโดยจำนวน PG ที่ต้องมีในการพิจารณาข้อเสนอ หากผ่านการลงคะแนนภายในเวลาที่กำหนดข้อเสนอจะถูกดำเนินการผ่านธุรกรรมบนเครือข่ายและมีผลใช้งาน",
        VotingTaken: 'กำลังดำเนินการลงคะแนน',
        Sponsor: "ผู้สนับสนุน",
        Active: "กำลังดำเนินการ",
        Executed: "ดำเนินการแล้ว",
        Voted: "ลงคะแนนแล้ว",
        SUBMITPROPOSAL: "ส่งข้อเสนอ",
        OnChainParameters: "พารามิเตอร์บนเครือข่าย",
        PendingTime: "เวลารอ",
        VotingTime: "เวลาลงคะแนน",
        ExecutionTime: "เวลาดำเนินการ",
        TotalVotesTotalVt: "จำนวนโหวตทั้งหมด / จำนวน VT ทั้งหมด",
        SupportedVotesTotalVotes: "จำนวนโหวตที่สนับสนุน / จำนวนโหวตทั้งหมด",
        StakePG: "การจำนอง PG",
        Proposals: "ข้อเสนอ",
        SEEALL: "ดูทั้งหมด",
        ThreeStages: `<span class='nameColor'>{PGStrategies}</span> กลยุทธ์สามขั้นตอน`,
        PGStrategies: "ยุทธภัณฑ์ PG",
        BasedOnDao: "อิงตาม DAO",
        InTheInitial: "ในขั้นตอนการพัฒนาเริ่มต้นของ PEGO การเตรียมทุกอย่างจะพึ่งอยู่กับแนวคิด DAO ในระบบนิเวศ เพื่อให้เกิดการสร้างและใช้งานร่วมกันทั้งประชาชนและการแบ่งปันทั่วทุกฟังก์ชัน นอกจากนี้ระบบนิเวศยังมีการบริหารจัดการโดยผู้ใช้ทุกคนตามกฎระเบียบขององค์กรปกครองตนเองที่เป็นประชาธิปไตยและเป็นธรรม",
        DAODevelopment: "การพัฒนา DAO",
        InThePEGO: "ในเครือข่าย PEGO จะมี DAO ชนิดต่าง ๆ เพิ่มขึ้นเมื่อผู้ร่วมทางด้านนิเวศมีมากขึ้น ซึ่งอาจช่วยเร่งความเร็วของการใช้งานบล็อกเชน",
        ServingDAO: "การให้บริการ DAO",
        AllBlockchainBased: "DAO ที่มีตัวเลือกบล็อกเชนทั้งหมดเป็นของสมาชิกชุมชน ตามยุทธภัณฑ์สามขั้นตอน PEGO เครือข่ายจะกลายเป็นเครือข่ายสาธารณะแรกที่ใช้ DAO เป็นเอกภาพ",
        PEGORoadmap: "แผนที่ถนนของ PEGO",
        latestUpdates: "และข้อมูลอัปเดตล่าสุด",
        TheProposal: "การอัปเดตระบบเครือข่าย PEGO เครื่องหมายอนุญาตโมเดล",
        publicChain: "ข้อมูลเครือข่ายสาธารณะ: เครือข่าย PEGO สัญลักษณ์: PG เป็นเครือข่ายการบริหารของ PEGO และเครือข่ายนี้ยังเผยแพร่บนเครือข่าย BSC เพื่อการโต้ตอบระหว่างเครือข่าย...",
        TheW3BIWO: "W3B IWO ใกล้จะเปิด",
        theFuture: "อนาคตของ W3Bridge จะเป็นสะพานสำหรับโลก Web3 ที่แตกต่างกัน สิ่งนี้จะสามารถเกิดขึ้นบนพื้นฐานของนิเวศระบบเริ่มต้นของ PEGO และเมื่อนิเวศขยายตัว...",
        PEGONetwork: "เครือข่าย PEGO เปิดตัวโทเคน PN สำหรับการบริหารจัดการ",
        toIncentivese: "เพื่อส่งเสริมผู้สนับสนุน PEGO เครือข่ายทั่วโลก เครือข่าย PEGO จะเปิดตัว PN เป็นโทเคนสำหรับการบริหารจัดการของชุมชน โทเคนนี้จะใช้ในการส่งเสริม...",
        ThePEGOFoundation: `PEGO <span class='nameColor'>{Foundations}</span>`,
        Foundations: 'มูลนิธิ',
        pegoEmphasizes: "มูลนิธิ PEGO เป็นองค์กรที่ถูกสร้างขึ้นเพื่อสร้าง สนับสนุน และพัฒนาเครือข่าย PEGO และชุมชนของมัน องค์กรนี้ประกอบด้วยผู้เชี่ยวชาญในวงการและอาสาสมัครรากฐานจากทุกส่วนของโลก",
        StakePools: "สระว่ายน้ำเดิมพัน",
        stakePools: "สระว่ายน้ำเดิมพัน เป็นโซลูชันการเดิมพันที่รองรับการกระจายอำนาจและการเติบโตของ PEGOs ในรูปแบบ DeFi ที่ไม่ผ่านการพิสูจน์",
        STAKEPG: "การจำนอง PG",
        Grants: "ทุนการสนับสนุน",
        thePegp: "โครงการทุน PEGP ให้ทุนในการเพิ่มประสิทธิภาพของเครือข่าย PEGO",
        APPLYFORGRANTS: "สมัครรับทุน",
        HundredFamily: "",
        HundredFamilyDesc: "",
        APPLY100Family: "",
        APPLY100FamilyLink: "",
        JOINPEGOFOUNDATION: "เข้าร่วมมูลนิธิ PEGO",
        Ended: "เสร็จสิ้น",
        linkWallet: "เชื่อมต่อกระเป๋าเงิน",
        BNBSmartChain: "BNB สมาร์ทเชน",
        TelegramCommunity: "ชุมชนเทเลแกรม",
        EnglishCommunity: "ชุมชนภาษาอังกฤษ",
        validator: "ผู้ตรวจสอบ",
        quitValidator: "เลิกสมัครผู้ตรวจสอบ",
        claim: "เคลม",
        GetPGVT: "รับ PGVT",
        voteValidator: "โหวตให้โหนด",
        ReturnPGVT: 'คืน PG/W3FT',
        ReturnPGVT2: 'ไม่สามารถคืนในช่วงระยะเวลาที่เย็น',
        ReturnPGVT3: 'ยอดเงินไม่เพียงพอ',
        IncreaseVote: 'เพิ่มโหวต',
        Refund: 'คืนเงิน',
        quitTheValidator: 'เลิกสมัครตัวแทน',
        onceQuited: 'หากคุณยกเลิกคุณจะไม่ได้รับรางวัล PG อีกต่อไป!',
        andYour: 'และไม่สามารถได้รับ PNN ของคุณกลับมาได้อีก',
        noIDont: 'ไม่ฉันไม่ต้องการออก',
        SureIWant: 'แน่ใจ ฉันต้องการออก',
        getPGVT: 'รับ PGVT',
        pleaseEnter: 'โปรดใส่',
        MAX: 'สูงสุด',
        Balance: 'ยอดคงเหลือ',
        BalancePGVT: 'ยอดเงิน PGVT',
        returnPGVT: 'คืน PGVT',
        youWantTo: 'คุณต้องการคืน',
        YourBalanceIs: 'ยอดคงเหลือของคุณคือ',
        isYourBalanceIsVT: 'ยอดคงเหลือ VT',
        YourBalanceIsVT: 'ยอดคงเหลือ VT ของคุณไม่เพียงพอ',
        YourBalanceIs1:'จำนวนที่ผ่านการหล่อด้วยแหล่งที่มานี้',
        YouneedReturn: 'คุณต้องคืน',
        CooldownCountdown: 'ระยะเวลาที่เย็น',
        Seconds: 'วินาที',
        VoteAmount: 'จำนวนโหวต',
        ValidatorAddress: 'ที่อยู่ผู้ตรวจสอบ',
        Withdraw: 'ถอนคะแนน',
        Withdraw2: 'ไม่สามารถถอนคะแนนในช่วงระยะเวลาที่เย็น',
        WithdrawAmount: 'จำนวนคะแนนที่ถอน',
        NoticeYou: 'ข้อสังเกต: หลังจากช่วงผ่อนผัน คุณจะได้รับ PGVT ของคุณคืน.',
        ExchangeNFTPNNT: "สร้าง NFT-PNG",
        ValidatorApplication: "ใบสมัครผู้ตรวจสอบ",
        SorryYou: "ขออภัย คุณยังไม่มี PNN-NFT,",
        anyPNNNFT: "โปรดใช้ PNN เพื่อหล่อ",
        // howToGet: "วิธีการรับ NFT-PNN (PEGO-Chain) คืออะไร?",
        // IfYouHave: "หากคุณมีโทเคน PNN-BSC",
        clickHere: "หล่อ",
        // toMint: "สร้าง NFT-PNN (PEGO-Chain) เหรียญ",
        ConnectWallet: "เชื่อมต่อกระเป๋าเงิน",
        YourPNNNFT: "NFT PNN ของคุณ",
        applyToBeAValidator: "สมัครเป็นผู้ตรวจสอบ",
        Transaction: "ธุรกรรม",
        CongratulationsYou: "ยินดีด้วย! คุณเป็นผู้ตรวจสอบแล้ว!",
        JoinOur: 'เข้าร่วมกลุ่ม',
        discord: 'Discord',
        toGetHow: 'เพื่อเรียนรู้วิธีการเป็นโหนดผู้ตรวจสอบ',
        Failed: 'ล้มเหลว',
        Pending: 'รอดำเนินการ',
        MoreAboutDAO: 'เกี่ยวกับ DAO มากขึ้น',
        CreatProposal: "สร้างข้อเสนอ",
        CoolingOffPeriodOfPGVT: "ระยะเวลาเย็นของ PGVT",
        CoolingOffPeriodOfPGW3FT: "ระยะเวลาเย็นของ PG&W3FT",
        ValidatorDistrbution: "การกระจายผู้ตรวจสอบ",
        AdditionalPGIssued: "PG ที่ออกเพิ่มเติม",
        LowestGasFee: "ค่าธรรมเนียมแก๊สต่ำสุด",
        BlockReward: "รางวัลบล็อก",
        ValidatorNumbers: "จำนวนผู้ตรวจสอบ",
        SupportedToken: "โทเคนที่รองรับ",
        Blacklist: "รายชื่อดำ",
        TotalVT: "VT ทั้งหมด",
        Supports: "สนับสนุน",
        Invalid: "ไม่ถูกต้อง",
        Passed: "ผ่าน",
        Core: "แกนนำ",
        ProposalType: "ประเภทข้อเสนอ",
        AdjustThe: "ปรับเปลี่ยนพารามิเตอร์ข้อเสนอ",
        AddOrDelete: "เพิ่มหรือลบโทเคนฟาร์มเพื่อรับ PGVT",
        AdjustTheDistribution: "ปรับเปลี่ยนสัดส่วนการแจกแจงรางวัล",
        BlaDecentralized: "มูลนิธิ BlaDecentralized จะออก PG เพิ่มให้กับที่อยู่ผู้สนับสนุน",
        AdjustTheLowestGasFee: "ปรับเปลี่ยนค่าธรรมเนียมแก๊สต่ำสุด",
        AdjustTheBlockReward: "ปรับเปลี่ยนรางวัลบล็อก",
        AdjustThenumberOfValidators: "ปรับเปลี่ยนจำนวนผู้ตรวจสอบ",
        ChoosePDP: "เลือก PDP",
        ParametersChanges: "การเปลี่ยนแปลงพารามิเตอร์",
        NowIs: "ขณะนี้เป็น",
        ExeuctionTime: "เวลาดำเนินการ",
        TotalVotesvt: "จำนวนโหวตทั้งหมด / VT ทั้งหมด",
        SupportedVotesTotalVotess: "จำนวนโหวตที่รองรับ / จำนวนโหวตทั้งหมด",
        AddDelete: "เพิ่ม / ลบ",
        AddANewFarmingToken: "เพิ่มโทเคนเกษตรใหม่",
        DeleteoneFarmingToken: "ลบโทเคนเกษตรหนึ่ง",
        TokenAddress: "ที่อยู่โทเคน",
        CoolingOffPeriodOf: "ระยะเวลาเย็นชา",
        TheDistributionProportionForValidator: "สัดส่วนการแบ่งเบาะแสระหุ้นส่วนของโหนดและผู้ใช้ ณ ขณะนี้ผู้ใช้เป็น",
        AddAnAddressToBlacklist: "เพิ่มที่อยู่ในรายชื่อดำ",
        DeleteAnAddressOutOfBlacklist: "ลบที่อยู่ออกจากรายชื่อดำ",
        Amount: "จำนวน",
        TheAmountOfPGIssued: "จำนวน PG ที่ออก",
        NumberOfValidators: "จำนวนผู้ตรวจสอบ",
        YouNeedToStake: "คุณต้องเปิดเผย",
        makeSureYou: "PG โปรดตรวจสอบว่าคุณมียอดเงินเพียงพอ",
        PGBalance: "สมดุล PG",
        FailedToCreateProposal: "การสร้างข้อเสนอล้มเหลว",
        Detail: "รายละเอียด",
        StartTime: "เวลาเริ่มต้น",
        VoteTime: "เวลาลงคะแนนเสียง",
        TotalVoteToken: "รวมโทเคนลงคะแนนเสียง",
        Against: "ต่อต้าน",
        GetBackVT: "เรียกคืน VT",
        Execute: "ดำเนินการ",
        Metamask: "Metamask",
        DoNotHaveMetamask: "ไม่มี Metamask ติดตั้งหรือไม่?",
        Install: "ติดตั้ง",
        YoureAlreadyAValidator: "คุณเป็นผู้ตรวจสอบแล้ว!",
        Error: "ข้อผิดพลาด",
        Successful: "สำเร็จ",
        GetBackPG: "เรียกคืน PG",
        About: "เกี่ยวกับ",
        proposal: "ข้อเสนอ",
        pegoScan: "เบราว์เซอร์ PEGO",
        other: "อื่น ๆ",
        comingSoon: "เร็ว ๆ นี้",
        PWGBountyProgram: 'โปรแกรม PWG Bounty',
        Web3GrantProgram: 'โปรแกรมทุนการให้สินเชื่อเทคโนโลยี PEGO Web3 มีเป้าหมายเพื่อให้การสนับสนุนทางการเงินเทคโนโลยีและทรัพยากรในเครือข่าย Web3 สำหรับนักพัฒนาเอกชนที่เกิดใหม่',
        PWGCanApplyForType: 'PWGสามารถที่จะสมัครได้',
        seedIncentive: 'ระบบสิทธิบัตรเมล็ดพันธุ์',
        StartupFunding: 'เงินทุนเริ่มต้นสำหรับความคิด Web3 ที่ดี',
        financialSupport: 'การสนับสนุนทางการเงิน',
        otherSupport: 'การสนับสนุนอื่น ๆ',
        ProjectLanding: 'การปฏิบัติงานปรึกษาเพื่อการลงตัวของโครงการ',
        EcologicalCoCreation: 'ระบบการสร้างชีวิตร่วมกัน',
        ecosystem: 'เป็นส่วนสำคัญในระบบ PEGO Web3 นักพัฒนา',
        CoCreationSupport: 'การสนับสนุนการสร้างชีวิตร่วมกัน',
        ProvideFull: 'การสนับสนุนเทคโนโลยีทั้งหมด การเชื่อมต่อทรัพยากรร่วมกันตามความต้องการในการพัฒนาโดยรวมของระบบ PEGO',
        SpecialType: 'สายตรง 2 & 3',
        ProvideSpecial: 'การสนับสนุนเฉพาะสำหรับผู้เข้าร่วมในการสร้างส่วนต่อประสานกับ PEGO บนเครือข่าย L2 และ L3',
        AccelerateSupport: 'การสนับสนุนในการเร่งความเร็ว',
        TargetedTechnical: 'การสนับสนุนเทคโนโลยีที่เป้าหมายอย่างชัดเจนและช่วยให้สร้างทีมหลัก',
        StrategicInvestment: 'การลงทุนแบบกลยุทธ์',
        MeetTheDevelopment: 'ตอบสนองความต้องการในการพัฒนาตามยุทธศาสตร์ PEGO',
        strategicSupport: 'การสนับสนุนแบบกลยุทธ์',
        ImplementationOfThe: 'สร้างผลิตภัณฑ์ตามวงจรชีวิตทั้งหมด รวมถึงการพัฒนายุทธศาสตร์การดำเนินธุรกิจทางเทคโนโลยีและการให้คำปรึกษาทางธุรกิจทั้งหมด การเชื่อมต่อทรัพยากรระดับยอดธุรกิจและการแนะนำทางการเงินที่ตามมา',
        WhatIs100FamilyTitle: '',
        WhatIs100Family: '',
        Why100FamilyTitle: '',
        Why100Family: '',
        Type100FamilyTitle: '',
        Type100Family: '',
        Contact100Family: '',
        Contact100FamilyName: '',
        Contact100FamilyLink: '',
        VoterDistribution: 'อัตราส่วนผู้ใช้งาน',
        homeHeader: 'PEGO เกิดมาเพื่อสร้างสรรค์',
        infrastructure: 'พื้นฐาน Web3 ของ PEGO ระบบ PPOS และเครื่องจำลองเสมือน การปกครองด้วย DAO และเครื่องมือบล็อกเชน พื้นฐานของบล็อกเชน PEGO ที่เปิดกว้างสำหรับทุกคนในยุค Web3',
        CompleteTheMainnetLaunch: 'เสร็จสิ้นการเปิดตัวเครือข่ายหลัก',
        CompleteNodeCampaign: 'เสร็จสิ้นแคมเปญโหนด',
        W3swapLaunched: 'เปิดให้บริการ W3swap',
        W3WalletIsOnline: 'W3Wallet ออนไลน์',
        ListedOnTheWorld: 'เรียกเก็บในตลาดสำคัญทั่วโลก',
        StartApplicationEcologicalConstruction: 'เริ่มต้นการสร้างนิเวศในการใช้งาน',
        PromoteMEMEEcologicalConstruction: "ส่งเสริมการสร้างนิเวศ MEME",
        PromoteFOMO: "ส่งเสริมการสร้างนิเวศ FOMO",
        StarttheGlobal: "เริ่มต้นโครงการโลก",
        AchieveEcological: "บรรลุจำนวน 200 รายการนิเวศ",
        TheNumberOfValid: "จำนวนกระเป๋าสตางค์ที่ถูกต้องมากกว่า 100 หมื่น",
        TheTotalNumber: "จำนวนการทำธุรกรรมบล็อกทั้งหมดเกิน 500 หมื่น",
        TVLExceeded: "TVL เกิน 100 ล้านดอลลาร์",
        CompleteTheLaunch: 'เสร็จสิ้นการเปิดตัวบนตลาดสำคัญ',
        AchieveEcologicalOnline: 'บรรลุจำนวน 500 รายการนิเวศออนไลน์',
        TheNumberOfEffective: 'จำนวนกระเป๋าสตางค์ที่ถูกต้องมากกว่า 200 หมื่น',
        heTotalNumberOfBlock: 'จำนวนการทำธุรกรรมบล็อกทั้งหมดเกิน 20 ล้าน',
        TVLExceededBillion: 'TVL เกิน 10 พันล้านดอลลาร์',
        Alreadyin: 'มีอยู่แล้ว',
        NotIn: 'ไม่มี',
        FillInAAddressAlreadyIn: 'กรุณากรอกที่อยู่ที่มีอยู่แล้ว',
        FillInTheTokenContractAddress: "กรอกที่อยู่สัญญาโทเค็น",
        AlreadySupported: "ได้รับการสนับสนุนแล้ว",
        DecentralizedFoundationWillIssue: "มูลนิธิที่กระจายอำนวยความสะดวกจะเพิ่มเงิน PG ไปยังที่อยู่ของผู้สร้าง",
        GasFee: "ค่าธรรมเนียมแก๊ส",
        Add: 'เพิ่ม',
        Delete: 'ลบ',
        W3swap:'W3swap',
        AMegaPlatform:'แพลตฟอร์มขนาดใหญ่ที่รวมรวมการแก้ปัญหาการซื้อขายที่ดีที่สุด',
        W3Wallet:'W3Wallet',
        MultiFunction:'กระเป๋าเงิน Web3 ที่สามารถทำงานในหลายๆ โซนเครือข่าย PEGO',
        W3Bridge:"W3Bridge",
        RebuildTheFragmented:"สร้างสรรค์เครือข่าย Web3 ที่แตกแยก",
        EnterAsAnInteger:"ป้อนเป็นจำนวนเต็ม",
        NetworkError:"ข้อผิดพลาดของเครือข่าย",
        nodeReward:"รางวัลโหนด",
        award:"รางวัล",
        toBeCollected:"รอการเก็บ",
        received:"ได้รับแล้ว",
        NumberOfBlocks:'จำนวนบล็อก',
        EcologicalDAPP:'DAPP ในระบบนิเวศ',
        receiveAward:'รับรางวัล',
        pleaseGoToWallet:'โปรดไปที่กระเป๋าเงิน',
        AuditReport:'รายงานการตรวจสอบ',
        ProposalDescription:'คำอธิบายข้อเสนอ',
        nodeCard:'การ์ดโหนด',
        PDP1:'ข้อเสนอ PDP-1',
        DetermineOr:'วัตถุประสงค์: ใช้ในการกำหนดหรือปรับเปลี่ยนพารามิเตอร์สำคัญของข้อเสนอการบริหารจัดการ DAO',
        TheAdjustableParameters:'พารามิเตอร์ที่สามารถปรับได้มีดังนี้: ระยะเวลาการประกาศข้อเสนอ/ระยะเวลาการลงคะแนนเสียง/ระยะเวลาการดำเนินการ/จำนวน PG ที่จำเป็นสำหรับข้อเสนอ/เงื่อนไขหมวก1/เงื่อนไขหมวก2',
        PDP2:'ข้อเสนอ PDP-2',
        decideWhich:'ตัดสินใจว่าประเภทโทเค็น LP ใดที่สร้างโฟลเดอร์ที่สามารถใช้เพื่อสแต็กและแลกเปลี่ยน PGVT (หากจำนวน PG ใน LP ต่ำกว่า 500,000 หลังจากการอนุมัติข้อเสนอก็จะไม่สามารถสร้าง PGVT ใหม่ได้)',
        PDP3:'ข้อเสนอ PDP-3',
        adjustTheCooling:'ใช้ในการกำหนดและปรับเปลี่ยนระยะเวลาการหน่วงสร้างและระยะเวลาการหน่วงการแลกเปลี่ยน PGVT ผ่านการลงคะแนนเสียง VT',
        PDP4:'ข้อเสนอ PDP-4',
        usedToDetermine:'ใช้ในการกำหนดและปรับเปลี่ยนอัตราส่วนการแบ่งเงินตอบแทนให้กับโหนดและผู้ลงคะแนนเสียง',
        PDP5:'ข้อเสนอ PDP-5',
        controlMalicious:'กำหนดว่าจะนำมาตรการป้องกันหรือระบบควบคุมเพื่อบังคับใช้สำหรับสัญญาและบัญชีที่เป็นอันตราย เพื่อควบคุมและกำจัดผลกระทบที่ไม่ดีที่เกิดจากพฤติกรรมที่ไม่ดี',
        PDP6:'ข้อเสนอ PDP-6',
        controlMaliciousContracts:'ใช้สำหรับการเพิ่ม PG ใหม่ ผู้ใช้ทุกคนสามารถสร้างข้อเสนอได้',
        PDP7:'ข้อเสนอ PDP-7',
        usedToAdjust:'ใช้ในการปรับค่าค่าธรรมเนียมแก๊สขั้นต่ำบนเชื่อมโยงเพื่อตอบสนองความต้องการในระยะเวลาต่างๆ',
        PDP8:'ข้อเสนอ PDP-8',
        adjustTheBlock:'ปรับเลขรางวัลบล็อก',
        PDP9:'PDP-9 ประเภทการเสนอ',
        adjustTheNumber:'ปรับจำนวนโหนดการตรวจสอบที่ออนไลน์',
        BitKeep:'BitKeep',
        TOKENPOCKET:'TOKEN POCKET',
        OKXWallet:'OKX Wallet',

        //6-13
        votingAddress:'ที่อยู่ในการลงคะแนนเสียง',
        numberOfVotes:'จำนวนโหวต',
        votingTime:'เวลาลงคะแนนเสียง',
        votingList:'รายการลงคะแนนเสียง',
        ExploreThePEGOEcosystem:'สำรวจนิเวศ PEGO',
        submitProject:'ส่งโครงการ',
        allItems:'รายการทั้งหมด',
        all:'ทั้งหมด',
        startSearching:'เริ่มการค้นหา',
        audit:'ตรวจสอบ',
        CreatingAndOwning:'การสร้างและเป็นเจ้าของกระเป๋าเป็นขั้นตอนแรกในการเชื่อมต่อกับ PEGO และเข้าสู่ Web3 โปรดรักษาคีย์ส่วนตัวและชุดคำจำกัดความปลอดภัย',
        OpeningMetamask:"กำลังเปิด Metamask",
        PleaseConfirmInMetamask:"โปรดยืนยันใน Metamask",
        OpeningBitkeep:"กำลังเปิด Bitkeep",
        OpeningOKXWallet:"กำลังเปิด OKX",
        NotInstalled:"ยังไม่ได้ติดตั้ง",
        EcologicalCooperationApplicationForm:"แบบฟอร์มใบสมัครความร่วมมือทางนิเวศ",
        WelcomeToJoin:"ยินดีต้อนรับสู่การเข้าร่วม หลังจากที่คุณส่งข้อมูลเราจะติดต่อคุณโดยเร็วที่สุด ก่อนนั้นโปรดกรอกข้อมูลด้านล่างให้ครบถ้วน",
        PEGOEcologicalBusiness:"ID ผู้ติดต่อทางธุรกิจนิเวศ PEGO",
        Telegram:"เทเลแกรม",
        Btok:"Btok",
        projectName:"ชื่อโครงการ",
        projectLogo:"โลโก้โครงการ",
        ImageFormatsLike:"รองรับรูปแบบไฟล์รูปภาพเช่น .jpg, .png, .gif, .bmp, .psd, .tiff",
        ChooseImage:'เลือกรูปภาพ',
        Lessthan:'น้อยกว่า 500MB',
        ProjectDescription:'คำอธิบายโครงการ',
        itemCategory:'หมวดหมู่หรือตำแหน่งของโครงการ',
        anyOtherType:'DEX, NFT หรือประเภทอื่น ๆ',
        ProjectBenefits:'ประโยชน์ของโครงการ',
        WhatCanItBringToThe:'โครงการสามารถนำเข้าสู่ระบบ PEGO ได้อย่างไร?',
        ProjectInfluence:'ผลกระทบของโครงการ',
        WhatKindOfCooperation:'คุณต้องการร่วมมือกับนิเวศ PEGO ในด้านใด?',
        TokenListedOn:'เครื่องหมายที่ระบุอยู่ใน W3swap หรือไม่?',
        TokenListedOnW3swap:'เครื่องหมายที่ระบุอยู่ใน W3swap',
        TokensListedOnW3Wallet:'เครื่องหมายที่ระบุอยู่ใน W3Wallet',
        TokenListingMappingTool:'เครื่องมือการจับคู่เครื่องหมายที่ระบุ',
        TokenListedOnLunchpadHomepage:'เครื่องหมายที่ระบุอยู่ในโฮมเพจ Lunchpad',
        TokenOpening:'เปิดเครื่องหมายที่ระบุของ BTOK-PEGO chain/token หรือแพ็คเก็ตสีแดงของชุมชน',
        TheTokenIsListed:'เครื่องหมายที่ระบุอยู่ในส่วน AVE-PEGO',
        TheProjectSeeks:'โครงการมองหาการสนับสนุนทางเทคนิค การเงิน และทรัพยากรอื่น ๆ',
        TheApplicationEnters:'แอปพลิเคชันเข้าร่วม PEGO เน็ตเวิร์ก',
        Other:'อื่น ๆ',
        ProjectEconomicModel:'โมเดลเศรษฐกิจของโครงการ',
        ProjectInformation:'ข้อมูลโครงการ',
        ProjectPPTAndAny:'สามารถอัปโหลดเอกสารโครงการ PPT และข้อมูลที่เกี่ยวข้องอื่น ๆ',
        ProjectOfficialWebsite:'เว็บไซต์อย่างเป็นทางการของโครงการ',
        ProjectTwitter:'ทวิตเตอร์ของโครงการ',
        ProjectTelegramCommunity:'ชุมชนโครงการทางเทเลแกรม',
        ProjectDiscordCommunity:'ชุมชนโครงการทางดิสคอร์ด',
        ProjectGithub:'Github ของโครงการ',
        ContactTelegramID:'ID เทเลแกรมติดต่อ',
        Submit:'ส่ง',
        PleaseEnterAKeyword:'โปรดป้อนคำสำคัญ',
        downloadFile:"ดาวน์โหลด",
        votingRecord:"บันทึกการลงคะแนนเสียง.csv",
        previousPage:"หน้าก่อนหน้า",
        nextPpage:"หน้าถัดไป",
        ReturnPGVTWPG:"ไม่สามารถสร้างได้",
        officialEmail:'อีเมลอย่างเป็นทางการ',
        businessCooperation:'ความร่วมมือทางธุรกิจ',
        copy:'คัดลอกสำเร็จ',
    },

}
