import {
  // connect,
  // init
} from "@/utils/web3";
import api from '@/api/api';
import erc20 from "@/utils/contract/erc20";
import {tokenAddressAdder} from "@/utils/address";
import {getStore} from '@/config/storage'
// import {getStore} from "@/config/storage";

export default {
  data() {
    return {
      walletInfo: {},
      themeOverrides: {
        "Dialog": {
          "textColor": "rgba(255, 255, 255, 1)",
          "color": "rgba(56, 63, 70, 0.7)",
          "closeColorPressed": "rgba(196, 15, 15, 0.13)",
          "closeColorHover": "rgba(35, 2, 2, 0)",
          "closeIconColor": "rgba(255, 255, 255, 1)",
          "closeIconColorHover": "rgba(230, 146, 30, 1)",
          "iconColorInfo": "#7398C4FF",
          "borderRadius": "15px",
        },
        "Dropdown": {
          "color": "#211B1BFF",
          "textColor": "#F9F6F6FF",
          "optionTextColor": "#FFF",
          "optionTextColorHover": "rgba(111, 120, 118, 1)",
          "optionTextColorActive": "#E2B163FF",
          "optionColorHoverInverted": "rgba(111, 120, 118, 1)",
          "optionColorActiveInverted": "",
          "optionTextColorActiveInverted": "#FFB526",
          "optionTextColorHoverInverted": "#FFF",
          "optionIconSuffixWidthHuge": "100px",
        },
        "Input": {
          "color": "#222427",
          "placeholderColor": "rgba(237, 228, 228, 1)",
          "caretColor": "#F1ECE8FF",
          "textColor": "rgba(241, 245, 249, 1)",
          "colorFocus": "#222427",
          "border": "1.5px solid rgba(255, 255, 255, 0.3)",
          "borderHover": "1px solid #fff",
          "borderFocus": "1px solid #fff",
          "borderRadius": "4px",
        },
        "Button": {
          "textColorHover": "#ffffff",
          "border": "1px rgba(255, 255, 255, 0.1) solid",
          "borderFocus": "1px #ffffff solid",
          "borderHover": "1px #ffffff solid",
          "borderPressed": "1px #ffffff solid",
          "rippleColor": "#FFB526",
          "borderRadius": "15px",
        }
      },
      actionStatus: 0, // 1 pending 2 success 3 error
      exchangeShow: false,//exchange
    }
  },
  async mounted() {
    // getStore('isWalletStatus')?this.walletInfo = await init():''
  },
  computed: {
    walletStatus() {
      console.log("walletStatus", this.$store.state.walletStatus)
      return Number(this.$store.state.walletStatus)
    },
    walletAddress() {
      return this.$store.state.walletAddress
    },
  },
  methods: {
    // 申请成为节点
    async apply() {
      //未安装
      if (this.walletStatus === -2) {
        // this.$store.commit('isShoModal', true)
        // this.$store.commit('showManyModal', true)
        const wallName = getStore('wallName')
        if (wallName === 'Metamask') {
          return window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en');
        } else if (wallName === 'BitKeep') {
          return window.open('https://bitkeep.com/en/download?type=2');
        } else if (wallName === 'TokenPocket') {
          return window.open('https://www.tokenpocket.pro/zh/download/app');
        } else if (wallName === 'OKXWallet') {
          return window.open('https://www.okx.com/web3');
        }
        return;
      }
      //安装未连接
      if (this.walletStatus === -1) {
        // this.$store.commit('isShoModal', true)
        this.$store.commit('showManyModal', true)
        // connect().then(window.location.reload);
        return;
      }
      // 查询是否有资格申请
      const flag = await this.isHasNft();
      if (flag) {
        //有资格申请节点
        if (this.validatorStatus < 4) {
          this.$store.commit('isAlreadyModal', true)
        } else {
          this.applyModal = true
        }
      } else {
        //没有PNN-NFT
        this.valiModal = true
        const tokenAddress = tokenAddressAdder;
        const {balanceOf} = erc20(tokenAddress);
        this.exchange.balance = await balanceOf(this.walletAddress);
        // this.$store.commit('isValiModal', true)
      }
    },
    //查询节点
    async isHasNft() {
      const r = await api.nft({address: this.walletAddress});
      this.nftList = r;
      console.log("---nftList--", this.nftList, r)
      return (r || []).length;
    },
  }
}
