import { createI18n } from "vue-i18n";
import enUS from '@/i18n/en_US'
import zhCN from '@/i18n/zh_CN'
import zhJa from '@/i18n/ja-JP'
import zhKo from '@/i18n/ko-KR'
import zhTh from '@/i18n/th-TH'
import zhVi from '@/i18n/vi-VN'

const i18n = createI18n({
    locale: sessionStorage.getItem('lang')||"en-US",
    globalInjection: true,
    messages: {
        'en-US': enUS,
        'zh-CN': zhCN,
        'ja-JP': zhJa,
        'ko-KR': zhKo,
        'th-TH': zhTh,
        'vi-VN': zhVi,
    }
})

export default i18n

