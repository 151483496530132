<template>
  <!--  Bublid-->
  <div class="conts">
    <div class="bakg">
      <div class="header-one">
        <div class="head-left">
          <h1 data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500" v-html="$t(`message.Built`,{network:$t(`message.network`)})">
          </h1>
          <div class="text-dev wow animate__bounceInLeft">
            {{ $t(`message.PEGOHasDesigned`) }}
          </div>
          <div class="but-ul" data-aos="flip-left">
            <n-button @click="submitProject" round color="#FF7A00" class="PDP buts" ghost
                      text-color="#ffffff"> {{ $t(`message.SubmitYourProject`) }}
            </n-button>
            <n-button @click="$store.commit('iscomingModal', true)" round color="#FF7A00" class="PDP but-plain" ghost
                      :bordered="false"> {{ $t(`message.ApplyForInvestment`) }}
            </n-button>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-bg">
      <div class="dao">
        <div class="ti-ti" data-aos="flip-up" data-aos-duration="1500"> {{ $t(`message.useCases`) }}</div>
        <p> {{ $t(`message.LoremIpsum`) }}</p>
        <div class="container">
          <div class="Cross-Chain" @click="Community('https://w3swap.link/')" data-aos="flip-left">
            <div class="img-d">
              <img src="@/assets/PEGO/swap.svg" alt="">
            </div>
            <div class="txt-c">
              <div class="c_c"> {{ $t(`message.W3swap`) }}</div>
              <div class="s_s"> {{ $t(`message.AMegaPlatform`) }}</div>
            </div>

          </div>
          <div class="Cross-Chain" @click="Community('https://www.w3wallet.link/#/')" data-aos="flip-up">
            <div class="img-d">
              <img src="@/assets/PEGO/w3wallet.svg" alt="">
            </div>
            <div class="txt-c">
              <div class="c_c"> {{ $t(`message.W3Wallet`) }}</div>
              <div class="s_s"> {{ $t(`message.MultiFunction`) }}</div>
            </div>

          </div>
          <div class="Cross-Chain" @click="Community('https://www.w3bridge.net/')" data-aos="flip-right">
            <div class="img-d">
              <img src="@/assets/PEGO/bd.svg" alt="">
            </div>
            <div class="txt-c">
              <div class="c_c"> {{ $t(`message.W3Bridge`) }}</div>
              <div class="s_s"> {{ $t(`message.RebuildTheFragmented`) }}</div>
            </div>

          </div>
        </div>
        <!--        Ecological DAPP-->
        <div class="ecol">
          <div class="ecol-div">
            <div class="cand-ti" data-aos="flip-up" data-aos-duration="1500"> {{$t('message.ExploreThePEGOEcosystem')}}
            </div>
<!--            <n-button @click="$router.push({path: '/dapp/applicationForm'})" round color="#FF7A00" class="PDP buts heg" ghost-->
<!--                      text-color="#ffffff"> {{$t('message.submitProject')}}-->

<!--            </n-button> -->
            <n-button @click="$store.commit('iscomingModal', true)" round color="#FF7A00" class="PDP buts heg" ghost
                      text-color="#ffffff"> {{$t('message.submitProject')}}

            </n-button>
          </div>
          <div class="x-m">
            <div>{{$t('message.allItems')}}</div>
            <div class="dis">
              <n-input v-model:value="searchForm.name" @blur="handleSearch" @keyup.enter="handleSearch"
                       :placeholder="$t(`message.search`)" class="seachs">
                <template #prefix>
                  <Icon color="#D9D9E6" size="20" class="icons">
                    <SearchOutline/>
                  </Icon>
                </template>
              </n-input>
              <div class="sns">
                <n-config-provider :theme-overrides="themeOverrides">
                  <div class="popo">
                    <n-button @click="ulShow=!ulShow" icon-placement="right" text-color="#ffffff"
                              color="#222427" strong
                              class="wins">
                      {{ selPopo(searchForm.type) || $t(`message.all`) }}
                      <template #icon>
                        <Icon color="#ffffff" size="20" class="icons">
                          <ChevronDown v-show="!ulShow"/>
                          <ChevronUp v-show="ulShow"/>
                        </Icon>
                      </template>
                    </n-button>
                    <div class="cot-po" :class="{'ans':ulShow}">
                      <div class="bodr" v-for="(item,index) in typeList" :key="index"
                           @click="butClick(item)">
                        <!--                        <div class="bagst" :class="{'corBg':searchForm.type===''}">ALL</div>-->
                        <div class="bagst" :class="{'corBg':searchForm.type===item.id}"> {{ item.type }}</div>
                      </div>
                    </div>
                  </div>
                </n-config-provider>
              </div>
            </div>
          </div>
          <div class="ul-u">
            <div class="li-l" @click="$router.push({ name: 'dApp-detail', query: { id: item.id } })"
                 v-for="item in list" :key="item.id">
              <div class="imgs">
                <!--                <img src="@/assets/ecological/w3.svg" alt="">-->
                <img :src="item.logoUrl" alt="">
              </div>

              <div class="tit-t">
                {{ item.name }}
              </div>
              <div class="txt">
                <div class="t-t" v-html="item[fullKey('desc')] "></div>
              </div>
              <div class="tragt-t">
                <template v-for="(item) in item.typeList" :key="item">
                  <span >{{selPopo(item)}}</span>
                </template>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="join" data-aos="fade-down"
           data-aos-easing="linear"
           data-aos-duration="500">
        <div class="j-cont">
          <h1 data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
              v-html="$t(`message.JoinTheCommunity`,{Community:$t(`message.Community`)})">
          </h1>
          <div class="j-img" @click="Community('https://dsc.gg/pegonetwork')">
            <img src="@/assets/PEGO/Union.svg" alt="">
            <div>
               <span>
             {{ $t(`message.PEGODiscordSupport`) }}
          </span>
            </div>

          </div>
        </div>

      </div>
      <foot></foot>
      <img src="@/assets/PEGO/b-bag.svg" alt="" class="bag-img">
    </div>
  </div>
</template>

<script>
import foot from "@/components/foot";
import {WOW} from "wowjs";
import api from '@/api/api';
import {
  ChevronDown,
  ChevronUp,
  SearchOutline
} from "@vicons/ionicons5";
import {Icon} from '@vicons/utils'

export default {
  name: "home-01",
  components: {
    foot,
    Icon,
    ChevronDown,
    ChevronUp,
    SearchOutline
  },
  data() {
    return {
      list: [],
      typeList: [],
      searchForm: {
        pageIndex: 1,
        pageSize: 20,
        type: 0,
        name: '',
      },
      total: 0,
      totalPages: 1,
      id: '',
      idName: 'PDP-1',
      ulShow: false,
      themeOverrides: {
        "Dropdown": {
          "color": "#211B1BFF",
          "textColor": "#F9F6F6FF",
          "optionTextColor": "#FFF",
          "optionTextColorHover": "rgba(111, 120, 118, 1)",
          "optionTextColorActive": "#E2B163FF",
          "optionColorHoverInverted": "rgba(111, 120, 118, 1)",
          "optionColorActiveInverted": "",
          "optionTextColorActiveInverted": "#FFB526",
          "optionTextColorHoverInverted": "#FFF",
          "optionIconSuffixWidthHuge": "100px",
        },
        "Input": {
          "color": "#222427",
          "placeholderColor": "rgba(237, 228, 228, 1)",
          "caretColor": "#F1ECE8FF",
          "textColor": "rgba(241, 245, 249, 1)",
          "colorFocus": "#222427",
          "border": "1.5px solid rgba(255, 255, 255, 0.3)",
          "borderHover": "1px solid #fff",
          "borderFocus": "1px solid #fff",
          "borderRadius": "4px",
        },
        "Button": {
          "textColorHover": "#ffffff",
          "border": "1px rgba(255, 255, 255, 0.1) solid",
          "borderFocus": "1px #ffffff solid",
          "borderHover": "1px #ffffff solid",
          "borderPressed": "1px #ffffff solid",
          "rippleColor": "#FFB526",
          "borderRadius": "15px",
        }
      },
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',    //需要执行动画元素的Class
      animateClass: 'animated',    //animation.css动画的Class
      offset: 0,    //距离可视区域多少开始执行动画
      mobile: true,    //是否在移动设备执行动画
      live: false    //异步加载的内容是否有效
    })
    wow.init();
    this.init();
    this.getList();

  },
  computed: {
    walletAddress() {
      return this.$store.state.walletAddress
    },
    fullKey() {
      return function (key) {
        return `${key}${this.$i18n.locale === 'en-US' ? 'En' : 'Zh'}`;
      }
    },
    pdpOptions() {
      return [
        {
          label: "PDP-1",
          key: 1,
        },
        {
          label: "PDP-2",
          key: 2,
        },
        {
          label: "PDP-3",
          key: 3,
        },
        {
          label: "PDP-4",
          key: 4,
        },
        {
          label: "PDP-5",
          key: 5,
        },
        {
          label: "PDP-6",
          key: 6,
        },
        {
          label: "PDP-7",
          key: 7,
        },
        {
          label: "PDP-8",
          key: 8,
        },
        {
          label: "PDP-9",
          key: 9,
        },
      ]
    },
    selPopo() {
      let that=this
      return function (val) {
        if (val&&that.typeList.length>0) {
          let obj =that.typeList.find(item => item.id === val)
          return obj ? obj.type : ''
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    init() {
      api.getDAppTypeList().then((res) => {
        this.typeList = res;
        this.typeList.unshift({id: 0, type: this.$t('message.all')})
      });
    },
    Community(url) {
      window.open(url);
    },
    getList() {
      api.getDAppList(this.searchForm).then((res) => {
        this.list = res?.rows;
        this.total = res?.totalNum;
        this.totalPages = res?.totalPages;
      });

    },
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.getList();
    },
    butClick(item) {
      console.log("items", item)
      this.searchForm.type = item.id
      this.ulShow = !this.ulShow
      this.handleSearch()
    },
    submitProject() {
      const link = this.$t('message.SubmitYourProjectLink')
      if(link != '') {
        window.open(link)
      } else {
        this.$store.commit('iscomingModal', true)
      }
    },
  },
}
</script>
<style lang="less" scoped>
//@import "../components/modals.less";
//@import "../components/modals.less";

.conts {
  color: #ffffff;
  background: #151617;
  position: relative;
}

.bakg {
  background: url("../assets/PEGO/bag1.svg") no-repeat;
  padding: 0px 0 0px 100px;
  background-size: 800px;

}

.header-one {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 70px 0 100px 0px;
  background: url("@/assets/PEGO/why-pego-network.png") no-repeat right 51px top 114px;
  background-size: 564px auto;

  .head-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      width: 100%;
      font-family: 'FOLD';
      text-align: left;
    }

    .text-dev {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      font-family: LIGHT;
      color: #ffffff;
      width: 50%;

    }

    .but-ul {
      padding-top: 75px;

    }

  }

  .slider_img {
    height: 595.31px;
    object-fit: contain;
    position: relative;
    z-index: -1;
  }

}

.buts {
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
  border-radius: 33px;
  border: none !important;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 20px;
  height: 54px;
  //width: 235px;
  font-weight: 400;
  line-height: 54px;
  min-height: 20PX;
  text-transform: uppercase;
  font-family: RREGULAR;
  color: #FFFFFF;
  font-style: normal;
  font-size: 16px !important;
  padding: 20px 20px;
  box-sizing: border-box;
}

.but-plain {
  //border: 1px solid #FFFFFF;
  border-radius: 33px;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  height: 54px;
  //width: 235px;
  font-weight: 400;
  font-size: 16px;
  line-height: 54px;
  min-height: 20PX;
  text-transform: uppercase;
  font-family: RREGULAR;
  overflow: hidden;

}

button:active {
  animation: zoomOut; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.5s;
}

.but-plain:after {
  content: '';
  display: block;
  position: absolute;
  top: -36px;
  left: -100px;
  background: white;
  width: 50px;
  height: 125px;
  opacity: 20%;
  transform: rotate(-45deg);
}

.but-plain:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
  0 0 20px #FFB800;
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 20px;
  text-shadow: 1px 1px 2px #427388;
}

.but-plain:hover:after {
  left: 120%;
  transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
  -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
}

</style>
<style scoped lang="less">
h2 {
  font-size: 30px !important;

}

.ti-ti {
  font-weight: 800;
  font-size: 42px;
  line-height: 134%;
  font-family: BOLD;
  background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

}


.ins {
  width: 250px;
  height: 250px;
}


.fd {
  width: 371px;
  height: 38px;
  margin-top: 20px;
  margin-left: 18%;
}

.foot-bg {
  background: url("../assets/PEGO/bgjojn.svg") no-repeat;
  background-position: right 30px bottom 0;
  background-blend-mode: lighten;
  background-size: 672px 569px;
  border-top: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 122, 0, 0.3) 0%, rgba(255, 184, 0, 0.2) 40%, rgba(56, 57, 58, 0.05) 50%);
  border-image-slice: 1;
  z-index: 10;
}

.dao {
  padding: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: linear-gradient(132.39deg, #313539 1.54%, #22262B 10.66%, rgba(32, 35, 38, 0) );
  background: linear-gradient(132.39deg, #313539 6.54%, #22262B 30.66%, rgba(32, 35, 38, 0) 45.46%);

  p {
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 70px;
    font-family: LIGHT;
  }

  .ecol {
    padding: 98px 0 0;

    .ecol-div {
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    .cand-ti {
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 57px;
      text-align: left;
      background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      //background-clip: text;
      //text-fill-color: transparent;
      display: inline-block;
      font-family: BOLD, sans-serif;
      padding: 60px 0;

    }

    .ul-u {
      padding-top: 60px;
      display: inline-grid;
      grid-template-columns: 300px 300px 300px;
      //grid-template-rows: 235px;
      grid-column-gap: 30px;
      grid-row-gap: 30px;

      .li-l {
        width: 300px;
        text-align: center;
        background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
        box-shadow: 0px 3.19626px 14.3832px #17191B;
        backdrop-filter: blur(5.99299px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 11.986px;
        overflow: hidden;

        .imgs {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          //background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
          background: #5E5E5E;
          backdrop-filter: blur(7.5px);
          //border-radius: 15px;
          height: 100px;
          width: 300px;
          border: 0.5px solid rgba(255, 255, 255, 0.1);
          //overflow: hidden;
          position: relative;

          img {
            position: absolute;
            left: 32px;
            bottom: -33.16px;
            width: 66.32px;
            height: 66.32px;
            object-fit: contain;
            border-radius: 100%;
            background: rgba(204, 204, 204, 0.3);
          }
        }

        .tit-t {
          font-weight: 700;
          font-size: 16px;
          text-align: left;
          padding: 50px 30px 0;
        }

        .txt {
          padding: 28px 30px;
          height: 110px;
          text-align: left;
          font-weight: 300;
          font-size: 12px;
          line-height: 19px;
          box-sizing: border-box;
          overflow: hidden;
          .t-t {
            font-family: 'LIGHT', sans-serif;
            font-weight: 300;
            font-size: 12px;
            line-height: 19px;
            color: #FFFFFF;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box; //作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
            -webkit-line-clamp: 3; //显示的行
            box-sizing: border-box;
          }

        }

        .tragt-t {
          padding:0 30px 30px;
          text-align: left;
          span {
            text-align: center;
            display: inline-block;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            border: 0.399533px solid rgba(255, 255, 255, 0.5);
            border-radius: 239.72px;
            width: 55.36px;
            font-family: 'LIGHT', sans-serif;
            margin-right: 10px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.bag-img {
  position: absolute;
  width: 133px;
  z-index: 900;
  top: 540px;
  right: 0;
}

.container {
  display: inline-grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: 235px;
  grid-column-gap: 30px;

}

.Cross-Chain {
  background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
  backdrop-filter: blur(7.5px);
  border-radius: 15px;
  width: 300px;
  height: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border: 0.5px rgba(153, 153, 153, 0.5) solid;
  padding: 45px 30px;

  .img-d {
    height: 45px;
    line-height: 45px;

  }

  .txt-c {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  img {
    width: 170px;
    object-fit: contain;
  }

  .c_c {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    padding: 37px 0 5px;
    font-family: SEMIBOLD;
  }

  .s_s {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    font-family: LIGHT;
  }
}

.Cross-Chain:hover {
  border: 0.5px rgba(153, 153, 153, 1) solid;
  transform: scale(1.1);
  background: linear-gradient(180deg, rgba(56, 63, 70, 1) 0%, rgba(33, 37, 45, 1) 129.36%);
  backdrop-filter: blur(20px);
  box-shadow: 0px 0px 10px 1px rgba(255, 255, 255, 0.3);
  cursor: pointer;
  animation: all 0.5s linear infinite;
}

//join
.join {
  //border: 1px red solid;
  background: linear-gradient(180deg, #17181A 6.54%, rgba(32, 35, 38, 0) 45.46%), url("../assets/PEGO/Ellipse.png") no-repeat bottom;
  //background-position: bottom ;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 100px 110px;

  .j-cont {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 4px 24px 0.1px rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    padding: 45px 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 700;
      font-size: 60px;
      line-height: 134%;
      color: #FFFFFF;
      width: 40%;
      text-align: left;
      font-family: 'FOLD';

    }

    .j-img {
      --background: transparent;
      --text: #fff;
      --font-size: 20px;
      --font-size2: 30px;
      --duration: .44s;
      --move-hover: -4px;
      //--shadow: 0 2px 8px -1px linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
      //--shadow-hover: 0 4px 20px -2px rgba(255, 223, 111, 0.05);
      --font-shadow: var(--font-size2);
      --m: calc(var(--font-size2) * -1);
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      text-shadow: 0 var(--font-shadow) 0 var(--text);
      //text-shadow: 0 30px 0 var(--text);
      outline: none;
      line-height: 30px;
      text-decoration: none;
      //box-shadow: var(--shadow);
      transform: translateY(var(--y)) translateZ(0);
      transition: transform var(--duration) ease, box-shadow var(--duration) ease;

      div {
        display: flex;
        overflow: hidden;
        //line-height: 30px;
        text-shadow: 0 var(--font-shadow) 0 var(--text);

      }

      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
        margin-right: 30px;
        border-radius: 10px;
        -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
      }

      span {
        font-weight: 600;
        font-size: var(--font-size);
        //line-height: 27px;
        line-height: 30px;
        color: #FFFFFF;
        font-family: 'SEMIBOLD', sans-serif;
        display: block;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform var(--duration) ease;
        transform: translateY(var(--m)) translateZ(0);
        background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
      }

      span:nth-child(2) {
        border: 1px red solid;
        transition-delay: 0.1s;
      }

      span:nth-child(4) {
        transition-delay: 0.2s;
      }

      span:nth-child(6) {
        transition-delay: 0.3s;
      }

      span:nth-child(8) {
        transition-delay: 0.4s;
      }

      span:nth-child(10) {
        transition-delay: 0.5s;
      }

    }

    .j-img:hover {
      //transform: scale(1.1);
      //animation: 2s;
      --y: var(--move-hover);
      //--shadow: var(--shadow-hover);
    }

    .j-img:hover span {
      //--m: calc(var(--font-size2) * -1);
      --m: 0;
    }

    .j-img:hover img {
      background-image: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
      background-size: cover;
      background-blend-mode: multiply; //排除模式
      mix-blend-mode: difference;

    }
  }
}

.heg {
  height: 45px !important;
  line-height: 45px !important;
  font-size: 16px !important;
}

.x-m {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dis {
  display: flex;
  align-items: center;
}

.seachs {
  --n-border-hover: 1px solid rgba(255, 255, 255, 1) !important;
  --n-border-focus: 1px solid rgba(255, 255, 255, 1) !important;
  --n-caret-color: #FFFFFF !important;
  --n-border: 1px solid rgba(255, 255, 255, 0.3) !important;
  width: 266px;
  height: 50px;
  line-height: 50px;
  background: #2C2F34 !important;
  border-radius: 4px;
  caret-color: #2C2F34 !important;
  color: white !important;
  font-size: 16px;
  margin-right: 5px;
  text-align: left;

  ///deep/ .n-input__input-el {
  :deep(.n-input__input-el) {
    color: #ffffff !important;
    caret-color: #FFFFFF !important;
    -webkit-appearance: none;
    scrollbar-width: none;
    width: 100%;
    min-width: 0;
    height: 50px;
    line-height: 50px;
    background-color: transparent;
  }

  .icons {
    font-size: 16px !important;
  }
}

.cot-po {
  top: 55px !important;
  border-radius: 4px !important;
  padding: 0 10px;
  background: #2C2F34 !important;
  border: 1px solid rgba(255, 255, 255, 0.1);

  .bodr {
    line-height: 30px;
    border-bottom: 0.3px solid rgba(255, 255, 255, 0.1);
  }

  .bodr:last-child {
    border-bottom: none;
  }

  div {
    padding: 5px 15px;

  }

  .bagst:hover {
    background: #383F46 !important;
    border-radius: 8px;

  }

  .corBg {
    background: #383F46 !important;
    border-radius: 8px;
  }
}

.sns {
  //padding-bottom: 30px;
  height: 50px;
  line-height: 50px;

  .popo {
    position: relative;
    height: 50px;
    line-height: 50px;
    //z-index: 4;
    .wins {
      width: 174px;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      height: 50px !important;
      line-height: 50px !important;
      justify-content: space-between;
      border: 1.5px rgba(255, 255, 255, 0.3) solid;
      background: #2C2F34;
    }
  }

  .cot-po {
    width: 100%;
    text-align: left;
    line-height: 30px;
    position: absolute;
    box-sizing: border-box;
    z-index: 3;
    top: 50px;
    background: #2C2F34;
    border-radius: 0 0 4px 4px;
    visibility: hidden;

  }

  .pad-lr {
    padding: 0 15px;
  }

  .ans {
    visibility: visible;
    animation: ant 0.1s;
    @keyframes ant {
      0% {
        z-index: -1;
        top: 0;
        opacity: 0;
      }

      100% {
        top: 50px;
        opacity: 1;
      }
    }
  }


}
</style>
