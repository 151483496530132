/**
 *@title
 *@author liqingjie
 *@date 2023/2/13 18:18
 */
import detectProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import store from "@/store";
import {createDiscreteApi} from "naive-ui";
import i18n from "@/i18n";
import api from "@/api/api"
import {
  getStore, removeStore,
  setStore
} from "@/config/storage";


const {message} = createDiscreteApi(
  ["message"],
);
const networkConfig = {
  chainId: Web3.utils.numberToHex(process.env.VUE_APP_BASE_CHAINID),
  chainName: process.env.VUE_APP_BASE_CHAINNAME,
  nativeCurrency: {
    name: process.env.VUE_APP_BASE_NAME,
    symbol: process.env.VUE_APP_BASE_SYMBOL,
    decimals: 18,
  },
  rpcUrls: [process.env.VUE_APP_BASE_RPCURLS],
  blockExplorerUrls: [process.env.VUE_APP_BASE_BLOCKEXPLORERURLS],
};


export const formWei = (n) => Web3.utils.fromWei(n);

export const isWallet = async (name) => {
  try {
    if(name==='Metamask'){
      const provider = await detectProvider();
      if (provider) {
        return provider;
      }else {
        return null;
      }
    }else if(name==='BitKeep'){
      const providerBitkeep = window.bitkeep && window.bitkeep.ethereum;
      if (providerBitkeep) {
        return providerBitkeep;
      }else {
        return null;
      }
    }else if(name==='TokenPocket'){
      if (window?.ethereum?.isTokenPocket) {
        return window.ethereum;
      }else {
        return null;
      }
    }else if(name==='OKXWallet'){
      const providerOKXWallet = window.okxwallet;
      if (providerOKXWallet) {
        return providerOKXWallet;
      }else {
        return null;
      }
    }else{
      const provider = await detectProvider();
      if (provider) {
        return provider;
      }else {
        if (window?.ethereum?.isTokenPocket) {
          return window.ethereum;
        }else {
          return null;
        }
      }
    }
  } catch (e) {
    console.log(e)
  }

};
export const getProvider = async () => {
  const provider = window.bitkeep && window.bitkeep.ethereum;
  if (!provider) {
    return window.open('https://bitkeep.com/en/download?type=2');
  }
  return provider;
}

export const BitKeepConnect = async () => {
  const BitKeepProvider = window.bitkeep && window.bitkeep.ethereum;
  // const web3 = new Web3(BitKeepProvider)
  try {
    const accounts = await BitKeepProvider.request({method: 'eth_requestAccounts'});
    console.log(accounts)
  } catch (err) {
    console.log(err)
  }
  BitKeepProvider.removeAllListeners();
  BitKeepProvider.on('accountsChanged', async (accounts) => {
    console.log("accounts changed", accounts)
  });
  BitKeepProvider.on('chainChanged', async (chainId) => {
    console.log("chainId changed", chainId)
  });
}

// eslint-disable-next-line no-async-promise-executor
export const connect = () => new Promise(async (resolve, reject) => {
  const provider = await isWallet(getStore('wallName'));
  // const provider = await getProvider()
  if (!provider) {
    // return reject(new Error('wallet not find'));
    return reject('wallet not find');
  }
  try {
    await provider.request({method: 'wallet_switchEthereumChain', params: [{chainId: networkConfig.chainId}]});
  } catch (error) {
    console.log('error**********', error);
    if (error.code === 4902) {
      try {
        await provider.request({method: 'wallet_addEthereumChain', params: [networkConfig]});
      } catch (e) {
        console.log(123, e);
      }
    }

    if (error.code === -32002 && !store.state.GivenProvider) {
      // eslint-disable-next-line no-undef
      message.warning(i18n.global.t(`message.pleaseGoToWallet`))
    }
  }
  await provider.request({method: 'eth_requestAccounts'})
    .then((accounts) => {
      resolve(accounts[0]);
    }).catch(e => {
      console.log(123, e);
    });
});

/**
 * -2 未安装 -1 已安装，未连接 1 正常连接
 * @returns {Promise<{code: number, accounts: unknown}|{code: number}>}
 */
export const init = async (name) => {
  const provider = await isWallet(name);
  // const provider = await getProvider()
  if (!provider) return {code: -2};
  const web3js = new Web3(provider);
  const coinbase = await web3js.eth.getCoinbase();
  if (!coinbase) {
    removeStore("PEGO_TOKEN")
    store.commit('updateWalletStatus', {code: -1, account: ""});
    return {code: -1};
  }
  const account = await connect();
  store.commit('updateWalletStatus', {code: 1, account});
  //暂时隐藏5-26
  //节点领取6-8
  if(!getStore("PEGO_TOKEN")){
      api.getNonce({prefix: "login", userAddress: store.state.walletAddress}).then(logins=>{
          console.log("logins",logins,logins.data)
          provider.request({
              method: "personal_sign",
              params: [logins.data || '', store.state.walletAddress]
          }).then(sign=>{
              api.walletLogin({
                  nonce: logins.data,
                  password:sign,
                  username: store.state.walletAddress
              }).then(res => {
                  console.log(res)
                  if (res.code === 200) {
                      setStore("PEGO_TOKEN", res.data)
                      window.location.reload()
                  } else {
                      setStore("PEGO_TOKEN", '')
                  }
              })
          })
      })
  }
  return {code: 1, account};


};

export const isGivenProvider = async () => {
  const providerWallet = await isWallet(getStore('wallName'));
  // const providers = await getProvider()
  if (providerWallet) {
    // const web3js = new Web3(providerWallet);
    // // 判断网络是否连接
    // //  判断当前连接网络类型
    // // let isConnecting = web3js.eth.net.isListening();  // 返回结果为布尔值
    // // 返回当前链接网络链id
    // web3js.eth.net.getId().then(e => {
    //   store.commit('isGivenProvider', Web3.utils.numberToHex(e) === networkConfig.chainId ? true : false);
    //   try {
    //     providerWallet.request({method: 'wallet_switchEthereumChain', params: [{chainId: networkConfig.chainId}]});
    //   } catch (error) {
    //     console.log('error**********', error);
    //     if (error.code === 4902) {
    //       try {
    //         providerWallet.request({method: 'wallet_addEthereumChain', params: [networkConfig]});
    //       } catch (e) {
    //         console.log(123, e);
    //       }
    //     }
    //     if (error.code === -32002 && !store.state.GivenProvider) {
    //       // eslint-disable-next-line no-undef
    //       message.warning(i18n.global.t(`message.pleaseGoToWallet`))
    //     }
    //   }
    //   providerWallet.removeAllListeners();
    //   providerWallet.on('chainChanged', async (chainId) => {
    //     window.location.reload()
    //     console.log("chainId changed", chainId)
    //   });
    //   console.log('on-----chanings')
    //   providerWallet.on('accountsChanged', (val) => {
    //     setStore("PEGO_TOKEN", '')
    //     window.location.reload();
    //     console.log('accountsChanged', val);
    //   });

    //   providerWallet.on('disconnect', (val) => {
    //     window.location.reload();
    //     console.log('disconnect', val);
    //   });
    //   return Web3.utils.numberToHex(e) === networkConfig.chainId ? true : false
    // });
    // return
  }

};
export const getBalance = async (address) => {
  try {
    const provider = await isWallet(getStore('wallName'));
    const n = await new Web3(provider).eth.getBalance(address);
    return Web3.utils.fromWei(n);
  } catch (e) {
    console.log(e)
  }

};

export const currenBlock = async () => {
  try {
    const provider = await isWallet(getStore('wallName'));
    if (!provider) {
      return null
    }
    return new Web3(provider).eth.getBlockNumber();
  } catch (e) {
    console.log(e)
  }

};

export const monitor = async () => {
  const provider = await isWallet(getStore('wallName'));
  // const provider = await getProvider()
  provider.removeAllListeners();

  if (provider) {
    console.log('on-----chanings')
    provider.on('chainChanged', (val) => {
      window.location.reload();
      console.log('accountsChanged', val);
    });

    provider.on('accountsChanged', (val) => {
      window.location.reload();
      console.log('accountsChanged', val);
    });

    provider.on('disconnect', (val) => {
      window.location.reload();
      console.log('disconnect', val);
    });

  }
};
