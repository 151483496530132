<template>
  <div class="conts">
    <div class="bakg">
      <div class="header-one">
        <div class="head-left">
          <h1 data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500" v-html="$t(`message.ToBeA`,{TOValidator:$t(`message.TOValidator`)})">
          </h1>
          <div class="text-dev" data-aos="fade-right" data-aos-easing="linear"
               data-aos-duration="500">
            {{ $t(`message.PEGOIsCommitted`) }}
          </div>
          <div class="but-ul" data-aos="flip-left">
            <n-button v-if="validatorStatus < 4" style="cursor: not-allowed; opacity: 0.5;" round color="#FF7A00"
                      class="but-dis" ghost text-color="#ffffff"
                      :bordered="false">
              {{ $t(`message.applyToBe`) }}
            </n-button>
            <n-button v-else-if="walletAddress" @click="apply" round color="#FF7A00" class="buts PDP" ghost
                      text-color="#ffffff"
                      :bordered="false">
              {{ $t(`message.applyToBe`) }}
            </n-button>
            <n-button v-else @click="apply" round color="#FF7A00" class="buts PDP" ghost text-color="#ffffff"
                      :bordered="false">
              {{ $t(`message.CONNECT`) }}
            </n-button>
          </div>
        </div>
      </div>
    </div>
    <div class="pad">
      <div class="ti-card">
        <div class="cand-ti">
          {{ $t(`message.MyAccount`) }}
        </div>
        <!--        登录后显示-->
        <div class="accounts" v-if="walletAddress">
          <!--          登录账号地址显示-->
          <div>
            {{ walletAddress }}

            <div class="sta-val" v-if="validatorStatus < 4">
              <span class="sue" v-if="validatorStatus === 1">{{ $t(`message.validator`) }}</span>
              <span class="err" v-else-if="validatorStatus === 2">{{ $t(`message.validator`) }}</span>
              <span v-else>{{ $t(`message.validator`) }}</span>
            </div>
            <div class="sta-val" style="margin-left: 10px" v-if="!isGivenProvider">
              <span class="err">{{ $t(`message.NetworkError`) }}</span>
            </div>
          </div>
          <div>
<!--            查询投票列表-->
<!--            <n-button v-if="validatorStatus < 4" round color="#FF9D00" class="buts PDP" text-color="#ffffff"-->
<!--                      style="margin-right: 10px"-->
<!--                      :bordered="false" @click="theList">-->
<!--              {{ $t('message.votingList') }}-->
<!--            </n-button>-->
            <n-button v-if="validatorStatus < 4" round color="#FF9D00" class="buts PDP" text-color="#ffffff"
                      :bordered="false" @click="lodiongs">
              {{ $t(`message.quitValidator`) }}
            </n-button>
          </div>

        </div>
        <n-grid x-gap="30" y-gap="30" cols="2 m:3 l:3 xl:3 2xl:3" responsive="screen">
          <n-gi>
            <div class="li_tet" data-aos="flip-left">
              <div class="dis_li">
                <div class="txt">
                  {{ $t(`message.VotingReward`) }}
                </div>
                <img src="@/assets/PEGO/reward.svg" alt="">
              </div>
              <div class="numb">
                <div>{{ Number(reward).toFixed(4) }} PG</div>
              </div>
              <div v-if="walletAddress">
                <div>
                  <n-button v-if="Number(reward)" @click="handleClaimReward" round color="#FF9D00"
                            class="buts PDP numBut"
                            text-color="#ffffff" :bordered="false"
                  >
                    {{ $t(`message.claim`) }}
                  </n-button>
                  <n-button v-else style="cursor: not-allowed; opacity: 0.5;" round color="#FF9D00"
                            class="buts PDP numBut"
                            text-color="#ffffff" :bordered="false"
                  >
                    {{ $t(`message.claim`) }}
                  </n-button>
                </div>
              </div>
            </div>
          </n-gi>
          <n-gi>
            <div class="li_tet" data-aos="flip-up">
              <div class="dis_li">
                <div class="txt">
                  {{ $t(`message.TokenBalance`) }}
                </div>
                <img src="@/assets/PEGO/balance.svg" alt="">
              </div>
              <div class="numb">
                <div>{{ Number(balance).toFixed(4) }}PG</div>
              </div>
              <div v-if="walletAddress">
                <div>
                  <n-button round color="#FF9D00" class="buts PDP numBut" text-color="#ffffff" :bordered="false"
                            @click="GetShow=true">
                    {{ $t(`message.GetPGVT`) }}
                  </n-button>
                  <n-button round color="#FF9D00" class="buts PDP numButs" text-color="#ffffff" :bordered="false"
                            @click="retPG">
                    {{ $t(`message.ReturnPGVT`) }}
                  </n-button>
                </div>
              </div>
            </div>
          </n-gi>
          <n-gi>
            <div class="li_tet" data-aos="flip-right">
              <div class="dis_li">
                <div class="txt"> {{ $t(`message.VotingPower`) }}
                </div>
                <img src="@/assets/PEGO/power.svg" alt="">
              </div>
              <div class="numb">
                <div>{{ Number(pgvtBalance).toFixed(4) }} PGVT</div>
              </div>
              <div v-if="walletAddress">
                <div>
                  <n-button round color="#FF9D00" class="buts PDP numBut" text-color="#ffffff" :bordered="false"
                            @click="voteClick('')">
                    {{ $t(`message.VotingPower`) }}
                  </n-button>

                </div>
              </div>

            </div>
          </n-gi>
        </n-grid>
      </div>
      <div class="ti-card" v-if="validatorStatus < 4">
        <div class="sea-h1">
          <div class="cand-ti"> {{ $t(`message.nodeReward`) }}
          </div>
          <div class="cks">
            {{ $t(`message.NumberOfBlocks`) }}:{{ BlockNum }}
            <!--                  {{ $t(`message.NumberOfBlocks`) }}:{{getBolck}}-->
          </div>
        </div>
        <n-grid cols="4" responsive="screen" class="grd-rd ranks node" data-aos="zoom-in-left">
          <n-grid-item class="grid-item">
            <div class="tlt">
              {{ $t(`message.award`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div class="tlt">
              {{ $t(`message.toBeCollected`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div class="tlt">
              {{ $t(`message.received`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div class="tlt">
              {{ $t(`message.Operate`) }}
            </div>
          </n-grid-item>
        </n-grid>
        <template v-for="(item) in rewardList" :key="item.coinCode">
          <n-grid cols="4" responsive="screen" class="grd-rd ranks node bor-bag" data-aos="zoom-in-left">
            <n-grid-item class="grid-item">
              <div>
                {{ item.coinCode }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <div class="green">
                {{ Number(item.balance).toFixed(2) || 0 }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <div class="green">
                {{ Number(item.hasBalance).toFixed(2) || 0 }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <n-button v-if="item.balance>0" @click="getWithdraw(item)" type="warning" round ghost class="nubt PDP"
                        text-color="#ffffff"
                        :bordered="false">
                {{ $t(`message.receiveAward`) }}
              </n-button>
              <n-button v-else style="cursor: not-allowed; color: rgba(255,255,255,0.7);" type="warning" round ghost
                        class="nubt PDP" text-color="#ffffff" :bordered="false"
              >
                {{ $t(`message.receiveAward`) }}
              </n-button>
            </n-grid-item>
          </n-grid>
        </template>
        <!--        <div class="bust-arw">-->
        <!--          <n-button round color="#FF9D00" class="buts PDP" text-color="#ffffff"-->
        <!--                    :bordered="false">-->
        <!--            {{ $t(`message.receiveAward`) }}-->
        <!--          </n-button>-->
        <!--        </div>-->

      </div>
      <!--                  vote-->
      <div class="ti-card">
        <div class="cand-ti"> {{ $t(`message.VotedValidators`) }}
        </div>
        <n-grid cols="9" responsive="screen" class="grd-rd ranks" data-aos="zoom-in-left">
          <n-grid-item class="grid-item">
            <div class="tlt">
              {{ $t(`message.Rank`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item" :span="2">
            <div class="tlt">
              {{ $t(`message.Address`) }}

            </div>
          </n-grid-item>
          <n-grid-item class="grid-item" :span="2">
            <div class="tlt">
              {{ $t(`message.Status`) }}

            </div>
          </n-grid-item>
          <n-grid-item class="grid-item" :span="2">
            <div class="tlt">
              {{ $t(`message.MyShare`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item" :span="2">
            <div class="tlt">
              {{ $t(`message.Operate`) }}
            </div>
          </n-grid-item>
        </n-grid>
        <template v-for="(item,i) in myRanks" :key="i">
          <n-grid cols="9" responsive="screen" class="grd-rd ranks bor-bag" data-aos="zoom-in-left">
            <n-grid-item class="grid-item">
              <div>
                {{ item.rank }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item" :span="2">
              <div class="green">
                {{ $Public.account(item.address) }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item" :span="2">
              <div class="light-green">
                <Icon color="#43FFAA" size="30" class="icons" v-if="item.available">
                  <CheckmarkCircleOutline/>
                </Icon>
                <Icon color="#FF706A" size="30" class="icons" v-else>
                  <CloseCircleOutline/>
                </Icon>

              </div>
            </n-grid-item>
            <n-grid-item class="grid-item" :span="2">
              <div class="green">
                {{ item.myDelegate }}/{{ Number(item.delegate).toFixed(4) }} ({{ item.share }}%)
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item" :span="2">
              <div class="light-green">
                <div class="dis" @click="voteClick(item.address)">
                  {{ $t(`message.IncreaseVote`) }}
                  <Icon color="#43FFAA" size="13" class="icons seu">
                    <Add/>
                  </Icon>
                </div>
                <div class="dis pads" @click="refundClick(item.address,item.myDelegate)">
                  {{ $t(`message.Refund`) }}
                  <Icon color="#FF706A" size="13" class="icons er">
                    <Remove/>
                  </Icon>
                </div>
              </div>
            </n-grid-item>
          </n-grid>
        </template>

      </div>
      <!--      stats-->
      <div class="ti-card">
        <div class="cand-ti"> {{ $t(`message.ValidatorStatus`) }}
        </div>
        <div class="status" data-aos="zoom-in-up">
          <n-grid cols="3" responsive="screen" class="sta-li">
            <n-grid-item>
              <div class="">
                {{ $t(`message.Validator`) }}

              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="">
                {{ $t(`message.TotalVotes`) }}
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="">
                {{ $t(`message.NextRound`) }}
              </div>
            </n-grid-item>
          </n-grid>
          <n-grid cols="3" responsive="screen" class="sta-ti">
            <n-grid-item>
              <div class="">
                <span>{{ validatorNumber }} [<span style="color: rgba(255, 157, 0, 1)">{{
                    onlineValidatorNumber
                  }}</span>] </span>
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="">
                {{ Number(totalSupply).toFixed(4) }}
              </div>
            </n-grid-item>
            <n-grid-item>
              <div class="">
                {{ `${this.hh}:${this.mm}:${this.ss}` }}
                <!--                <n-countdown :duration="420000" :active="active"/>-->
              </div>
            </n-grid-item>
          </n-grid>
        </div>
      </div>
      <!--      rank-->
      <div class="rank">
        <div class="sea-h1">
          <div class="cand-ti">{{ $t(`message.ValidatorRanking`) }}</div>
          <n-input v-model:value="rankValue" :placeholder="$t(`message.search`)" class="seachs">
            <template #prefix>
              <Icon color="#D9D9E6" size="20" class="icons">
                <SearchOutline/>
              </Icon>
            </template>
          </n-input>
        </div>

        <n-grid cols="7" responsive="screen" class="grd-rd ranks" data-aos="zoom-in-left">
          <n-grid-item class="grid-item">
            <div>
              {{ $t(`message.Ranking`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div style="color: #ffffff">
              {{ $t(`message.Address`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div>
              {{ $t(`message.Status`) }}

            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div>
              {{ $t(`message.LatestBlock`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div>
              {{ $t(`message.ValidatedBlocks`) }}
            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div>
              {{ $t(`message.Votes`) }}

            </div>
          </n-grid-item>
          <n-grid-item class="grid-item">
            <div>
              {{ $t(`message.Action`) }}

            </div>
          </n-grid-item>
        </n-grid>
        <template v-for="(item,i) in filterRanks" :key="i">
          <n-grid cols="7" responsive="screen" class="grd-rd ranks one-bag" data-aos="zoom-in-left">
            <n-grid-item class="grid-item">
              <div>
                {{ i + 1 }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <div>
                {{ $Public.account(item.address) }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <div class="light-green">
                <Icon color="#43FFAA" size="30" class="icons" v-if="item.available">
                  <CheckmarkCircleOutline/>
                </Icon>
                <Icon color="#FF706A" size="30" class="icons" v-else>
                  <CloseCircleOutline/>
                </Icon>
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <div class="green">
                {{ item.lastBlock }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <div class="light-green">
                {{ item.blockProduced }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <div class="green">
                {{ Number(item.delegate).toFixed(4) }}
              </div>
            </n-grid-item>
            <n-grid-item class="grid-item">
              <n-button v-if="walletAddress" type="warning" round ghost class="nubt PDP" text-color="#ffffff"
                        :bordered="false"
                        @click="voteClick(item.address)">
                {{ $t(`message.Vote`) }}
              </n-button>
              <n-button v-else style="cursor: not-allowed; color: rgba(255,255,255,0.7);" type="warning" round ghost
                        class="nubt PDP" text-color="#ffffff" :bordered="false"
              >
                {{ $t(`message.Vote`) }}
              </n-button>
            </n-grid-item>
          </n-grid>
        </template>
      </div>
    </div>

    <!--     退出弹出框-->
    <modals :LoadingShow="quitShow" @OnUpdate="quitShow=!quitShow" :wids="3">
      <template #default>
        <h1>
          {{ $t(`message.quitTheValidator`) }}
        </h1>
        <p class="red-p">
          {{ $t(`message.onceQuited`) }}
        </p>
        <p class="txt-p">
          {{ $t(`message.andYour`) }}
        </p>
        <div class="mod-but">
          <n-button round color="#FF7A00" class="PDP but-plain" ghost :bordered="false" @click="doNotQuit">
            {{ $t(`message.noIDont`) }}
          </n-button>
          <n-button round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false" @click="quit">
            {{ $t(`message.SureIWant`) }}
          </n-button>
        </div>
      </template>
    </modals>
    <!--    投票列表-->
    <modals :LoadingShow="theListShow" @OnUpdate="theListUpdate" :wids="8">
      <template #default>
        <n-config-provider :theme-overrides="themeOverrides">
          <n-space vertical>
            <div class="tables">
              <n-data-table :loading="dataLoading" :columns="theColumns" :data="delegateEntities"/>
            </div>
            <div class="but-dist">
              <div class="download" @click="downloadFile">
                {{ $t(`message.downloadFile`) }}
              </div>
              <div class="dist-f">
                <div>
                  <n-button v-if="pageIndex===1" style="cursor: not-allowed; color: rgba(255,255,255,0.7);"
                            type="warning" round
                            ghost
                            class="nubt PDP" text-color="#ffffff" :bordered="false"
                  >
                    {{ $t('message.previousPage') }}
                  </n-button>
                  <n-button v-else @click="delegates(false)" type="warning" round ghost
                            class="nubt PDP" text-color="#ffffff" :bordered="false"
                  >
                    {{ $t('message.nextPpage') }}
                  </n-button>
                </div>
                <div>
                  <n-button v-if="pageSize" type="warning" round ghost @click="delegates(true)"
                            class="nubt PDP" text-color="#ffffff" :bordered="false"
                  >
                    {{ $t('message.previousPage') }}
                  </n-button>
                  <n-button v-else style="cursor: not-allowed; color: rgba(255,255,255,0.7);" type="warning" round ghost
                            class="nubt PDP" text-color="#ffffff" :bordered="false"
                  >
                    {{ $t('message.nextPpage') }}
                  </n-button>
                </div>
              </div>

            </div>
          </n-space>
        </n-config-provider>
      </template>
    </modals>
    <!--    获取pgbt-->
    <modals :LoadingShow="GetShow" @OnUpdate="GetShow=!GetShow" :wids="3">
      <template #default>
        <h1>{{ $t(`message.getPGVT`) }}</h1>
        <div class="but-s">
          <n-config-provider :theme-overrides="themeOverrides">
            <n-space vertical>
              <div style="position: relative" class="getPgvt">
                <n-input-number v-model:value="getPGVTForm.value" :show-button="false"
                                :placeholder="$t(`message.pleaseEnter`)">
                </n-input-number>
                <n-dropdown trigger="hover" key-field="address" label-field="currency" :options="tokens"
                            @select="handleSelect">
                  <n-button icon-placement="right" text-color="#ffffff" color="#2C2F34" strong class="but-dro">
                    {{ getPGVTForm.currency }}
                    <template #icon>
                      <Icon color="#ffffff" size="20" class="icons">
                        <ChevronDown/>
                      </Icon>
                    </template>
                  </n-button>
                </n-dropdown>
                <span class="max-span" @click="getPGVTForm.value = fromBalance">
                 {{ $t(`message.MAX`) }}
              </span>
              </div>

            </n-space>
          </n-config-provider>
          <div class="bal-rig">
            {{ $t(`message.Balance`) }}: {{ fromBalance }}{{ $t(`message.Balance`) }}:{{fromBalance2}}
          </div>
        </div>
        <div class="icon-cent">
          <Icon color="#FFA800" size="30" class="icons">
            <ArrowDownCircleSharp/>
          </Icon>
        </div>
        <div class="but-s ">
          <n-config-provider :theme-overrides="themeOverrides">
            <n-space vertical>
              <div style="position: relative">
                <n-input-number v-model:value="willGetPgvt" readonly :show-button="false" placeholder="PGVT">
                </n-input-number>
                <span class="max-span cor">
                PGVT
              </span>
              </div>

            </n-space>
          </n-config-provider>

        </div>
        <div class="mod-but pgBut" v-if="getPGVTForm.rate==='1'">
          <n-button v-if="getPGVTForm.value > 0&&getPGVTForm.value<=fromBalance" round color="#FF7A00" class="PDP buts"
                    ghost text-color="#ffffff"
                    :bordered="false"
                    @click="getPgvt">
            {{ $t(`message.getPGVT`) }}
          </n-button>
          <n-button v-else style="cursor: not-allowed; color: rgba(255,255,255,0.7);" round color="#FF7A00"
                    class="PDP buts" ghost text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.getPGVT`) }}
          </n-button>
        </div>
        <div class="mod-but pgBut" v-else>
          <n-button v-if="getPGVTForm.value > 0&&getPGVTForm.value<=fromBalance&&BigNumber(fromBalance2)>=500000" round
                    color="#FF7A00" class="PDP buts"
                    ghost text-color="#ffffff"
                    :bordered="false"
                    @click="getPgvt">
            {{ $t(`message.getPGVT`) }}
          </n-button>
          <n-button v-else-if="BigNumber(fromBalance2)>=500000" style="cursor: not-allowed; color: rgba(255,255,255,0.7);" round color="#FF7A00"
                    class="PDP buts" ghost text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.getPGVT`) }}
          </n-button>
         <n-button v-else-if="BigNumber(fromBalance2)<500000" style="cursor: not-allowed; color: rgba(255,255,255,0.7);" round color="#FF7A00"
                    class="PDP buts" ghost text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.ReturnPGVTWPG`) }}
          </n-button>
        </div>
      </template>
    </modals>
    <!--    return pgbt-->
    <modals :LoadingShow="retuShow" @OnUpdate="retuShow=!retuShow" :wids="3">
      <template #default>
        <h1>{{ $t(`message.ReturnPGVT`) }}</h1>

        <div class="but-s sns">
          <label for="" class="labe">
            {{ $t(`message.youWantTo`) }}
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <div class="popo">
              <n-button @click="ulShow=!ulShow" icon-placement="right" text-color="#ffffff" color="#2C2F34" strong
                        class="wins">
                {{ returnPGVTForm.currency }}
                <template #icon>
                  <Icon color="#ffffff" size="20" class="icons">
                    <ChevronDown/>
                  </Icon>
                </template>
              </n-button>
              <div class="cot-po pad-lr" :class="{'ans':ulShow}">
                <div :class="{'cors':selVlue===item.address}" v-for="(item,index) in tokens" :key="index"
                     @click="returnPGVTChangeFromCurrency(item)">
                  {{ item.currency }}
                </div>
              </div>
            </div>
          </n-config-provider>
          <div class="bal-rig">
            {{ $t(`message.YourBalanceIs1`) }}: {{ returnPGVTForm.used }}
          </div>
        </div>
        <div class="but-s ">
          <label for="" class="labe">
            {{ $t(`message.YouneedReturn`) }}
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <n-space vertical>
              <div style="position: relative">
                <n-input-number v-model:value="(returnPGVTForm.returnAmount)" readonly
                                :show-button="false" :placeholder="$t(`message.pleaseEnter`)">
                </n-input-number>
                <span class="max-span cor">
                PGVT
              </span>
              </div>

            </n-space>
          </n-config-provider>
          <div class="bal-rig">
            {{ $t(`message.BalancePGVT`) }}: {{ pgvtBalance }}
          </div>
        </div>
        <p class="custom-down" style="color: #AEAEAE" v-if="returnPeriodEndTime > 0">
          {{ $t(`message.CooldownCountdown`) }}:
          <span v-if="d > 0">{{ d }} {{ $t(`message.Days`) }} :</span>
          {{ h }} {{ $t(`message.Hours`) }}
          : {{ m }} {{ $t(`message.Minutes`) }}
          : {{ s }} {{ $t(`message.Seconds`) }}
        </p>
        <div class="mod-but pgBut">
          <n-button @click="handleReturnPGVT"
                    v-if="BigNumber(returnPGVTForm.used) > 0 && returnPeriodEndTime <= 0&&BigNumber(returnPGVTForm.returnAmount)<=pgvtBalance"
                    round
                    color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.ReturnPGVT`) }}
          </n-button>
          <n-button v-else style="cursor: not-allowed; color: rgba(255,255,255,0.7);" round color="#FF7A00"
                    class="PDP buts" ghost text-color="#ffffff" :bordered="false"
          >
            <span v-if="returnPeriodEndTime > 0">{{ $t(`message.ReturnPGVT2`) }}</span>
            <span v-else-if="BigNumber(returnPGVTForm.returnAmount) > pgvtBalance">{{
                $t(`message.ReturnPGVT3`)
              }}</span>
            <span v-else>{{ $t(`message.ReturnPGVT`) }}</span>
          </n-button>
        </div>
      </template>
    </modals>
    <!--    给节点投票-->
    <modals :LoadingShow="voteShow" @OnUpdate="voteShow=!voteShow" :wids="3">
      <template #default>
        <h1> {{ $t(`message.Vote`) }}</h1>

        <div class="but-s sns">
          <label for="" class="labe">
            {{ $t(`message.ValidatorAddress`) }}
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <n-input v-model:value="voteForm.address" type="text" size="large" placeholder="Address"/>
          </n-config-provider>
        </div>
        <div class="but-s ">
          <label for="" class="labe">
            {{ $t(`message.VoteAmount`) }}
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <n-space vertical>
              <div style="position: relative" class="pgvtMax">
                <n-input-number v-model:value="voteForm.value" :show-button="false"
                                :placeholder="$t(`message.pleaseEnter`)">
                </n-input-number>
                <span class="cor pgvt-span">
                PGVT
              </span>
                <span class="max-span" @click="voteForm.value = pgvtBalance">
                {{ $t(`message.MAX`) }}
              </span>
              </div>

            </n-space>
          </n-config-provider>
          <div class="bal-rig">
            {{ $t(`message.YourBalanceIs`) }}: {{ pgvtBalance }}
          </div>
        </div>
        <div class="mod-but pgBut">
          <n-button round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
                    @click="handleDelegate" v-if="voteForm.value > 0&&voteForm.value<=pgvtBalance">
            {{ $t(`message.Vote`) }}
          </n-button>
          <n-button v-else round color="#FF7A00" style="cursor: not-allowed; color: rgba(255,255,255,0.7);"
                    class="PDP buts"
                    ghost text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.Vote`) }}
          </n-button>
        </div>
      </template>
    </modals>
    <!--    refundShow退票-->
    <modals :LoadingShow="refundShow" @OnUpdate="refundShow=!refundShow" :wids="3">
      <template #default>
        <h1>{{ $t(`message.Withdraw`) }}</h1>
        <div class="but-s sns">
          <label for="" class="labe">
            {{ $t(`message.ValidatorAddress`) }}
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <n-input v-model:value="withdrawForm.address" type="text" size="large" placeholder="Address"/>
          </n-config-provider>
        </div>
        <div class="but-s ">
          <label for="" class="labe">
            {{ $t(`message.WithdrawAmount`) }}
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <n-space vertical>
              <div style="position: relative" class="pgvtMax">
                <n-input-number v-model:value="withdrawForm.value" :show-button="false"
                                :placeholder="$t(`message.pleaseEnter`)">
                </n-input-number>
                <span class="cor pgvt-span">
                PGVT
              </span>
                <span class="max-span" @click="withdrawForm.value = myDelegate">
                {{ $t(`message.MAX`) }}
              </span>
              </div>

            </n-space>
            <!--            <n-space vertical>-->
            <!--              <div style="position: relative">-->
            <!--                <n-input-number v-model:value="withdrawForm.value" :show-button="false"-->
            <!--                                :placeholder="$t(`message.pleaseEnter`)">-->
            <!--                </n-input-number>-->
            <!--                <span class="max-span cor">-->
            <!--               PGVT-->
            <!--              </span>-->
            <!--              </div>-->
            <!--            </n-space>-->
          </n-config-provider>
          <div class="bal-rig">
            {{ $t(`message.isYourBalanceIsVT`) }}: {{ tokenInfo }}
          </div>
        </div>
        <!--        <div class="but-s vith">-->
        <!--          <label for="" class="labe">-->
        <!--            {{$t(`message.WithdrawAmount`)}}:-->
        <!--          </label>-->
        <!--          <div class="bal-rig">-->
        <!--           000000 VT-->
        <!--          </div>-->
        <!--        </div>-->
        <p class="custom-down" style="color: #AEAEAE" v-if="withdrawPeriodEndTime > 0">
          {{ $t(`message.CooldownCountdown`) }}:
          <span v-if="d > 0">{{ d }} {{ $t(`message.Days`) }} :</span>
          {{ h }} {{ $t(`message.Hours`) }}
          : {{ m }} {{ $t(`message.Minutes`) }}
          : {{ s }} {{ $t(`message.Seconds`) }}
        </p>
        <div class="mod-but pgBut">
          <n-button @click="handleWithdraw"
                    v-if="BigNumber(withdrawForm.value) > 0 && withdrawPeriodEndTime <= 0 && BigNumber(withdrawForm.value) <= myDelegate && BigNumber(withdrawForm.value) <= tokenInfo"
                    round
                    color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.Withdraw`) }}
          </n-button>
          <n-button v-else round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false"
                    style="cursor: not-allowed; color: rgba(255,255,255,0.7);">
            <div v-if="withdrawPeriodEndTime > 0">{{ $t(`message.Withdraw2`) }}</div>
            <div v-else-if="BigNumber(withdrawForm.value) > myDelegate">{{ $t(`message.ReturnPGVT3`) }}</div>
            <div v-else-if="BigNumber(withdrawForm.value) > tokenInfo">{{ $t(`message.YourBalanceIsVT`) }}</div>
            <div v-else>{{ $t(`message.Withdraw`) }}</div>
          </n-button>

        </div>
        <!--        <div class="txts">-->
        <!--          {{ $t(`message.NoticeYou`) }}-->
        <!--        </div>-->
      </template>
    </modals>
    <!--    Exchange NFT-PNN-->
    <modals :LoadingShow="exchangeShow" @OnUpdate="exchangeShow=!exchangeShow" :wids="3">
      <template #default>
        <h1>{{ $t(`message.ExchangeNFTPNNT`) }}</h1>
        <div class="but-s">
          <label for="" class="labe">
            PNN(erc20)
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <n-space vertical>
              <div style="position: relative" class="getPgvt">
                <!--                :precision="precision" -->
                <n-input-number v-model:value="exchange.value" @update:value="exchangeClack" :show-button="false"
                                placeholder="PNN">
                </n-input-number>
                <span class="max-span" @click="exchange.value =Number(exchange.balance)">
               {{ $t(`message.MAX`) }}
              </span>
              </div>
            </n-space>
          </n-config-provider>
          <div class="bal-rig">
            {{ $t(`message.Balance`) }}: {{ Number(exchange.balance).toFixed(4) }}
          </div>
        </div>
        <div class="icon-cent">
          <Icon color="#FFA800" size="30" class="icons">
            <ArrowDownCircleSharp/>
          </Icon>
        </div>
        <div class="but-s ">
          <label for="" class="labe">
            NFT-PNN(erc721)
          </label>
          <n-config-provider :theme-overrides="themeOverrides">
            <n-space vertical>
              <div style="position: relative">
                <n-input-number v-model:value="exchange.value" readonly :show-button="false" placeholder="NFT-PNN">
                </n-input-number>
                <span class="max-span cor">
                NFT-PNN
              </span>
              </div>
            </n-space>
          </n-config-provider>

        </div>
        <div class="mod-but pgBut">
          <n-button v-if="exchange.value > 0&&exchange.value%1===0" round color="#FF7A00" class="PDP buts" ghost
                    text-color="#ffffff"
                    :bordered="false"
                    @click="staking">
            {{ $t(`message.clickHere`) }}
          </n-button>
          <n-button v-else style="cursor: not-allowed; color: rgba(255,255,255,0.7);" round color="#FF7A00"
                    class="PDP buts" ghost text-color="#ffffff" :bordered="false"
          >
            {{ $t(`message.clickHere`) }}
          </n-button>
        </div>
      </template>
    </modals>
    <!--       没有PNN-NFT-->
    <modals :LoadingShow="valiModal" @OnUpdate="valiModal=!valiModal" :wids="2.5">
      <template #action>
        <div class="tra" :style="{ width:'2.5rem'}">
          <h1>{{ $t(`message.nodeCard`) }}</h1>
          <div class="img-wang">
            <img src="@/assets/PEGO/wang.svg" alt="" class="macts">
          </div>
          <div class="font_text pad-b">
            {{ $t(`message.SorryYou`) }}<br/>
            {{ $t(`message.anyPNNNFT`) }}
          </div>
          <div class="mod-but pgBut">
            <n-button @click.stop.prevent="openExchange" round color="#FF7A00" class="PDP buts" ghost
                      text-color="#ffffff"
                      :bordered="false"
            >
              {{ $t(`message.clickHere`) }}
            </n-button>
          </div>
        </div>
      </template>
    </modals>
    <!--      申请验证者-->
    <modals :LoadingShow="applyModal" @OnUpdate="applyModal=!applyModal" :wids="2.5">
      <template #action>
        <div class="tra" :style="{ width:'2.5rem'}">
          <h1> {{ $t(`message.ConnectWallet`) }}</h1>
          <h2>
            {{ $t(`message.YourPNNNFT`) }}
          </h2>
          <div v-for="(item,i) in nftList" :key="i">
            <div class="bags">
              <img src="@/assets/PEGO/icon-logo.svg" alt="" style="width: 100%">
            </div>
            <div class="pnn">
              PNN-{{ item }}
            </div>
          </div>
          <div class="mod-but pgBut">
            <n-button @click="register" round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff"
                      :bordered="false"
            >
              {{ $t(`message.applyToBeAValidator`) }}
            </n-button>
          </div>
        </div>
      </template>
    </modals>
    <!--公共请求状态-->
    <modals :LoadingShow="statusModal" @OnUpdate="statusModal=!statusModal" :wids="2.5">
      <template #action>
        <div class="tra" :style="{ width:'2.5rem'}">
          <h1> {{ $t(`message.Transaction`) }}</h1>
          <!--            成功-->
          <div v-if="actionStatus===2">
            <div class="now">
              {{ $t(`message.CongratulationsYou`) }}
            </div>
            <div class="disco">
              {{ $t(`message.JoinOur`) }}<span>{{ $t(`message.discord`) }}</span>{{ $t(`message.toGetHow`) }}
            </div>
          </div>
          <div class="peds redCol" v-else-if="actionStatus === 3">
            {{ $t(`message.Failed`) }}
          </div>
          <div class="peds" v-else-if="actionStatus === 1">
            {{ $t(`message.Pending`) }}......
          </div>
        </div>
      </template>
    </modals>
    <foot></foot>
  </div>
</template>
<script>
import BigNumber from 'bignumber.js';
import foot from "@/components/foot";
import {ApolloClient, InMemoryCache, gql} from '@apollo/client'
import {
  Add,
  ArrowDownCircleSharp,
  CheckmarkCircleOutline,
  ChevronDown,
  CloseCircleOutline,
  Remove,
  SearchOutline
} from "@vicons/ionicons5";
import {Icon} from '@vicons/utils'
import {WOW} from "wowjs";
import modals from "@/components/modals.vue"
import {currenBlock, formWei, getBalance,} from '@/utils/web3';
import {
  cancelValidator,
  claimReward,
  delegate,
  getCandidates,
  getCandidatesByDelegator,
  getPendingUndelegateTime,
  getUnClaimedReward,
  onlineValidatorNumber,
  registerToValidator,
  totalSupply,
  undelegate,
  validatorNumber,
  validatorStatus,
  balanceOf as balanceOfVT
} from "@/utils/contract/validator";
import {
  balanceOf,
  getFarmingTokenLockTime,
  getFarmingTokenPrice,
  getStakingAmount,
  stakingFarmingToken,
  stakingPg,
  stakingPgvtAmount,
  unStaking
} from "@/utils/contract/pgvt";
import {supportFarmToken} from "@/utils/contract/dao";
import erc20 from "@/utils/contract/erc20";
import {moment} from "@/utils/common";
import {staking} from "@/utils/contract/nftPnn";
import {Decimal} from "decimal.js";
import {nftPnnAdder, PGAdder, pgvtAdder, tokenAddressAdder, WPG} from "@/utils/address";
import {getStore} from "@/config/storage";
import api from "@/api/api";
import {useMessage} from "naive-ui";
import {getTokenWithdraw} from "@/utils/contract/Withdraw";
import {getTokenLp, token0, token1} from '@/utils/contract/farm'
// import store from "@/store";
import Web3 from 'web3'
import {h} from 'vue'

export default {
  name: "PEGOScan",
  components: {
    foot,
    SearchOutline,
    ChevronDown,
    ArrowDownCircleSharp,
    Icon,
    modals,
    CheckmarkCircleOutline,
    CloseCircleOutline,
    Add, Remove
  },
  metaInfo() {
    return {
      title: this.$t("message.PEGOScan"),
      meta: [
        {
          name: 'keywords',
          content: this.keyword
        },
        {
          name: 'description',
          content: this.description
        }
      ]
    }

  },
  data() {
    return {
      tokensQuery: `
      query($first: Int,$skip: Int,$address: String){
       delegateEntities(first:$first,skip:$skip, where: {validator:$address}, orderBy: amount, orderDirection: desc) {
        id
        delegator
        validator
        amount
        blockNumber
        blockTimestamp
        transactionHash
      }
    }
`,
      header: [
        {
          name: 'ID',
          label: "id",
        },
        {
          name: this.$t('message.votingAddress'),
          label: "delegator",

        },
        {
          name: this.$t('message.numberOfVotes'),
          label: "amount",

        },
        {
          name: this.$t('message.votingTime'),
          label: "blockTimestamp",
        },
      ],
      APIURL: 'https://graph.pegorpc.com/subgraphs/name/satoshirock/pegovote',
      msg: useMessage(),
      tokenInfo: 0,
      precision: 0,
      walletInfo: {},
      actionStatus: 0, // 1 pending 2 success 3 error
      validatorNumber: 0, // 总节点
      onlineValidatorNumber: 0, // 在线节点
      tokens: [],
      delegateEntities: [],
      downloadEntities: [],
      dataLoading: false,
      withdrawPeriodEndTime: 0,
      returnPeriodEndTime: 0,
      balance: 0, // 主币余额
      fromBalance: 0, // 兑换from 余额
      fromBalance2: 0, // 兑换from 余额
      pgvtBalance: 0, // PGVT 余额
      myDelegate: 0,
      reward: 0,
      rankValue: '', // 搜索
      wpgBalnce: null,
      returnPGVTForm: {
        currency: 'PG',
        address: PGAdder,
        used: 0,
        returnAmount: 0,
      },
      getPGVTForm: {
        currency: 'PG',
        address: '',
        value: '',
        rate: '1',
      },
      voteForm: {
        address: '',
        value: '',
      },
      withdrawForm: {
        address: '',
        value: '',
      },
      exchange: {
        balance: '',
        from: 'PNN(erc20)',
        to: 'NFT-PNN(erc721)',
        value: '',
      },
      timers: null,
      timer: null,
      hh: '--',
      mm: '--',
      ss: '--',
      d: 0,
      h: 0,
      m: 0,
      s: 0,
      BlockNum: 0,
      rewardList: [],
      ranks: [],
      myRanks: [],
      nftList: [],
      totalSupply: 0,
      validatorStatus: 4,// 1 validator online,2 validator offline,3 candidate,4 nothing
      active: true,
      LoadingShow: false,//请求显示
      quitShow: false,//退出显示
      theListShow: false,//投票显示
      first: 30,
      pageIndex: 0,
      pageSize: true,
      GetShow: false,//获取PGVT显示
      voteShow: false,//节点投票显示
      ulShow: false,
      retuShow: false,//归还PGVT显示
      refundShow: false,//退票
      valiModal: false,
      applyModal: false,
      statusModal: false,
      exchangeShow: false,//exchange
      voteAddress: '',//输入地址
      PG: "PG",
      selVlue: PGAdder,
      options: [
        {
          label: "PG",
          key: "PG",
          props: {
            onClick: () => {
              this.PG = "PG"
            }
          }
        }, {
          label: "W3FT",
          key: "W3FT",
          props: {
            onClick: () => {
              this.PG = "W3FT"
            }
          }
        },
      ],
      themeOverrides: {
        "DataTable": {
          "borderColor": "rgba(229, 235, 241, 0.1)",
          "thColor": "rgba(36, 36, 187, 1)",
          "thColorModal": 'rgba(28, 31, 34, 1)',
          "tdColorModal": 'rgba(28, 31, 34, 0.5)',
          "tdColor": "rgba(28, 31, 34, 0.5)",
          "tdTextColor": "#ffffff",
          "loadingColor": "#C7D3CDFF",
          "thTextColor": "#ffffff",
          "tdColorHoverModal": "rgba(28, 31, 34, 0.3)",
        },
        "Dialog": {
          "textColor": "rgba(255, 255, 255, 1)",
          "color": "rgba(56, 63, 70, 0.7)",
          "closeColorPressed": "rgba(196, 15, 15, 0.13)",
          "closeColorHover": "rgba(35, 2, 2, 0)",
          "closeIconColor": "rgba(255, 255, 255, 1)",
          "closeIconColorHover": "rgba(230, 146, 30, 1)",
          "iconColorInfo": "#7398C4FF",
          "borderRadius": "15px",
        },
        "Dropdown": {
          "color": "#211B1BFF",
          "textColor": "#F9F6F6FF",
          "optionTextColor": "#FFF",
          "optionTextColorHover": "rgba(111, 120, 118, 1)",
          "optionTextColorActive": "#E2B163FF",
          "optionColorHoverInverted": "rgba(111, 120, 118, 1)",
          "optionColorActiveInverted": "",
          "optionTextColorActiveInverted": "#FFB526",
          "optionTextColorHoverInverted": "#FFF",
          "optionIconSuffixWidthHuge": "100px",
        },
        "Input": {
          "color": "#222427",
          "placeholderColor": "rgba(237, 228, 228, 1)",
          "caretColor": "#F1ECE8FF",
          "textColor": "rgba(241, 245, 249, 1)",
          "colorFocus": "#222427",
          "border": "1.5px solid rgba(255, 255, 255, 0.3)",
          "borderHover": "1px solid #fff",
          "borderFocus": "1px solid #fff",
          "borderRadius": "4px",
        },
        "Button": {
          "textColorHover": "#ffffff",
          "border": "1px rgba(255, 255, 255, 0.1) solid",
          "borderFocus": "1px #ffffff solid",
          "borderHover": "1px #ffffff solid",
          "borderPressed": "1px #ffffff solid",
          "rippleColor": "#FFB526",
          "borderRadius": "15px",
        },

      },


    }
  },
  async mounted() {
    const wow = new WOW({
      boxClass: 'wow',    //需要执行动画元素的Class
      animateClass: 'animated',    //animation.css动画的Class
      offset: 0,    //距离可视区域多少开始执行动画
      mobile: true,    //是否在移动设备执行动画
      live: false    //异步加载的内容是否有效
    })
    wow.init();
    this.walletList();
    await this.getBlock();
    this.validatorNumber = await validatorNumber();
    this.onlineValidatorNumber = await onlineValidatorNumber();
    // rank 排名
    this.ranks = await getCandidates();
    this.totalSupply = await totalSupply();
    if (this.walletAddress) {
      this.BlockNum = this.getBolck(this.walletAddress)
      await this.init();
      // 获取已投票列表
      this.myRanks = await getCandidatesByDelegator(this.walletAddress);

    }

  },
  computed: {
    walletAddress() {
      return this.$store.state.walletAddress
    },
    isGivenProvider() {
      return this.$store.state.GivenProvider
    },
    filterRanks() {

      return this.ranks.filter((v) => v.address.includes(this.rankValue));
    },
    willGetPgvt() {
      if (this.getPGVTForm.value && this.getPGVTForm.rate) {
        return new Decimal(+this.getPGVTForm.value).times(this.getPGVTForm.rate).toString();
      }
      return '';
    },
    keyword() {
      return this.$t("message.homeHeader")
    },
    description() {
      return this.$t("message.infrastructure")
    },
    BigNumber() {
      return function (val) {
        return new BigNumber(val).toNumber()
      }
    },
    theColumns() {
      let that = this
      return [
        {
          key: 'id',
          titleAlign: "center",
          align: "center",
          title: "#",
          render(row, rowIndex) {
            return h('div', {}, {
                  default: () =>
                      rowIndex
                }
            )
          }
        },
        {
          title: this.$t('message.votingAddress'),
          key: "delegator",
          titleAlign: "center",
          align: "center",
          render(row) {
            return h('div', {}, {
                  default: () =>
                      row.delegator ? that.$Public.account(row.delegator) : "-"

                }
            )
          }
        },
        {
          title: this.$t('message.numberOfVotes'),
          key: "amount",
          titleAlign: "center",
          align: "center",
          render(row) {
            return h('div', {}, {
                  default: () =>
                      row.amount ? formWei(row.amount) : "-"
                }
            )
          }
        },
        {
          title: this.$t('message.votingTime'),
          key: "blockTimestamp",
          titleAlign: "center",
          align: "center",
          render(row) {
            return h('div', {}, {
                  default: () =>
                      row.blockTimestamp ? that.$Public.timeStampToDate(row.blockTimestamp || "") : "-"
                }
            )
          }

        },
      ]
    },
    // 要求head是数组，数组中的每个元素是对象，并且每个对象都有label属性
    // 用来作为csv文件的表头
    headerLabel() {
      let result;
      result = this.header.length ? this.header.map((item) => {
        console.log(item.title)
        return item.name;
      }) : "";
      result = result.join(",");
      return result;
    },
    //用来获取对应的值
    headerProp() {
      let result;
      result = this.header.length ? this.header.map((item) => {
        return item.label;
      }) : "";
      return result;
    },
  },
  watch: {
    'returnPGVTForm.returnAmount'(val) {
      this.returnPGVTForm.returnAmount = val
    },
    'exchange.value'(val) {
      this.exchange.value = val
    },
    ulShow(val) {
      this.ulShow = val
    },
    voteForm: {
      handler(val,) {
        console.log("nftList----val", val)
        this.voteForm = val
      },
      // 这里是关键，代表递归监听 items 的变化
      deep: true
    },
  },
  methods: {
    getBolck(address) {
      return this.filterRanks.find((x) => x.address === Web3.utils.toChecksumAddress(address))?.blockProduced || 0;
    },
    //资产列表
    walletList() {
      if (getStore("PEGO_TOKEN")) {
        api.walletList().then((res) => {
          if (res.code === 200) {
            this.rewardList = res.data
          }
        });

        // api.getBlockNum().then((res) => {
        //   if (res.code === 200) {
        //     this.BlockNum = res.data.blockNum
        //   }
        // });
      }
    },
    //领取
    async getWithdraw(item) {
      let that = this
      if (getStore("PEGO_TOKEN")) {
        this.$store.commit('isTranModal', true)
        api.getWithdrawCoin({coin: item.coinCode}).then((res) => {
          if (res.code === 200) {
            getTokenWithdraw(Web3.utils.toWei(res.data.amount), item.address, res.data.sign, this.walletAddress).then(e => {
              if (e) {
                this.$store.commit('tranStatus', 2)
                this.$store.commit('isTranModal', true)
                that.walletList()
              } else {
                this.$store.commit('tranStatus', 1)
                this.$store.commit('isTranModal', true)
              }
            })

          } else {
            this.$store.commit('tranStatus', 1)
            this.$store.commit('isTranModal', true)
            // that.msg.error(res.message);
          }
        });
      }
    },
    exchangeClack(val) {
      console.log('Number(val)', Number(val) % 1 === 0)
      this.exchange.value = Number(val).toFixed(0) ? Number(val).toFixed(0) : 0
    },
    async staking() {
      if (this.exchange.value % 1 === 0) {
        this.exchangeShow = false
        this.$store.commit('isTranModal', true)
        try {
          const spender = nftPnnAdder;
          const {approve, allowance} = erc20(tokenAddressAdder);
          const n = await allowance(this.walletAddress, spender);
          if (+n < +this.exchange.value) {
            await approve(spender, '1000000', this.walletAddress);
          }
          await staking(this.exchange.value.toString(), this.walletAddress);
          this.$store.commit('tranStatus', 2)
          this.$store.commit('isTranModal', true)
        } catch (e) {
          console.log('staking', e);
          this.$store.commit('tranStatus', 1)
          this.$store.commit('isTranModal', true)
        }

        await window.location.reload()
        // this.$store.commit('isTranModal', false)
      } else {
        this.msg.error(this.$t(`message.EnterAsAnInteger`))
      }

    },
    async openExchange() {
      this.valiModal = false
      this.exchangeShow = true;
      const {balanceOf} = await erc20(tokenAddressAdder);
      this.exchange.balance = await balanceOf(this.walletAddress);
    },
    //获取区块高度倒计时
    async getBlock() {
      const block = await currenBlock();
      const b = block % 17280;
      const endTime = new Date().getTime() + (17280 - b) * 5000;
      // const b = block % 200;
      // const endTime = new Date().getTime() + (200 - b) * 5000;
      console.log(`本轮剩余区块高度: ${17280 - b}`);
      if (this.timers) clearInterval(this.timers);
      this.timers = setInterval(() => {
        this.initNextTime(endTime);
      }, 1000);
    },
    // 提交成为节点
    async register() {
      this.applyModal = false;
      this.statusModal = true
      this.actionStatus = 1;
      try {
        await registerToValidator(this.walletAddress, this.nftList[0]);
        await this.init();
        this.actionStatus = 2;
      } catch (e) {
        console.log('register', e)
        this.actionStatus = 3;
      }
    },
    lodiongs() {
      let that = this
      that.quitShow = true
    },
    async delegates(num) {
      this.dataLoading = true
      num ? this.pageIndex++ : this.pageIndex--
      // const validator = "0x7b8B3256Cdc38361D9F1Ee7Fc0358A4913B1F5CD"
      let that = this
      const client = await new ApolloClient({
        uri: this.APIURL,
        cache: new InMemoryCache(),
      })
      await client.query({
        query: gql(this.tokensQuery),
        variables: {
          first: that.first,
          skip: (that.pageIndex - 1) * that.first,
          address: that.walletAddress,
        },
      }).then((data) => {
        this.dataLoading = false
        this.delegateEntities = data.data.delegateEntities.filter(item => item.amount !== "0")
        // this.delegateEntities = data.data.delegateEntities
        this.pageSize = data.data.delegateEntities.length < that.first ? false : true
        console.log('Subgraph data: ', data)
      }).catch((err) => {
        this.dataLoading = false
        console.log('Error fetching data: ', err)
      })
    },
    async theList() {
      this.theListShow = true
      await this.delegates(true)
    },
    theListUpdate() {
      this.theListShow = false
      this.pageIndex = 0
      this.pageSize = true
    },
    //  取消退出
    doNotQuit() {
      this.quitShow = !this.quitShow
    },
    //  退出节点竞选(仅节点地址)
    async quit() {
      this.quitShow = !this.quitShow
      this.$store.commit('isTranModal', true)
      try {
        await cancelValidator(this.walletAddress);

      } catch {
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
      }
      this.$store.commit('isTranModal', false)
      await this.init();
    },

    // 提取奖励
    async handleClaimReward() {
      this.$store.commit('isTranModal', true)
      try {
        await claimReward(this.walletAddress);
      } catch {
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
      }
      this.$store.commit('isTranModal', false)
      await this.init();
    },

    handleSelect(obt, item) {
      this.fromBalance = 0;
      this.getPGVTForm.value = '';
      this.wpgBalnce = item.balance
      this.getPGVTForm.address = item.address;
      this.getPGVTForm.currency = item.currency;
      this.getPGVTForm.rate = item.rate;
      this.getBalanceNum(item.address, item.currency !== 'PG', item.lp);
    },
    async returnPGVTChangeFromCurrency(item) {
      this.ulShow = !this.ulShow;
      this.returnPGVTForm.used = 0;
      this.returnPGVTForm.address = item.address;
      this.returnPGVTForm.currency = item.currency;
      this.returnPGVTForm.used = await getStakingAmount(item.address, this.walletAddress);
      this.returnPGVTForm.returnAmount = await stakingPgvtAmount(item.address, this.walletAddress);
      const s = await getFarmingTokenLockTime(this.returnPGVTForm.address, this.walletAddress);
      console.log(`归还PGVT冷却时间: ${new Date(+`${s}000`)}`);
      const time = moment(Number(`${s}000`)).diff(moment());
      this.returnPeriodEndTime = time > 0 ? time : 0;
      console.log(`归还PGVT冷却时间: ${this.returnPeriodEndTime}`);
      this.down(this.returnPeriodEndTime, 'returnPeriodEndTime');
    },
    async getBalanceNum(address, isToken = false, lp) {
      if (isToken) {
        const {balanceOf: balance} = erc20(WPG);
        const {balanceOf: balance2} = erc20(address);
        this.fromBalance2 = await balance(lp); //池子总数
        this.fromBalance = await balance2(this.walletAddress);
      } else {
        this.fromBalance = await getBalance(this.walletAddress);
      }
    },
    async init() {
      const address = this.walletAddress;
      this.balance = await getBalance(address);
      this.fromBalance = this.balance;
      this.reward = await getUnClaimedReward(address);
      this.pgvtBalance = await balanceOf(address);
      this.validatorStatus = await validatorStatus(address);
      // this.$emit('validatorStatus', this.validatorStatus);
      const tokens = await supportFarmToken();
      // const tokens =['0xD3Af36CB864a02e14B962616c42963d9E42d1c36'];
      const newTokens = [];
      for (let i = 0; i < tokens.length; i += 1) {
        const v = tokens[i];
        const tokenLp = await getTokenLp(v)
        const tokenAdeeer = await token0(tokenLp)
        const token1Adeeer = await token1(tokenLp)
        const {getSymbol} = erc20(v);
        const {getSymbol: getTokenSymbol0} = erc20(tokenAdeeer);
        const {getSymbol: getTokenSymbol1} = erc20(token1Adeeer);
        const tokneSymbol0 = await getTokenSymbol0()
        const tokneSymbol1 = await getTokenSymbol1()
        // eslint-disable-next-line no-await-in-loop
        const symbol = await getSymbol();
        // eslint-disable-next-line no-await-in-loop
        const rate = await getFarmingTokenPrice(v);
        const {balanceOf: balance} = erc20(WPG);
        const aunt = await balance(tokenLp); //池子总数
        console.log("-------余额", aunt)
        newTokens.push({
          lp: tokenLp,
          address: v,
          balance: aunt,
          // currency: symbol,
          currency: tokneSymbol0 ? tokneSymbol1 ? symbol + '(' + tokneSymbol0 + '/' + tokneSymbol1 + ')' : symbol + '(' + tokneSymbol0 + ')' : tokneSymbol1 ? symbol + '(' + tokneSymbol1 + ')' : symbol,
          rate,
        });
      }
      newTokens.unshift({currency: 'PG', address: PGAdder, rate: '1',});
      this.tokens = newTokens;
      console.log("---this.tokens---", this.tokens)
    },

    //获取pgvt
    async getPgvt() {
      this.GetShow = !this.GetShow
      this.$store.commit('isTranModal', true)
      try {
        if (this.getPGVTForm.currency === 'PG') {
          await stakingPg(this.walletAddress, this.getPGVTForm.value);
        } else {
          const spender = pgvtAdder;
          const {allowance, approve} = erc20(this.getPGVTForm.address);
          const amount = await allowance(this.walletAddress, spender);
          if (+amount < +this.getPGVTForm.value) {
            await approve(spender, '1000000', this.walletAddress);
          }
          await stakingFarmingToken(this.getPGVTForm.address, this.getPGVTForm.value.toString(), this.walletAddress);
        }
      } catch (e) {
        console.log(e);
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
      }
      this.$store.commit('isTranModal', false)
      await this.init();
    },
    //   打开归还PGVT弹窗
    async retPG() {
      this.retuShow = true
      this.returnPGVTForm.used = await getStakingAmount(this.returnPGVTForm.address, this.walletAddress);
      this.returnPGVTForm.returnAmount = await stakingPgvtAmount(this.returnPGVTForm.address, this.walletAddress);
      const s = await getFarmingTokenLockTime(this.returnPGVTForm.address, this.walletAddress);
      console.log(`归还PGVT冷却时间: ${new Date(+`${s}000`)}`);
      const time = moment(Number(`${s}000`)).diff(moment());
      this.returnPeriodEndTime = time > 0 ? time : 0;
      console.log(`归还PGVT冷却时间: ${this.returnPeriodEndTime}`);
      this.down(this.returnPeriodEndTime, 'returnPeriodEndTime');
    },
    //  取消投票
    async handleWithdraw() {
      this.refundShow = !this.refundShow
      this.$store.commit('isTranModal', true)
      try {
        await undelegate(this.withdrawForm.address, this.withdrawForm.value.toString(), this.walletAddress);
      } catch {
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
      }
      this.$store.commit('isTranModal', false)
      await this.init();
      await window.location.reload()
    },
    // 归还PGVT
    async handleReturnPGVT() {
      this.retuShow = !this.retuShow
      this.$store.commit('isTranModal', true)
      console.log("归还PGVT",)
      try {
        await unStaking(this.returnPGVTForm.address, this.walletAddress);
      } catch (e) {
        console.log(e);
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
      }
      this.$store.commit('isTranModal', false)
      await this.init();
      await window.location.reload()
    },
    // 投票
    async handleDelegate() {
      this.voteShow = !this.voteShow
      this.$store.commit('isTranModal', true)
      try {
        await delegate(this.voteForm.address, this.voteForm.value.toString(), this.walletAddress);
      } catch (err) {
        console.log("00000====", err)
        this.$store.commit('tranStatus', 1)
        this.$store.commit('isTranModal', true)
      }
      await this.init();
      this.$store.commit('isTranModal', false)
      await window.location.reload()

    },
    // 打开投票弹窗
    voteClick(addr) {
      this.voteShow = !this.voteShow
      this.voteForm.value = this.pgvtBalance
      this.voteForm.address = addr;
    },
    //  退票
    async refundClick(validatorAddr, myDelegate) {
      this.refundShow = !this.refundShow
      this.withdrawForm.value = null;
      this.myDelegate = myDelegate
      const s = await getPendingUndelegateTime(this.walletAddress, validatorAddr);
      console.log(s)
      const time = moment(Number(`${s}000`)).diff(moment());
      this.withdrawPeriodEndTime = time > 0 ? time : 0;
      this.down(this.withdrawPeriodEndTime, 'withdrawPeriodEndTime');
      this.withdrawForm.address = validatorAddr;
      this.tokenInfo = await balanceOfVT(this.walletAddress);
    },
    down(time, key) {
      let diffTime = time;
      const that = this;
      if (this.timer) {
        clearInterval(this.timer);
      }

      function repack(timezone) {
        const t = timezone / 1000;
        const d = Math.floor(t / (24 * 3600)); // 剩余天数，如果需要可以自行补上
        const h = Math.floor((t - 24 * 3600 * d) / 3600); // 不需要天数，把天数转换成小时
        const m = Math.floor((t - 24 * 3600 * d - h * 3600) / 60);
        const s = Math.floor((t - 24 * 3600 * d - h * 3600 - m * 60));

        that.d = String(d).length === 1 ? `0${String(d)}` : String(d);
        that.h = String(h).length === 1 ? `0${String(h)}` : String(h);
        that.m = String(m).length === 1 ? `0${String(m)}` : String(m);
        that.s = String(s).length === 1 ? `0${String(s)}` : String(s);
      }

      this.timer = setInterval(() => {
        if (diffTime <= 0) {
          this[key] = 0;
          clearInterval(this.timer);
        } else {
          repack(diffTime);
          diffTime -= 1000;
        }
      }, 1000);
    },
    initNextTime(endTime) {
      const time = moment(endTime).diff(moment());
      if (time <= 0) {
        this.getBlock();
        return;
      }
      const t = time / 1000;
      const d = Math.floor(t / (24 * 3600)); // 剩余天数，如果需要可以自行补上
      const h = Math.floor((t - 24 * 3600 * d) / 3600); // 小时
      const m = Math.floor((t - 24 * 3600 * d - h * 3600) / 60);
      const s = Math.floor((t - 24 * 3600 * d - h * 3600 - m * 60));

      // this.d = String(d).length === 1 ? `0${String(d)}` : String(d);
      this.hh = String(h).length === 1 ? `0${String(h)}` : String(h);
      this.mm = String(m).length === 1 ? `0${String(m)}` : String(m);
      this.ss = String(s).length === 1 ? `0${String(s)}` : String(s);
    },
    async downloadFile() {
      let that = this
      // const validator = "0x7b8B3256Cdc38361D9F1Ee7Fc0358A4913B1F5CD"
      const client = await new ApolloClient({
        uri: this.APIURL,
        cache: new InMemoryCache(),
      })
      await client.query({
        query: gql(this.tokensQuery),
        variables: {
          first: 1000,
          address: that.walletAddress,
        },
      }).then((data) => {
        that.downloadEntities = data.data.delegateEntities
        that.xiazai()
        console.log('Subgraph data: ', data)
      }).catch((err) => {
        console.log('Error fetching data: ', err)
      })
    },
    xiazai() {
      let that = this
      var csvContent = "data:text/csv;charset=utf-8,\ufeff";
      //表头数据
      csvContent += this.headerLabel + "\n";
      //遍历list将数据排列好
      this.downloadEntities.forEach((item, index) => {
        let dataString = "";
        this.headerProp.forEach((i) => {
          if (i === "blockTimestamp") {
            const time = that.$Public.timeStampToDate(item[i] || "")
            dataString += time + ",";
          } else if (i === "amount") {
            const amount = item[i] ? formWei(item[i]) : ""
            dataString += amount + ",";
          } else {
            dataString += item[i] + ",";
          }
        });
        //如果没到最后一条数据就换行添加，如果是最后一条就直接添加
        csvContent +=
            index < this.downloadEntities.length
                ? dataString.replace(/,$/, "\n")
                : dataString;
      });
      //创建a标签，并添加到文档中
      let a = document.createElement("a");
      document.body.appendChild(a);
      //文件href指向我们处理好的csv数据
      a.href = encodeURI(csvContent);
      //设置下载的文件名
      a.download = this.$t('message.votingRecord');
      //触发a标签的点击事件，进行下载
      a.click();
    },
  },
  beforeUnmount() {
    if (this.timer) clearInterval(this.timer);
    if (this.timers) clearInterval(this.timers);
  },
}
</script>

<style scoped lang="less">
@import "@/components/modals.less";

.conts {
  color: #ffffff;
  background: #17181A;
  background: url("../assets/PEGO/bgjojn.svg") no-repeat 100%;
  background-position: right 30px bottom 0;
  background-size: 672px 672px;
  height: 100%;
}

.bakg {
  background: url("../assets/PEGO/bag1.svg") no-repeat;
  padding: 0 0 0 100px;
  background-size: 800px;
  height: 100%;
}

.header-one {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 90px 0 100px 0;
  background: url("@/assets/PEGO/pego_validator.png") no-repeat right 80px top 67px, url("@/assets/PEGO/bgscan.png") no-repeat right 13% top 300px;
  background-size: 470px 466.03px, 398px 374px;

  .head-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      width: 100%;
      font-family: BOLD, sans-serif;
      text-align: left;
      margin: 0 0 5px !important;
    }


  }


  .slider_img {
    width: 470px;
    height: 466.03px;
    object-fit: contain;
  }

}

.text-dev {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  font-family: LIGHT, sans-serif;;
  width: 74%;
  color: #ffffff;
}

.but-ul {
  padding-top: 75px;

  .buts {
    background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    margin-right: 20px;
    min-width: 194px;
    height: 54px;
    color: #ffffff;
    font-family: RREGULAR, sans-serif;
  }

  button:active {
    animation: zoomOut; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.5s;
  }

  .but-dis {
    background: linear-gradient(90deg, rgba(255, 122, 0, 0.5) 0%, rgba(255, 184, 0, 0.5) 100%);
    margin-right: 20px;
    min-width: 194px;
    height: 54px;
    color: #ffffff;
    font-family: RREGULAR, sans-serif;
  }

  .but-dis:active {
    animation: none !important; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0s;
  }
}

.pad {
  padding-bottom: 180px;
  border-top: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 122, 0, 0.1) 0%, rgba(255, 184, 0, 0.08) 40%, rgba(56, 57, 58, 0.05) 50%);
  border-image-slice: 1;
}


.cand-ti {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  text-align: center;
  background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //background-clip: text;
  //text-fill-color: transparent;
  display: inline-block;
  font-family: BOLD, sans-serif;
  padding: 60px 0;
}

.accounts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  position: relative;
  margin-bottom: 15px;

  div {
    font-family: 'REGULAR', sans-serif;
    font-size: 18px;
    color: #FF9D00;
    display: flex;
    align-items: center;

  }

  .sta-val {
    margin-left: 30px;

    span {
      border: 1px #797979 solid;
      font-weight: 400;
      font-size: 10px;
      color: #797979;
      padding: 5px 20px;
      border-radius: 20.6897px;
    }

    .sue {
      background: #17D07C;
      border: none;
      color: #ffffff;
    }

    .err {
      background: #FF706A;
      border: none;
      color: #ffffff;
    }
  }
}

.sea-h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cks {
    padding-top: 15px;
  }
}

.seachs {
  --n-border-hover: 1px solid rgba(255, 255, 255, 1) !important;
  --n-border-focus: 1px solid rgba(255, 255, 255, 1) !important;
  --n-caret-color: #FFFFFF !important;
  --n-border: 1px solid rgba(255, 255, 255, 0.3) !important;
  width: 500px;
  height: 50px;
  line-height: 50px;
  background: #2C2F34 !important;
  border-radius: 4px;
  caret-color: #2C2F34 !important;
  color: white !important;
  font-size: 20px;

  ///deep/ .n-input__input-el {
  :deep(.n-input__input-el) {
    color: #ffffff !important;
    caret-color: #FFFFFF !important;
    -webkit-appearance: none;
    scrollbar-width: none;
    width: 100%;
    min-width: 0;
    height: 50px;
    line-height: 50px;
    background-color: transparent;
  }

  .icons {
    font-size: 20px !important;
  }
}

.ti-card {
  text-align: left;
  padding: 0 100px;
  height: 100% !important;

  .li_tet {
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    padding: 30px;

    .dis_li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .txt {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        height: 45px;
        font-family: SEMIBOLD, sans-serif;
      }

      img {
        //width: 31px;
        height: 40px;
        object-fit: contain;

      }
    }

    .numb {
      font-style: normal;
      font-weight: 300;
      font-size: 30px;
      font-family: 'LIGHT', sans-serif;

    }

    .numBut {
      margin-top: 30px;
      //width: 110px;
    }

    .numButs {
      margin-top: 30px;
      margin-left: 20px;
      //width: 110px;
      background: #FFFFFF;
      color: black;
    }

  }

  .grd-rd {
    height: 60px;
    line-height: 60px;
    background: #23262C;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    //padding-left: 80px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    font-family: REGULAR, sans-serif;

    .grid-item:nth-child(1) {
      padding-right: 4px;
      box-sizing: border-box;
    }
  }

  .node {
    //.grid-item:nth-child(1) {
    //  padding-right: 100px;
    //  box-sizing: border-box;
    //  text-align: right;
    //}
    //
    //.grid-item:nth-child(3) {
    //  text-align: left;
    //  padding-left: 100px;
    //}
  }

  .bust-arw {
    text-align: center;
    padding: 35px;

    button {
      font-weight: 400;
      font-size: 16px !important;
      width: 194px;
      height: 54px;
    }
  }

  .tlt {
    font-family: REGULAR, sans-serif;
    color: #C4C5C7;
    font-size: 16px;
  }

  .one-bag {
    border-radius: 0;
    background: rgba(28, 31, 34, 0.5) !important;
    border-bottom: 1px solid #3A3B3D;
    font-family: REGULAR, sans-serif;
    font-size: 16px;
  }

  .bor-bag {
    background: rgba(28, 31, 34, 0.5);
    font-family: REGULAR, sans-serif;
    font-size: 16px;
    border-bottom: 1px solid #3A3B3D;
  }

  .bor-bag:last-child {
    border: none;
    border-radius: 0 0 16px 16px;
  }

  .status {
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    text-align: center;
    padding: 35px 0;

    .sta-li {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      font-family: SEMIBOLD, sans-serif;
    }

    .sta-ti {
      font-style: normal;
      font-weight: 300;
      font-size: 50px;
      font-family: LIGHT, sans-serif;
    }
  }
}

.rank {
  text-align: left;
  padding: 0 100px;
  height: 100% !important;

  .grd-rd {
    line-height: 60px;
    background: #23262C;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    //padding-left: 80px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    font-family: REGULAR, sans-serif;
    text-align: center;

    .grid-item {
      position: relative;
      left: -10px;
    }

    .grid-item:nth-child(2) {
      color: #FF9D00;
    }
  }

  .one-bag {
    border-radius: 0;
    background: rgba(28, 31, 34, 0.5) !important;
    border-bottom: 1px solid #3A3B3D;
    font-family: REGULAR, sans-serif;
    font-size: 16px;
    border-bottom: 1px solid #3A3B3D;

  }

  .one-bag:last-child {
    border-radius: 0;
    background: rgba(28, 31, 34, 0.5);
    border-bottom: none;
    font-family: REGULAR, sans-serif;
    font-size: 16px;
  }
}

.nubt {
  background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
  color: #ffffff;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  padding: 0 15px;
}

.ranks {
  //padding-left: 40px !important;
  height: 100%;


}

.tables {
  margin: 30px 0;
  max-height: 60vh !important;
  overflow-y: scroll;
}

.tables::-webkit-scrollbar {
  width: 5px;
}

.tables::-webkit-scrollbar-track {
  background-color: black;
}

.tables::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 25px;
}

.but-dist {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:nth-child(1) {
    margin-right: 20px;
  }

  .dist-f {
    display: flex;
  }

  .download {
    cursor: pointer;
    color: #ffffff;
    //font-size: 20px;
    text-decoration: underline;
  }
}

</style>
