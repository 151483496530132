export default {
    message: {
        Product: '主頁',
        Network: '治理',
        Build: '生態',
        PEGOScan: '節點競選',
        bridge: '跨鏈橋',
        Proposal: 'DAO',
        browser: '區塊瀏覽器',
        BecomeAValidator: '成為驗證人',
        wallet: '錢包',
        news: '新聞',
        routeMap: '發展線路',
        foundation: '基金會',
        EN: 'English',
        ZN: '繁體中文',
        JP: '日本語',
        KO: '한국인',
        TH: 'แบบไทย',
        VI: 'Tiếng Việt',
        Whitepaper: '白皮書',
        Blog: '博客',
        Foundation: '基金會',
        blockchain: "為每個人打造的<span class='nameColor'>{name}</span>",
        name: '區塊鏈',
        Support: '支持高自治性、可擴展性和可持續性的去中心化應用程序。 ',
        scalability: '可擴展性',
        solution: '面向Web3和AI開發的高性能解決方案。 ',
        EnterTheDAPP: '白皮書',
        ReadDocs: '閱讀文檔',
        GITHUB: 'GITHUB',
        MainnetLaunchCountdown: '主網上線倒計時',
        Days: '天',
        Hours: '小時',
        Minutes: '分鐘',
        PEGOSolutions: 'PEGO解決方案',
        PPOS: 'PPOS',
        EnablesSustainable: '通過公平、安全的治理模型實現可持續的生態系統。 ',
        EVMCompatible: '與EVM兼容',
        CompatibleWith: '與Golang、Rust、Solidity和EVM虛擬機兼容，為構建可無縫跨平台工作的dApp的開發者提供友好環境。 ',
        NFTPNNIdentityVerificationSystem: 'NFT-PNN身份驗證系統',
        EnsuresNetwork: '通過PNN質押和PGVT投票，確保網絡的去中心化和安全性。 ',
        CommunityProposedProposals: '社區提議的提案',
        ProvidesFlexibility: '通過社區提議的提案，提供調整網絡參數的靈活性。 ',
        AIIntegration: '人工智能集成',
        IntegratesWith: '與人工智能集成，提升AI應用所需的處理速度和效率，同時保持透明性和安全性。 ',
        DAOGovernment: '生態治理',
        partner: '合作伙伴',
        PEGOIsGoverned: 'PEGO由全球DAO通過其原生加密資產PG進行治理。 ',
        BuyPG: '購買PG',
        PGAllowsWeb3: 'PG允許Web3社區成員參與協議升級提案、驗證PEGO的區塊鏈網絡、質押獲取獎勵、燃燒拍賣等。 ',
        SupportByPEGOFoundation: '由PEGO基金會支持',
        ContactUs: '聯繫我們',
        Government: '治理',
        Validator: '驗證人',
        Wallet: '錢包',
        Ecosystem: '生態系統',
        W3bridge: '跨鏈橋',
        Exchange: '交易所',
        Loan: '借貸',
        W3BANK: 'W3BANK',
        PEGOTechnology: '© 2023 PEGO技術',
        ADAOCommunityBasedPublicChain: '開放，自治，可擴展且可持續的Web3應用層基礎設施',
        Built: `與<span class='nameColor'>{network}</span>共建？`,
        network: "PEGO網絡",
        PEGOHasDesigned: "PEGO設計了一個完全由社區驅動的、自治的Web3基礎設施，所有鏈上參數都可以通過社區提議的方式進行靈活調整。基於PPOS共識機制，建立了一種複合經濟激勵機制，促進PEGO生態系統的可持續發展。此外，PEGO區塊鏈與EVM系統完全兼容，成為開發者構建可擴展的去中心化應用程序的理想選擇。",
        SubmitYourProject: "提交您的項目",
        SubmitYourProjectLink: "http://pegoecosystem.mikecrm.com/vGv1Mtf",
        ApplyForInvestment: "申請投資",
        useCases: "應用場景",
        LoremIpsum: "多樣化的Web3應用是PEGO Network最大的價值，這其中可能隱藏著未來的殺手級應用。",
        CrossChain: "跨鏈",
        CrossChainBrigde: "跨鏈橋",
        JoinTheCommunity: `加入<span class='nameColor'>{Community}</span>`,
        Community: "社區",
        PEGODiscordSupport: "PEGO Discord支持",
        ToBeA: `成為一個<span class='nameColor'>{TOValidator}</span>`,
        TOValidator: "驗證人",
        PEGOIsCommitted: "PEGO致力於打造一個完全由社區驅動的去中心化網絡，驗證人節點的設計對實現這一目標至關重要。通過PPOS共識機制，PEGO承諾在公平機制下，去中心化網絡的所有參與者可以更有效地合作。",
        CONNECT: "連接",
        applyToBe: "申請成為驗證人",
        PEGOValidator: "PEGO驗證人節點的特點是具有特定權益所有權主體的NFT-PNN“身份證”。候選人通過質押PNN並參與正式驗證人節點的選舉，成為PEGO網絡驗證人節點。",
        NFTPNNIDENTITYCARD: "NFT-PNN身份證",
        MyAccount: "我的賬戶",
        VotingReward: "投票獎勵",
        TokenBalance: "代幣餘額",
        VotingPower: "投票",
        VotedValidators: "已投票的驗證人",
        Rank: "排名",
        Address: "地址",
        Status: "狀態",
        MyShare: "我的份額",
        Operate: "操作",
        ValidatorStatus: "驗證人狀態",
        TotalVotes: "總票數",
        NextRound: "下一輪",
        ValidatorRanking: "驗證人排名",
        search: "搜索",
        Ranking: "排名",
        LatestBlock: "最新區塊",
        ValidatedBlocks: "已驗證區塊",
        Votes: "票數",
        Action: "操作",
        Vote: "投票",
        PEGO: `PEGO  <span class='nameColor'>{dao}</span>`,
        dao: "DAO",
        PEGOEmphasizes: "PEGO強調效率，並允許通過社區發起的提案靈活調整鏈上的所有參數。節點或普通用戶可以通過質押一定數量的PG來發起提案。如果在規定的時間內通過投票，提案將通過鏈上交易執行並生效。",
        VotingTaken: '投票進行中',
        Sponsor: "發起人",
        Active: "進行中",
        Executed: "已執行",
        Voted: "已投票",
        SUBMITPROPOSAL: "提交提案",
        OnChainParameters: "鏈上參數",
        PendingTime: "待定時間",
        VotingTime: "投票時間",
        ExecutionTime: "執行時間",
        TotalVotesTotalVt: "總票數/總VT",
        SupportedVotesTotalVotes: "支持票數/總票數",
        StakePG: "質押PG",
        Proposals: "提案",
        SEEALL: "查看全部",
        ThreeStages: `<span class='nameColor'>{PGStrategies}</span> 三階段策略`,
        PGStrategies: "PG策略",
        BasedOnDao: "基於DAO",
        InTheInitial: "在PEGO公鏈的初始發展階段，所有準備工作都基於生態系統中的DAO概念，以實現全民共建、全民共享。此外，生態系統通過DAO治理由所有人共同治理，實現根據自治規則的民主和公平。",
        DAODevelopment: "發展DAO",
        InThePEGO: "在PEGO網絡中，將出現不同類型的DAO，隨著各類生態參與者的增多，對DAO治理的需求將增加。這可能有助於加速區塊鏈的應用。",
        ServingDAO: "服務DAO",
        AllBlockchainBased: "所有基於區塊鏈的DAO都歸社區成員所有。 根據三階段策略，PEGO網絡最終將成為全球首個完全基于DAO治理的公鏈。",
        PEGORoadmap: "PEGO路線圖",
        latestUpdates: "及最新更新",
        TheProposal: "PEGO網絡更新通證經濟模型提案",
        publicChain: "公鏈信息：PEGO網絡 代幣標誌：PG 作為PEGO網絡的治理代幣，PG也在BSC鏈上發行，用於跨鏈交互...",
        TheW3BIWO: "W3B IWO即將開啟",
        theFuture: "W3Bridge的未來將成為碎片化Web3世界的橋樑，這將在PEGO早期生態系統的發展基礎上實現。隨著生態系統的擴展...",
        PEGONetwork: "PEGO網絡推出PN治理代幣",
        toIncentivese: "為了激勵全球的PEGO網絡推廣者，PEGO網絡將推出PN作為其社區的治理代幣。該代幣將應用於激勵...",
        ThePEGOFoundation: `PEGO <span class='nameColor'>{Foundations}</span>`,
        Foundations: '基金會',
        pegoEmphasizes: "PEGO基金会是為建立、支持和發展PEGO網路及其社區所發起的去中心化組織。 该组织由來自於全球各地各領域的行業專家和社区核心志愿者组成。",
        StakePools: "質押池",
        stakePools: "質押池是一種流動質押解決方案，促進了PEGO的去中心化、反審查和DeFi的增長。",
        STAKEPG: "質押PG",
        Grants: "資助",
        thePegp: "PEGP基金會資助計劃專注於資助進一步提高PEGO網絡的抗審查性能。",
        APPLYFORGRANTS: "申請資助",
        HundredFamily: "百家生态2.0",
        HundredFamilyDesc: "全面升级的生态扶持战略，向PEGO链上每一个Web3的明日之星提供全生命周期的资助",
        APPLY100Family: "申请加入",
        APPLY100FamilyLink: "http://pegoecosystem.mikecrm.com/6zHUpZM",
        JOINPEGOFOUNDATION: "加入PEGO基金會",
        Ended: "已結束",
        linkWallet: "鏈接錢包",
        BNBSmartChain: "BNB智能鏈",
        TelegramCommunity: "Telegram社區",
        EnglishCommunity: "英文社區",
        validator: "驗證人",
        quitValidator: "退出節點競選",
        claim: "申領",
        GetPGVT: "獲取PGVT",
        voteValidator: "給節點投票",
        ReturnPGVT: '贖回PG/W3FT',
        ReturnPGVT2: '冷卻期不可贖回',
        ReturnPGVT3: '餘額不足',
        IncreaseVote: '加票',
        Refund: '退票',
        quitTheValidator: '退出節點競選',
        onceQuited: '一旦退出，您將不再獲得PG獎勵！ ',
        andYour: '并且无法收回您的PNN',
        noIDont: '不，我不想退出',
        SureIWant: '確定，我要退出',
        getPGVT: '獲取PGVT',
        pleaseEnter: '請輸入',
        MAX: '最大',
        Balance: '餘額',
        BalancePGVT: 'PGVT餘額',
        returnPGVT: '歸還PGVT',
        youWantTo: '贖回',
        YourBalanceIs: '您的餘額為',
        isYourBalanceIsVT: 'VT 餘額',
        YourBalanceIsVT: '您的VT餘額不足',
        YourBalanceIs1:'已通過此來源鑄造數量',
        YouneedReturn: '您需要歸還',
        CooldownCountdown: '冷卻期',
        Seconds: '秒',
        VoteAmount: '投票數量',
        ValidatorAddress: '驗證人地址',
        Withdraw: '退票',
        Withdraw2: '冷卻期不可退票',
        WithdrawAmount: '撤回票數',
        NoticeYou: '注意：在冷卻期結束後，您將收回您的PGVT。 ',
        ExchangeNFTPNNT: "鑄造NFT-PNNT",
        ValidatorApplication: "驗證人申請",
        SorryYou: "您還未持有PNN-NFT,",
        anyPNNNFT: "請使用PNN進行鑄造",
        // howToGet: "如何獲得NFT-PNN（PEGO-Chain）？",
        // IfYouHave: "如果您擁有PNN-BSC代幣，",
        clickHere: "鑄造",
        // toMint: "來鑄造一枚PNN-NFT（PEGO-Chain）。",
        ConnectWallet: "連接錢包",
        YourPNNNFT: "您的PNN NFT",
        applyToBeAValidator: "申請成為驗證人",
        Transaction: "交易",
        CongratulationsYou: "恭喜！您現在是一個驗證人！",
        JoinOur: '加入我們的',
        discord: 'Discord',
        toGetHow: '了解如何運行驗證人節點。 ',
        Failed: '失敗',
        Pending: '已發起交易',
        MoreAboutDAO: '關於DAO',
        CreatProposal: "創建提案",
        CoolingOffPeriodOfPGVT: "PGVT冷卻期",
        CoolingOffPeriodOfPGW3FT: "PG&W3FT冷卻期",
        ValidatorDistrbution: "驗證人分佈",
        AdditionalPGIssued: "額外發行PG",
        LowestGasFee: "最低燃料費",
        BlockReward: "區塊獎勵",
        ValidatorNumbers: "驗證人數量",
        SupportedToken: "支持的代幣",
        Blacklist: "黑名單",
        TotalVT: "總VT",
        Supports: "支持",
        Invalid: "無效",
        Passed: "已通過",
        Core: "核心",
        ProposalType: "提案類型",
        AdjustThe: "調整提案參數",
        AddOrDelete: "增加或刪除農場代幣以獲取PGVT",
        AdjustTheDistribution: "調整獎勵分配比例",
        BlaDecentralized: "BlaDecentralized基金會將向發起人地址發行額外的PG",
        AdjustTheLowestGasFee: "調整最低燃料費",
        AdjustTheBlockReward: "調整區塊獎勵",
        AdjustThenumberOfValidators: "調整驗證人數量",
        ChoosePDP: "選擇PDP",
        ParametersChanges: "參數變更",
        NowIs: "當前是",
        ExeuctionTime: "執行時間",
        TotalVotesvt: "總票數/總VT",
        SupportedVotesTotalVotess: "支持票數/總票數",
        AddDelete: "增加/刪除",
        AddANewFarmingToken: "增加新的農場代幣",
        DeleteoneFarmingToken: "刪除一個農場代幣",
        TokenAddress: "代幣地址",
        CoolingOffPeriodOf: "冷卻期",
        TheDistributionProportionForValidator: "節點和用戶分配比例，現在用戶佔",
        AddAnAddressToBlacklist: "將地址添加到黑名單",
        DeleteAnAddressOutOfBlacklist: "從黑名單中刪除地址",
        Amount: "數量",
        TheAmountOfPGIssued: "發行的PG數量",
        NumberOfValidators: "驗證人數量",
        YouNeedToStake: "您需要質押",
        makeSureYou: "PG，請確保您有足夠的餘額。",
        PGBalance: "PG餘額",
        FailedToCreateProposal: "創建提案失敗",
        Detail: "詳情",
        StartTime: "開始時間",
        VoteTime: "投票時間",
        TotalVoteToken: "總投票代幣",
        Against: "反對",
        GetBackVT: "收回VT",
        Execute: "執行",
        Metamask: "Metamask",
        DoNotHaveMetamask: "沒有安裝Metamask嗎？",
        Install: "安裝",
        YoureAlreadyAValidator: "您已經是一個驗證人！",
        Error: "錯誤",
        Successful: "成功",
        GetBackPG: "收回PG",
        About: "關於",
        proposal: "提案",
        pegoScan: "區塊瀏覽器",
        other: "關於",
        comingSoon: "即將上線",
        PWGBountyProgram: 'PWG賞金計劃',
        Web3GrantProgram: '即PEGO Web3 Grant計劃，旨在為Web3應用層的新生力量提供資金、技術和資源上的全面支持。 ',
        PWGCanApplyForType: 'PWG可申請類型',
        seedIncentive: '種子激勵型',
        StartupFunding: '為絕佳的Web3創意提供啟動資金',
        financialSupport: '資金扶持',
        otherSupport: '其他扶持',
        ProjectLanding: '項目落地輔導諮詢',
        EcologicalCoCreation: '生態共創型',
        ecosystem: '成為PEGO Web3生態中的重要一環',
        CoCreationSupport: '共創扶持',
        ProvideFull: '提供全程的技術支持，根據其發展需要對接相應的合作資源，併入PEGO生態整體運營活動中。 ',
        SpecialType: 'Layer2＆3專項型',
        ProvideSpecial: '為在PEGO上建立L2及L3的參與者提供專項支持',
        AccelerateSupport: '加速扶持',
        TargetedTechnical: '針對性的技術支持、幫助其搭建核心團隊',
        StrategicInvestment: '戰略投資型',
        MeetTheDevelopment: '符合PEGO生態戰略發展需要',
        strategicSupport: '戰略支持',
        ImplementationOfThe: '全生命週期的落地、戰略、業務運營、技術及商業諮詢，提供全面的團隊搭建和管理支持，對接業內頂級合作資源，後續融資輔導',
        WhatIs100FamilyTitle: '什么是百家生态2.0',
        WhatIs100Family: '百家生态2.0是原有PEGO百家生态的全面升级，项目可以获得全面、多层且更具有针对性的赋能，主要包括针对项目LP赋能从而提升项目价值以及基于PEGO官方生态矩阵提升项目曝光率两个维度。',
        Why100FamilyTitle: '为什么需要百家生态',
        Why100Family: '百家生态重点在于为PEGO生态培养优质项目，繁荣且强力的应用层也是PEGO之于外界最核心的竞争力。优质项目在发展的各个阶段都可以获得最需要的资源支持，获得流动性和用户市场的注入，更快进入爆发期。',
        Type100FamilyTitle: '百家生态2.0等级',
        Type100Family: '观察类，种子类，优质类，强势类和生态核心类',
        Contact100Family: '获得申请入口请联系 TelegramID:',
        Contact100FamilyName: 'PEGOWeb3_business',
        Contact100FamilyLink: 'https://t.me/PEGOWeb3_business',
        VoterDistribution: '用戶佔比',
        homeHeader: 'PEGO Network - The blockchain built for everyone',
        infrastructure: 'Web3 infrastructure. PPOS Consensus. Virtual Machine (VM). DAO Governance. Blockchain tools. PEGO is the blockchain infrastructure for everyone in the Web3 era.',
        CompleteTheMainnetLaunch: '完成主網上線',
        CompleteNodeCampaign: '完成節點競選',
        W3swapLaunched: 'W3swap上線',
        W3WalletIsOnline: 'W3錢包上線',
        ListedOnTheWorld: '上線全球主要交易所',
        StartApplicationEcologicalConstruction: '開啟應用生態建設',
        PromoteMEMEEcologicalConstruction: "推動MEME生態建設",
        PromoteFOMO: "推動FOMO生態建設",
        StarttheGlobal: "開啟全球生態路演",
        AchieveEcological: "實現200個生態上線",
        TheNumberOfValid: "有效錢包數超過100萬",
        TheTotalNumber: "區塊交易總量突破500萬",
        TVLExceeded: "TVL突破1億美金",
        CompleteTheLaunch: '完成全球大部分主流交易所上線',
        AchieveEcologicalOnline: '實現500個生態上線',
        TheNumberOfEffective: '有效錢包數超過200萬',
        heTotalNumberOfBlock: '區塊交易總量突破2000萬',
        TVLExceededBillion: 'TVL突破10億美金',
        Alreadyin: '已存在',
        NotIn: '不在',
        FillInAAddressAlreadyIn: '填寫已經存在的地址',
        FillInTheTokenContractAddress: "填寫代幣合約地址",
        AlreadySupported: "已經支持",
        DecentralizedFoundationWillIssue: "去中心化基金會將增發 PG 至發起人地址",
        GasFee: "燃料數量",
        Add: '增加',
        Delete: '刪除',
        W3swap:'W3swap',
        AMegaPlatform:'結合所有最佳交易解決方案的大型平台',
        W3Wallet:'W3Wallet',
        MultiFunction:'基於PEGO Network生態的多功能Web3跨鏈錢包',
        W3Bridge:"跨鏈橋",
        RebuildTheFragmented:"重建碎片化的 Web3 網絡",
        EnterAsAnInteger:"輸入為正整數",
        NetworkError:"錯誤的網絡",
        nodeReward:"節點獎勵",
        award:"獎勵",
        toBeCollected:"待領取",
        received:"已領取",
        NumberOfBlocks:'出塊數',
        EcologicalDAPP:'生態DAPP',
        receiveAward:'領取',
        pleaseGoToWallet:'請前往錢包切換網絡',
        AuditReport:'審計報告',
        ProposalDescription:'提案說明',
        nodeCard:'鑄造節點卡',
        PDP1:'PDP-1型提案',
        DetermineOr:'用途：用於確定或調整DAO治理提案的一些關鍵參數 ',
        TheAdjustableParameters:'可調整參數如下：提案公示期投票期//執行期/提案需質押PG數量/提案生效1類條件/提案生效2類條件',
        PDP2:'PDP-2型提案',
        decideWhich:'決定哪一類LP生成的農場代幣可以用來質押獲取PGVT（如果LP內PG數量低於500000，提案通過後也不可鑄造PGVT）',
        PDP3:'PDP-3型提案',
        adjustTheCooling:'用於確定和調整PGVT鑄造贖回冷卻期和VT投票贖回冷卻期時長',
        PDP4:'PDP-4型提案',
        usedToDetermine:'用於確定和調整節點、投票人獎勵分配比例',
        PDP5:'PDP-5型提案',
        controlMalicious:'決定對某些作惡合約和賬戶實施凍結或風控措施，快速控制和消除作惡行為的不良影響',
        PDP6:'PDP-6型提案',
        controlMaliciousContracts:'用於增發PG的，任何用戶都可以發起提案',
        PDP7:'PDP-7型提案',
        usedToAdjust:'用於調整鏈上最低Gas fee，以適應不同階段需求',
        PDP8:'PDP-8型提案',
        adjustTheBlock:'調整塊獎勵的數值',
        PDP9:'PDP-9型提案',
        adjustTheNumber:'調整驗證節點上線數量',
        BitKeep:'BitKeep',
        TOKENPOCKET:'TOKEN POCKET',
        OKXWallet:'OKX Wallet',

        //6-13
        votingAddress:'投票地址',
        numberOfVotes:'投票數量',
        votingTime:'投票時間',
        votingList:'投票列表',
        ExploreThePEGOEcosystem:'探索PEGO生態系統',
        submitProject:'提交項目',
        allItems:'所有項目',
        all:'全部',
        startSearching:'開始探索',
        audit:'審計',
        CreatingAndOwning:'創建並且擁有一個錢包是連接PEGO，進入Web3的第一步，請按步驟創建或連接錢包。記住務必安全保管錢包私鑰和助記詞。',
        OpeningMetamask:"正在打開 Metamask",
        PleaseConfirmInMetamask:"請在 Metamask 中確認",
        OpeningBitkeep:"正在打開Bitkeep",
        OpeningOKXWallet:"正在打開 OKX",
        NotInstalled:"未安裝",
        EcologicalCooperationApplicationForm:"生態合作申請表",
        WelcomeToJoin:"歡迎加入，提交資料後我們將盡快聯繫您，在此之前請務必將以下信息填寫完整。",
        PEGOEcologicalBusiness:"PEGO生態商務合作官方聯繫人ID",
        Telegram:"電報",
        Btok:"托克",
        projectName:"項目名稱",
        projectLogo:"項目logo",
        ImageFormatsLike:"支持 .jpg、.png、.gif、.bmp、.psd、.tiff 等圖像格式。",
        ChooseImage:'選擇圖像',
        Lessthan:'小於 500MB',
        ProjectDescription:'項目簡介',
        itemCategory:'項目類別或定位',
        anyOtherType:'DEX、NFT或其他任何類型',
        ProjectBenefits:'項目優勢',
        WhatCanItBringToThe:'能為PEGO生態帶來什麼？',
        ProjectInfluence:'項目影響力',
        WhatKindOfCooperation:'您想與PEGO生態進行哪方面的合作？',
        TokenListedOn:'代幣上架W3跨鏈橋？',
        TokenListedOnW3swap:'代幣上架W3swap',
        TokensListedOnW3Wallet:'代幣上架W3錢包',
        TokenListingMappingTool:'代幣上架映射工具',
        TokenListedOnLunchpadHomepage:'代幣上架Lunchpad首頁',
        TokenOpening:'代幣開通BTOK-PEGO鏈代幣打榜/社區紅包',
        TheTokenIsListed:'代幣上架AVE-PEGO板塊',
        TheProjectSeeks:'項目尋求技術、資金及其他資源支持',
        TheApplicationEnters:'應用入駐PEGO公鏈',
        Other:'其他',
        ProjectEconomicModel:'項目經濟模型',
        ProjectInformation:'項目資料',
        ProjectPPTAndAny:'可上傳項目PPT及其他任何項目相關資料',
        ProjectOfficialWebsite:'項目官網',
        ProjectTwitter:'項目Twitter',
        ProjectTelegramCommunity:'項目Telegram社群',
        ProjectDiscordCommunity:'項目Discord社群',
        ProjectGithub:'項目Github',
        ContactTelegramID:'聯繫人電報ID',
        Submit:'提交',
        PleaseEnterAKeyword:'請輸入關鍵字 ',
        downloadFile:"下載",
        votingRecord:"投票記錄.csv",
        previousPage:"上一页",
        nextPpage:"下一页",
        ReturnPGVTWPG:"無法鑄造",
        officialEmail:'官方郵箱',
        businessCooperation:'商務合作',
        copy:'複製成功',
    },

}
