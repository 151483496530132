export default {
    message: {
        Product: 'Trang chủ',
        Network: 'Quản trị',
        Build: 'Hệ sinh thái',
        PEGOScan: 'Cuộc đua nút',
        bridge: 'Cầu',
        Proposal: 'DAO',
        browser: 'Trình duyệt',
        BecomeAValidator: 'Trở thành một người xác minh',
        wallet: 'Ví',
        news: 'Tin tức',
        routeMap: 'Bản đồ tuyến đường',
        foundation: 'Quỹ',
        EN: 'English',
        ZN: '繁体中文',
        JP: '日本語',
        KO: '한국인',
        TH: 'แบบไทย',
        VI: 'Tiếng Việt',
        Whitepaper: 'Sách trắng',
        Blog: 'Blog',
        Foundation: 'Quỹ',
        blockchain: "dành cho mọi người<span class='nameColor'>{name}</span>",
        name: 'Blockchain (Chuỗi khối)',
        Support: 'Hỗ trợ ứng dụng phi tập trung có tính tự quản, mở rộng và bền vững.',
        scalability: 'Khả năng mở rộng',
        solution: 'Giải pháp hiệu suất cao dành cho Web3 và trí tuệ nhân tạo.',
        EnterTheDAPP: 'Sách trắng',
        ReadDocs: 'Đọc tài liệu',
        GITHUB: 'GITHUB',
        MainnetLaunchCountdown: 'Đếm ngược đến ngày ra mắt mainnet',
        Days: 'Ngày',
        Hours: 'Giờ',
        Minutes: 'Phút',
        PEGOSolutions: 'Các giải pháp PEGO',
        PPOS: 'PPOS',
        EnablesSustainable: 'Tạo ra một hệ sinh thái bền vững thông qua mô hình quản trị công bằng và an toàn.',
        EVMCompatible: 'Tương thích với EVM',
        CompatibleWith: 'Tương thích với Golang, Rust, Solidity và máy ảo EVM, tạo môi trường thân thiện cho các nhà phát triển xây dựng dApp có thể hoạt động trên nhiều nền tảng.',
        NFTPNNIdentityVerificationSystem: 'Hệ thống xác minh danh tính NFT-PNN',
        EnsuresNetwork: 'Đảm bảo tính phi tập trung và an ninh của mạng thông qua việc thế chấp PNN và bỏ phiếu PGVT.',
        CommunityProposedProposals: 'Các đề xuất từ cộng đồng',
        ProvidesFlexibility: 'Cung cấp tính linh hoạt trong việc điều chỉnh các tham số mạng thông qua các đề xuất từ cộng đồng.',
        AIIntegration: 'Tích hợp Trí tuệ nhân tạo',
        IntegratesWith: 'Tích hợp với Trí tuệ nhân tạo để nâng cao tốc độ và hiệu suất xử lý cho các ứng dụng AI, đồng thời đảm bảo tính minh bạch và an toàn.',
        DAOGovernment: 'Quản trị DAO',
        partner: 'Partner',
        PEGOIsGoverned: 'PEGO được quản trị bởi DAO toàn cầu thông qua tài sản mã hóa gốc của nó là PG.',
        BuyPG: 'Mua PG',
        PGAllowsWeb3: 'PG cho phép cộng đồng Web3 tham gia các đề xuất nâng cấp giao thức, xác minh mạng blockchain PEGO, tham gia thế chấp và nhận phần thưởng, cũng như đấu giá đốt cháy.',
        SupportByPEGOFoundation: 'Được hỗ trợ bởi Quỹ PEGO',
        ContactUs: 'Liên hệ chúng tôi',
        Government: 'Quản trị',
        Validator: 'Người xác minh',
        Wallet: 'Ví',
        Ecosystem: 'Hệ sinh thái',
        W3bridge: 'Cầu W3',
        Exchange: 'Sàn giao dịch',
        Loan: 'Khoản vay',
        W3BANK: 'Ngân hàng W3',
        PEGOTechnology: '© 2023 Công nghệ PEGO',
        ADAOCommunityBasedPublicChain: 'Hạ tầng ứng dụng Web3 dựa trên cộng đồng, tự trị, mở rộng và bền vững',
        Built: `Xây dựng cùng với<span class='nameColor'>{network}</span>?`,
        network: "Mạng PEGO",
        PEGOHasDesigned: "PEGO đã thiết kế một cơ sở hạ tầng Web3 hoàn toàn được cộng đồng điều hành và tự trị, mọi tham số trên chuỗi có thể linh hoạt điều chỉnh thông qua đề xuất từ cộng đồng. Dựa trên cơ chế đồng thuận PPOS, đã xây dựng một cơ chế kích thích kinh tế phức hợp, thúc đẩy sự phát triển bền vững của hệ sinh thái PEGO. Ngoài ra, blockchain PEGO hoàn toàn tương thích với hệ thống EVM, là lựa chọn lý tưởng cho nhà phát triển xây dựng các ứng dụng phi tập trung có khả năng mở rộng.",
        SubmitYourProject: "Gửi dự án của bạn",
        SubmitYourProjectLink: "",
        ApplyForInvestment: "Đăng ký đầu tư",
        useCases: "Các trường hợp sử dụng",
        LoremIpsum: "Các ứng dụng Web3 đa dạng là giá trị lớn nhất của PEGO Network, trong đó có thể ẩn chứa các ứng dụng tiềm năng sẽ trở thành công cụ giết chết trong tương lai.",
        CrossChain: "Cross-Chain",
        CrossChainBrigde: "Cầu Cross-Chain",
        JoinTheCommunity: `Tham gia cộng đồng <span class='nameColor'>{Community}</span>`,
        Community: "Cộng đồng",
        PEGODiscordSupport: "Hỗ trợ PEGO Discord",
        ToBeA: `Trở thành một<span class='nameColor'>{TOValidator}</span>`,
        TOValidator: "Người xác minh",
        PEGOIsCommitted: "PEGO cam kết xây dựng một mạng phi tập trung hoàn toàn do cộng đồng điều hành, thiết kế của nút xác minh là yếu tố quan trọng để đạt được mục tiêu này. Thông qua cơ chế đồng thuận PPOS, PEGO cam kết rằng tất cả các bên tham gia mạng phi tập trung có thể hợp tác hiệu quả trong một cơ chế công bằng.",
        CONNECT: "KẾT NỐI",
        applyToBe: "Đăng ký trở thành người xác minh",
        PEGOValidator: "Đặc điểm của nút xác minh PEGO là có 'chứng minh thư' NFT-PNN có quyền sở hữu quyền lợi cụ thể. Ứng cử viên trở thành nút xác minh mạng PEGO chính thức thông qua việc thế chấp PNN và tham gia vào cuộc bầu cử nút xác minh.",
        NFTPNNIDENTITYCARD: "Thẻ nhận dạng NFT-PNN",
        MyAccount: "Tài khoản của tôi",
        VotingReward: "Phần thưởng bỏ phiếu",
        TokenBalance: "Số dư token",
        VotingPower: "Quyền biểu quyết",
        VotedValidators: "Người xác minh đã bỏ phiếu",
        Rank: "Xếp hạng",
        Address: "Địa chỉ",
        Status: "Trạng thái",
        MyShare: "Chia sẻ của tôi",
        Operate: "Vận hành",
        ValidatorStatus: "Trạng thái người xác minh",
        TotalVotes: "Tổng số phiếu",
        NextRound: "Vòng tiếp theo",
        ValidatorRanking: "Xếp hạng người xác minh",
        search: "Tìm kiếm",
        Ranking: "Xếp hạng",
        LatestBlock: "Khối mới nhất",
        ValidatedBlocks: "Các khối đã xác minh",
        Votes: "Phiếu",
        Action: "Hành động",
        Vote: "Bỏ phiếu",
        PEGO: `PEGO  <span class='nameColor'>{dao}</span>`,
        dao: "DAO",
        PEGOEmphasizes: "PEGO nhấn mạnh vào hiệu suất và cho phép điều chỉnh linh hoạt tất cả các tham số trên chuỗi thông qua các đề xuất được khởi xướng bởi cộng đồng. Các node hoặc người dùng thông thường có thể khởi xướng đề xuất bằng cách gửi cọc một số lượng PG nhất định. Nếu đề xuất được thông qua bỏ phiếu trong khoảng thời gian quy định, đề xuất sẽ được thực thi và có hiệu lực trên chuỗi.",
        VotingTaken: 'Đang tiến行 bỏ phiếu',
        Sponsor: "Người tài trợ",
        Active: "Đang tiến hành",
        Executed: "Đã thực thi",
        Voted: "Đã bỏ phiếu",
        SUBMITPROPOSAL: "Gửi đề xuất",
        OnChainParameters: "Tham số trên chuỗi",
        PendingTime: "Thời gian chờ định",
        VotingTime: "Thời gian bỏ phiếu",
        ExecutionTime: "Thời gian thực thi",
        TotalVotesTotalVt: "Tổng số phiếu/ Tổng VT",
        SupportedVotesTotalVotes: "Số phiếu ủng hộ/ Tổng số phiếu",
        StakePG: "Gửi cọc PG",
        Proposals: "Các đề xuất",
        SEEALL: "Xem tất cả",
        ThreeStages: `<span class='nameColor'>{PGStrategies}</span> Ba giai đoạn chiến lược`,
        PGStrategies: "Chiến lược PG",
        BasedOnDao: "Dựa trên DAO",
        InTheInitial: "Trong giai đoạn phát triển ban đầu của PEGO public chain, tất cả các công việc chuẩn bị dựa trên khái niệm DAO trong hệ sinh thái để thực hiện xây dựng và chia sẻ cùng nhau của toàn bộ cộng đồng. Hơn nữa, hệ sinh thái được cộng đồng quản lý theo nguyên tắc tự trị, thực hiện sự dân chủ và công bằng dựa trên quy tắc tự trị.",
        DAODevelopment: "Phát triển DAO",
        InThePEGO: "Trong mạng lưới PEGO, sẽ xuất hiện các loại DAO khác nhau, và với sự gia tăng của các bên tham gia hệ sinh thái, nhu cầu quản lý DAO sẽ tăng lên. Điều này có thể góp phần tăng tốc ứng dụng blockchain.",
        ServingDAO: "Dịch vụ DAO",
        AllBlockchainBased: "Tất cả các DAO dựa trên blockchain đều thuộc sở hữu của cộng đồng thành viên. Theo ba giai đoạn chiến lược, mạng lưới PEGO cuối cùng sẽ trở thành public chain đầu tiên trên toàn cầu được quản lý hoàn toàn bằng DAO.",
        PEGORoadmap: "Lộ trình PEGO",
        latestUpdates: "và cập nhật mới nhất",
        TheProposal: "Đề xuất về cải tiến mô hình kinh tế của mạng lưới PEGO",
        publicChain: "Thông tin về public chain: Mạng lưới PEGO - Biểu tượng mã thông báo: PG. Là mã thông báo quản trị của mạng lưới PEGO, PG cũng được phát hành trên chuỗi BSC để thực hiện trao đổi liên chuỗi...",
        TheW3BIWO: "W3B IWO sắp sửa khởi động",
        theFuture: "Tương lai của W3Bridge sẽ là cây cầu của thế giới Web3 phân mảnh hóa, điều này sẽ được thực hiện trên cơ sở phát triển sớm của hệ sinh thái PEGO. Với việc mở rộng hệ sinh thái...",
        PEGONetwork: "Mạng lưới PEGO ra mắt mã thông báo PN quản trị",
        toIncentivese: "Để khuyến khích những người quảng bá mạng lưới PEGO trên toàn cầu, mạng lưới PEGO sẽ ra mắt PN là mã thông báo quản trị cho cộng đồng của mình. Mã thông báo này sẽ được áp dụng để khuyến khích...",
        ThePEGOFoundation: `PEGO <span class='nameColor'>{Foundations}</span>`,
        Foundations: 'Tổ chức',
        pegoEmphasizes: "PEGO Foundation là tổ chức phi tập trung được khởi xướng để xây dựng, hỗ trợ và phát triển mạng lưới PEGO và cộng đồng của nó. Tổ chức này bao gồm các chuyên gia ngành công nghiệp và tình nguyện viên cốt lõi của cộng đồng đến từ nhiều lĩnh vực trên toàn thế giới.",
        StakePools: "Các hồ gửi cọc",
        stakePools: "Các hồ gửi cọc là một giải pháp gửi cọc linh hoạt, thúc đẩy sự phát triển phi tập trung, chống kiểm duyệt và DeFi của PEGO.",
        STAKEPG: "Gửi cọc PG",
        Grants: "Nhận trợ giúp",
        thePegp: "Chương trình trợ giúp PEGP Foundation tập trung vào việc tài trợ để nâng cao hiệu suất chống kiểm duyệt của mạng lưới PEGO.",
        APPLYFORGRANTS: "Nộp đơn nhận trợ giúp",
        HundredFamily: "",
        HundredFamilyDesc: "",
        APPLY100Family: "",
        APPLY100FamilyLink: "",
        JOINPEGOFOUNDATION: "Tham gia PEGO Foundation",
        Ended: "Đã kết thúc",
        linkWallet: "Liên kết ví",
        BNBSmartChain: "BNB Smart Chain",
        TelegramCommunity: "Cộng đồng Telegram",
        EnglishCommunity: "Cộng đồng tiếng Anh",
        validator: "Người xác thực",
        quitValidator: "Thoát khỏi cuộc đua",
        claim: "Yêu cầu",
        GetPGVT: "Nhận PGVT",
        voteValidator: "Bỏ phiếu cho người xác thực",
        ReturnPGVT: 'Hoàn trả PG/W3FT',
        ReturnPGVT2: 'Thời gian làm mát không thể đổi được',
        ReturnPGVT3: 'Số dư không đủ',
        IncreaseVote: 'Tăng phiếu',
        Refund: 'Hoàn lại',
        quitTheValidator: 'Thoát khỏi cuộc đua',
        onceQuited: 'Sau khi thoát, bạn sẽ không nhận được phần thưởng PG nữa!',
        andYour: 'và bạn sẽ không thể lấy lại PNN của mình',
        noIDont: 'Không, tôi không muốn thoát',
        SureIWant: 'Chắc chắn, tôi muốn thoát',
        getPGVT: 'Nhận PGVT',
        pleaseEnter: 'Vui lòng nhập',
        MAX: 'Tối đa',
        Balance: 'Số dư',
        BalancePGVT: 'Số dư PGVT',
        returnPGVT: 'Hoàn trả PGVT',
        youWantTo: 'Bạn muốn hoàn trả',
        YourBalanceIs: 'Số dư của bạn là',
        isYourBalanceIsVT: 'Số dư VT',
        YourBalanceIsVT: 'Số dư VT của bạn không đủ',
        YourBalanceIs1:'Bạn đã tạo ra số lượng được đúc từ nguồn này',
        YouneedReturn: 'Bạn cần hoàn trả',
        CooldownCountdown: 'Thời gian làm lạnh',
        Seconds: 'Giây',
        VoteAmount: 'Số phiếu bỏ phiếu',
        ValidatorAddress: 'Địa chỉ người xác thực',
        Withdraw: 'Rút lại',
        Withdraw2: 'Không thể rút lại trong thời gian làm lạnh',
        WithdrawAmount: 'Số phiếu rút lại',
        NoticeYou: 'Chú ý: Sau khi kết thúc thời gian làm lạnh, bạn sẽ nhận lại PGVT của mình.',
        ExchangeNFTPNNT: "Đổi NFT-PNNT",
        ValidatorApplication: "Đơn xin xác thực",
        SorryYou: "Bạn chưa sở hữu PNN-NFT,",
        anyPNNNFT: "Vui lòng sử dụng PNN để đúc",
        // howToGet: "Làm cách nào để nhận được NFT-PNN (PEGO-Chain)?",
        // IfYouHave: "Nếu bạn đã sở hữu mã thông báo PNN-BSC,",
        clickHere: "Nhấp vào đây",
        // toMint: "để đúc một PNN-NFT (PEGO-Chain).",
        ConnectWallet: "Kết nối ví",
        YourPNNNFT: "PNN NFT của bạn",
        applyToBeAValidator: "Ứng tuyển thành người xác thực",
        Transaction: "Giao dịch",
        CongratulationsYou: "Chúc mừng! Bạn đã trở thành một người xác thực!",
        JoinOur: 'Tham gia',
        discord: 'Discord',
        toGetHow: 'để biết cách vận hành nút người xác thực.',
        Failed: 'Thất bại',
        Pending: 'Giao dịch đang chờ xử lý',
        MoreAboutDAO: 'Thông tin thêm về DAO',
        CreatProposal: "Tạo đề xuất",
        CoolingOffPeriodOfPGVT: "Thời gian làm lạnh của PGVT",
        CoolingOffPeriodOfPGW3FT: "Thời gian làm lạnh của PG&W3FT",
        ValidatorDistrbution: "Phân bố người xác thực",
        AdditionalPGIssued: "PG được phát hành thêm",
        LowestGasFee: "Phí gas thấp nhất",
        BlockReward: "Phần thưởng khối",
        ValidatorNumbers: "Số lượng người xác thực",
        SupportedToken: "Token được hỗ trợ",
        Blacklist: "Danh sách đen",
        TotalVT: "Tổng VT",
        Supports: "Hỗ trợ",
        Invalid: "Không hợp lệ",
        Passed: "Đã thông qua",
        Core: "Core",
        ProposalType: "Loại đề xuất",
        AdjustThe: "Điều chỉnh các tham số đề xuất",
        AddOrDelete: "Thêm hoặc xóa token farming để nhận PGVT",
        AdjustTheDistribution: "Điều chỉnh tỷ lệ phân phối phần thưởng",
        BlaDecentralized: "Quỹ BlaDecentralized sẽ phát hành thêm PG tới địa chỉ người khởi xướng",
        AdjustTheLowestGasFee: "Điều chỉnh phí gas thấp nhất",
        AdjustTheBlockReward: "Điều chỉnh phần thưởng khối",
        AdjustThenumberOfValidators: "Điều chỉnh số lượng người xác thực",
        ChoosePDP: "Chọn PDP",
        ParametersChanges: "Thay đổi tham số",
        NowIs: "Hiện tại là",
        ExeuctionTime: "Thời gian thực thi",
        TotalVotesvt: "Tổng số phiếu/ Tổng VT",
        SupportedVotesTotalVotess: "Số phiếu ủng hộ/ Tổng số phiếu",
        AddDelete: "Thêm/Xóa",
        AddANewFarmingToken: "Thêm token farming mới",
        DeleteoneFarmingToken: "Xóa một token farming",
        TokenAddress: "Địa chỉ token",
        CoolingOffPeriodOf: "Thời gian làm lạnh",
        TheDistributionProportionForValidator: "Tỷ lệ phân phối cho người xác thực và người dùng, hiện tại người dùng chiếm",
        AddAnAddressToBlacklist: "Thêm địa chỉ vào danh sách đen",
        DeleteAnAddressOutOfBlacklist: "Xóa địa chỉ khỏi danh sách đen",
        Amount: "Số lượng",
        TheAmountOfPGIssued: "Số lượng PG phát hành",
        NumberOfValidators: "Số lượng người xác thực",
        YouNeedToStake: "Bạn cần gửi cọc",
        makeSureYou: "PG, vui lòng đảm bảo bạn có đủ số dư.",
        PGBalance: "Số dư PG",
        FailedToCreateProposal: "Tạo đề xuất thất bại",
        Detail: "Chi tiết",
        StartTime: "Thời gian bắt đầu",
        VoteTime: "Thời gian bỏ phiếu",
        TotalVoteToken: "Tổng số token bỏ phiếu",
        Against: "Phản đối",
        GetBackVT: "Thu hồi VT",
        Execute: "Thực thi",
        Metamask: "Metamask",
        DoNotHaveMetamask: "Chưa cài đặt Metamask?",
        Install: "Cài đặt",
        YoureAlreadyAValidator: "Bạn đã là một người xác thực rồi!",
        Error: "Lỗi",
        Successful: "Thành công",
        GetBackPG: "Thu hồi PG",
        About: "Về",
        proposal: "Đề xuất",
        pegoScan: "PEGO Trình duyệt",
        other: "Khác",
        comingSoon: "Sắp ra mắt",
        PWGBountyProgram: "Chương trình thưởng PWG",
        Web3GrantProgram: "Chương trình hỗ trợ Web3 Grant của PEGO nhằm cung cấp hỗ trợ toàn diện về tài chính, công nghệ và nguồn lực cho lớp ứng dụng Web3 mới nổi.",
        PWGCanApplyForType: "PWG có thể ứng tuyển vào các loại sau",
        seedIncentive: "Hỗ trợ khởi nghiệp từ hạt giống",
        StartupFunding: "Cung cấp vốn khởi nghiệp cho ý tưởng Web3 xuất sắc",
        financialSupport: "Hỗ trợ tài chính",
        otherSupport: "Hỗ trợ khác",
        ProjectLanding: "Hỗ trợ tư vấn và triển khai dự án",
        EcologicalCoCreation: "Hỗ trợ đồng sáng tạo sinh thái",
        ecosystem: "Trở thành một phần quan trọng của sinh thái Web3 của PEGO",
        CoCreationSupport: "Hỗ trợ đồng sáng tạo",
        ProvideFull: "Cung cấp hỗ trợ kỹ thuật toàn diện, kết nối với nguồn lực hợp tác tương ứng và tích hợp vào hoạt động toàn diện của sinh thái PEGO.",
        SpecialType: "Loại đặc biệt Layer2 & 3",
        ProvideSpecial: "Cung cấp hỗ trợ đặc biệt cho người tham gia xây dựng L2 và L3 trên PEGO",
        AccelerateSupport: "Hỗ trợ tăng tốc",
        TargetedTechnical: "Cung cấp hỗ trợ kỹ thuật đích danh, hỗ trợ xây dựng nhóm lõi",
        StrategicInvestment: "Loại đầu tư chiến lược",
        MeetTheDevelopment: "Đáp ứng nhu cầu phát triển chiến lược sinh thái của PEGO",
        strategicSupport: "Hỗ trợ chiến lược",
        ImplementationOfThe: "Triển khai, chiến lược, hoạt động kinh doanh, tư vấn kỹ thuật và kinh doanh toàn vòng đời, cung cấp hỗ trợ toàn diện về xây dựng và quản lý nhóm, kết nối với nguồn lực hợp tác hàng đầu trong ngành và hỗ trợ tài chính tiếp theo.",
        WhatIs100FamilyTitle: '',
        WhatIs100Family: '',
        Why100FamilyTitle: '',
        Why100Family: '',
        Type100FamilyTitle: '',
        Type100Family: '',
        Contact100Family: '',
        Contact100FamilyName: '',
        Contact100FamilyLink: '',
        VoterDistribution: "Tỷ lệ người bỏ phiếu",
        homeHeader: "PEGO sinh ra để sáng tạo",
        infrastructure: "Cơ sở hạ tầng Web3, PPOS Consensus, Máy ảo (VM), Quản trị DAO, Công cụ blockchain. PEGO là cơ sở hạ tầng blockchain dành cho tất cả mọi người trong thời đại Web3",
        CompleteTheMainnetLaunch: "Hoàn thành việc ra mắt mainnet",
        CompleteNodeCampaign: "Hoàn thành chiến dịch node",
        W3swapLaunched: "W3swap được ra mắt",
        W3WalletIsOnline: "W3Wallet đã có mặt trực tuyến",
        ListedOnTheWorld: "Được niêm yết trên các sàn giao dịch hàng đầu thế giới",
        StartApplicationEcologicalConstruction: "Bắt đầu xây dựng sinh thái ứng dụng",
        PromoteMEMEEcologicalConstruction: "Thúc đẩy xây dựng sinh thái MEME",
        PromoteFOMO: "Thúc đẩy xây dựng sinh thái FOMO",
        StarttheGlobal: "Bắt đầu hành trình sinh thái toàn cầu",
        AchieveEcological: "Đạt được 200 sinh thái trực tuyến",
        TheNumberOfValid: "Số ví hợp lệ vượt quá 1 triệu",
        TheTotalNumber: "Tổng số giao dịch khối vượt quá 5 triệu",
        TVLExceeded: "Giá trị TVL vượt quá 100 triệu đô la",
        CompleteTheLaunch: "Hoàn thành niêm yết trên hầu hết các sàn giao dịch hàng đầu toàn cầu",
        AchieveEcologicalOnline: "Đạt được 500 sinh thái trực tuyến",
        TheNumberOfEffective: "Số ví hợp lệ vượt quá 2 triệu",
        heTotalNumberOfBlock: "Tổng số giao dịch khối vượt quá 20 triệu",
        TVLExceededBillion: "Giá trị TVL vượt quá 10 tỷ đô la",
        Alreadyin: "Đã tồn tại",
        NotIn: "Không tồn tại",
        FillInAAddressAlreadyIn: "Nhập địa chỉ đã tồn tại",
        FillInTheTokenContractAddress: "Nhập địa chỉ hợp đồng token",
        AlreadySupported: "Đã được hỗ trợ",
        DecentralizedFoundationWillIssue: "Quỹ phi tập trung sẽ phát hành PG vào địa chỉ người khởi xướng",
        GasFee: "Lượng Gas",
        Add: "Thêm",
        Delete: "Xóa",
        W3swap: "W3swap",
        AMegaPlatform: "Một nền tảng toàn diện kết hợp các giải pháp giao dịch tốt nhất",
        W3Wallet: "W3Wallet",
        MultiFunction: "Một ví tiền Web3 đa chức năng dựa trên mạng lưới PEGO",
        W3Bridge: "W3Bridge",
        RebuildTheFragmented: "Xây dựng lại mạng lưới Web3 đa phân mảnh",
        EnterAsAnInteger: "Nhập dưới dạng số nguyên dương",
        NetworkError: "Lỗi mạng",
        nodeReward: "Phần thưởng node",
        award: "Phần thưởng",
        toBeCollected: "Đang chờ thu",
        received: "Đã nhận",
        NumberOfBlocks: "Số khối",
        EcologicalDAPP: "Ứng dụng sinh thái DAPP",
        receiveAward: "Nhận phần thưởng",
        pleaseGoToWallet: "Vui lòng chuyển đổi mạng trong ví tiền",
        AuditReport: "Báo cáo kiểm toán",
        ProposalDescription: "Mô tả đề xuất",
        nodeCard: "Thẻ node",
        PDP1: "Đề xuất loại PDP-1",
        DetermineOr: "Mục đích: Xác định hoặc điều chỉnh các tham số quan trọng của đề xuất quản lý DAO",
        TheAdjustableParameters: "Các tham số có thể điều chỉnh bao gồm: Thời gian thông báo đề xuất/Thời gian bỏ phiếu/Thời gian thực thi/Số lượng PG cần đặt cọc để đề xuất có hiệu lực (loại 1)/Số lượng PG cần đặt cọc để đề xuất có hiệu lực (loại 2)",
        PDP2: "Đề xuất loại PDP-2",
        decideWhich: "Quyết định loại token nông trại được tạo ra bởi LP nào có thể được đặt cọc để nhận PGVT (nếu số lượng PG trong LP thấp hơn 500.000, sau khi đề xuất được thông qua, không thể tạo ra PGVT)",
        PDP3: "Đề xuất loại PDP-3",
        adjustTheCooling: "Dùng để xác định và điều chỉnh thời gian làm lạnh cho quá trình tạo ra và đổi PGVT và thời gian làm lạnh cho quá trình đặt cược VT",
        PDP4: "Đề xuất loại PDP-4",
        usedToDetermine: "Dùng để xác định và điều chỉnh tỷ lệ phân phối phần thưởng cho node và người bỏ phiếu",
        PDP5: "Đề xuất loại PDP-5",
        controlMalicious: "Quyết định áp dụng các biện pháp đóng băng hoặc kiểm soát đối với các hợp đồng và tài khoản độc hại để nhanh chóng kiểm soát và loại bỏ các hành vi độc hại",
        PDP6: "Đề xuất loại PDP-6",
        controlMaliciousContracts: "Dùng để phát hành thêm PG, bất kỳ người dùng nào cũng có thể đề xuất",
        PDP7: "Đề xuất loại PDP-7",
        usedToAdjust: "Dùng để điều chỉnh mức phí Gas tối thiểu trên chuỗi để phù hợp với yêu cầu giai đoạn khác nhau",
        PDP8: "Đề xuất loại PDP-8",
        adjustTheBlock:'Điều chỉnh giá trị phần thưởng khối',
        PDP9:'Đề xuất loại PDP-9',
        adjustTheNumber:'Điều chỉnh số lượng nút xác minh trực tuyến',
        BitKeep:'BitKeep',
        TOKENPOCKET:'TOKEN POCKET',
        OKXWallet:'OKX Wallet',

        //6-13
        votingAddress:'Địa chỉ bỏ phiếu',
        numberOfVotes:'Số phiếu bỏ',
        votingTime:'Thời gian bỏ phiếu',
        votingList:'Danh sách bỏ phiếu',
        ExploreThePEGOEcosystem:'Khám phá hệ sinh thái PEGO',
        submitProject:'Gửi dự án',
        allItems:'Tất cả các mục',
        all:'Tất cả',
        startSearching:'Bắt đầu tìm kiếm',
        audit:'Kiểm toán',
        CreatingAndOwning:'Việc tạo và sở hữu ví là bước đầu tiên để kết nối với PEGO và gia nhập Web3. Hãy đảm bảo an toàn bảo quản khóa riêng tư và cụm từ khóa.',
        OpeningMetamask:"Đang mở Metamask",
        PleaseConfirmInMetamask:"Vui lòng xác nhận trong Metamask",
        OpeningBitkeep:"Đang mở Bitkeep",
        OpeningOKXWallet:"Đang mở OKX",
        NotInstalled:"Chưa cài đặt",
        EcologicalCooperationApplicationForm:"Mẫu Đơn Hợp tác Sinh thái",
        WelcomeToJoin:"Chào mừng bạn tham gia, chúng tôi sẽ liên hệ với bạn sớm nhất sau khi bạn gửi dữ liệu, trước đó hãy điền đầy đủ thông tin dưới đây.",
        PEGOEcologicalBusiness:"ID Người liên hệ Hợp tác Kinh doanh Sinh thái PEGO",
        Telegram:"Telegram",
        Btok:"Btok",
        projectName:"Tên dự án",
        projectLogo:"Logo dự án",
        ImageFormatsLike:"Hỗ trợ các định dạng hình ảnh như .jpg, .png, .gif, .bmp, .psd, .tiff.",
        ChooseImage:'Chọn hình ảnh',
        Lessthan:'Ít hơn 500MB',
        ProjectDescription:'Giới thiệu dự án',
        itemCategory:'Danh mục hoặc vị trí dự án',
        anyOtherType:'DEX, NFT hoặc bất kỳ loại nào khác',
        ProjectBenefits:'Lợi ích của dự án',
        WhatCanItBringToThe:'Dự án có thể mang lại điều gì cho hệ sinh thái PEGO?',
        ProjectInfluence:'Tác động của dự án',
        WhatKindOfCooperation:'Bạn muốn hợp tác với hệ sinh thái PEGO trong lĩnh vực nào?',
        TokenListedOn:'Token đã được niêm yết trên W3swap chưa?',
        TokenListedOnW3swap:'Token đã được niêm yết trên W3swap',
        TokensListedOnW3Wallet:'Token đã được niêm yết trên W3Wallet',
        TokenListingMappingTool:'Công cụ ánh xạ danh sách Token',
        TokenListedOnLunchpadHomepage:'Token đã được niêm yết trên trang chủ Lunchpad',
        TokenOpening:'Token đã được mở trên BTOK-PEGO chain/token / Gói hồng cộng đồng',
        TheTokenIsListed:'Token đã được niêm yết trên phần AVE-PEGO',
        TheProjectSeeks:'Dự án tìm kiếm sự hỗ trợ về công nghệ, tài chính và các nguồn lực khác',
        TheApplicationEnters:'Ứng dụng gia nhập vào chuỗi công cộng PEGO',
        Other:'Khác',
        ProjectEconomicModel:'Mô hình kinh tế của dự án',
        ProjectInformation:'Thông tin dự án',
        ProjectPPTAndAny:'Có thể tải lên bài trình chiếu dự án và bất kỳ tài liệu liên quan nào khác',
        ProjectOfficialWebsite:'Trang web chính thức của dự án',
        ProjectTwitter:'Trang Twitter của dự án',
        ProjectTelegramCommunity:'Cộng đồng Telegram của dự án',
        ProjectDiscordCommunity:'Cộng đồng Discord của dự án',
        ProjectGithub:'Github của dự án',
        ContactTelegramID:'ID Telegram liên hệ',
        Submit:'Gửi',
        PleaseEnterAKeyword:'Vui lòng nhập từ khóa',
        downloadFile:"Tải xuống",
        votingRecord:"Bản ghi bỏ phiếu.csv",
        previousPage:"Trang trước",
        nextPpage:"Trang sau",
        ReturnPGVTWPG:"Không thể tạo",
        officialEmail:'email chính thức',
        businessCooperation:'Hợp tác kinh doanh',
        copy:'Sao chép thành công',
    },

}
