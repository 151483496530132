/**
 *@title
 *@author liqingjie
 *@date 2023/2/21 10:54
 */

import Web3 from 'web3';
import validatorAbi from '../../abi/PnnNft.json';
import {nftPnnAdder} from "@/utils/address";
import {getStore} from '@/config/storage'

const contractAddress = nftPnnAdder;

let contract = null;

const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
const web3js = new Web3(BitKeepProvider);
if (web3js) {
  contract = new web3js.eth.Contract(validatorAbi.abi, contractAddress);
}

export const staking = async (number, addr) => contract.methods.staking().send({ from: addr, price: Web3.utils.toWei(number) });

export const balanceOf = async (addr) => {
  const v = await contract.methods.balanceOf(addr).call();
  return Web3.utils.fromWei(v, 'ether');
};
