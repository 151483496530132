
export const daoAdder='0x0000000000000000000000000000000000001002'
export const governorAdder='0x0000000000000000000000000000000000001001'
export const nftPnnAdder='0x0000000000000000000000000000000000001003'
export const pgvtAdder='0x0000000000000000000000000000000000001004'
export const validatorAdder='0x0000000000000000000000000000000000001000'
// export const tokenAddressAdder='0xB84AC451A43B9A81d753c9a9d1F4F9A1B9DB4BFb'//PNN 地址
// export const tokenAddressAdder='0xe944043E8217E686D4916C83FdfB89b85775da1e'//PNN 测试服地址
export const tokenAddressAdder=process.env.VUE_APP_BASE_PNN//PNN 地址
export const PGAdder='0x0000000000000000000000000000000000000000'
export const getWithdraw='0xc5EeD1C81d39d52540192D9ED336368973F5DE75'
export const farm='0x6dfaFc49C09d041b2E732aA67C76F87ee4e3f65E'//农场合约地址
export const WPG='0x0cF4071940782b640d0b595Cb17bDf3E90869d70'//wpg合约地址
