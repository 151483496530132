<template>
  <div class="conts">
    <div id="dApp-detail" class="ti-card" data-aos="fade-down"
         data-aos-easing="linear">
      <div class="content">
        <div class="icon">
          <div style="display: flex">
            <img :src="info.logoUrl" alt="">
            <div class="name-t">
              <div class="n-t">{{ info.name }}</div>
              <div class="marks">
                <div class="mark-l">
                  <div ><a :href="info.dappUrl" target="_blank">{{$t(`message.startSearching`)}}</a></div>
                  <div><a :href="info.whiteBook" target="_blank">{{$t(`message.Whitepaper`)}}</a></div>
                  <div><a :href="info.auditReport" target="_blank">{{$t(`message.audit`)}}</a></div>
                </div>
                <p class="link">
                  <a :href="info.twitter" target="_blank"><img src="@/assets/PEGO/Exclude.svg" alt=""></a>
                  <a :href="info.telegram" target="_blank"><img src="@/assets/PEGO/FJ.svg" alt=""></a>
                  <a :href="info.github" target="_blank"><img src="@/assets/ecological/subtract-1.svg" alt=""></a>
                </p>
              </div>
            </div>
          </div>
          <div class="tragt-t">
            <template v-for="(item) in info.typeList" :key="item">
              <span>{{ selPopo(item) }}</span>
            </template>
          </div>

        </div>
        <!--        <h1 :class="textBold">{{ info.name }}</h1>-->
        <div class="p-text">
          <p class="desc">{{ info[fullKey('desc')] }}</p>

        </div>

      </div>
      <div class="ecol">
        <div class="x-m">
          <div>{{$t(`message.allItems`)}}</div>
          <div class="dis">
            <n-input v-model:value="searchForm.name" @blur="handleSearch" @keyup.enter="handleSearch"
                     :placeholder="$t(`message.search`)" class="seachs">
              <template #prefix>
                <Icon color="#D9D9E6" size="20" class="icons">
                  <SearchOutline/>
                </Icon>
              </template>
            </n-input>
            <div class="sns">
              <n-config-provider :theme-overrides="themeOverrides">
                <div class="popo">
                  <n-button @click="ulShow=!ulShow" icon-placement="right" text-color="#ffffff"
                            color="#222427" strong
                            class="wins">
                    {{ selPopo(searchForm.type) || $t(`message.all`) }}
                    <template #icon>
                      <Icon color="#ffffff" size="20" class="icons">
                        <ChevronDown v-show="!ulShow"/>
                        <ChevronUp v-show="ulShow"/>
                      </Icon>
                    </template>
                  </n-button>
                  <div class="cot-po" :class="{'ans':ulShow}">
                    <div class="bodr" v-for="(item,index) in typeList" :key="index"
                         @click="butClick(item)">
                      <!--                        <div class="bagst" :class="{'corBg':searchForm.type===''}">ALL</div>-->
                      <div class="bagst" :class="{'corBg':searchForm.type===item.id}"> {{ item.type }}</div>
                    </div>
                  </div>
                </div>
              </n-config-provider>
            </div>
          </div>
        </div>
        <div class="ul-u">
          <div class="li-l" @click="details(item)"
               v-for="item in list" :key="item.id">
            <div class="imgs">
              <!--                <img src="@/assets/ecological/w3.svg" alt="">-->
              <img :src="item.logoUrl" alt="">
            </div>

            <div class="tit-t">
              {{ item.name }}
            </div>
            <div class="txt">
              <div class="t-t" v-html="item[fullKey('desc')] "></div>
            </div>
            <div class="tragt-t ">
              <template v-for="(item) in item.typeList" :key="item">
                <span>{{ selPopo(item) }}</span>

              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot></foot>
  </div>
</template>
<script>
import api from '@/api/api';
import foot from "@/components/foot";
import {
  ChevronDown,
  ChevronUp,
  SearchOutline
} from "@vicons/ionicons5";
import {Icon} from '@vicons/utils'

export default {
  name: "detail-1",
  components: {
    foot,
    Icon,
    ChevronDown,
    ChevronUp,
    SearchOutline
  },
  data() {
    return {
      info: {},
      detailId:null,
      themeOverrides: {
        "Dropdown": {
          "color": "#211B1BFF",
          "textColor": "#F9F6F6FF",
          "optionTextColor": "#FFF",
          "optionTextColorHover": "rgba(111, 120, 118, 1)",
          "optionTextColorActive": "#E2B163FF",
          "optionColorHoverInverted": "rgba(111, 120, 118, 1)",
          "optionColorActiveInverted": "",
          "optionTextColorActiveInverted": "#FFB526",
          "optionTextColorHoverInverted": "#FFF",
          "optionIconSuffixWidthHuge": "100px",
        },
        "Input": {
          "color": "#222427",
          "placeholderColor": "rgba(237, 228, 228, 1)",
          "caretColor": "#F1ECE8FF",
          "textColor": "rgba(241, 245, 249, 1)",
          "colorFocus": "#222427",
          "border": "1.5px solid rgba(255, 255, 255, 0.3)",
          "borderHover": "1px solid #fff",
          "borderFocus": "1px solid #fff",
          "borderRadius": "4px",
        },
        "Button": {
          "textColorHover": "#ffffff",
          "border": "1px rgba(255, 255, 255, 0.1) solid",
          "borderFocus": "1px #ffffff solid",
          "borderHover": "1px #ffffff solid",
          "borderPressed": "1px #ffffff solid",
          "rippleColor": "#FFB526",
          "borderRadius": "15px",
        }
      },
      list: [],
      typeList: [],
      searchForm: {
        pageIndex: 1,
        pageSize: 20,
        type: 0,
        name: '',
      },
      total: 0,
      totalPages: 1,
      id: '',
      idName: 'PDP-1',
      ulShow: false,
    }
  },
  computed: {
    fullKey() {
      return function (key) {
        return `${key}${this.$i18n.locale === 'en-US' ? 'En' : 'Zh'}`;
      }
    },
    selPopo() {
      let that=this
      return function (val) {
        if (val&&that.typeList.length>0) {
          let obj =that.typeList.find(item => item.id === val)
          return obj ? obj.type : ''
        } else {
          return ''
        }
      }
    },
  },
  mounted() {
    this.detailId = this.$route.query.id
    this.detailClick()
    this.init();
    this.getList();
  },
  methods: {
    detailClick() {
      api.articleDetail({id: this.detailId})
          .then((res) => {
            this.info = res;
          });
    },
    details(item){
      this.detailId=item.id
      this.detailClick()
    },
    init() {
      api.getDAppTypeList().then((res) => {
        console.log("9090",res)
        this.typeList = res||[];
        this.typeList.unshift({id: 0, type: this.$t('message.all')})
      });
    },
    getList() {
      api.getDAppList(this.searchForm).then((res) => {
        this.list = res?.rows;
        this.total = res?.totalNum;
        this.totalPages = res?.totalPages;
      });

    },
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.getList();
    },
    butClick(item) {
      console.log("items", item)
      this.searchForm.type = item.id
      this.ulShow = !this.ulShow
      this.handleSearch()
    }
  }
}
</script>

<style scoped lang="less">
a{
  text-decoration-line:none;
  color: #ffffff;
}
.conts {
  background: url("@/assets/PEGO/Ellipse6.svg") no-repeat, url("@/assets/PEGO/bgjojn.svg") no-repeat;
  background-position: center top -75px, right 30px bottom 0;
  background-size: 100%, 672px 672px;

}

#dApp-detail {
  overflow: hidden;
  //padding-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  padding: 74px 0 30px;

  .content {
    width: 961px;
    background: linear-gradient(180deg, rgba(56, 63, 70, 0.2) 0%, rgba(33, 37, 45, 0.2) 129.36%);
    backdrop-filter: blur(7.5px);
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    //margin: 74px auto 0;
    position: relative;
    padding: 69px 30px 46px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .2s;
    overflow: hidden;

    .icon {
      //width: 170px;
      //overflow: hidden;
      text-align: left;
      width: 100%;
      //margin-bottom: 49px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 114.41px;
        height: 114.41px;
        object-fit: contain;
        border-radius: 100%;
        background: rgba(204, 204, 204, 0.3);
      }

      .name-t {
        margin-left: 20px;

        .n-t {
          font-weight: 600;
          font-size: 36.5906px;
          line-height: 50px;
        }
      }

      .marks {
        display: flex;
        align-items: center;

        .mark-l {
          display: flex;
          align-items: center;
          cursor: pointer;

          div {
            text-align: center;
            width: 100.29px;
            height: 31.71px;
            line-height: 31.71px;
            border: 1.21969px solid #FFFFFF;
            border-radius: 19.7451px;
            margin-right: 10px;
            font-weight: 600;
            font-size: 11.9667px;
          }

          div:nth-child(1) {
            background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
          }
        }

      }

    }

    h1 {
      font-size: 48px;
      margin: 78px 0 45px;
      text-align: center;
    }

    .p-text {
      width: 100%;

    }

    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 34px;
      text-align: left;
    }

    .link {
      //margin-top: 80px;
      display: flex;
      justify-content: center;
      margin-left: 20px;

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        outline-color: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        outline-offset: 0;
        text-shadow: none;
        -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
      }

      img:hover {
        transform: scale(1.5);
        cursor: pointer;
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 15px;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
        0 0 20px #FFB800;
        //background-blend-mode: lighten;
        background-image: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
        background-size: cover;
        background-blend-mode: multiply; //排除模式
        mix-blend-mode: difference;
      }

      a + a {
        margin-left: 20px;
      }
    }
  }

  .content:after {
    content: "";
    position: absolute;
    left: -120%;
    top: 0;
    width: 100%;
    height: 100%;
    transform: skew(-30deg);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));
  }

  .content:hover {
    //transform: scale(1.01) translateY(-5px);
    transform: scale(1.01);
    box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);

    &:after {
      left: 120%;
      transition: all .3s;
    }

  }
}

.ecol {
  width: 961px;
  padding: 69px 0px 46px;
  box-sizing: border-box;

  .cand-ti {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 57px;
    text-align: left;
    background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //background-clip: text;
    //text-fill-color: transparent;
    display: inline-block;
    font-family: BOLD, sans-serif;
    padding: 60px 0;

  }

  .ul-u {
    padding-top: 60px;
    display: inline-grid;
    grid-template-columns: 300px 300px 300px;
    //grid-template-rows: 235px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    .li-l {
      width: 300px;
      text-align: center;
      background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
      box-shadow: 0px 3.19626px 14.3832px #17191B;
      backdrop-filter: blur(5.99299px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 11.986px;
      overflow: hidden;

      .imgs {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        //background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
        background: #5E5E5E;
        backdrop-filter: blur(7.5px);
        //border-radius: 15px;
        height: 100px;
        width: 300px;
        border: 0.5px solid rgba(255, 255, 255, 0.1);
        //overflow: hidden;
        position: relative;

        img {
          position: absolute;
          left: 32px;
          bottom: -33.16px;
          width: 66.32px;
          height: 66.32px;
          object-fit: contain;
          border-radius: 100%;
          background: rgba(204, 204, 204, 0.3);
        }
      }

      .tit-t {
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        padding: 50px 30px 0;
      }

      .txt {
        padding: 28px 30px;
        height: 110px;
        text-align: left;
        font-weight: 300;
        font-size: 12px;
        line-height: 19px;
        box-sizing: border-box;
        overflow: hidden;

        .t-t {
          font-family: 'LIGHT', sans-serif;
          font-weight: 300;
          font-size: 12px;
          line-height: 19px;
          color: #FFFFFF;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 3; //显示的行
          box-sizing: border-box;
        }

      }


    }
  }

  .x-m {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dis {
    display: flex;
    align-items: center;
  }

  .seachs {
    --n-border-hover: 1px solid rgba(255, 255, 255, 1) !important;
    --n-border-focus: 1px solid rgba(255, 255, 255, 1) !important;
    --n-caret-color: #FFFFFF !important;
    --n-border: 1px solid rgba(255, 255, 255, 0.3) !important;
    width: 266px;
    height: 50px;
    line-height: 50px;
    background: #2C2F34 !important;
    border-radius: 4px;
    caret-color: #2C2F34 !important;
    color: white !important;
    font-size: 16px;
    margin-right: 5px;
    text-align: left;

    ///deep/ .n-input__input-el {
    :deep(.n-input__input-el) {
      color: #ffffff !important;
      caret-color: #FFFFFF !important;
      -webkit-appearance: none;
      scrollbar-width: none;
      width: 100%;
      min-width: 0;
      height: 50px;
      line-height: 50px;
      background-color: transparent;
    }

    .icons {
      font-size: 16px !important;
    }
  }

  .cot-po {
    top: 55px !important;
    border-radius: 4px !important;
    padding: 0 10px;
    background: #2C2F34 !important;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .bodr {
      line-height: 30px;
      border-bottom: 0.3px solid rgba(255, 255, 255, 0.1);
    }

    .bodr:last-child {
      border-bottom: none;
    }

    div {
      padding: 5px 15px;

    }

    .bagst:hover {
      background: #383F46 !important;
      border-radius: 8px;

    }

    .corBg {
      background: #383F46 !important;
      border-radius: 8px;
    }
  }

  .sns {
    //padding-bottom: 30px;
    height: 50px;
    line-height: 50px;

    .popo {
      position: relative;
      height: 50px;
      line-height: 50px;
      //z-index: 4;
      .wins {
        width: 174px;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        height: 50px !important;
        line-height: 50px !important;
        justify-content: space-between;
        border: 1.5px rgba(255, 255, 255, 0.3) solid;
        background: #2C2F34;
      }
    }

    .cot-po {
      width: 100%;
      text-align: left;
      line-height: 30px;
      position: absolute;
      box-sizing: border-box;
      z-index: 3;
      top: 50px;
      background: #2C2F34;
      border-radius: 0 0 4px 4px;
      visibility: hidden;

    }

    .pad-lr {
      padding: 0 15px;
    }

    .ans {
      visibility: visible;
      animation: ant 0.1s;
      @keyframes ant {
        0% {
          z-index: -1;
          top: 0;
          opacity: 0;
        }

        100% {
          top: 50px;
          opacity: 1;
        }
      }
    }


  }
}

.tragt-t {
  padding: 0 30px 30px;
  text-align: left;

  span {
    text-align: center;
    display: inline-block;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
    border: 0.399533px solid rgba(255, 255, 255, 0.5);
    border-radius: 239.72px;
    width: 55.36px;
    font-family: 'LIGHT', sans-serif;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

.wits {
  width: 500px !important;
}

</style>
