<template>
  <!--  首页-->
  <div class="conts">
    <div class="bakg">
      <div class="header-one  ">
        <div class="head-left">
          <h1 class="wow animate__bounceInRight" v-html="$t(`message.blockchain`,{name:$t(`message.name`)})">
          </h1>
          <div class="text-dev ">
            <p class="wow animate__bounceInLeft"> {{ $t(`message.Support`) }}
              <br/>
              {{ $t(`message.scalability`) }}</p>
            <p class="wow animate__bounceInRight"> {{ $t(`message.solution`) }}</p>
          </div>
          <div class="but-ul wow rollIn">
            <n-button @click="Dapp" round color="#FF7A00" class="PDP buts" ghost text-color="#ffffff" :bordered="false">
              <img src="@/assets/PEGO/enter.svg" alt="">
              {{ $t(`message.EnterTheDAPP`) }}
            </n-button>
            <n-button @click="buyPg('https://beosin.com/audits/PEGO%20Network_202305161441.pdf')" round color="#FF7A00" class="PDP but-plain" ghost
                      :bordered="false">
              <img src="@/assets/PEGO/sj.svg" alt="">
              {{ $t(`message.AuditReport`) }}
            </n-button>
            <n-button @click="buyPg('https://github.com/pego-labs/pego-chain')" round color="#FF7A00" class="PDP but-plain" ghost
                      :bordered="false">
              <img src="@/assets/PEGO/reads.svg" alt="">
              {{ $t(`message.ReadDocs`) }}
            </n-button>
            <!--            暂时隐藏-->
            <!--            <n-button round color="#FF7A00" class="PDP but-plain" ghost :bordered="false">-->
            <!--              <img src="@/assets/PEGO/github.svg" alt="">-->
            <!--              {{ $t(`message.GITHUB`) }}-->
            <!--            </n-button>-->

          </div>
        </div>
        <div class="dist wow bounceIn">
          <div class="dis">
            <div class="ho-li">
              <img class="ho2" src="@/assets/PEGO/test/ho-2.png" alt="">
              <img class="ho1" src="@/assets/PEGO/test/ho-1.png" alt="">
            </div>
            <div class="ellips">
              <img class="dot" src="@/assets/PEGO/test/hom-5.png" alt="">
              <img class="dots" src="@/assets/PEGO/test/hom-4.png" alt="">
              <img class="dots3" src="../assets/PEGO/test/Group2.svg" alt="">
              <img class="dots4" src="../assets/PEGO/test/Group2.svg" alt="">
            </div>
            <div class="ellips2">
              <img class="dot" src="../assets/PEGO/test/Group2.svg" alt="">
              <img class="dots" src="@/assets/PEGO/test/hom-3.png" alt="">
              <img class="dots3" src="../assets/PEGO/test/Group2.svg" alt="">
              <img class="dots4" src="../assets/PEGO/test/Group2.svg" alt="">
            </div>
            <div class="ellips3">
              <img class="dots4" src="../assets/PEGO/test/Group2.svg" alt="">
              <img class="dot" src="../assets/PEGO/test/Group2.svg" alt="">
              <img class="dots" src="@/assets/PEGO/test/hom-3.png" alt="">
              <img class="dots3" src="../assets/PEGO/test/Group2.svg" alt="">
            </div>
            <div class="bott">
              <img class="b-img" src="@/assets/PEGO/test/s-2.png" alt="">
              <img class="b-img" src="@/assets/PEGO/test/s-1.png" alt="">
            </div>
            <div class="abs-img">
              <img src="@/assets/PEGO/test/h-3.svg" alt="">
              <img src="@/assets/PEGO/test/h-6.svg" alt="">
              <img src="@/assets/PEGO/test/h-1.svg" alt="">
              <img src="@/assets/PEGO/test/h-5.svg" alt="">
              <img src="@/assets/PEGO/test/h-2.svg" alt="">
              <img src="@/assets/PEGO/test/h-7.svg" alt="">
              <img src="@/assets/PEGO/test/h-4.svg" alt="">
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="head-2 wow">-->
      <!--        <div class="head-title">{{ $t(`message.MainnetLaunchCountdown`) }}</div>-->
      <!--        <div class="head-row">-->
      <!--          <div class="head-li" data-aos="fade-right">-->
      <!--            <div class="t-t">{{ Days }}</div>-->
      <!--            <div>{{ $t(`message.Days`) }}</div>-->
      <!--          </div>-->
      <!--          <div class="head-li" data-aos="fade-up">-->
      <!--            <div class="t-t">{{ hours }}</div>-->
      <!--            <div>{{ $t(`message.Hours`) }}</div>-->
      <!--          </div>-->
      <!--          <div class="head-li" data-aos="fade-left">-->
      <!--            <div class="t-t">{{ minutes }}</div>-->
      <!--            <div>{{ $t(`message.Minutes`) }}</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="bags ant">
        <div class="ti-ti t-pad">{{ $t(`message.PEGOSolutions`) }}</div>
        <div v-for="(item,index) in list" :key="index">
          <div class="li-m " v-if="index%2===0">
            <div class="l-f wow animate__lightSpeedInLeft">
              <img :src="item.imgs" class="ins" alt="">
            </div>
            <div>
              <div class="r-g wow animate__lightSpeedInRight">
                <h2>{{ item.title }}</h2>
                <div class="d-l" v-for="(int) in item.opint" :key="int.title">
                  <div>
                    <p v-html="int.text" class="text-font"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="li-m m-index " v-if="index%2!==0">
            <div class="r-g rg wow animate__lightSpeedInLeft">
              <div class="p-r">
                <h2>{{ item.title }}</h2>
                <div class="d-l" v-for="(int) in item.opint" :key="int.title">
                  <div>
                    <p v-html="int.text" class="text-font"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="l-f lf wow animate__lightSpeedInRight">
              <img :src="item.imgs" class="ins" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-bg">
      <div class="dao" data-aos="fade-up"
           data-aos-anchor-placement="center-bottom">
        <div class="ti-ti t-pad">{{ $t(`message.DAOGovernment`) }}</div>
        <p class="pg">{{ $t(`message.PGAllowsWeb3`) }}</p>
        <n-button @click="buyPg('https://w3swap.link/')" round color="#FF7A00" class="PDP pego" ghost text-color="#ffffff" :bordered="false">
          {{ $t(`message.BuyPG`) }}
        </n-button>
        <div class="ti-ti t-pad">{{ $t(`message.partner`) }}</div>
        <div class="partner">
          <img src="@/assets/PEGO/metamask.png" alt="">
          <img src="@/assets/PEGO/bitget.png" alt="">
          <img src="@/assets/PEGO/token-pocket.png" alt="">
          <img src="@/assets/PEGO/coinmarketcap.png" alt="">
        </div>
        <div class="partner">
          <img src="@/assets/PEGO/token-tool.png" alt="">
          <img src="@/assets/PEGO/defillama.png" alt="">
          <img src="@/assets/PEGO/btok.png" style="height: 40px; width: 100px; padding: 0 20px;" alt="">
          <img src="@/assets/PEGO/bnb-chain.png" alt="">
        </div>
        <div class="partner">
          <img src="@/assets/PEGO/shield-security.png" alt="">
          <img src="@/assets/PEGO/lunaray.png" alt="">
          <img src="@/assets/PEGO/beosin.png" alt="">
          <img src="@/assets/PEGO/mexc.png" alt="">
        </div>
        <div class="partner">
          <img src="@/assets/PEGO/lbank.png" alt="">
          <img src="@/assets/PEGO/bitmart.png" alt="">
          <img src="@/assets/PEGO/citex.png" alt="">
          <img src="@/assets/PEGO/ave-ai.png" alt="">
        </div>
        <div class="partner">
          <img src="@/assets/PEGO/alibaba-cloud.png" alt="">
          <img src="@/assets/PEGO/task-on.png" alt="">
          <img src="@/assets/PEGO/okx-logo.png" style="height: 40px; width: 100px; padding: 0 20px;" alt="">
          <img src="@/assets/PEGO/denet.png" alt="">
        </div>
        <div class="partner">
          <img src="@/assets/PEGO/revoke.png" alt="">
          <img src="" alt="">
          <img src="" alt="">
          <img src="" alt="">
        </div>
      </div>
      <foot></foot>
    </div>
  </div>
</template>

<script>
import foot from "@/components/foot";
import {WOW} from 'wowjs'

export default {
  name: "home-01",
  components: {
    foot,
  },
  metaInfo() {
    return {
      title: this.$t("message.homeHeader"),
      meta: [
        {
          name: 'keywords',
          content: this.keyword
        },
        {
          name: 'description',
          content: this.description
        }
      ]
    }

  },
  data() {
    return {
      active: true,
      LoadingShow: false,
      tionShow: '',
      hours: 0,
      Days: 0,
      minutes: 0,

    }
  },
  watch: {
    Days(val) {
      this.Days = val
    }, hours(val) {
      this.hours = val
    }, minutes(val) {
      this.minutes = val
    },
  },
  computed: {
    keyword() {
      // return this.$i18n.locale === "en-US"?this.$t("message.homeHeader"):'PEGO为创造而生'
      return this.$t("message.homeHeader")
    },
    description() {
      return this.$t("message.infrastructure")
    },
    duration() {
      return this.showTime()
    },
    list() {
      return [
        {
          imgs: require('@/assets/PEGO/pos.png'),
          title: this.$t('message.PPOS'),
          opint: [
            {
              text: this.$t('message.EnablesSustainable')
            },
          ]
        },
        {
          imgs: require('@/assets//PEGO/EVM.png'),
          title: this.$t('message.EVMCompatible'),
          opint: [
            {
              title: '',
              text: this.$t('message.CompatibleWith')
            },
          ]
        },
        {
          imgs: require('@/assets/PEGO/NFT.png'),
          title: this.$t('message.NFTPNNIdentityVerificationSystem'),
          opint: [
            {
              title: '',
              text: this.$t('message.EnsuresNetwork')
            },
          ]
        },
        {
          imgs: require('@/assets/PEGO/COM.png'),
          title: this.$t('message.CommunityProposedProposals'),
          opint: [
            {
              title: '',
              text: this.$t('message.ProvidesFlexibility')
            },
          ]
        },
        {
          imgs: require('@/assets/PEGO/AI.png'),
          title: this.$t('message.AIIntegration'),
          opint: [
            {
              title: '',
              text: this.$t('message.IntegratesWith')
            },
          ]
        },
      ]
    }
  },
  mounted() {
    // this.showTime()
    // setInterval(() => {
    //   this.showTime()
    // }, 10000)
    var wow = new WOW({
      boxClass: 'wow',    //需要执行动画元素的Class
      animateClass: 'animated',    //animation.css动画的Class
      offset: 0,    //距离可视区域多少开始执行动画
      mobile: true,    //是否在移动设备执行动画
      live: false    //异步加载的内容是否有效
    })
    wow.init();


  },
  methods: {
    showTime() {
      //设置2020年春节时间
      var newyear = new Date('2023-05-15').getTime();
      //获取当前时间
      var date1 = new Date().getTime();
      //两个日期对象直接相减就能获取两个日期相差的时间戳(以毫秒计，也就是能得出相差多少毫秒)
      var ms = newyear - date1;
      // return ms
      //获取天数
      var day = Math.floor(ms / 1000 / 3600 / 24);
      //获取小时数
      var h = Math.floor(ms % (3600 * 24 * 1000) / 1000 / 3600);
      //获取分钟数
      var m = Math.floor(ms % (3600 * 24 * 1000) / 1000 % 3600 / 60);
      //获取秒数
      // var s = Math.floor(ms % (3600 * 24 * 1000) / 1000 % 3600 % 60);
      this.hours = h < 0 ? 0 : h
      this.minutes = m < 0 ? 0 : m
      this.Days = day < 0 ? 0 : day

    },
    buyPg(url) {
      window.open(url);
    },
    //白皮书
    Dapp() {
      if (this.$i18n.locale !== "zh-CN") {
        window.open(window.location.origin+`/pego/whitepaper_en.pdf`);
      } else {
        window.open(window.location.origin+`/pego/whitepaper_zh-tw.pdf`);
      }
    }
  }
}
</script>
<style lang="less" scoped>
.conts {
  color: #ffffff;
}

.bakg {
  background: url("../assets/PEGO/bag1.svg") no-repeat, url("../assets/PEGO/bag3.svg") left 0 top 1576px no-repeat, url("../assets/PEGO/bag2.svg") right 0% top 507px no-repeat, url("../assets/PEGO/bag4.svg") right top 97% no-repeat;
  padding: 0px 100px 100px;
  background-size: 683px, 239px, 239px, 624px, 206px 712px;

}

.header-one {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 50px 0 175px 0;
  position: relative;

  .head-left {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h1 {
      font-style: normal;
      font-weight: 800 !important;
      font-size: 68px;
      font-family: 'BOLD', sans-serif;
      text-align: left;
      width: 50%;

      .nameColor {
        background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
      }
    }

    .text-dev {
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);

      p {
        font-size: 20px;
        font-family: 'LIGHT', sans-serif;
        animation: lightSpeedInLeft;
        animation-duration: 2s;
      }
    }

    .but-ul {
      padding-top: 30px;

      img {
        margin-right: 10px;
      }

      .buts {
        background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
        border-radius: 33px;
        border: none !important;
        color: #FFFFFF;
        margin-right: 20px;
        font-family: 'RREGULAR', sans-serif;
        font-style: normal;
        font-weight: 400;
        height: 54px;
        line-height: 54px;
        min-height: 15PX;
        font-size: 16px !important;
        padding: 20px 20px;
        box-sizing: border-box;
      }

      button:active {
        animation: zoomOut; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 0.5s;
      }

      .but-plain {
        border-radius: 33px;
        background: transparent;
        color: #FFFFFF;
        font-family: 'RREGULAR', sans-serif;
        font-style: normal;
        font-weight: 400;
        height: 54px;
        line-height: 54px !important;
        min-height: 15PX;
        padding: 20px 30px;
        box-sizing: border-box;
        font-size: 16px !important;
        overflow: hidden;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
        outline: 1px solid rgba(255, 255, 255, 0.5);
        //outline-color: rgba(255, 255, 255, 0.5);
        outline-offset: 0px;
        text-shadow: none;
        -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        margin-right: 20px;
      }

      .but-plain:after {
        content: '';
        display: block;
        position: absolute;
        top: -36px;
        left: -100px;
        background: white;
        width: 50px;
        height: 125px;
        opacity: 20%;
        transform: rotate(-45deg);
      }

      .but-plain:hover {
        border: 1px solid;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
        0 0 20px #FFB800;
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 20px;
        text-shadow: 1px 1px 2px #427388;
      }

      .but-plain:hover:after {
        left: 120%;
        transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
        -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
      }
    }
  }
}

.head-2 {
  background: linear-gradient(180deg, rgba(56, 63, 70, 0.7) 0%, rgba(33, 37, 45, 0.7) 129.36%);
  backdrop-filter: blur(3.5px);
  padding: 20px;
  border-radius: 15px;
  animation: lightSpeedInLeft;
  animation-duration: 2s;

  .head-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    font-family: 'SEMIBOLD', sans-serif;
  }

  .head-row {
    display: flex;
    align-items: center;
    justify-content: center;

    .head-li {
      width: 100px;
      font-size: 14px;
      font-family: "RREGULAR", sans-serif;

      .t-t {
        font-weight: 700;
        font-size: 48px;
        font-family: "RBOLD", sans-serif;
      }
    }
  }
}
</style>
<style scoped lang="less">
h2 {
  font-size: 30px !important;
  font-family: "SEMIBOLD", sans-serif;
}

h6 {
  font-weight: 700;
  font-size: 42px;
  //line-height: 134%;
  background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-family: "BOLD", sans-serif;
  display: inline-block;

}

.ant {

}

.li-m {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 376px;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;

  .l-f {
    width: 36%;
    text-align: left;
    box-sizing: border-box;

    .imgs {
      object-fit: contain;
      height: 250px;
      width: 250px;
    }
  }

  .lf {
    text-align: right;
  }

  .r-g {
    text-align: left;

    .d-l {
      width: 586px;
      //min-width: 300px;
    }

    p {
      max-height: 200px;
      overflow: auto;
      font-size: 14px;
    }
  }

  .rg {
    display: flex;
    padding-right: 50px;
    box-sizing: border-box;

    .p-r {
      display: inline-block;
    }
  }
}

.m-index {
  position: relative;
  justify-content: flex-start;
  padding-left: 140px;


}

.text-font {
  font-weight: 300;
  font-size: 20px !important;
  color: #EAF4FF;
  font-family: "LIGHT", sans-serif;
}

.ins {
  width: 200px;
  height: 200px;
}

.fd {
  width: 371px;
  height: 38px;
  margin-top: 20px;
  margin-left: 18%;
}

.foot-bg {
  background: url("../assets/PEGO/bgblur.png") no-repeat right 30px bottom 0;
  background-size: 672px;
}

.dao {
  background: linear-gradient(132.39deg, #313539 6.54%, #22262B 30.66%, rgba(32, 35, 38, 0) 45.46%);
  padding: 120px 120px 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;


  .pego {
    background: linear-gradient(90deg, #FF7A00 0%, #FFB800 100%);
    border-radius: 33px;
    border: none !important;
    color: #ffffff;
    font-weight: 800 !important;
    font-style: normal;
    margin: 60px;
    padding: 0 60px;
    height: 63px;
    line-height: 63px;
    font-size: 20px !important;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    font-family: 'RREGULAR';
  }
.t-t{
  padding-top: 50px;
}
  .pg {
    width: 730px;
  }
}

.t-pad {
  font-weight: 700;
  font-size: 42px;
  background: linear-gradient(90deg, #FA7878 33.19%, #FFDF6F 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-family: "BOLD", sans-serif;
  display: inline-block;
  //line-height: 100px;
  //margin-bottom: 10px;
}
</style>
<style lang="less" scoped>
.dist {
  position: absolute;
  right: 0;
  top: 50px;
  padding-top: 260px;
  width: 500px;
}

.abs-img {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
  z-index: 30;
  mix-blend-mode: overlay;

  img {
    position: absolute;
    bottom: 100px;
    object-fit: contain;
    display: none;
  }

  img:nth-child(1) {
    display: inline;
    left: 150px;
    width: 25px;
    height: 26px;
    animation: pao1 10s linear infinite, direciton1 5s ease-in-out infinite alternate;
    @keyframes pao1 {
      0% {
        bottom: 100px;
        transform: scale(0.5);
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
        bottom: 400px;
      }
    }
    @keyframes direciton1 {
      20% {
        left: 170px;
      }
      100% {
        left: 150px;
      }
    }
  }

  img:nth-child(2) {
    display: inline;
    left: 185px;
    z-index: 30;
    width: 17px;
    height: 17px;
    animation: pao2 12s linear infinite, direciton2 5s ease-in-out infinite alternate;
    @keyframes pao2 {
      0% {
        bottom: 100px;
        transform: scale(0.5);
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
        bottom: 300px;
      }
    }
    @keyframes direciton2 {
      20% {
        left: 185px;
      }
      100% {
        left: 170px;
      }
    }
  }

  img:nth-child(3) {
    display: inline;
    left: 215px;
    width: 18px;
    height: 17px;
    animation: pao3 14s linear infinite, direciton3 5s ease-in-out infinite alternate;
    @keyframes pao3 {
      0% {
        bottom: 100px;
        transform: scale(0.5);
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
        bottom: 500px;
      }
    }
    @keyframes direciton3 {
      20% {
        left: 215px;
      }
      100% {
        left: 205px;
      }
    }
  }

  img:nth-child(4) {
    display: inline;
    left: 245px;
    width: 20px;
    height: 21px;
    animation: pao4 16s linear infinite, direciton4 5s ease-in-out infinite alternate;
    @keyframes pao4 {
      0% {
        bottom: 100px;
        transform: scale(0.5);
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
        bottom: 300px;
      }
    }
    @keyframes direciton4 {
      20% {
        left: 245px;
      }
      100% {
        left: 285px;
      }
    }
  }

  img:nth-child(5) {
    display: inline;
    left: 275px;
    width: 18px;
    height: 15px;
    animation: pao5 18s linear infinite, direciton5 5s ease-in-out infinite alternate;
    @keyframes pao5 {
      0% {
        bottom: 100px;
        transform: scale(0.5);
      }
      80% {
        transform: scale(1);
      }
      100% {
        bottom: 200px;
        transform: scale(1);
      }
    }
    @keyframes direciton5 {
      20% {
        left: 275px;
      }
      100% {
        left: 245px;
      }
    }
  }

  img:nth-child(6) {
    display: inline;
    left: 305px;
    width: 22px;
    height: 21px;
    animation: pao6 20s linear infinite, direciton6 5s ease-in-out infinite alternate;
    @keyframes pao6 {
      0% {
        bottom: 100px;
        transform: scale(0.5);
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
        bottom: 450px;

      }
    }
    @keyframes direciton6 {
      20% {
        left: 305px;
      }
      100% {
        left: 285px;
      }
    }
  }

  img:nth-child(7) {
    display: inline;
    left: 335px;
    width: 24px;
    height: 27px;
    animation: pao7 22s linear infinite, direciton7 5s ease-in-out infinite alternate;
    @keyframes pao7 {
      0% {
        bottom: 100px;
        transform: scale(0.5);
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
        bottom: 350px;

      }
    }
    @keyframes direciton7 {
      20% {
        left: 335px;
      }
      100% {
        left: 315px;
      }
    }
  }
}

.ho-li {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: -230px;
  z-index: 4;

  .ho2 {
    width: 73.44px;
    height: 70.52px;
    animation-name: beat2;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-play-state: running;
    @keyframes beat2 {
      0% {
        transform: rotate3d(1, 1, 1, 45deg);
      }
      100% {
        transform: rotate3d(0);
      }
    }
  }

  .ho1 {
    width: 113.8px;
    height: 130.38px;
    animation-name: beats;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-play-state: running;
    @keyframes beats {
      0% {
        margin-top: 10px;
        transform: scale(1);
      }
      100% {
        margin-top: 20px;
        transform: scale(0.9);
      }
    }
  }
}

@a: 160px; // 椭圆x轴半径(长半径)
@b: 70px; // 椭圆y轴半径(短半径)
@s: 40; // 坐标点的数目(数目越大，动画越精细)
@ss: 100; // 坐标点的数目(数目越大，动画越精细)
.dis {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bott {
  background: url("../assets/PEGO/test/but.png") no-repeat right 0%;
  background-size: 100%;
  display: flex;
  align-items: flex-start;
  height: 154px;
  width: 330px;
  margin-top: 35px;
  padding: 10px 60px;
  position: relative;
  box-sizing: border-box;

  .b-img {
    width: 136px;
    height: 80px;
  }

  .b-img:nth-child(2) {
    position: absolute;
    right: 50px;
    top: 30px;
  }
}

.bott:before {
  content: '';
  position: absolute;
  height: 114px;
  width: 266px;
  background: radial-gradient(50% 49.99% at 50% 49.95%, #F5B4C4 0%, #F2B2C2 22%, #EAACBB 34%, #DCA1B0 45%, #C893A0 54%, #AE7F8B 62%,);
  background-blend-mode: multiply;
  mix-blend-mode: overlay;
  border-radius: 50%;
  left: 31px;
  top: 5px;
  transform: rotate(-1deg);
  z-index: 2;
}

.bott:after {
  content: '';
  position: absolute;
  height: 114px;
  width: 330px;
  left: 0;
  bottom: -30px;
  background: radial-gradient(50% 49.56% at 50% 48.2%, #000034 13%, #010b15 46%, transparent 100%);
  border-radius: 50%;
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
  z-index: -3;
}

.dot {
  width: 10px;
  height: 10px;
  left: @a - 5px;
  top: @b - 5px;
  position: absolute;
  animation: sca 2s linear infinite alternate running, move 10s linear infinite;
}

.dots {
  width: 30px;
  height: 30px;
  left: @a - 15px;
  top: @b  - 15px;
  position: absolute;
  animation: sca 2s linear infinite alternate running, moves 10s linear infinite;
}

.dots3 {
  width: 15px;
  height: 15px;
  left: @a - 12.5px;
  top: @b  - 12.5px;
  position: absolute;
  animation: sca 2s linear infinite alternate running, move4 10s linear infinite;
}

.dots4 {
  width: 15px;
  height: 15px;
  left: @a - 12.5px;
  top: @b  - 12.5px;
  position: absolute;
  animation: sca 2s linear infinite alternate running, move3 10s linear infinite;
}

@keyframes sca {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.ellips {
  width: @a*2;
  height: @b*2;
  border: 1px solid transparent;
  border-radius: 100%;
  position: relative;
  background-clip: border-box;
  background-origin: border-box;
  background-image: url("../assets/PEGO/test/Vector.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  animation: sca 3s linear infinite alternate running;

  .dot {
    width: 66px;
    height: 66px;
    left: @a - 33px;
    top: @b  - 33px;
  }

  .dots {
    width: 100px;
    height: 100px;
    left: @a - 50px;
    top: @b  - 50px;
    position: absolute;
    animation: sca 2s linear infinite alternate running, moves 10s ease infinite;
  }

  .dots3 {
    width: 8px;
    height: 8px;
    left: @a - 4px;
    top: @b  - 4px;
    position: absolute;
    animation: sca 2s linear infinite alternate running, move4 10s linear infinite;
  }

  .dots4 {
    width: 8px;
    height: 8px;
    left: @a - 4px;
    top: @b  - 4px;
    position: absolute;
    animation: sca 2s linear infinite alternate running, move3 10s linear infinite;
  }


}

.ellips2 {
  width: @a*2;
  height: @b*2;
  border-radius: 100%;
  margin: -80px 0 0 20px;
  transform: scale(1.3, 1.3);
  background-clip: content-box;
  background-origin: content-box;
  background-image: url("../assets/PEGO/test/Vector.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  animation: sca2 3s linear infinite alternate running;

  .dot {
    animation: sca 2s linear infinite alternate running, move 12s linear infinite;
  }

  .dots {
    width: 60px;
    height: 60px;
    left: @a - 30px;
    top: @b  - 30px;
    animation: sca 2s linear infinite alternate running, moves 12s linear infinite;
  }

  .dots3 {
    animation: sca 2s linear infinite alternate running, move4 12s linear infinite;
  }

  .dots4 {
    animation: sca 2s linear infinite alternate running, move3 12s linear infinite;
  }

  @keyframes sca2 {
    0% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1.2);
    }

  }
}

.ellips3 {
  width: @a*2;
  height: @b*2;
  border: 1px solid transparent;
  border-radius: 100%;
  position: relative;
  margin: -270px 0 0 20px;
  transform: scale(0.6, 0.6);
  mix-blend-mode: normal;
  background-blend-mode: multiply;
  background-clip: border-box;
  background-origin: border-box;
  background-image: url("../assets/PEGO/test/Vector.svg");
  background-repeat: no-repeat;
  background-size: @a*2 @b*2;
  z-index: 3;
  animation: sca3 3s linear infinite alternate running;

  .dots {
    width: 150px;
    height: 150px;
    left: @a - 125px;
    top: @b - 125px;
    animation: sca 3s linear infinite alternate running, move5 10s linear infinite;
  }

  @keyframes sca3 {
    0% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(0.5);
    }

  }
}

.loop(@index) when (@index < (@s+1) ) {
  .loop((@index + 1));
  @keyframeSel: @index * 100%/@s;
  @{keyframeSel} {
    transform: translate(@a *cos(360deg/@s*@index),@b*sin(360deg/@s*@index),);
  }
}

@keyframes move {
  .loop(0);
}

.loops(@index) when (@index < (@ss+1) ) {
  .loops((@index + 1));
  @keyframeSel: @index * 100%/@ss;
  @{keyframeSel} {
    transform: translate(-@a*cos(360deg/@ss*@index)/1.5, -@b*sin(360deg/@ss*@index)/1.5,);
  }
}

@keyframes moves {
  .loops(0);
}

.loop3(@index) when (@index < (@ss+1) ) {
  .loop3((@index + 1));
  @keyframeSel: @index * 100%/@ss;
  @{keyframeSel} {
    transform: translate(@a*cos(360deg/@ss*@index+100), @b*sin(360deg/@ss*@index+100),);
  }
}

@keyframes move3 {
  .loop3(0);
}

.loop4(@index) when (@index < (@ss+1) ) {
  .loop4((@index + 1));
  @keyframeSel: @index * 100%/@ss;
  @{keyframeSel} {
    transform: translate(-@a*cos(360deg/@ss*@index+100), -@b*sin(360deg/@ss*@index+100),);
  }
}

@keyframes move4 {
  .loop4(0);
}

.loop5(@index) when (@index < (@ss+1) ) {
  .loop5((@index + 1));
  @keyframeSel: @index * 100%/@ss;
  @{keyframeSel} {
    transform: translate(-@a*cos(360deg/@ss*@index+200), -@b*sin(360deg/@ss*@index+200),);
  }
}

@keyframes move5 {
  .loop5(0);
}

.partner {
    padding-top: 0.15972rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    img {
      width: 140px;
      height: auto
    }
}
</style>


