/**
 *@title
 *@author liqingjie
 *@date 2023/2/21 10:54
 */

import Web3 from 'web3';
import dao from '../../abi/Dao.json';
import {daoAdder} from "@/utils/address";
import {getStore} from '@/config/storage'

export const contractAddress = daoAdder;

let contract = null;
const BitKeepProvider = getStore('wallName') === "BitKeep" ? window.bitkeep && window.bitkeep.ethereum : getStore('wallName') === "OKXWallet" ? window.okxwallet : window.ethereum;
const web3js = new Web3(BitKeepProvider);
if (web3js) {
    contract = new web3js.eth.Contract(dao.abi, contractAddress);
}

export const getStakingPgAmount = async (id) => {
    try {
        const c = await contract.methods.getVotingRules(id).call();
        return Web3.utils.fromWei(c['5']).toString();
    } catch (e) {
        console.log(e)
    }

};

// pdp-1
export const getVotingRules = async (type) => {
    try {
        const c = await contract.methods.getVotingRules(type).call();
        const v0 = (Number(c['0']) / 60).toFixed(2).toString();
        const v1 = (Number(c['1']) / 60).toFixed(2).toString();
        const v2 = (Number(c['2']) / 60).toFixed(2).toString();
        const v3 = (Number(c['3']) / 100).toFixed(2).toString();
        const v4 = (Number(c['4']) / 100).toFixed(2).toString();
        const v5 = Web3.utils.fromWei(c['5']).toString();
        c['0'] = c.p0 = v0;
        c['1'] = c.p1 = v1;
        c['2'] = c.p2 = v2;
        c['3'] = c.p3 = v3;
        c['4'] = c.p4 = v4;
        c['5'] = c.p5 = v5;
        return c;
    } catch (e) {
        // console.log(e)
        return {
            pdp1: {
                p0: '-',
                p1: '-',
                p2: '-',
                p3: '-',
                p4: '-',
                p5: '-',
            },
            pdp2: [],
            pdp3: {
                0: '-',
                1: '-',
            },
            pdp4: '-',
            pdp5: [],
            pdp6: '-',
            pdp7: '-',
            pdp8: '-',
            pdp9: '-',
        }
    }

};
export const setVotingRules = async (type, v0, v1, v2, v3, v4, v5) => {
    try {
        const {toBN} = Web3.utils;
        const value1 = Math.floor(v0 * 60);
        const value2 = Math.floor(v1 * 60);
        const value3 = Math.floor(v2 * 60);
        const value4 = v3 * 100;
        const value5 = v4 * 100;
        console.log(type);
        return contract.methods.setVotingRules(type, toBN(value1), toBN(value2), toBN(value3), toBN(value4), toBN(value5), Web3.utils.toWei(v5)).encodeABI();

    } catch (e) {
        console.log(e)
    }
};

// pdp-2
export const supportFarmToken = async () => {
    try {
        return contract.methods.supportFarmToken().call()
    } catch (e) {
        console.log(e)
    }
};
export const setFarmToken = async (address, flag) => {
    try {
        return contract.methods.setFarmToken(address, flag).encodeABI()
    } catch (e) {
        console.log(e)
    }
};

// pdp-3
export const getLockTime = async () => {
    try {
        const c = await contract.methods.getLockTime().call();
        c['0'] = c.pgvtLockTimeInMinutes = (Number(c['0']) / 60).toFixed(2).toString();
        c['1'] = c.validatorLockTimeInMinutes = (Number(c['1']) / 60).toFixed(2).toString();
        c['2'] = c.vtLockTimeInMinutes = (Number(c['2']) / 60).toFixed(2).toString();
        return c;
    } catch (e) {
        console.log(e)
        return {
            0: 0,
            1: 0,
        }

    }
};
export const setLockTime = async (v1, v2, v3) => {
    try {
        const {toBN} = Web3.utils;
        const value1 = Math.floor(v1 * 60);
        const value2 = Math.floor(v2 * 60);
        const value3 = Math.floor(v3 * 60);
        return contract.methods.setLockTime(toBN(value1), toBN(value2), toBN(value3)).encodeABI();
    } catch (e) {
        console.log(e)
    }
};

// pdp-4
export const getDelegatorRewardPercent = async () => {
    try {
        const v = await contract.methods.getDelegatorRewardPercent().call();
        return v / 100;
    } catch (e) {
        console.log(e)
    }
};
export const setDelegatorRewardPercent = async (value) => {
    try {
        const rate = value * 100;
        return contract.methods.setDelegatorRewardPercent(Web3.utils.toBN(rate)).encodeABI();
    } catch (e) {
        console.log(e)
    }
};

// pdp-5
//黑名单验证
export const getIisBlack = async (address) => {
    try {
        return contract.methods.isBlack(address).call()
    } catch (e) {
        return 'null'
    }

};
export const getBlackAddress = async () => {

    try {
        return contract.methods.getBlackAddress().call()
    } catch (e) {
        console.log(e)
    }
};
export const setBlackAddress = async (address, flag) => {
    try {
        return contract.methods.setBlackAddress(address, flag).encodeABI()
    } catch (e) {
        console.log(e)
    }
};

// pdp-6
export const getMintPg = async () => {
    try {
        const n = await contract.methods.getMintPg().call();
        return n['1'];
    } catch (e) {
        console.log(e)
    }
};
export const mintPg = async (address, amount) => {
    try {
        return contract.methods.mintPg(address, Web3.utils.toWei(amount)).encodeABI()
    } catch (e) {
        console.log(e)
    }
};

// pdp-7
export const getBaseGasPrice = async () => {
    try {
        const p = await contract.methods.getBaseGasPrice().call();
        return Web3.utils.fromWei(p);
    } catch (e) {
        console.log(e)
    }
};
export const setBaseGasPrice = async (price) => {
    try {
        return contract.methods.setBaseGasPrice(Web3.utils.toWei(price)).encodeABI();
    } catch (e) {
        console.log(e)
    }
}

// pdp-8
export const getBlockRewards = async () => {
    try {
        const p = await contract.methods.getBlockRewards().call();
        return Web3.utils.fromWei(p);
    } catch (e) {
        console.log(e)
    }
};
export const setBlockRewards = async (price) => {
    try {
        return contract.methods.setBlockRewards(Web3.utils.toWei(price)).encodeABI();
    } catch (e) {
        console.log(e)
    }
}

// pdp-9
export const getValidatorNum = async () => {
    try {
        return contract.methods.getValidatorNum().call()
    } catch (e) {
        console.log(e)
    }
};
export const setValidatorNum = async (n) => {
    try {
        return contract.methods.setValidatorNum(Web3.utils.toBN(n)).encodeABI();
    } catch (e) {
        console.log(e)
    }
}
